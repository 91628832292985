import { Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconView from "@mui/icons-material/RemoveRedEye";
import {
  customReadRequest,
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import { ArchiveButtons, MachineForm } from "components";

const MachinesUpdatePage = (props) => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(state, "machines", params.id),
    }),
    shallowEqual
  );

  const { id, name, archived } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            {`${name} Configuration `}{" "}
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
        action={
          <Fragment>
            <ArchiveButtons resource="machines" id={id} archived={archived} />
            <Button
              startIcon={<IconView />}
              component={Link}
              to={`/machines/${id}`}
            >
              Details
            </Button>
          </Fragment>
        }
      />
      <Divider />
      <MachineForm id={id} />
    </Card>
  );
};

MachinesUpdatePage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("machines", params.id)),
    store.dispatch(resourceListReadRequest("kycproviders")),
    store.dispatch(customReadRequest("cryptocurrencies/list")),
    hasPermission("kioskversions", "GET")
      ? store.dispatch(customReadRequest("kioskversions"))
      : Promise.resolve([]),
  ]);
};

export default MachinesUpdatePage;
