import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { basename } from "config";
import {
  Init,
  Notifications,
  PendingNavDataLoader,
  PrivateRoutes,
} from "components";
import theme from "components/themes/default";
import routes from "../routes";
import { ingectProgessStyles } from "./common/PendingNavDataLoader";
import store from "../store";

const App = () => (
  <Provider store={store}>
    <BrowserRouter basename={basename}>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <CssBaseline />
            <Init routes={routes}>
              <PrivateRoutes routes={routes}>
                <PendingNavDataLoader routes={routes} />
              </PrivateRoutes>
            </Init>
            <Notifications />
          </LocalizationProvider>
          {ingectProgessStyles}
        </MuiThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
