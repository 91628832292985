import * as React from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import MuiLink from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconPrint from "@mui/icons-material/Print";
import { resourceListReadRequest } from "store/actions";
import { fromResource } from "store/selectors";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const StyledTable = styled(Table)(({ theme }) => ({
  "& tbody tr:hover td": {
    backgroundColor: theme.palette.action.hover,
    [`&:hover .label`]: {
      backgroundColor: "transparent",
    },
  },
  [`& tbody`]: {
    [`&:hover .label`]: {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const PerformanceReportPage = () => {
  const printRef = React.useRef();

  const { performanceReport } = useSelector(
    (state = {}) => ({
      performanceReport: fromResource.getList(state, "performance"),
    }),
    shallowEqual
  );

  const totals = React.useMemo(() => {
    const totals = [];
    performanceReport.forEach(({ report }) => {
      report.forEach(({ cryptoCurrencyAmount }, index) => {
        if (!totals[index]) totals[index] = {};
        Object.keys(cryptoCurrencyAmount).forEach((cryptoCurrency) => {
          if (!totals[index][cryptoCurrency]) totals[index][cryptoCurrency] = 0;
          totals[index][cryptoCurrency] += cryptoCurrencyAmount[cryptoCurrency];
          totals[index][cryptoCurrency] = Number.parseFloat(
            totals[index][cryptoCurrency].toFixed(8)
          );
        });
      });
    });
    performanceReport.forEach(({ report }) => {
      report.forEach(({ fiatCurrencyAmount }, index) => {
        if (!totals[index]) totals[index] = {};
        Object.keys(fiatCurrencyAmount).forEach((cryptoCurrency) => {
          if (!totals[index][cryptoCurrency]) totals[index][cryptoCurrency] = 0;
          totals[index][cryptoCurrency] += fiatCurrencyAmount[cryptoCurrency];
          totals[index][cryptoCurrency] = Number.parseFloat(
            totals[index][cryptoCurrency].toFixed(8)
          );
        });
      });
    });

    return totals;
  }, [performanceReport]);

  return (
    <Card id="printPaper" ref={printRef}>
      <CardHeader
        sx={{
          margin: "-3px 0",
          "@media print": {
            display: "none",
          },
        }}
        title={
          <React.Fragment>
            Perfomance Report
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >
              {moment(Date.now()).format("L")}
            </Typography>
          </React.Fragment>
        }
        action={
          <ReactToPrint
            trigger={() => <Button startIcon={<IconPrint />}>Print</Button>}
            content={() => printRef.current}
          />
        }
      />
      <Divider />
      <div>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "31%" }}>ATM</TableCell>
              <TableCell sx={{ width: "1%" }}>Currency</TableCell>
              <TableCell sx={{ width: "17%" }}>24 Hrs</TableCell>
              <TableCell sx={{ width: "17%" }}>7 Days</TableCell>
              <TableCell sx={{ width: "17%" }}>1 Month</TableCell>
              <TableCell sx={{ width: "17%" }}>Total</TableCell>
            </TableRow>
          </TableHead>
          {performanceReport.map(({ atmId, atmName, report }) => {
            const dayReport = report.find(({ period }) => period === "day");
            const weekReport = report.find(({ period }) => period === "week");
            const monthReport = report.find(({ period }) => period === "month");
            const totalReport = report.find(({ period }) => period === "total");
            const { cryptoCurrencyAmount, fiatCurrencyAmount } = totalReport;
            const rows = [].concat(
              Object.keys(cryptoCurrencyAmount),
              Object.keys(fiatCurrencyAmount)
            );

            return (
              <TableBody key={atmId}>
                {rows.map((currency, index) => (
                  <TableRow key={currency}>
                    {index === 0 && (
                      <TableCell rowSpan={rows.length} className="label">
                        <MuiLink
                          component={Link}
                          to={`/machines/${atmId}`}
                          underline="hover"
                        >
                          {atmName}
                        </MuiLink>
                      </TableCell>
                    )}
                    <TableCell>{currency}</TableCell>
                    <TableCell>
                      {dayReport.cryptoCurrencyAmount[currency] ||
                        dayReport.fiatCurrencyAmount[currency] ||
                        0}
                    </TableCell>
                    <TableCell>
                      {weekReport.cryptoCurrencyAmount[currency] ||
                        weekReport.fiatCurrencyAmount[currency] ||
                        0}
                    </TableCell>
                    <TableCell>
                      {monthReport.cryptoCurrencyAmount[currency] ||
                        monthReport.fiatCurrencyAmount[currency] ||
                        0}
                    </TableCell>
                    <TableCell>
                      {totalReport.cryptoCurrencyAmount[currency] ||
                        totalReport.fiatCurrencyAmount[currency] ||
                        0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            );
          })}
          <TableBody>
            {Object.keys(totals[3]).map((currency, index) => (
              <TableRow key={currency}>
                {index === 0 && (
                  <TableCell
                    rowSpan={Object.keys(totals[3]).length}
                    className="label"
                  >
                    <b>Totals</b>
                  </TableCell>
                )}
                <TableCell>{currency}</TableCell>
                <TableCell>
                  {totals[0][currency] || 0}
                  {totals[0][currency] >= totals[1][currency] / 7 && (
                    <ArrowUpward color="primary" />
                  )}
                  {totals[0][currency] < totals[1][currency] / 7 && (
                    <ArrowDownward color="secondary" />
                  )}
                </TableCell>
                <TableCell>
                  {totals[1][currency] || 0}
                  {totals[1][currency] >= totals[2][currency] / 4 && (
                    <ArrowUpward color="primary" />
                  )}
                  {totals[1][currency] < totals[2][currency] / 4 && (
                    <ArrowDownward color="secondary" />
                  )}
                </TableCell>
                <TableCell>{totals[2][currency] || 0}</TableCell>
                <TableCell>{totals[3][currency] || 0}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </div>
    </Card>
  );
};

PerformanceReportPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(resourceListReadRequest("performance", { ...query })),
  ]);
};

export default PerformanceReportPage;
