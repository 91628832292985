import { useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import {
  resourceListReadRequest,
  resourceDetailReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { ExpenseLogsForm } from "components";

const ExpenseLogsUpdatePage = () => {
  const params = useParams();

  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "expenselogs",
        params.id
      ),
    }),
    shallowEqual
  );

  const { id } = detail;

  return (
    <Card elevation={0}>
      <CardHeader title="Update Payment" />
      <Divider />
      <ExpenseLogsForm id={id} />
    </Card>
  );
};

ExpenseLogsUpdatePage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("expenselogs", params.id)),
    store.dispatch(resourceListReadRequest("expenses", { _limit: 1000 })),
  ]);
};

export default ExpenseLogsUpdatePage;
