import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Form, Field } from "react-final-form";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "mui-rff";
import {
  createValidator,
  required,
  maxValue,
  minValue,
  float,
} from "services/validation";
import { handleError } from "services/helpers";
import { notificationSend, resourceCreateRequest } from "store/actions";
import { fromEntities, fromCustom } from "store/selectors";

const StyledLinearProgress = styled(LinearProgress)({
  borderRadius: "2px 2px 0 0",
  overflow: "hidden",
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 2,
});

const StyledOverlay = styled("div")({
  backgroundColor: "rgba(255, 255, 255, 0.38)",
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 1,
});

const validate = ({ amount }) =>
  createValidator({
    amount: [required, float, minValue(0), maxValue(amount)],
  });

const LoansForm = ({ id, ...props }) => {
  const { handleDialogClose, open } = props;

  const { initialValues, walletWhiteList } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "loans/available", id),
      walletWhiteList: fromCustom.get(state, "loans/walletwhitelist"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const onSubmit = React.useCallback(
    (values) =>
      dispatch(resourceCreateRequest("loans", values))
        .then(() => {
          handleDialogClose();
          dispatch(
            notificationSend("Borrowed successfully", { variant: "success" })
          );
        })
        .catch((error) => {
          dispatch(notificationSend("Failed borrow", { variant: "error" }));
          return handleError(error);
        }),
    [dispatch, handleDialogClose]
  );

  return (
    <Dialog maxWidth="sm" open={open} fullWidth onClose={handleDialogClose}>
      <Form
        initialValues={{
          ...initialValues,
          address: walletWhiteList[0].address,
        }}
        onSubmit={onSubmit}
        validate={validate(initialValues)}
        render={({
          dirtySinceLastSubmit,
          handleSubmit,
          hasSubmitErrors,
          submitError,
          submitting,
          valid,
        }) => (
          <form onSubmit={handleSubmit}>
            {submitting && <StyledLinearProgress />}
            {submitting && <StyledOverlay />}
            <DialogTitle>How much do you want to borrow?</DialogTitle>
            <DialogContent>
              {hasSubmitErrors && (
                <Typography color="error">{submitError}</Typography>
              )}
              <Field
                name="ruleId"
                value={initialValues.id}
                component="input"
                type="hidden"
              />
              <TextField
                name="amount"
                label="Amount"
                type="text"
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={{ inputMode: "decimal" }}
              />
              <TextField
                name="address"
                label="Wallet"
                variant="outlined"
                margin="normal"
                fullWidth
                required
                select
              >
                {walletWhiteList.map(({ id, name, address }) => (
                  <MenuItem
                    key={id}
                    value={address}
                  >{`${name} (${address})`}</MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button
                type="submit"
                color="primary"
                autoFocus
                disabled={submitting || (!valid && !dirtySinceLastSubmit)}
              >
                Borrow
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

LoansForm.propTypes = {
  handleDialogClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default LoansForm;
