import { Fragment, useState, useMemo } from "react";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Checkboxes, TextField } from "mui-rff";
import { handleError, trim } from "services/helpers";
import {
  createValidator,
  required,
  boolean,
  integer,
} from "services/validation";
import {
  notificationSend,
  resourceCreateRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import { DiffBlock, Confirmation, ReadonlyTextField } from "components";

const validate = createValidator({
  name: [required],
  active: [boolean],
});

const ExchangeForm = ({ id }) => {
  const { initialValues = { active: true }, supportedExchanges } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "exchanges", id),
      supportedExchanges: fromCustom.get(state, "exchanges/available") || [],
    }),
    shallowEqual
  );

  const [selectedExchange, setExchange] = useState(
    supportedExchanges.find((item) => item.name === initialValues.name) || {
      pairs: [],
    }
  );

  const newExchange = !initialValues.id;

  const validateNew = useMemo(() => {
    const validator = { name: [required], active: [boolean] };
    if (selectedExchange && selectedExchange.name === "Blockfills") {
      validator["options.host"] = [required];
      validator["options.port"] = [required, integer];
      validator["options.username"] = [required];
      validator["options.password"] = [required];
      validator["options.targetCompId"] = [required];
      validator["options.senderCompId"] = [required];
    } else {
      validator.apiKey = [required];
      validator.secret = [required];
    }
    return createValidator(validator);
  }, [selectedExchange]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    const data = newExchange
      ? { ...values }
      : { active: values.active, pair: values.pair };
    let action = resourceCreateRequest("exchanges", data);
    if (id) action = resourceUpdateRequest("exchanges", id, data);

    return dispatch(action)
      .then((resultId) => {
        dispatch(
          notificationSend(
            `Exchange ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        if (!id) navigate(`/settings/exchanges/${resultId}/update`);
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} exchange`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  const handleExchangeSelect = (event) => {
    const { value } = event.target;
    const exchange = supportedExchanges.find((item) => item.name === value);
    setExchange(exchange);
  };

  if (selectedExchange) {
    initialValues.name = selectedExchange.name;
    initialValues.pair = selectedExchange.pairs[0];
    if (selectedExchange.name === "Blockfills" && newExchange) {
      delete initialValues.apiKey;
      delete initialValues.secret;
      initialValues.options = {
        host: "fix.blockfills.com",
        port: "",
        targetCompId: "BLOCKFILLS",
        senderCompId: "",
        username: "",
        password: "",
      };
    }
  }

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={newExchange ? validateNew : validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid item xs={6}>
                        <TextField
                          name="name"
                          label="Select Exchange"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          select
                          disabled={!newExchange}
                          onChange={handleExchangeSelect}
                        >
                          {supportedExchanges.map(({ name }) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Checkboxes
                        name="active"
                        data={{ label: "Active", value: "active" }}
                      />
                      <Grid item xs={2}>
                        <TextField
                          name="pair"
                          label="Select Pair"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          select
                          disabled={selectedExchange.pairs.length < 2}
                        >
                          {selectedExchange.pairs.map((name) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      {selectedExchange.name !== "Blockfills" && (
                        <Grid item xs={6}>
                          <TextField
                            name="apiKey"
                            label="API key"
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            disabled={!newExchange}
                            autoComplete="off"
                            fieldProps={{ format: trim }}
                          />
                          <TextField
                            name="secret"
                            label="API secret"
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required
                            disabled={!newExchange}
                            autoComplete="off"
                            fieldProps={{ format: trim }}
                          />
                        </Grid>
                      )}
                      {selectedExchange.name === "Blockfills" && (
                        <Fragment>
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={4}>
                              <TextField
                                name="options.host"
                                label="Host"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                disabled={!newExchange}
                                fieldProps={{ format: trim }}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                name="options.port"
                                label="Port"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                disabled={!newExchange}
                                fieldProps={{ format: trim }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                name="options.senderCompId"
                                label="Client Comp Id"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                disabled={!newExchange}
                                fieldProps={{ format: trim }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                name="options.targetCompId"
                                label="Blockfills Comp Id"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                disabled={!newExchange}
                                fieldProps={{ format: trim }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                name="options.username"
                                label="Username"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                disabled={!newExchange}
                                autoComplete="off"
                                fieldProps={{ format: trim }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                name="options.password"
                                label="Password"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                disabled={!newExchange}
                                autoComplete="off"
                                fieldProps={{ format: trim }}
                              />
                            </Grid>
                          </Grid>
                        </Fragment>
                      )}
                    </Grid>
                    {!newExchange && (
                      <Grid item xs={12} md={6}>
                        <ReadonlyTextField
                          label="Created At"
                          value={moment(initialValues.createdAt).format(
                            "L, LTS"
                          )}
                          fullWidth
                        />
                        <ReadonlyTextField
                          label="Updated At"
                          value={moment(initialValues.updatedAt).format(
                            "L, LTS"
                          )}
                          fullWidth
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock
                      {...{
                        initialValues: {
                          ...initialValues,
                        },
                        values,
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

export default ExchangeForm;
