import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import copy from "copy-to-clipboard";
import orderBy from "lodash/orderBy";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Chart } from "react-google-charts";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import MuiTextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconKey from "@mui/icons-material/VpnKey";
import IconLogs from "@mui/icons-material/Dvr";
import IconOrders from "@mui/icons-material/InsertDriveFile";
import IconRefresh from "@mui/icons-material/Refresh";
import IconSettings from "@mui/icons-material/Settings";
import IconEvent from "@mui/icons-material/Event";
import IconSession from "@mui/icons-material/Web";
import IconCopy from "@mui/icons-material/FileCopy";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import api from "api";
import {
  ArchiveButtons,
  Label,
  ReadonlyTextField,
  ShortCard,
  IconSmartEmpties,
  NotesList,
} from "components";
import { Box } from "@mui/system";

const texts = {
  getting: {
    title: "Getting Machine Token",
    message: "",
  },
  got: {
    title: "Copy Machine Token",
    message: "",
  },
  getError: {
    title: "Error",
    messgae: "Error while getting token",
  },
  refresh: {
    title: "Refresh Machine Token?",
    message:
      "This action will wipe the machines access key and will generate a new one",
  },
  refreshing: {
    title: "Refreshing Machine Token",
    message: "",
  },
  refreshed: {
    title: "Copy New Machine Token",
    message: "",
  },
  refreshError: {
    title: "Error",
    messgae: "Error while refreshing token",
  },
};

const getStats = (systemInformation) => {
  const stat = [];

  stat.push(["Date", "CPU %", "MEM %"]);

  stat.push(["", 0, 0]);

  if (Array.isArray(systemInformation) && systemInformation.length > 0) {
    // get latest hearbeat date
    const latestDate = moment(
      systemInformation[systemInformation.length - 1].timestamp
    )
      .startOf("minute")
      .unix();
    // start date can be only 2 hour old
    const startDate = moment().subtract(2, "hours").startOf("minute").unix();
    const currentDate = moment().startOf("minute").unix();

    // if we have system data then is not older then 2 hours
    if (latestDate >= startDate) {
      const si = systemInformation.map(({ timestamp, ...rest }) => ({
        ...rest,
        timestamp: moment(timestamp).startOf("minute").unix(),
      }));

      for (let time = startDate; time <= currentDate; time += 60) {
        const item = si.find(({ timestamp }) => timestamp === time);

        if (item) {
          const { memoryUsage } = item;
          const mem =
            ((memoryUsage.total - memoryUsage.free) / memoryUsage.total) * 100;

          stat.push([moment.unix(time).format("hh:mm A"), item.cpuUsage, mem]);
        } else {
          stat.push([moment.unix(time).format("hh:mm A"), 0, 0]);
        }
      }
    }
  }

  return stat;
};

const getTally = (machineState) => {
  let tally = [];

  const { recycler: recyclerNotes, cashboxNotes } = machineState || {};

  if (Array.isArray(recyclerNotes))
    recyclerNotes.forEach(({ Count, Note }) => {
      if (Count > 0) {
        tally.push({
          note: Note,
          recycler: Count,
          cashbox: 0,
          total: Count,
        });
      }
    });
  if (Array.isArray(cashboxNotes))
    cashboxNotes.forEach(({ count, denomination }) => {
      const index = tally.findIndex(({ note }) => note === denomination);
      if (index !== -1) {
        tally[index] = {
          ...tally[index],
          cashbox: count,
          total: tally[index].total + count,
        };
      } else {
        tally.push({
          note: denomination,
          recycler: 0,
          cashbox: count,
          total: count,
        });
      }
    });

  tally.sort((a, b) => a.note - b.note);

  return tally;
};

const getStatusColor = (status) => {
  if (/active/i.test(status)) return "success";
  if (/busy/i.test(status)) return "success";
  if (/error/i.test(status)) return "error";
  if (/config/i.test(status)) return "info";
  if (/init/i.test(status)) return "info";
  if (/online/i.test(status)) return "info";
  if (/maintenance/i.test(status)) return "warning";
  return "default";
};

const MachinesReadPage = () => {
  const theme = useTheme();
  const inputRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState("getting");
  const [token, setToken] = React.useState("");

  const params = useParams();

  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(state, "machines", params.id),
    }),
    shallowEqual
  );

  const {
    id,
    aggregatorId,
    archived,
    customData,
    featured,
    fiatCurrency,
    language,
    lastHeartbeat,
    lastSmartEmpty,
    location = {},
    machineState = {},
    mode,
    name,
    statusLog = [],
    rentPayments = [],
  } = detail;

  const {
    address,
    city,
    country,
    latitude,
    longitude,
    state,
    name: contactPerson,
    phone: contactNumber,
    zipCode,
    businessName,
    operationHours,
  } = location || {};

  const {
    cashbox = 0,
    datasetVersion,
    firmwareVersion,
    kioskVersion,
    queueLength = 0,
    orderQueueLength = 0,
    recyclerCash = 0,
    systemInformation = [],
    totalCash = 0,
  } = machineState || {};

  const hasSystemInformation =
    Array.isArray(systemInformation) && systemInformation.length > 0;
  const stats = React.useMemo(
    () => getStats(systemInformation),
    [systemInformation]
  );
  const tally = React.useMemo(() => getTally(machineState), [machineState]);
  let driveList = React.useMemo(() => {
    if (Array.isArray(systemInformation) && systemInformation.length > 0) {
      const { drives } = systemInformation[systemInformation.length - 1];
      return drives || [];
    }
    return [];
  }, [systemInformation]);

  const handleRefreshToken = () => {
    setAction("refresh");
    setOpen(true);
  };

  const handleRefreshTokenRequest = React.useCallback(async () => {
    setAction("refreshing");
    try {
      const data = await api.post("/machines/refreshtoken", { atmId: id });
      setAction("refreshed");
      setToken(data.token);
    } catch (error) {
      setAction("refreshError");
    }
  }, [id]);

  const handleGetMachineToken = React.useCallback(async () => {
    setAction("getting");
    setOpen(true);
    try {
      const data = await api.post("/machines/authenticate", { atmId: id });
      setToken(data.token);
      setAction("got");
    } catch (error) {
      setAction("getError");
    }
  }, [id]);

  const handleToggleDialog = () => {
    setOpen((state) => !state);
  };

  const handleCopy = () => {
    copy(token);
    inputRef.current.select();
  };

  const { statusLogData, colors } = React.useMemo(() => {
    const statusLogData = [["Status", "Start", "End"]];

    const ordered = orderBy(statusLog, ["createdAt"], ["asc"]);

    const statuses = [];

    for (let i = 0; i < ordered.length; i++) {
      const current = ordered[i];
      const next = ordered[i + 1];

      statusLogData.push([
        current.status,
        moment(current.createdAt).valueOf(),
        next ? moment(next.createdAt).valueOf() : Date.now(),
      ]);

      if (statuses.indexOf(current.status) === -1)
        statuses.push(current.status);
    }

    const colors = statuses.map((status) => {
      const color = getStatusColor(status);

      if (color === "default") return theme.palette.grey[500];
      else return theme.palette[color].main;
    });

    return {
      statusLogData,
      colors,
    };
  }, [statusLog, theme.palette]);

  return (
    <React.Fragment>
      <Toolbar
        sx={{ flexWrap: { xs: "wrap", md: "nowrap" }, py: { xs: 1, md: 0 } }}
      >
        <Typography variant="h5">
          {`${name} Details`}
          <Typography
            variant="inherit"
            color="textSecondary"
            display="inline"
          >{` #${id}`}</Typography>
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            mr: -1,
            ml: { xs: -1, md: 0 },
            mt: { xs: 1, md: 0 },
            "> * + *": {
              ml: 1,
            },
          }}
        >
          <ArchiveButtons resource="machines" id={id} archived={archived} />
          {hasPermission("machines", "PUT") && (
            <Button
              startIcon={<IconSettings />}
              component={Link}
              to={`/machines/${id}/update`}
            >
              Configuration
            </Button>
          )}
        </Box>
      </Toolbar>
      <Divider />
      <Container maxWidth="100%" sx={{ py: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" gutterBottom>
                  General
                </Typography>
                <ReadonlyTextField label="ATM id" value={id} fullWidth />
                <ReadonlyTextField label="Name" value={name} fullWidth />
                <ReadonlyTextField
                  label="Language"
                  value={language}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Featured"
                  value={
                    featured ? (
                      <Label color="warning">Yes</Label>
                    ) : (
                      <Label color="info">No</Label>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Archived"
                  value={
                    archived ? (
                      <Label color="error">Yes</Label>
                    ) : (
                      <Label color="info">No</Label>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Coin ATM Radar ID"
                  value={aggregatorId}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" gutterBottom>
                  Information
                </Typography>
                <ReadonlyTextField
                  label="Mode"
                  value={
                    <Label
                      color={clsx({
                        success: mode === 1,
                        warning: mode === 0,
                        info: mode === 2,
                      })}
                      variant="outlined"
                    >
                      {mode === 0 && "maintenance"}
                      {mode === 1 && "active"}
                      {mode === 2 && "configuration"}
                    </Label>
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Status"
                  value={
                    <Label color={getStatusColor(machineState.status)}>
                      {machineState.status}
                    </Label>
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Critical Queue Length"
                  value={`${orderQueueLength}`}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Utility Queue Length"
                  value={`${queueLength}`}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Recycler"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatCurrency,
                  }).format(recyclerCash)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Cashbox"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatCurrency,
                  }).format(cashbox)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Last Heartbeat"
                  value={
                    lastHeartbeat ? (
                      moment(lastHeartbeat).format("L, LTS")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" gutterBottom>
                  Software
                </Typography>
                <ReadonlyTextField
                  label="Kiosk"
                  value={kioskVersion || <i>[missing]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Dataset"
                  value={datasetVersion || <i>[missing]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Firmware"
                  value={firmwareVersion || <i>[missing]</i>}
                  fullWidth
                />
                {hasPermission("machines", "POST") && (
                  <ReadonlyTextField
                    label="Machine Token"
                    value={
                      <ButtonGroup size="small">
                        <Button
                          startIcon={<IconKey />}
                          onClick={handleGetMachineToken}
                        >
                          Get
                        </Button>
                        <Button
                          startIcon={<IconRefresh />}
                          onClick={handleRefreshToken}
                        >
                          Refresh
                        </Button>
                      </ButtonGroup>
                    }
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" gutterBottom>
                  Location
                </Typography>
                <ReadonlyTextField
                  label="Address"
                  value={address || <i>[not set]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="City"
                  value={city || <i>[not set]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="State"
                  value={state || <i>[not set]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Zip Code"
                  value={zipCode || <i>[not set]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Country"
                  value={country || <i>[not set]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Operation Hours"
                  value={operationHours || <i>[not set]</i>}
                  fullWidth
                />
                {latitude && longitude && (
                  <ReadonlyTextField
                    label="Google Maps"
                    value={
                      <a
                        href={`http://maps.google.com/maps?&z=15&mrt=yp&t=h&q=${latitude}+${longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open in new window
                      </a>
                    }
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" gutterBottom>
                  Contacts
                </Typography>
                <ReadonlyTextField
                  label="Business Name"
                  value={businessName || <i>[not set]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Contact Person"
                  value={contactPerson || <i>[not set]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Contact Number"
                  value={contactNumber || <i>[not set]</i>}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                {customData.length > 0 && (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Custom data
                    </Typography>
                    {customData.map(({ key, value }) => (
                      <ReadonlyTextField
                        key={key}
                        label={key}
                        value={value || <i>{`[not set]`}</i>}
                        fullWidth
                      />
                    ))}
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  System
                </Typography>
                {hasSystemInformation && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={8}>
                      <Typography variant="subtitle1">
                        Graph shows the system health from the latest 120
                        heartbeats
                      </Typography>
                      <Card elevation={1}>
                        <CardContent>
                          <Chart
                            width="100%"
                            height={400}
                            chartType="Line"
                            loader={<div>Loading Chart</div>}
                            data={stats}
                            legendToggle
                            options={{
                              legend: "none",
                              vAxis: {
                                viewWindowMode: "maximized",
                                minValue: 0,
                                maxValue: 100,
                                viewWindow: {
                                  min: 0,
                                  max: 100,
                                },
                              },
                            }}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="subtitle1">
                        Drives space information
                      </Typography>
                      {driveList.map((item) => (
                        <ReadonlyTextField
                          key={item.name}
                          label={`Drive ${item.name}`}
                          value={
                            <React.Fragment>
                              <div>{`${item.free}Gb free of ${item.total}Gb`}</div>
                              <LinearProgress
                                variant="determinate"
                                color="primary"
                                value={
                                  ((item.total - item.free) / item.total) * 100
                                }
                              />
                            </React.Fragment>
                          }
                          fullWidth
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
                {!hasSystemInformation && (
                  <i>[Not enough data to display the graph]</i>
                )}
              </Grid>
              {statusLog.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Status Report: Past 30 Days
                  </Typography>
                  <Chart
                    width="100%"
                    style={{ minHeight: 400 }}
                    chartType="Timeline"
                    loader={<div>Loading Chart</div>}
                    data={statusLogData}
                    columns={[
                      { type: "string", id: "Status" },
                      { type: "date", id: "Start" },
                      { type: "date", id: "End" },
                    ]}
                    options={{
                      timeline: { colorByRowLabel: true },
                      alternatingRowStyle: false,
                      colors,
                    }}
                  />
                </Grid>
              )}
              {hasSystemInformation && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Current Tally
                  </Typography>
                  <Table
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                    size="small"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Note</TableCell>
                        <TableCell>Recycler</TableCell>
                        <TableCell>Cashbox</TableCell>
                        <TableCell>Total Notes</TableCell>
                        <TableCell>Total Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tally.map(({ note, cashbox, recycler, total }) => (
                        <TableRow hover key={note}>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: fiatCurrency,
                            }).format(note)}
                          </TableCell>
                          <TableCell>{recycler}</TableCell>
                          <TableCell>{cashbox}</TableCell>
                          <TableCell>{total}</TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: fiatCurrency,
                            }).format(total * note)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={4}>Total Cash</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: fiatCurrency,
                          }).format(totalCash)}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </Grid>
              )}
              {lastSmartEmpty && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Latest smart empty
                  </Typography>
                  <Table
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Operator</TableCell>
                        <TableCell>Emptied Amount</TableCell>
                        <TableCell>Float Amount</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell sx={{ width: "1%" }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {lastSmartEmpty.operatorId || <i>[missing]</i>}
                        </TableCell>
                        <TableCell>{lastSmartEmpty.amountEmptied}</TableCell>
                        <TableCell>{lastSmartEmpty.amountFloat}</TableCell>
                        <TableCell>
                          {moment(lastSmartEmpty.createdAt).format("L, LTS")}
                        </TableCell>
                        <TableCell>
                          <Button
                            component={Link}
                            to={`/smartempties/${lastSmartEmpty.id}`}
                          >
                            Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              )}
              {rentPayments.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Rent payments
                  </Typography>
                  <Table
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Paid For</TableCell>
                        <TableCell>Amount, USD</TableCell>
                        <TableCell>Created At</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rentPayments.map(
                        ({ id, paidFor, amount, createdAt }) => (
                          <TableRow key={id}>
                            <TableCell>{id}</TableCell>
                            <TableCell>
                              {new Date(paidFor).toLocaleString("en", {
                                year: "numeric",
                                month: "long",
                              })}
                            </TableCell>
                            <TableCell>{amount}</TableCell>
                            <TableCell>
                              {new Date(createdAt).toLocaleString("en")}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="machines" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("orders", "GET") && (
                    <Grid item xs={12} md={6} xl={12}>
                      <ShortCard
                        icon={<IconOrders />}
                        to={`/orders?atmId=${id}`}
                        title="Orders"
                        description="View all machine orders"
                      />
                    </Grid>
                  )}
                  {hasPermission("smartempties", "GET") && (
                    <Grid item xs={12} md={6} xl={12}>
                      <ShortCard
                        icon={<IconSmartEmpties />}
                        to={`/smartempties?atmId=${id}`}
                        title="Smart Empties"
                        description="View all machine smart empties"
                      />
                    </Grid>
                  )}
                  {hasPermission("trackingsessions", "GET") && (
                    <Grid item xs={12} md={6} xl={12}>
                      <ShortCard
                        icon={<IconSession />}
                        to={`/sessions?atmId=${id}`}
                        title="Sessions"
                        description="View all machine sessions"
                      />
                    </Grid>
                  )}
                  {hasPermission("useractions", "GET") && (
                    <Grid item xs={12} md={6} xl={12}>
                      <ShortCard
                        icon={<IconLogs />}
                        to={`/changelog?entityType=machines&entityId=${id}`}
                        title="Changelog"
                        description="View machine configuration changes made by users"
                      />
                    </Grid>
                  )}
                  {hasPermission("machineevents", "GET") && (
                    <Grid item xs={12} md={6} xl={12}>
                      <ShortCard
                        icon={<IconEvent />}
                        to={`/machineevents?atmId=${id}`}
                        title="Machine events"
                        description="View all machine events"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleToggleDialog}
          disableEscapeKeyDown
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{texts[action].title}</DialogTitle>
          <DialogContent
            sx={{
              wordWrap: "break-word",
              fontSize: "15px",
            }}
          >
            {!!texts[action] && (
              <Typography>{texts[action].message}</Typography>
            )}
            {(action === "getting" || action === "refreshing") && (
              <center>
                <CircularProgress />
              </center>
            )}
            {(action === "got" || action === "refreshed") && (
              <Tooltip
                disableHoverListener
                disableTouchListener
                disableInteractive
                title="Copied to clipboard"
              >
                <MuiTextField
                  type="text"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  readOnly
                  onClick={handleCopy}
                  inputRef={inputRef}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton color="primary" edge="end" size="large">
                          <IconCopy />
                        </IconButton>
                      </InputAdornment>
                    ),
                    value: token,
                  }}
                />
              </Tooltip>
            )}
          </DialogContent>
          {(action === "got" ||
            action === "refresh" ||
            action === "refreshed") && (
            <DialogActions>
              <Button onClick={handleToggleDialog} color="primary" autoFocus>
                Close
              </Button>
              {action === "refresh" && (
                <Button
                  onClick={handleRefreshTokenRequest}
                  color="primary"
                  autoFocus
                >
                  Refresh anyway
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
      </Container>
    </React.Fragment>
  );
};

MachinesReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("machines", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entityType: "machines",
        entityId: params.id,
      })
    ),
  ]);
};

export default MachinesReadPage;
