import * as React from "react";
import copy from "copy-to-clipboard";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MuiLink from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import IconOpenInNew from "@mui/icons-material/OpenInNew";
import { getBlockchainLink } from "services/helpers";

const StyledLink = styled(MuiLink)(({ nowrap, short }) => ({
  whiteSpace: "normal",
  ...(!short && {
    wordBreak: "break-all",
    whiteSpace: "nowrap",
  }),
  ...(nowrap && {
    whiteSpace: "nowrap",
  }),
}));

const BlockchainLink = (props) => {
  const {
    coinType,
    short = false,
    transaction = "",
    wallet = "",
    value = null,
  } = props;
  const isWallet = Boolean(wallet);

  const type = isWallet ? "address" : "tx";
  let item = isWallet ? wallet : transaction;

  const label = React.useMemo(
    () =>
      short
        ? item
            .replace(/(\n|\r)/gim, "")
            .replace(/^(.{5})(.+?)(.{5})$/, "$1...$3")
        : item.replace(/(\n|\r)/gim, ""),
    [item, short]
  );

  const { blockchainLink, blockcypherLink } = React.useMemo(
    () => ({
      blockchainLink: getBlockchainLink(type, coinType, item),
      blockcypherLink: getBlockchainLink(type, coinType, item, "blockcypher"),
    }),
    [type, coinType, item]
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [copied, setCopied] = React.useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    const { currentTarget } = e;
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = () => {
    setCopied(true);
    setAnchorEl(null);
    copy(item);
  };

  const handleCopyClose = () => {
    setCopied(false);
  };

  return (
    <React.Fragment>
      <StyledLink href="#" onClick={handleOpen} underline="hover">
        {value || label} <IconOpenInNew fontSize="inherit" />
      </StyledLink>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          component="a"
          target="_blank"
          href={blockchainLink}
          onClick={handleClose}
        >
          View on blockchain.com
        </MenuItem>
        <MenuItem
          component="a"
          target="_blank"
          href={blockcypherLink}
          onClick={handleClose}
        >
          View on blockcypher.com
        </MenuItem>
        <MenuItem onClick={handleCopy}>Copy to clipboard</MenuItem>
      </Menu>
      <Snackbar
        autoHideDuration={3000}
        disableWindowBlurListener
        open={copied}
        onClose={handleCopyClose}
        message="Copied successfully"
      />
    </React.Fragment>
  );
};

export default BlockchainLink;
