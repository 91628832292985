import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const ErrorPage = ({ error, code }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Grid item>
        <Typography
          component="h2"
          variant="h1"
          gutterBottom
          sx={{ color: "text.secondary" }}
        >
          {code && (
            <Box
              sx={{
                color: "text.primary",
                fontWeight: 600,
              }}
            >
              {code}
            </Box>
          )}
          {error.replace("Error: ", "").replace(code || "", "")}
        </Typography>
        <Button variant="text" color="primary" href="/">
          Go Home
        </Button>
      </Grid>
    </Grid>
  );
};

ErrorPage.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string.isRequired,
};

export default ErrorPage;
