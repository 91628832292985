import { Fragment } from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import JSONPretty from "react-json-prettify";
import { github } from "react-json-prettify/dist/themes";
import { Label, ReadonlyTextField, NotesList } from "components";

const MachineEventsReadPage = (props) => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "machineevents",
        params.id
      ),
    }),
    shallowEqual
  );

  const handleGetAction = (level) => {
    switch (level.toLowerCase()) {
      case "info":
        return "info";
      case "warn":
      case "debug":
      case "trace":
        return "warning";
      case "error":
        return "error";
      default:
        return "info";
    }
  };

  const {
    id,
    machine,
    message,
    softwareVersion,
    category,
    data,
    level,
    createdAt,
  } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Machine Event
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="Machine"
              value={
                <MuiLink
                  component={Link}
                  to={`/machines/${machine.id}`}
                  underline="hover"
                >
                  {machine.name}
                </MuiLink>
              }
              fullWidth
            />
            <ReadonlyTextField
              label="Software Version"
              value={softwareVersion || <i>[missing]</i>}
              fullWidth
            />
            <ReadonlyTextField label="Message" value={message} fullWidth />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="Level"
              value={
                <Label variant="outlined" color={handleGetAction(level)}>
                  {level}
                </Label>
              }
              fullWidth
            />
            <ReadonlyTextField label="Category" value={category} fullWidth />
            <ReadonlyTextField
              label="Created At"
              value={
                createdAt ? (
                  moment(createdAt).format("L, LTS")
                ) : (
                  <i>[missing]</i>
                )
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="Data"
              value={<JSONPretty theme={github} json={data} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Notes
            </Typography>
            <NotesList entityType="machineevents" entityId={id} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

MachineEventsReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("machineevents", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entityType: "machineevents",
        entityId: params.id,
      })
    ),
  ]);
};

export default MachineEventsReadPage;
