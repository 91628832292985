/* eslint-disable react/jsx-pascal-case */
import { useContext, useEffect, useRef, useState } from "react";
import isEqual from "lodash/isEqual";
import NProgress from "nprogress";
import { useStore } from "react-redux";
import {
  useLocation,
  useNavigate,
  useRoutes,
  UNSAFE_LocationContext,
} from "react-router-dom";
import GlobalStyles from "@mui/material/GlobalStyles";
import fetchData from "services/fetchData";
import { ErrorPage } from "components";

export const ingectProgessStyles = (
  <GlobalStyles
    styles={(theme) => ({
      "#nprogress": {
        pointerEvents: "none",
      },
      "#nprogress .bar": {
        background: theme.palette.secondary.light,
        position: "fixed",
        zIndex: 9999,
        top: 0,
        left: 0,
        width: "100%",
        height: 2,
      },
      "#nprogress .peg": {
        display: "block",
        position: "absolute",
        right: 0,
        width: 100,
        height: "100%",
        boxShadow: `0 0 10px ${theme.palette.secondary.light}, 0 0 5px ${theme.palette.secondary.light}`,
        opacity: 1,
        transform: "rotate(3deg) translate(0px, -4px)",
      },
      "#nprogress .spinner": {
        display: "block",
        position: "fixed",
        zIndex: 9999,
        top: 15,
        right: 15,
      },
      "#nprogress .spinner-icon": {
        width: 18,
        height: 18,
        boxSizing: "border-box",
        border: "solid 2px transparent",
        borderTopColor: "#29d",
        borderLeftColor: "#29d",
        borderRadius: "50%",
        animation: "nprogress-spinner 400ms linear infinite",
      },
      ".nprogress-custom-parent": {
        overflow: "hidden",
        position: "relative",
      },
      ".nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar":
        {
          position: "absolute",
        },
      "@keyframes nprogress-spinner": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(360deg)",
        },
      },
    })}
  />
);

NProgress.configure({
  showSpinner: false,
  speed: 500,
  trickleSpeed: 200,
});

const PendingNavDataLoader = ({ routes }) => {
  const store = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState(location);
  const [error, setError] = useState(null);
  const keyRef = useRef(false);

  const { navigationType } = useContext(UNSAFE_LocationContext);

  const element = useRoutes(routes, previousLocation);

  useEffect(() => {
    const navigated = !isEqual(previousLocation, location);

    if (navigated) {
      NProgress.start();
      keyRef.current = location.key;
      fetchData(routes, location, store)
        .then(() => {
          if (keyRef.current === location.key) {
            if (error) {
              setError(null);
            }
          }
        })
        .catch((error) => {
          if (keyRef.current === location.key) {
            const errorCodeMatch = error
              .toString()
              .match(/401|402|403|404|50\d/m);
            if (errorCodeMatch) {
              const [errorCode] = errorCodeMatch;

              if (errorCode === "401") {
                navigate("/signin");
              } else {
                setError({
                  massage: error.toString(),
                  code: errorCode,
                });
              }
            }
          }
        })
        .finally(() => {
          window.scrollTo(0, 0);
          if (keyRef.current === location.key) {
            NProgress.done();
            setPreviousLocation(location);
          }
        });
    }
  }, [error, location, navigate, previousLocation, routes, store]);

  if (error) {
    return <ErrorPage code={error.code} error={error.message} />;
  }

  // use a controlled <Route> to trick all descendants into
  // rendering the old location
  // TOD
  // return element

  return (
    <UNSAFE_LocationContext.Provider
      value={{ location: previousLocation, navigationType }}
    >
      {element}
    </UNSAFE_LocationContext.Provider>
  );
};

export default PendingNavDataLoader;
