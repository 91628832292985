import { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField } from "mui-rff";
import { handleError, trim } from "services/helpers";
import { notificationSend, resourceUpdateRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { createValidator, required, float } from "services/validation";
import { DiffBlock, Confirmation } from "components";

const validate = createValidator({
  cryptoCurrencySpotPrice: [required, float],
  customerKey: [required],
});

const OrderForm = ({ id }) => {
  const { initialValues } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "orders", id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const onSubmit = (values) => {
    return dispatch(resourceUpdateRequest("orders", id, values))
      .then(() => {
        dispatch(
          notificationSend("Order updated successfully", { variant: "success" })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to update order", { variant: "error" })
        );
        return handleError(error);
      });
  };

  const { coinType, status } = initialValues;

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
              errors,
            }) => (
              <form onSubmit={show(handleSubmit)}>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {(status === "BAD WALLET" || status === "HOLD") && (
                        <TextField
                          name="wallet"
                          label="wallet"
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          fieldProps={{ format: trim, formatOnBlur: true }}
                        />
                      )}
                      <TextField
                        name="cryptoCurrencySpotPrice"
                        label={`${coinType} Spot Price`}
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        inputProps={{ inputMode: "decimal" }}
                      />
                      <TextField
                        name="customerKey"
                        label="customerKey"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

export default OrderForm;
