import * as React from "react";
import * as Yup from "yup";
import "yup-phone";
import getByPath from "lodash/get";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Link from "@mui/material/Link";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import {
  notificationSend,
  customCreateRequest,
  customUpdateRequest,
} from "store/actions";

const steps = [
  "Twilio configuration",
  "Phone number",
  "Verification code",
  "Done",
];

const validationSchema = Yup.object().shape({
  config: Yup.object().shape({
    phone: Yup.string().phone().required(),
    sid: Yup.string().trim().required(),
    token: Yup.string()
      .trim()
      // .matches(
      //   /^[0-9a-z\-_]+?\.[0-9a-z\-_]+?\.([0-9a-z\-_]+)?$/i,
      //   "${path} must be a valid Twilio JWT token"
      // )
      .required(),
  }),
  phone: Yup.string().phone().required(),
  code: Yup.string().min(4),
});

const TwilioForm = (props) => {
  const { initialValues } = props;

  const [activeStep, setActiveStep] = React.useState(
    initialValues.phone && initialValues.sid && initialValues.token ? 3 : 0
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const dispatch = useDispatch();

  const {
    control,
    formState: { isSubmitting, isDirty, isValid, errors, dirtyFields },
    getValues,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      config: initialValues,
    },
    mode: "all",
    reValidateMode: "all",
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });

  const hasValueAndValid = React.useCallback(
    (fields) => {
      for (const field of fields) {
        const hasValue = !!getValues(field);
        const hasError = getByPath(errors, field);
        if ((hasValue && hasError) || !hasValue) {
          return false;
        }
      }
      return true;
    },
    [errors, getValues]
  );

  const watchFields = watch();

  const nextActive = React.useMemo(() => {
    if (activeStep === 0) {
      return hasValueAndValid(["config.phone", "config.sid", "config.token"]);
    }

    if (activeStep === 1) {
      return hasValueAndValid(["phone"]);
    }

    return true;
  }, [activeStep, hasValueAndValid, watchFields]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = React.useCallback(
    async (values) => {
      if (activeStep === 1) {
        try {
          await dispatch(
            customCreateRequest("config/twilio/testmessagesend", values)
          );
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } catch (error) {
          dispatch(
            notificationSend("Failed to create test SMS!", { variant: "error" })
          );
        }
      } else if (activeStep === 2) {
        try {
          await dispatch(
            customCreateRequest("config/twilio/testmessageverify ", {
              code: values.code,
            })
          );
        } catch (error) {
          dispatch(
            notificationSend("Failed to confirm phone number!", {
              variant: "error",
            })
          );

          return;
        }

        try {
          dispatch(customUpdateRequest("config/twilio", { ...values.config }));
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          dispatch(
            notificationSend("Configuration is saved.", {
              variant: "success",
            })
          );
        } catch (error) {
          dispatch(
            notificationSend(
              "Code is valid but failed to save configuration values!",
              {
                variant: "error",
              }
            )
          );
        }
      }
    },
    [activeStep, dispatch]
  );

  const handleReset = () => {
    setActiveStep(0);
    reset({
      config: {
        phone: "",
        sid: "",
        token: "",
      },
      phone: "",
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      autoComplete="off"
      onReset={() => reset()}
    >
      <Grid container>
        <Grid item xs={12} lg={8} xl={6}>
          <CardContent>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>

            {(activeStep === 0 || activeStep === 3) && (
              <React.Fragment>
                {activeStep === 0 && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    Enter Twilio configuration values.
                  </Typography>
                )}
                {activeStep === 3 && (
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    Current configuration values
                  </Typography>
                )}
                <Controller
                  name="config.phone"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Phone"
                      inputRef={ref}
                      margin="normal"
                      required
                      type="text"
                      variant="outlined"
                      disabled={isSubmitting}
                      InputLabelProps={{
                        readOnly: activeStep === 3,
                      }}
                      error={!!error}
                      helperText={
                        !!error
                          ? error.message
                          : "Twilio number from which to originate the call"
                      }
                    />
                  )}
                />
                <Controller
                  name="config.sid"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="SID"
                      inputRef={ref}
                      margin="normal"
                      required
                      type="text"
                      variant="outlined"
                      disabled={isSubmitting}
                      InputLabelProps={{
                        readOnly: activeStep === 3,
                      }}
                      error={!!error}
                      helperText={
                        !!error ? (
                          error.message
                        ) : (
                          <span>
                            {`Account SID, which you can find in your `}
                            <Link
                              href="http://console.twilio.com"
                              target="_blank"
                            >
                              Twilio Console
                            </Link>
                          </span>
                        )
                      }
                    />
                  )}
                />
                <Controller
                  name="config.token"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Token"
                      inputRef={ref}
                      margin="normal"
                      required
                      type="text"
                      variant="outlined"
                      disabled={isSubmitting}
                      InputLabelProps={{
                        readOnly: activeStep === 3,
                      }}
                      error={!!error}
                      helperText={
                        !!error ? (
                          error.message
                        ) : (
                          <span>
                            {`You can create API keys from the `}
                            <Link
                              href="http://console.twilio.com"
                              target="_blank"
                            >
                              Twilio Console
                            </Link>
                          </span>
                        )
                      }
                    />
                  )}
                />
              </React.Fragment>
            )}

            {activeStep === 1 && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Enter a mobile phone number you have access to. This number
                  will be used to test current Twilio configuration.
                </Typography>
                <Controller
                  name="phone"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Phone"
                      inputRef={ref}
                      margin="normal"
                      required
                      type="text"
                      variant="outlined"
                      disabled={isSubmitting}
                      error={!!error}
                      helperText={
                        !!error ? error.message : "Phone number to test with"
                      }
                    />
                  )}
                />
              </React.Fragment>
            )}

            {activeStep === 2 && (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Enter confirmation code you received in order to save Twilio
                  configuration values.
                </Typography>
                <Controller
                  name="code"
                  control={control}
                  render={({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Code"
                      inputRef={ref}
                      margin="normal"
                      required
                      type="text"
                      variant="outlined"
                      disabled={isSubmitting}
                      error={!!error}
                      helperText={!!error ? error.message : "Code from sms"}
                    />
                  )}
                />
              </React.Fragment>
            )}
            {/* {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : ( */}
          </CardContent>

          <CardActions>
            {activeStep !== steps.length && (
              <React.Fragment>
                {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

                {activeStep > 0 && activeStep < steps.length - 1 && (
                  <Button
                    color="inherit"
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    disabled={isSubmitting}
                  >
                    Back
                  </Button>
                )}
                <Box sx={{ flex: "1 1 auto" }} />
                {activeStep === 0 && (
                  <Button
                    onClick={handleNext}
                    disabled={!nextActive || !isDirty}
                  >
                    {"Next"}
                  </Button>
                )}
                {[1, 2].includes(activeStep) && (
                  <Button
                    disabled={
                      isSubmitting ||
                      !isDirty ||
                      !isValid ||
                      (activeStep === 2 && !dirtyFields.code)
                    }
                    type="submit"
                  >
                    {"Next"}
                  </Button>
                )}
                {activeStep === steps.length - 1 && (
                  <Button onClick={handleReset}>Reset</Button>
                )}
              </React.Fragment>
            )}
          </CardActions>
        </Grid>
      </Grid>
    </form>
  );
};

export default TwilioForm;
