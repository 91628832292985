import { matchRoutes } from "react-router-dom";
import { parse } from "qs";

const fetchData = (routes, location, store, callback) => {
  const branch = matchRoutes(routes, location.pathname);
  const method = "get";
  const query = parse(location.search.substr(1));

  let promises = branch.map(({ route, params }) => {
    let { element } = route;
    if (element) {
      while (element && element.type && !element.type[method]) {
        element = element.type.WrappedComponent;
      }

      if (element && element.type && element.type[method]) {
        return element.type[method]({ store, params, query });
      }
    }

    return null;
  });

  promises = promises.filter((prom) => prom !== null);

  if (callback) {
    let completed = 0;
    promises.forEach((prom) => {
      prom.then(() => {
        completed += 1;
        callback((completed * 100) / promises.length);
      });
    });
  }

  return Promise.all(promises);
};

export default fetchData;
