import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { OperatorForm } from "components";

const OperatorsCreatePage = () => (
  <Card elevation={0}>
    <CardHeader title="Create Operator" />
    <Divider />
    <OperatorForm />
  </Card>
);

export default OperatorsCreatePage;
