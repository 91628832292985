import * as React from "react";
import * as Yup from "yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray } from "react-hook-form";
import countries from "i18n-iso-countries";
import en from "i18n-iso-countries/langs/en.json";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Masonry from "@mui/lab/Masonry";
import DeleteIcon from "@mui/icons-material/Delete";
import { handleError, tips, useConfirm } from "services/helpers";
import { fromCustom } from "store/selectors";
import { hasPermission } from "services/permission";
import { notificationSend, customUpdateRequest } from "store/actions";
import {
  RHFAutocomplete,
  RHFCheckbox,
  RHFTextField,
  DiffBlock,
} from "components";

countries.registerLocale(en);
const countriesList = countries.getNames("en", { select: "official" });

const validationSchema = Yup.object().shape({
  crystalblockchainApiKey: Yup.string().default(""),
  crystalblockchainEnabled: Yup.bool(),
  crystalblockchainThreshold: Yup.number().required(),
  crystalblockchainThresholdEnabled: Yup.bool(),
  coinatmradarDisplayFeeEnabled: Yup.bool(),
  coinatmradarDisplayPriceEnabled: Yup.bool(),
  customDataKeys: Yup.array()
    .of(Yup.object().shape({ value: Yup.string().trim().required() })) // TODO
    .notRequired(),
  defaultCountry: Yup.string().default("US").required(),
  disclaimerEnabled: Yup.bool(),
  disclaimerBody: Yup.string().when("disclaimerEnabled", {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),
  disclaimerTitle: Yup.string().when("disclaimerEnabled", {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.notRequired(),
  }),

  maintenanceScreenMessageEnabled: Yup.bool(),

  maintenanceScreenMessage: Yup.string().when(
    "maintenanceScreenMessageEnabled",
    {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }
  ),

  orderFiatAmountThreshold: Yup.number().required(),
  orderFiatAmountThresholdEnabled: Yup.bool(),
});

const MachinesSettingsForm = () => {
  const { crystalblockchainApiKey, defaultValues } = useSelector(
    (state = {}) => {
      const {
        coinatmradarDisplayFeeEnabled,
        coinatmradarDisplayPriceEnabled,
        crystalblockchainEnabled,
        crystalblockchainApiKey,
        crystalblockchainThresholdEnabled,
        crystalblockchainThreshold,
        customDataKeys,
        defaultCountry,
        disclaimerBody,
        disclaimerEnabled,
        disclaimerTitle,
        maintenanceScreenMessage,
        maintenanceScreenMessageEnabled,
        orderFiatAmountThreshold,
        orderFiatAmountThresholdEnabled,
      } = fromCustom.get(state, "config/machines");
      return {
        crystalblockchainApiKey,
        defaultValues: {
          coinatmradarDisplayFeeEnabled,
          coinatmradarDisplayPriceEnabled,
          crystalblockchainEnabled,
          crystalblockchainApiKey: "",
          crystalblockchainThresholdEnabled,
          crystalblockchainThreshold,
          customDataKeys: customDataKeys.map((value) => ({
            value,
          })),
          defaultCountry,
          disclaimerBody,
          disclaimerEnabled,
          disclaimerTitle,
          maintenanceScreenMessage,
          maintenanceScreenMessageEnabled,
          orderFiatAmountThreshold,
          orderFiatAmountThresholdEnabled,
        },
      };
    },
    shallowEqual
  );

  const [open, confirm, proceed, cancel] = useConfirm();

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    defaultValues,
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });

  const customDataKeys = useFieldArray({
    control,
    name: "customDataKeys",
    keyName: "key",
  });

  const customDataKeyConfirm = useConfirm(true);
  const [customDataKey, setCustomDataKey] = React.useState("");

  const handleAppendCustomDataKey = React.useCallback(() => {
    if (!customDataKey) return;
    customDataKeys.append({ value: customDataKey });
    setCustomDataKey("");
  }, [customDataKeys, customDataKey]);

  const handleDeleteCustomDataKey = React.useCallback(
    (index) => () => {
      customDataKeys.remove(index);
    },
    [customDataKeys]
  );

  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    (values) => {
      const data = {
        ...values,
        customDataKeys: values.customDataKeys.map(({ value }) => value),
      };

      const { crystalblockchainApiKey } = data;

      if (crystalblockchainApiKey === "") {
        delete data.crystalblockchainApiKey;
      }

      return dispatch(customUpdateRequest("config/machines", data))
        .then(() => {
          reset(values);
          dispatch(
            notificationSend("Information updated successfully", {
              variant: "success",
            })
          );
        })
        .catch((error) => {
          dispatch(
            notificationSend("Failed to update information", {
              variant: "error",
            })
          );
          return handleError(error);
        });
    },
    [dispatch, reset]
  );

  const values = getValues();

  return (
    <Container maxWidth="xl">
      <form
        onSubmit={confirm(handleSubmit(onSubmit))}
        noValidate
        autoComplete="off"
        onReset={() => reset()}
      >
        <CardContent sx={{ px: 0 }}>
          <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={3}>
            <div>
              <Typography variant="h6" gutterBottom>
                General
              </Typography>
              <RHFAutocomplete
                name="defaultCountry"
                label="Default Country"
                control={control}
                options={Object.keys(countriesList)}
                disableClearable
                getOptionLabel={(code) => `${countriesList[code]} (${code})`}
                disabled={isSubmitting}
                isOptionEqualToValue={(option, value) => option === value}
                filterSelectedOptions
                textFieldProps={{
                  fullWidth: true,
                  placeholder: "Select country",
                }}
              />
              <Divider />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Disclaimer
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {tips.settingsMachinesPage.disclaimer}
              </Typography>

              <RHFTextField
                control={control}
                name="disclaimerTitle"
                label="Title"
                type="text"
                disabled={isSubmitting}
                required
              />

              <RHFTextField
                control={control}
                name="disclaimerBody"
                label="Body"
                type="text"
                disabled={isSubmitting}
                multiline
                required
              />
              <RHFCheckbox
                name="disclaimerEnabled"
                label="Enabled"
                control={control}
              />
              <Divider />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Maintenance Screen
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {tips.settingsMachinesPage.disclaimer}
              </Typography>
              <RHFTextField
                control={control}
                name="maintenanceScreenMessage"
                label="Body"
                type="text"
                disabled={isSubmitting}
                multiline
                required
              />
              <RHFCheckbox
                name="maintenanceScreenMessageEnabled"
                label="Enabled"
                control={control}
              />
              <Divider />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Order Threshold
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                {tips.settingsMachinesPage.threshold}
              </Typography>

              <RHFTextField
                control={control}
                name="orderFiatAmountThreshold"
                label="Threshold"
                type="number"
                disabled={isSubmitting}
                required
              />

              <RHFCheckbox
                name="orderFiatAmountThresholdEnabled"
                label="Enabled"
                control={control}
              />
              <Divider />
            </div>

            <div>
              <Typography variant="h6" gutterBottom>
                Crystal Blockchain
              </Typography>

              <RHFTextField
                control={control}
                name="crystalblockchainApiKey"
                label="API Key"
                type="text"
                disabled={isSubmitting}
                required
                placeholder={crystalblockchainApiKey}
                InputLabelProps={{ shrink: !!crystalblockchainApiKey }}
              />

              <RHFTextField
                control={control}
                name="crystalblockchainThreshold"
                label="Threshold"
                type="number"
                disabled={isSubmitting}
                required
              />

              <RHFCheckbox
                name="crystalblockchainEnabled"
                label="Enabled"
                control={control}
              />

              <RHFCheckbox
                name="crystalblockchainThresholdEnabled"
                label="Threshold Enabled"
                control={control}
              />
              <Divider />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Coin ATM Radar
              </Typography>

              <RHFCheckbox
                name="coinatmradarDisplayFeeEnabled"
                label="Display fee"
                control={control}
              />

              <RHFCheckbox
                name="coinatmradarDisplayPriceEnabled"
                label="Display price"
                control={control}
              />
              <Divider />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Custom data fields
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Custom data fields to be added to machine configuration and
                details pages.
              </Typography>
              {customDataKeys.fields.length > 0 && (
                <List sx={{ border: "1px solid", borderColor: "divider" }}>
                  {customDataKeys.fields.map(({ key, value }, index) => (
                    <React.Fragment key={key}>
                      {index > 0 && <Divider component="li" />}
                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="end"
                            onClick={customDataKeyConfirm.confirm(
                              handleDeleteCustomDataKey(index)
                            )}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText primary={value} />
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              )}
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs>
                  <TextField
                    label="Filed name"
                    onChange={(event) => setCustomDataKey(event.target.value)}
                    value={customDataKey}
                    type="string"
                    size="small"
                    helperText="Example: Social Profile"
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleAppendCustomDataKey}
                    disabled={!customDataKey}
                  >
                    Add field
                  </Button>
                </Grid>
              </Grid>
              <Divider />
            </div>
          </Masonry>
        </CardContent>
        {hasPermission("machines", "PUT") && (
          <CardActions>
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting || !isDirty}
            >
              Save
            </Button>
            <Button type="reset" disabled={isSubmitting || !isDirty}>
              Reset
            </Button>
          </CardActions>
        )}
      </form>

      <Dialog open={open}>
        <DialogTitle>Are you sure you want to save these changes?</DialogTitle>
        <DialogContent>
          <DiffBlock
            {...{
              initialValues: {
                ...defaultValues,
                crystalblockchainApiKey: "",
              },
              values: {
                crystalblockchainApiKey: "",
                ...values,
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={cancel}>Cancel</Button>
          <Button onClick={proceed} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={customDataKeyConfirm.open}>
        <DialogTitle>Delete custom field?</DialogTitle>
        <DialogContent>
          Deleting this field will remove all data associated with it for all
          machines!
        </DialogContent>
        <DialogActions>
          <Button onClick={customDataKeyConfirm.cancel}>Cancel</Button>
          <Button
            onClick={customDataKeyConfirm.proceed}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MachinesSettingsForm;
