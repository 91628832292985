import { Fragment } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import { ReadonlyTextField, ShortCard, NotesList, Label } from "components";

const ExpensesReadPage = () => {
  const params = useParams();
  const { detail } = useSelector((state = {}) => ({
    baseCurrency: fromCustom.getBaseCurrency(state),
    detail: fromEntities.getDenormalizedDetail(state, "expenses", params.id),
  }));

  const { name, amount, createdAt, paymentDay, expenseLog, id, defaultStatus } =
    detail;

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Fragment>
            Payment
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={4}>
                <Typography variant="h6">Information</Typography>
                <ReadonlyTextField
                  label="Expense Purpose"
                  value={name}
                  fullWidth
                />
                <ReadonlyTextField label="Amount" value={amount} fullWidth />
                <ReadonlyTextField
                  label="Default Status"
                  value={
                    <Label
                      color={defaultStatus === "PAID" ? "success" : "error"}
                    >
                      {defaultStatus}
                    </Label>
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Payment Day"
                  value={paymentDay}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={moment(createdAt).format("L\n LTS")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} xl={8}></Grid>
              {expenseLog && expenseLog.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Unpaid
                  </Typography>
                  <Table
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "30%" }}>Id</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", width: 115 }}>
                          Payment Date
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", width: 115 }}>
                          Created At
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head">Total</TableCell>
                        <TableCell variant="head">
                          {expenseLog.reduce(
                            (acc, item) => acc + item.amount,
                            0
                          )}
                        </TableCell>
                        <TableCell variant="head"></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {expenseLog.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <MuiLink
                              component={Link}
                              to={`/expenses/logs/${row.id}/update`}
                              underline="hover"
                            >
                              {row.id}
                            </MuiLink>
                          </TableCell>
                          <TableCell>{row.amount}</TableCell>
                          <TableCell sx={{ py: 0 }}>
                            {row.paymentDate ? (
                              moment(row.paymentDate).format("L")
                            ) : (
                              <i>[missing]</i>
                            )}
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>
                            {row.createdAt ? (
                              moment(row.createdAt).format("L\n LTS")
                            ) : (
                              <i>[missing]</i>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="expenses" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("expenselogs", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<AssignmentTurnedInIcon />}
                        to={`/expenses/logs?expenseId=${id}`}
                        title="Payments List"
                        description="View Payments"
                      />
                    </Grid>
                  )}
                  {hasPermission("expenselogs", "POST") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<AssignmentTurnedInIcon />}
                        to={`/expenses/logs/create?expenseId=${id}`}
                        title="Create Payment"
                        description="Create New Payment"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ExpensesReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("expenses", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "expenses",
        entityId: params.id,
      })
    ),
  ]);
};

export default ExpensesReadPage;
