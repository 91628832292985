import React, { useState } from "react";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { authSignoutRequest } from "store/actions";
import { fromAuth } from "store/selectors";
import toHex from "services/toHex";
import { hasPermission } from "services/permission";

const UserMenu = () => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(undefined);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(
    (state = {}) => ({
      user: fromAuth.getUser(state),
    }),
    isEqual
  );

  const { avatarBg, userName } = React.useMemo(() => {
    const userName = user.userName || user.email;
    return {
      userName,
      avatarBg: toHex(userName),
    };
  }, [user.userName, user.email]);

  const handleClickMenuOpen = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
    setUserMenuOpen(true);
  };

  const handleClickMenuClose = () => {
    setUserMenuOpen(false);
  };

  const handleSignOut = React.useCallback(() => {
    dispatch(authSignoutRequest()).finally(() => {
      navigate("/signin");
    });
  }, [dispatch, navigate]);

  return (
    <React.Fragment>
      <IconButton
        disableRipple
        onClick={handleClickMenuOpen}
        sx={{
          padding: 0.5,
          marginRight: -0.5,
        }}
        size="large"
      >
        <Avatar alt={userName} style={{ backgroundColor: avatarBg }}>
          {userName.charAt(0)}
        </Avatar>
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        sx={{
          "& .MuiMenu-paper": {
            marginLeft: 1.5,
            marginTop: -1.5,
          },
        }}
        open={userMenuOpen}
        onClose={handleClickMenuClose}
      >
        <Stack
          spacing={2}
          direction="row"
          sx={{ px: 2, mb: 1, alignItems: "center" }}
        >
          <Typography type="subtitle1" color="inherit">
            {userName}
          </Typography>
          <Avatar alt={userName} style={{ backgroundColor: avatarBg }}>
            {userName.charAt(0)}
          </Avatar>
        </Stack>
        {hasPermission("users/me", "PUT") && hasPermission("users/me", "GET") && (
          <MenuItem
            onClick={handleClickMenuClose}
            component={Link}
            to="/settings/users/me/update"
          >
            Profile Settings
          </MenuItem>
        )}
        <MenuItem onClick={handleSignOut}>Log out</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default UserMenu;
