import { Children, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
import { parse, stringify } from "qs";
import { useLocation } from "react-router-dom";
import MuiTableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableCell from "@mui/material/TableCell";

const TableHead = (props) => {
  const {
    children,
    handleSelectAllClick,
    order,
    sort,
    indeterminate,
    checked,
  } = props;

  const location = useLocation();

  const getSortLink = (key) => {
    const query = parse(location.search.substr(1));
    const order = (query._order && query._order.toLowerCase()) || "desc";
    const orderParam = order === "asc" ? "DESC" : "ASC";

    return {
      pathname: location.pathname,
      search: `?${stringify(
        {
          ...query,
          _order: orderParam,
          _sort: key,
        },
        { arrayFormat: "repeat" }
      )}`,
    };
  };

  const query = parse(location.search.substr(1));
  const sortParam = query._sort || sort;
  const orderParam = (query._order && query._order.toLowerCase()) || order;

  return (
    <MuiTableHead>
      <MuiTableRow sx={{ height: 56 }}>
        {Children.map(children, (child) => {
          if (child.type.muiName === "TableColumn") {
            const { cell, header, ...other } = child.props;

            const headerProps = {
              ...other,
              getSortLink,
              handleSelectAllClick,
              order: orderParam,
              sort: sortParam,
              indeterminate,
              checked,
            };

            let content;
            if (isValidElement(header)) {
              content = cloneElement(header, headerProps);
            } else if (typeof header === "function") {
              content = header(headerProps);
            } else {
              content = <MuiTableCell {...headerProps}>{header}</MuiTableCell>;
            }
            return content;
          }
          return null;
        })}
      </MuiTableRow>
    </MuiTableHead>
  );
};

TableHead.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.array.isRequired,
  handleSelectAllClick: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  order: PropTypes.string,
  sort: PropTypes.string,
};

TableHead.defaultProps = {
  checked: false,
  indeterminate: false,
};

export default TableHead;
