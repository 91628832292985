import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { handleError, trim } from "services/helpers";
import { Autocomplete, Checkboxes, TextField } from "mui-rff";
import {
  createValidator,
  required,
  email,
  minLength,
} from "services/validation";
import {
  notificationSend,
  resourceCreateRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromAuth, fromEntities, fromResource } from "store/selectors";
import {
  DiffBlock,
  Confirmation,
  ReadonlyTextField,
  PhoneField,
  UserOTPDialog,
} from "components";

const validate = (newUser) =>
  createValidator({
    userName: [required],
    email: [email],
    password: [minLength(5), ...(newUser ? [required] : [])],
  });

const SettingsRow = ({ section, title }) => (
  <>
    <Grid item xs={3}>
      <Typography variant="subtitle2">{title}</Typography>
    </Grid>
    <Grid item xs={3}>
      <Checkboxes
        name={`notificationSettings.${section}.${title}.email`}
        data={{ label: "", value: "" }}
        formControlProps={{ margin: "none" }}
      />
    </Grid>
    <Grid item xs={3}>
      <Checkboxes
        name={`notificationSettings.${section}.${title}.phone`}
        data={{ label: "", value: "" }}
        formControlProps={{ margin: "none" }}
      />
    </Grid>
    <Grid item xs={3}>
      <Checkboxes
        name={`notificationSettings.${section}.${title}.system`}
        data={{ label: "", value: "" }}
        formControlProps={{ margin: "none" }}
      />
    </Grid>
  </>
);

const UserForm = ({ id, tab = "profile" }) => {
  const {
    initialValuesTmp = {
      userName: null,
      email: null,
      firstName: null,
      lastName: null,
      password: null,
    },
    roles,
    authUser,
    machines,
  } = useSelector(
    (state) => ({
      initialValuesTmp: fromEntities.getDenormalizedDetail(state, "users", id),
      roles: fromEntities.getList(state, "userroles"),
      authUser: fromAuth.getUser(state),
      machines: fromEntities.getList(
        state,
        "machines",
        fromResource.getList(state, "machines")
      ),
    }),
    shallowEqual
  );

  const initialValues = {
    limitedUserPermissions: { machines: { read: [] } },
    roleId: roles[0].id,
    ...initialValuesTmp,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    let action = resourceCreateRequest("users", values);
    if (id) action = resourceUpdateRequest("users", id, values);

    return dispatch(action)
      .then((resultId) => {
        dispatch(
          notificationSend(`User ${id ? "updated" : "created"} successfully`, {
            variant: "success",
          })
        );
        if (!id) navigate(`/settings/users/${resultId}/update`);
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} user`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  const newUser = initialValues.userName === null;

  const [enabledOtp, setEnabledOtp] = useState(
    initialValues.twoWayAuthenticateEnable
  );
  const [open, setOpen] = useState(false);

  const handle2FA = (event) => {
    if (event.target.checked) {
      setEnabledOtp(true);
      setOpen(true);
    } else {
      setEnabledOtp(false);
      setOpen(true);
      // api.post('/users/twowayauthdisable').catch(() => { })
    }
  };

  const handleClose = (result) => {
    setEnabledOtp(result);
    setOpen(false);
  };

  return (
    <Fragment>
      <UserOTPDialog
        open={open}
        variant={enabledOtp ? "enable" : "disable"}
        onClose={handleClose}
      />
      <Confirmation>
        {(show, confirm, hide, open) => (
          <Fragment>
            <Form
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate(newUser)}
              render={({
                dirtySinceLastSubmit,
                form: { reset },
                handleSubmit,
                hasSubmitErrors,
                pristine,
                submitError,
                submitting,
                valid,
                values,
              }) => (
                <form
                  onSubmit={show(handleSubmit)}
                  noValidate
                  autoComplete="off"
                >
                  <CardContent>
                    {hasSubmitErrors && (
                      <Typography color="error">{submitError}</Typography>
                    )}
                    {tab === "profile" && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          {newUser && (
                            <TextField
                              name="userName"
                              label="User name"
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              autoComplete="username"
                              fieldProps={{ format: trim }}
                            />
                          )}
                          {initialValues.userId !== authUser.userId && (
                            <TextField
                              name="roleId"
                              label="Role"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              required
                              select
                            >
                              {roles.map(({ id, name }) => (
                                <MenuItem key={id} value={id}>
                                  {name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                          {roles.findIndex(
                            ({ id, name }) =>
                              id === values.roleId && name === "LIMITED USER"
                          ) !== -1 && (
                            <Autocomplete
                              filterSelectedOptions
                              getOptionLabel={(value) => {
                                const item = machines.find(
                                  ({ id }) => id === value
                                );
                                return item
                                  ? `${item.name} (${item.id || item.atmId})`
                                  : "Missing";
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value
                              }
                              label="Machines access"
                              multiple
                              required
                              name="limitedUserPermissions.machines.read"
                              options={machines.map(({ id }) => id)}
                              textFieldProps={{
                                helperText: "Give access to these machines",
                                fullWidth: true,
                                margin: "normal",
                                placeholder: "Add atm",
                                variant: "outlined",
                              }}
                            />
                          )}
                          <TextField
                            name="firstName"
                            label="First name"
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoComplete="given-name"
                            fieldProps={{ format: trim, formatOnBlur: true }}
                          />
                          <TextField
                            name="lastName"
                            label="Last name"
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoComplete="family-name"
                            fieldProps={{ format: trim, formatOnBlur: true }}
                          />
                          <TextField
                            name="email"
                            label="Email"
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            autoComplete="email"
                            fieldProps={{ format: trim }}
                          />
                          <PhoneField name="phone" label="Phone" />
                          <TextField
                            name="password"
                            label={newUser ? "Password" : "Change password"}
                            type="password"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            required={newUser}
                            autoComplete="new-password"
                            fieldProps={{ format: trim }}
                          />
                          {initialValues.userId !== authUser.userId && (
                            <Checkboxes
                              name="archived"
                              helperText="Hide from the main list"
                              data={{ label: "Archived", value: "archived" }}
                              formControlProps={{ fullWidth: true }}
                            />
                          )}
                          {!newUser &&
                            initialValues.userId === authUser.userId && (
                              <FormControlLabel
                                sx={{ my: 2 }}
                                control={
                                  <Switch
                                    checked={enabledOtp}
                                    onChange={handle2FA}
                                  />
                                }
                                label="2-factor authentification"
                              />
                            )}
                        </Grid>
                        {!newUser && (
                          <Grid item xs={12} md={6}>
                            <ReadonlyTextField
                              label="User Name"
                              value={initialValues.userName}
                              fullWidth
                            />
                            <ReadonlyTextField
                              label="User role"
                              value={initialValues.role.name}
                              fullWidth
                            />
                            {/* <ReadonlyTextField
                            label="2-factor authentification"
                            value={(
                              <Switch
                                checked={initialValues.twoWayAuthenticateEnable}
                                // onChange={handleChange}
                              />
                            )}
                            fullWidth
                          /> */}
                            <ReadonlyTextField
                              label="Created At"
                              value={moment(initialValues.createdAt).format(
                                "L, LTS"
                              )}
                              fullWidth
                            />
                            <ReadonlyTextField
                              label="Updated At"
                              value={moment(initialValues.updatedAt).format(
                                "L, LTS"
                              )}
                              fullWidth
                            />
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {tab === "notifications" && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="h5">Orders</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography>Order status</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                Email
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">Sms</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                System
                              </Typography>
                            </Grid>
                            <SettingsRow section="orders" title="BAD TX" />
                            <SettingsRow section="orders" title="BAD WALLET" />
                            <SettingsRow section="orders" title="BLOCKED" />
                            <SettingsRow section="orders" title="ERROR" />
                            <SettingsRow section="orders" title="FULFILLED" />
                            <SettingsRow section="orders" title="HOLD" />
                            <SettingsRow section="orders" title="NO FUNDS" />
                            <SettingsRow section="orders" title="OPEN" />
                            <Grid item xs={12}>
                              {" "}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h5">Machines</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography>Machine status</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                Email
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">Sms</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                System
                              </Typography>
                            </Grid>
                            <SettingsRow section="machines" title="ERROR" />
                            <SettingsRow
                              section="machines"
                              title="MAINTENANCE"
                            />
                            <SettingsRow section="machines" title="OFFLINE" />
                            <Grid item xs={3}>
                              <Typography>Printer status</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                Email
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">Sms</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                System
                              </Typography>
                            </Grid>
                            <SettingsRow
                              section="machines.printer"
                              title="ERROR"
                            />
                            <SettingsRow
                              section="machines.printer"
                              title="OUTOFPAPER"
                            />
                            <Grid item xs={3}>
                              <Typography>Machine Cash Threshold</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                Email
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">Sms</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                System
                              </Typography>
                            </Grid>
                            <SettingsRow
                              section="machines"
                              title="cashThreshold"
                            />
                            <Grid item xs={6}>
                              <TextField
                                name="notificationSettings.machines.cashThreshold.threshold"
                                label="Cash Threshold"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {" "}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="h5">Wallets</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography>Wallet status</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                Email
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">Sms</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography color="textSecondary">
                                System
                              </Typography>
                            </Grid>
                            <SettingsRow section="wallets" title="lowBalance" />
                            <Grid item xs={6}>
                              <TextField
                                name="notificationSettings.wallets.lowBalance.threshold"
                                label="Low balance threshold"
                                type="number"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={
                        pristine ||
                        submitting ||
                        (!valid && !dirtySinceLastSubmit)
                      }
                    >
                      Save
                    </Button>
                    <Button onClick={reset} disabled={pristine || submitting}>
                      Reset
                    </Button>
                  </CardActions>
                  <Dialog open={open}>
                    <DialogTitle>
                      Are you sure you want to save these changes?
                    </DialogTitle>
                    <DialogContent>
                      <DiffBlock {...{ initialValues, values }} />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={hide}>Cancel</Button>
                      <Button onClick={confirm} color="primary" autoFocus>
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </form>
              )}
            />
          </Fragment>
        )}
      </Confirmation>
    </Fragment>
  );
};

UserForm.propTypes = {
  tab: PropTypes.string.isRequired,
};

UserForm.defaultProps = {
  tab: "profile",
};

export default UserForm;
