import { styled } from "@mui/material/styles";

const TableActions = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  paddingRight: 2,
  whiteSpace: "nowrap",
  [theme.breakpoints.up("lg")]: {
    opacity: 0.26,
    "tr:hover &": {
      opacity: 1,
    },
  },
}));

export default TableActions;
