import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { SigninForm } from "components";

const StyledGrid = styled(Grid)({
  backgroundColor: "#e0e0e0",
  backgroundImage:
    "linear-gradient(70deg, #e0e0e0  30%, rgba(0,0,0,0) 30%), linear-gradient(30deg, #bdbdbd 60%, #8d8d8d 60%)",
  flexGrow: 1,
  height: "100vh",
  margin: 0,
  width: "100%",
});

const StyledGridItem = styled(Grid)({
  maxWidth: 450,
  position: "relative",
  width: "100%",
  zIndex: 2,
});

function SigninPage() {
  return (
    <StyledGrid container alignItems="center" justifyContent="center">
      <StyledGridItem item>
        <SigninForm />
      </StyledGridItem>
    </StyledGrid>
  );
}

export default SigninPage;
