import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { ExpensesForm } from "components";

const ExpensesCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Add Expense" />
      <Divider />
      <ExpensesForm />
    </Card>
  );
};

export default ExpensesCreatePage;
