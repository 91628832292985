import { Controller } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";

export default function RHFCheckbox({
  name,
  validation = {},
  required,
  parseError,
  label,
  control,
  helperText,
  labelProps,
  ...rest
}) {
  return (
    <Controller
      name={name}
      rules={validation}
      control={control}
      render={({ field: { value, onChange, ref }, fieldState: { error } }) => {
        const parsedHelperText = error ? error.message : helperText;
        return (
          <FormControl
            required={required}
            error={!!error}
            fullWidth
            margin="normal"
          >
            <FormGroup row>
              <FormControlLabel
                {...labelProps}
                label={label || ""}
                control={
                  <Checkbox
                    {...rest}
                    color={rest.color || "primary"}
                    sx={[
                      ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx]),
                      {
                        color: error ? "error.main" : undefined,
                      },
                    ]}
                    value={value}
                    checked={!!value}
                    onChange={(ev) => {
                      onChange(!value);
                      if (typeof rest.onChange === "function") {
                        rest.onChange(ev, !value);
                      }
                    }}
                    inputRef={ref}
                  />
                }
              />
            </FormGroup>
            {parsedHelperText && (
              <FormHelperText error={!!error}>
                {parsedHelperText}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
