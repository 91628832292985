import * as React from "react";
import moment from "moment";
import clsx from "clsx";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { pending } from "redux-saga-thunk";
import { Link, useParams } from "react-router-dom";
import JSONPretty from "react-json-prettify";
import { github } from "react-json-prettify/dist/themes";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import MuiLink from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import IconEdit from "@mui/icons-material/Edit";
import IconFlag from "@mui/icons-material/EmojiFlags";
import IconLabel from "@mui/icons-material/Label";
import IconLocalAtm from "@mui/icons-material/LocalAtm";
import IconLogs from "@mui/icons-material/Dvr";
import IconPerson from "@mui/icons-material/Person";
import IconSession from "@mui/icons-material/Web";
import IconWalletTransaction from "@mui/icons-material/Toc";
import ReceiptIcon from "@mui/icons-material/Receipt";
import BlockIcon from "@mui/icons-material/Block";
import RestoreIcon from "@mui/icons-material/Restore";
import {
  notificationSend,
  resourceDetailReadRequest,
  resourceListReadRequest,
  resourceUpdateRequest,
} from "store/actions";
import { handleError } from "services/helpers";
import { fromCustom, fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import { tips } from "services/helpers";
import {
  BlockchainLink,
  Label,
  ReadonlyTextField,
  ShortCard,
  NotesList,
  SendReceiptDialog,
  IconQueue,
} from "components";
import { apiUrl } from "config";

const getStatusColor = (status) => {
  if (["PAYING", "BUYING", "RECEIVED", "PENDING", "PEND"].includes(status)) {
    return "info";
  }
  if (["HOLD", "EXPIRED"].includes(status)) {
    return "warning";
  }
  if (["ERROR", "NO FUNDS"].includes(status)) {
    return "error";
  }
  if ([["REDEEMED", "FULFILLED"]].includes(status)) {
    return "success";
  }
  return "grey";
};

const indicators = [
  {
    Indicator: "A1",
    Type: "Customer Activity Risk",
    Trigger: "Known scam, fraud, etc.",
  },
  {
    Indicator: "A2",
    Type: "Customer Activity Risk",
    Trigger: "MSB with AML and KYC reportedly in place and no recent hacks",
  },
  {
    Indicator: "A3",
    Type: "Customer Activity Risk",
    Trigger: "MSB without either AML or KYC (or both) reportedly in place",
  },
  {
    Indicator: "A4",
    Type: "Customer Activity Risk",
    Trigger: "Active exchange",
  },
  {
    Indicator: "A5",
    Type: "Customer Activity Risk",
    Trigger: "Validated through a partner KYC service (e.g., Onfido)",
  },
  {
    Indicator: "A6",
    Type: "Customer Activity Risk",
    Trigger: "Mixing/Tumbling service",
  },
  {
    Indicator: "A7",
    Type: "Customer Activity Risk",
    Trigger: "Potential darknet",
  },
  {
    Indicator: "A8",
    Type: "Customer Activity Risk",
    Trigger: "Ransomware",
  },
  {
    Indicator: "A9",
    Type: "Customer Activity Risk",
    Trigger: "No longer in service or shutdown",
  },
  {
    Indicator: "A10",
    Type: "Customer Activity Risk",
    Trigger: "Known Service Provider or Merchant",
  },
  {
    Indicator: "A11",
    Type: "Customer Activity Risk",
    Trigger: "Reported hack associated with entity owning this address",
  },
  {
    Indicator: "A12",
    Type: "Customer Activity Risk",
    Trigger: "Mining pool",
  },
  {
    Indicator: "A13",
    Type: "Customer Activity Risk",
    Trigger: "Gambling",
  },
  {
    Indicator: "A14",
    Type: "Customer Activity Risk",
    Trigger: "PrivateSend transaction",
  },
  {
    Indicator: "A15",
    Type: "Customer Activity Risk",
    Trigger: "OFAC sanctioned address",
  },
  {
    Indicator: "A16",
    Type: "Customer Activity Risk",
    Trigger: "Hosted wallet",
  },
  {
    Indicator: "A17",
    Type: "Customer Activity Risk",
    Trigger: "Terrorist financing or related",
  },
  {
    Indicator: "B1",
    Type: "Customer Transaction Risk",
    Trigger: "New address. No history.",
  },
  {
    Indicator: "B2",
    Type: "Customer Transaction Risk",
    Trigger: "Earliest transaction < 1 month",
  },
  {
    Indicator: "B3",
    Type: "Customer Transaction Risk",
    Trigger: "Earliest transaction between 1 to 3 months",
  },
  {
    Indicator: "B4",
    Type: "Customer Transaction Risk",
    Trigger: "Earliest transaction between 3 months to 1 year",
  },
  {
    Indicator: "B5",
    Type: "Customer Transaction Risk",
    Trigger: "Earliest transaction >= 1 year",
  },
  {
    Indicator: "B6",
    Type: "Customer Transaction Risk",
    Trigger: "Transaction amount > $1,000 USD equivalent",
  },
  {
    Indicator: "B7",
    Type: "Customer Transaction Risk",
    Trigger: "Transaction amount > $2,000 USD equivalent",
  },
  {
    Indicator: "B8",
    Type: "Customer Transaction Risk",
    Trigger: "Transaction amount > $3,000 USD equivalent",
  },
  {
    Indicator: "B9",
    Type: "Customer Transaction Risk",
    Trigger: "Transaction amount > $10K USD equivalent",
  },
  {
    Indicator: "B10",
    Type: "Customer Transaction Risk",
    Trigger: "Transaction amount > $100K USD equivalent",
  },
  {
    Indicator: "A18",
    Type: "Customer Activity Risk",
    Trigger: "Cryptocurrency ATM",
  },
  {
    Indicator: "A19",
    Type: "Customer Activity Risk",
    Trigger: "MSB registered with Fincen",
  },
  {
    Indicator: "A20",
    Type: "Customer Activity Risk",
    Trigger: "Cannabis provider registered with state",
  },
  {
    Indicator: "A21",
    Type: "Customer Activity Risk",
    Trigger: "MSB not registered with Fincen with company’s advertised name",
  },
  {
    Indicator: "A22",
    Type: "Customer Activity Risk",
    Trigger: "Cannabis provider not registered with state",
  },
  {
    Indicator: "C1",
    Type: "Geographic Risk",
    Trigger: "Has transaction originated in an OFAC country",
  },
  {
    Indicator: "C2",
    Type: "Geographic Risk",
    Trigger: "Has transaction originated in a FATF monitored jurisdiction",
  },
];

const OrdersReadPage = () => {
  const classes = {};
  const params = useParams();
  const { detail, loading } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      detail: fromEntities.getDenormalizedDetail(state, "orders", params.id),
      loading: pending(state, "ordersUpdate"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const updateOrder = (values) => {
    return dispatch(resourceUpdateRequest("orders", params.id, values))
      .then(() => {
        dispatch(
          notificationSend("Order updated successfully", { variant: "success" })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to update order", { variant: "error" })
        );
        return handleError(error);
      });
  };

  const handleChangeOrderStatus = (status) => () => {
    updateOrder({ status });
  };

  const handleWalletBlacklist = (blacklisted) => {
    return dispatch(resourceUpdateRequest("orders", params.id, { blacklisted }))
      .then(() => {
        dispatch(
          notificationSend(
            `${
              blacklisted ? "Wallet Removed From" : "Wallet Added To"
            } Blacklist`,
            { variant: "success" }
          )
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend(
            `${
              blacklisted ? "Failed To Remove From" : "Failed To Add to"
            } Blacklist`,
            { variant: "error" }
          )
        );
        return handleError(error);
      });
  };

  const {
    allowedStatuses = [],
    atm,
    attachments: files,
    blacklisted,
    bitrankData,
    blockchainintelData,
    campaign,
    cashLog,
    coinAmount,
    coinPrice,
    coinType,
    cost,
    createdAt,
    cryptoCurrencySpotPrice,
    crystalblockchainData,
    customer = {},
    customerReceipt,
    fiatAmount,
    fiatType,
    id,
    orderId,
    profit,
    queueId,
    sessions = [],
    snapshot,
    status,
    statusLog = [],
    transactionId,
    txFee = 0,
    txFeeCost = 0,
    type,
    wallet,
    walletTransactionId,
  } = detail;

  const hasCustomer = React.useMemo(
    () => typeof customer === "object" && Object.keys(customer).length > 0,
    [customer]
  );

  const hasCampaign = React.useMemo(
    () => typeof campaign === "object" && Object.keys(campaign).length,
    [campaign]
  );

  const hasIntelData = blockchainintelData !== null;

  const hasBitrankData =
    bitrankData !== null && bitrankData.error === undefined;

  const hasTransaction = React.useMemo(
    () =>
      walletTransactionId &&
      walletTransactionId !== null &&
      transactionId &&
      transactionId !== null,
    [walletTransactionId, transactionId]
  );

  const { approved, address, firstName, lastName, userName, phone, email } =
    customer;

  const customerName = React.useMemo(() => {
    let name = "";
    if (hasCustomer) {
      if (firstName || lastName) name = [firstName, lastName].join(" ");
      if (!name.length && userName) name = userName;
    }
    return name;
  }, [hasCustomer, firstName, lastName, userName]);

  const attachments = React.useMemo(() => {
    let attachmentList = files || [];
    if (snapshot && !attachmentList.find(({ url }) => url === snapshot)) {
      attachmentList = [...attachmentList, { id: "snapshot", url: snapshot }];
    }
    return attachmentList;
  }, [files, snapshot]);

  const cashTotal = React.useMemo(
    () =>
      cashLog.reduce(
        (accumulator, currentValue) => accumulator + currentValue.denomination,
        0
      ),
    [cashLog]
  );

  const crystalData = React.useMemo(() => {
    if (!crystalblockchainData) return null;

    const signals = Object.entries(crystalblockchainData.signals).reduce(
      (accum, [key, value]) => {
        const percentageValue = parseFloat(value.replace("%", ""));
        const weight = crystalblockchainData.riskscore_profile?.signals?.[key];
        let color = "#9e9e9e";
        let textColor = "black";

        if (percentageValue >= 1 && weight !== undefined) {
          if (weight > 0 && weight < 0.25) {
            color = "#43a047";
            textColor = "white";
          } else if (weight >= 0.25 && weight < 0.5) {
            color = "#ffeb3b";
          } else if (weight >= 0.5 && weight < 0.75) {
            color = "#ef6c00";
            textColor = "white";
          } else {
            color = "#e53935";
            textColor = "white";
          }

          accum.push({
            key,
            value,
            color,
            textColor,
          });
        }
        return accum;
      },
      []
    );

    return {
      riskscore: crystalblockchainData.riskscore,
      signals: signals.sort(
        (a, b) => parseFloat(b.value) - parseFloat(a.value)
      ),
    };
  }, [crystalblockchainData]);

  return (
    <Card elevation={0}>
      <CardHeader
        classes={{ action: classes.actions }}
        title={
          <React.Fragment>
            Order
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >
              {`#${orderId}`}
            </Typography>
          </React.Fragment>
        }
        action={
          <React.Fragment>
            {hasPermission("walletblacklist", "POST") && type !== "SELL" && (
              <Button
                startIcon={!blacklisted ? <BlockIcon /> : <RestoreIcon />}
                onClick={() => handleWalletBlacklist(!blacklisted)}
                disabled={loading}
              >
                {`${
                  !blacklisted ? "Add Wallet To" : "Remove Wallet From"
                } Blacklist`}
              </Button>
            )}
            {hasPermission("orders", "PUT") &&
              allowedStatuses.map((status) => (
                <Button
                  key={status}
                  startIcon={<IconLabel />}
                  onClick={handleChangeOrderStatus(status)}
                  disabled={loading}
                >
                  Mark as {status}
                </Button>
              ))}
            <SendReceiptDialog id={id} customer={customer} />
            {hasPermission("orders", "PUT") && (
              <Button
                startIcon={<IconEdit />}
                component={Link}
                to={`/orders/${id}/update`}
              >
                Edit
              </Button>
            )}
          </React.Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={4}>
                <Typography variant="h6">General</Typography>
                <ReadonlyTextField
                  label="Order Id"
                  value={orderId}
                  fullWidth
                  tooltip={tips.orders.id}
                />
                <ReadonlyTextField
                  label="Created At"
                  value={moment(createdAt).format("L, LTS")}
                  fullWidth
                  tooltip={tips.orders.createdAt}
                />
                <ReadonlyTextField
                  label="Type"
                  value={
                    <Label
                      variant="outlined"
                      color={type === "BUY" ? "success" : "error"}
                    >
                      {type}
                    </Label>
                  }
                  fullWidth
                  tooltip={tips.orders.type}
                />
                <ReadonlyTextField
                  label="Status"
                  value={
                    <Label
                      color={
                        clsx({
                          error: status === "ERROR" || status === "NO FUNDS",
                          warning: status === "HOLD" || status === "EXPIRED",
                          info:
                            [
                              "PAYING",
                              "BUYING",
                              "RECEIVED",
                              "PENDING",
                              "PEND",
                            ].indexOf(status.toUpperCase()) !== -1,
                          success:
                            ["REDEEMED", "FULFILLED"].indexOf(
                              status.toUpperCase()
                            ) !== -1,
                        }) || "default"
                      }
                    >
                      {status}
                    </Label>
                  }
                  fullWidth
                  tooltip={tips.orders.status}
                />
                {queueId && (
                  <ReadonlyTextField
                    label="Queue Id"
                    value={
                      <MuiLink component={Link} to={`/orderqueue/${queueId}`}>
                        {queueId}
                      </MuiLink>
                    }
                    fullWidth
                  />
                )}
                <ReadonlyTextField
                  label="Atm"
                  value={atm ? atm.name : "OTC Order"}
                  fullWidth
                  tooltip={tips.orders.atm}
                />
                <ReadonlyTextField
                  label="Coin Type"
                  value={coinType}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Fiat Type"
                  value={fiatType}
                  fullWidth
                />
                {hasCampaign && (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Campaign
                    </Typography>
                    <ReadonlyTextField
                      label="Type"
                      value={
                        <Label
                          variant="outlined"
                          color={
                            campaign.type === "CREDIT" ? "success" : "info"
                          }
                        >
                          {campaign.type}
                        </Label>
                      }
                      fullWidth
                    />
                    {campaign.type === "CREDIT" && (
                      <ReadonlyTextField
                        label="Amount"
                        value={new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: fiatType,
                        }).format(campaign.amount)}
                        fullWidth
                      />
                    )}
                    {campaign.type === "DISCOUNT" && (
                      <ReadonlyTextField
                        label="PERCENT"
                        value={`${campaign.percent}%`}
                        fullWidth
                      />
                    )}
                  </React.Fragment>
                )}
                {customerReceipt && (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Receipt
                    </Typography>
                    <ReadonlyTextField
                      label={customerReceipt.phone ? "Phone" : "Email"}
                      value={customerReceipt.phone || customerReceipt.email}
                      fullWidth
                    />
                    <ReadonlyTextField
                      label="Date"
                      value={moment(customerReceipt.createdAt).format("L, LTS")}
                      fullWidth
                    />
                  </React.Fragment>
                )}
                <React.Fragment>
                  <Typography variant="h6" gutterBottom>
                    Forensic Intelligence
                  </Typography>
                  {!!crystalData && (
                    <React.Fragment>
                      {crystalData.signals.length > 0 && (
                        <ReadonlyTextField
                          label="Signals Values"
                          value={
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                mx: -0.5,
                              }}
                            >
                              {crystalData.signals.map(
                                ({ key, value, color, textColor }) => (
                                  <Chip
                                    key={key}
                                    label={`${key}: ${value}`}
                                    size="small"
                                    sx={{
                                      backgroundColor: color,
                                      color: textColor,
                                      m: 0.5,
                                    }}
                                  />
                                )
                              )}
                            </Box>
                          }
                          fullWidth
                        />
                      )}
                      <ReadonlyTextField
                        label="Risk Score"
                        value={<b>{crystalData.riskscore}</b>}
                        fullWidth
                      />
                    </React.Fragment>
                  )}
                  {crystalData === null && (
                    <Box sx={{ py: 1.5 }}>
                      <Alert severity="warning">Not active</Alert>
                    </Box>
                  )}
                </React.Fragment>
                {hasBitrankData && (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Bitrank Report
                    </Typography>
                    <JSONPretty theme={github} json={bitrankData} />
                  </React.Fragment>
                )}
                {hasIntelData && (
                  <React.Fragment>
                    <Typography variant="h6" gutterBottom>
                      Blockchain Intelligence
                    </Typography>
                    <ReadonlyTextField
                      label="Score"
                      value={blockchainintelData.score}
                      fullWidth
                    />
                    <ReadonlyTextField
                      label="Indicators"
                      value={blockchainintelData.indicators.map((item) => {
                        const { Trigger } =
                          indicators.find(
                            ({ Indicator }) => Indicator === item
                          ) || {};
                        return (
                          <Label key={item}>
                            {item} - {Trigger || item}
                          </Label>
                        );
                      })}
                      fullWidth
                    />
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Typography variant="h6" gutterBottom>
                  Financial
                </Typography>
                <ReadonlyTextField
                  label="Coin Amount"
                  value={`${coinAmount} ${coinType}`}
                  fullWidth
                  tooltip={tips.orders.coinAmount}
                />
                <ReadonlyTextField
                  label="Coin Price"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatType,
                  }).format(coinPrice)}
                  fullWidth
                  tooltip={tips.orders.coinPrice}
                />
                <ReadonlyTextField
                  label="Spot Price"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatType,
                  }).format(cryptoCurrencySpotPrice)}
                  fullWidth
                  tooltip={tips.orders.spotPrice}
                />
                <ReadonlyTextField
                  label="Cost"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatType,
                  }).format(cost)}
                  fullWidth
                  tooltip={tips.orders.cost}
                />
                <ReadonlyTextField
                  label="Profit"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatType,
                  }).format(profit)}
                  fullWidth
                  tooltip={tips.orders.profit}
                />
                <ReadonlyTextField
                  label="Fiat Amount"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatType,
                  }).format(fiatAmount)}
                  fullWidth
                  tooltip={tips.orders.fiatAmount}
                />
                <Typography variant="h6" gutterBottom>
                  Transaction
                </Typography>
                <ReadonlyTextField
                  label="Wallet"
                  value={
                    wallet ? (
                      <BlockchainLink wallet={wallet} coinType={coinType} />
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Transaction Id"
                  value={
                    transactionId ? (
                      <BlockchainLink
                        transaction={transactionId}
                        coinType={coinType}
                      />
                    ) : (
                      <i>[no transaction]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Transaction Fee"
                  value={txFee ? `${txFee} ${coinType}` : 0}
                  fullWidth
                  tooltip={tips.orders.txFee}
                />
                <ReadonlyTextField
                  label="Transaction Fee Cost"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: fiatType,
                  }).format(txFeeCost || 0)}
                  fullWidth
                  tooltip={tips.orders.txFeeCost}
                />
              </Grid>
              {hasCustomer && (
                <Grid item xs={12} md={6} xl={4}>
                  <Typography variant="h6" gutterBottom>
                    Customer
                  </Typography>
                  <ReadonlyTextField
                    label="Approved"
                    value={
                      <Label color={approved ? "success" : "error"}>
                        {approved ? "yes" : "no"}
                      </Label>
                    }
                    fullWidth
                  />
                  <ReadonlyTextField
                    label="Full name"
                    value={customerName || <i>Not provided</i>}
                    fullWidth
                  />
                  <ReadonlyTextField
                    label="Phone"
                    value={phone || <i>Not provided</i>}
                    fullWidth
                  />
                  <ReadonlyTextField
                    label="Email"
                    value={email || <i>Not provided</i>}
                    fullWidth
                  />
                  <ReadonlyTextField
                    label="Address"
                    value={address || <i>Not provided</i>}
                    fullWidth
                  />
                </Grid>
              )}
              {attachments.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Attachments
                  </Typography>
                  <Grid container spacing={1} style={{ padding: "8px 0" }}>
                    {attachments.map(({ id, url }) => (
                      <Grid item key={id}>
                        <Card variant="outlined">
                          <CardActionArea
                            component="a"
                            href={url}
                            target="_blank"
                          >
                            <CardMedia
                              image={url}
                              title={id}
                              style={{ width: 88, height: 88 }}
                            />
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {cashLog && cashLog.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Cash operations log
                  </Typography>
                  <div style={{ overflow: "auto" }}>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Denomination</TableCell>
                          <TableCell>Currency</TableCell>
                          <TableCell>Created At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cashLog.map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell sx={{ py: 0 }}>
                              <Label
                                color={
                                  row.type === "DEPOSIT" ? "success" : "error"
                                }
                              >
                                {row.type}
                              </Label>
                            </TableCell>
                            <TableCell>{row.denomination}</TableCell>
                            <TableCell>{row.currency}</TableCell>
                            <TableCell>
                              {moment(row.createdAt).format("L, LTS")}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableHead>
                        <TableRow>
                          <TableCell>Total</TableCell>
                          <TableCell colSpan={2}>
                            {cashLog.length} bills
                          </TableCell>
                          <TableCell colSpan={2}>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: fiatType,
                            }).format(cashTotal)}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </div>
                </Grid>
              )}
              {statusLog.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Statuses log
                  </Typography>

                  <Timeline
                    sx={{
                      [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                      },
                    }}
                  >
                    <TimelineItem>
                      <TimelineOppositeContent color="textSecondary">
                        {moment(createdAt).format("L \nLTS")}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot color="info" />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography>Created</Typography>
                      </TimelineContent>
                    </TimelineItem>
                    {statusLog.map(
                      ({ id, createdAt, initiator, status, user }, index) => (
                        <TimelineItem key={id}>
                          <TimelineOppositeContent
                            color="textSecondary"
                            sx={{ m: "auto 0" }}
                          >
                            {moment(createdAt).format("L \nLTS")}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot
                              color={getStatusColor(status.toUpperCase())}
                            />
                            {index !== statusLog.length - 1 ? (
                              <TimelineConnector />
                            ) : (
                              <TimelineConnector
                                sx={{ backgroundColor: " transparent" }}
                              />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="h6" component="span">
                              {status}
                            </Typography>
                            <Typography>
                              {user ? `@${user.userName}` : initiator}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      )
                    )}
                  </Timeline>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="orders" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {atm && hasPermission("machines", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconLocalAtm />}
                        to={`/machines/${atm.id}`}
                        title="Machine"
                        description="View machine this order was placed on"
                      />
                    </Grid>
                  )}
                  {hasCustomer && hasPermission("customers", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconPerson />}
                        to={`/customers/${customer.id}`}
                        title="Customer"
                        description="View customer details"
                      />
                    </Grid>
                  )}
                  {queueId && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconQueue />}
                        to={`/orderqueue/${queueId}`}
                        title="Queue"
                        description="View queue details"
                      />
                    </Grid>
                  )}
                  {hasCampaign && hasPermission("campaigns", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconFlag />}
                        to={`/campaigns/${campaign.id}/update`}
                        title="Campaign"
                        description="View campaign applied to this order"
                      />
                    </Grid>
                  )}
                  {hasTransaction &&
                    hasPermission("wallettransactions", "GET") && (
                      <Grid item xs={12} sm={6} xl={12}>
                        <ShortCard
                          icon={<IconWalletTransaction />}
                          to={`/wallettransactions/${walletTransactionId}`}
                          title="Transaction"
                          description="View blockchain transaction details"
                        />
                      </Grid>
                    )}
                  {sessions.length === 1 &&
                    hasPermission("trackingsessions", "GET") && (
                      <Grid item xs={12} sm={6} xl={12}>
                        <ShortCard
                          icon={<IconSession />}
                          to={`/sessions/${sessions[0].id}`}
                          title="Session"
                          description="View session details"
                        />
                      </Grid>
                    )}
                  {sessions.length > 1 &&
                    hasPermission("trackingsessions", "GET") && (
                      <Grid item xs={12} sm={6} xl={12}>
                        <ShortCard
                          icon={<IconSession />}
                          to={`/sessions?orderId=${id}`}
                          title="Sessions"
                          description="View order sessions"
                        />
                      </Grid>
                    )}
                  {hasPermission("useractions", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconLogs />}
                        to={`/changelog?entityType=orders&entityId=${id}`}
                        title="Changelog"
                        description="View order changes made by users"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} xl={12}>
                    <ShortCard
                      icon={<ReceiptIcon />}
                      href={`${apiUrl}/orders/${id}/receipt`}
                      target="_blank"
                      title="Receipt"
                      description="View order original receipt"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

OrdersReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("orders", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "orders",
        entityId: params.id,
      })
    ),
  ]);
};

export default OrdersReadPage;
