import * as React from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { QRCodeSVG as QRCode } from "qrcode.react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconEdit from "@mui/icons-material/Edit";
import IconLogs from "@mui/icons-material/Dvr";
import IconOrders from "@mui/icons-material/InsertDriveFile";
import IconPrint from "@mui/icons-material/Print";
import IconSession from "@mui/icons-material/Web";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MuiLink from "@mui/material/Link";
import JSONPretty from "react-json-prettify";
import { github } from "react-json-prettify/dist/themes";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
  customUpdateRequest,
  notificationSend,
  resourceUpdateSuccess,
} from "store/actions";
import { fromEntities, fromCustom } from "store/selectors";
import { hasPermission } from "services/permission";
import { ReadonlyTextField, ShortCard, NotesList, Label } from "components";

const CustomersReadPage = () => {
  const qrRef = React.useRef();
  const params = useParams();
  const { detail, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      detail: fromEntities.getDetail(state, "customers", params.id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const {
    address,
    alternativeSnapshotUrl,
    attachments: files,
    createdAt,
    firstName,
    id,
    idBack,
    idFront,
    idNumber,
    key,
    lastName,
    middleName,
    phone,
    snapshot,
    updatedAt,
    userName,
    approved,
    occupation,
    email,
    socialSecurityNumber,
    idExpirationDate,
    kycProviders = [],
    orderReceipts,
    ofac,
    zip,
    orderNotes,
  } = detail;

  const updateOfacHandle = React.useCallback(() => {
    return dispatch(customUpdateRequest(`customers/${id}/ofac`, {}))
      .then((data) => {
        dispatch(resourceUpdateSuccess("customers", data, id, null));
        dispatch(
          notificationSend("Customer OFAC data updated", { variant: "success" })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to update OFAC data`, {
            variant: "error",
          })
        );
      });
  }, [dispatch, id]);

  const attachments = React.useMemo(() => {
    let attachments = files || [];
    if (snapshot && !attachments.find(({ url }) => url === snapshot))
      attachments.push({ id: "snapshot", url: snapshot });
    if (idFront && !attachments.find(({ url }) => url === idFront))
      attachments.push({ id: "idFront", url: idFront });
    if (idBack && !attachments.find(({ url }) => url === idBack))
      attachments.push({ id: "idBack", url: idBack });
    if (
      alternativeSnapshotUrl &&
      !attachments.find(({ url }) => url === alternativeSnapshotUrl)
    )
      attachments.push({ id: "idBack", url: alternativeSnapshotUrl });
    return attachments;
  }, [alternativeSnapshotUrl, files, idBack, idFront, snapshot]);

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <React.Fragment>
            Customer
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${key}`}</Typography>
          </React.Fragment>
        }
        action={
          hasPermission("customers", "PUT") && (
            <React.Fragment>
              <Button
                startIcon={<AssignmentIcon />}
                disabled={!firstName || !lastName}
                onClick={() => updateOfacHandle()}
              >
                {`Get OFAC Report${
                  !firstName && !lastName ? "(name required)" : ""
                }`}
              </Button>
              <Button
                startIcon={<IconEdit />}
                component={Link}
                to={`/customers/${id}/update`}
              >
                Edit
              </Button>
            </React.Fragment>
          )
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={3}>
                <Typography variant="h6" gutterBottom>
                  General
                </Typography>
                <ReadonlyTextField label="Customer id" value={id} fullWidth />
                <ReadonlyTextField
                  label="Approved"
                  value={
                    approved ? (
                      <Label color="success">Yes</Label>
                    ) : (
                      <Label color="error">No</Label>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField label="Customer key" value={key} fullWidth />
                <ReadonlyTextField
                  label="ID Expiration Date"
                  value={
                    idExpirationDate ? (
                      moment(idExpirationDate).format("MM/DD/YYYY")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={
                    createdAt ? (
                      moment(createdAt).format("L, LTS")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Updated At"
                  value={
                    updatedAt ? (
                      moment(updatedAt).format("L, LTS")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                {kycProviders.length > 0 && (
                  <ReadonlyTextField
                    label="KYC providers"
                    value={
                      <Grid container spacing={1} style={{ padding: "8px 0" }}>
                        {kycProviders.map(({ id, name }) => (
                          <Grid item key={id}>
                            <Chip label={name} />
                          </Grid>
                        ))}
                      </Grid>
                    }
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <Typography variant="h6" gutterBottom>
                  Personal
                </Typography>
                <ReadonlyTextField
                  label="First Name"
                  value={firstName || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Middle Name"
                  value={middleName || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Last Name"
                  value={lastName || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Phone"
                  value={phone || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Address"
                  value={address || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="ZIP"
                  value={zip || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Username"
                  value={userName || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Occupation"
                  value={occupation || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Email"
                  value={email || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="ID Number"
                  value={idNumber || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Social Security Number"
                  value={socialSecurityNumber || <i>[not provided]</i>}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} xl={2}>
                <Typography variant="h6" gutterBottom>
                  Statistics
                </Typography>
                <ReadonlyTextField
                  label="Number of Orders"
                  value={detail.orderCount}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Total Fiat Amount"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(detail.totalFiatAmount)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="First Machine Used"
                  value={
                    detail.firstMachine ? (
                      <MuiLink
                        component={Link}
                        to={`/machines/${detail.firstMachine.atmId}`}
                        underline="hover"
                      >
                        {detail.firstMachine.name}
                      </MuiLink>
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Last Machine Used"
                  value={
                    detail.lastMachine ? (
                      <MuiLink
                        component={Link}
                        to={`/machines/${detail.lastMachine.atmId}`}
                        underline="hover"
                      >
                        {detail.lastMachine.name}
                      </MuiLink>
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xl={4}>
                <Typography variant="h6" gutterBottom>
                  Customer key
                </Typography>
                <ReadonlyTextField label="Key" value={key} fullWidth />
                <div ref={qrRef}>
                  <QRCode
                    style={{ maxWidth: 200 }}
                    id="customer-qr-code"
                    value={`customer://${key}`}
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  />
                </div>
                <ReactToPrint
                  trigger={() => (
                    <Button variant="outlined" startIcon={<IconPrint />}>
                      Print Customer key
                    </Button>
                  )}
                  content={() => qrRef.current}
                />
              </Grid>
              {ofac && (
                <Grid item xl={12}>
                  <Typography variant="h6" gutterBottom>
                    OFAC
                  </Typography>
                  <JSONPretty theme={github} json={ofac} />
                </Grid>
              )}
              {attachments.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Attachments
                  </Typography>
                  <Grid container spacing={1} style={{ padding: "8px 0" }}>
                    {attachments.map(({ id, url }) => (
                      <Grid item key={id}>
                        <Card variant="outlined">
                          <CardActionArea
                            component="a"
                            href={url}
                            target="_blank"
                          >
                            <CardMedia
                              image={url}
                              title={id}
                              style={{ width: 88, height: 88 }}
                            />
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {orderReceipts.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Receipts
                  </Typography>
                  <div style={{ overflow: "auto" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>To</TableCell>
                          <TableCell>Order Id</TableCell>
                          <TableCell>Created At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderReceipts.map(
                          ({ id, phone, email, orderId, createdAt }) => (
                            <TableRow key={id}>
                              <TableCell>{phone ? "Phone" : "Email"}</TableCell>
                              <TableCell>{phone || email}</TableCell>
                              <TableCell>
                                <MuiLink
                                  component={Link}
                                  to={`/orders/${orderId}`}
                                  underline="hover"
                                >
                                  {orderId}
                                </MuiLink>
                              </TableCell>
                              <TableCell>
                                {moment(createdAt).format("L, LTS")}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="customers" entityId={id} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Order Notes
                </Typography>
                <NotesList
                  entityType="orders"
                  entityId={id}
                  notes={orderNotes}
                  displayForm={false}
                  displayEntityLink={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("orders", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconOrders />}
                        to={`/orders?customerKey=${key}`}
                        title="Orders"
                        description="View all customer orders"
                      />
                    </Grid>
                  )}
                  {hasPermission("trackingsessions", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconSession />}
                        to={`/sessions?customerId=${id}`}
                        title="Sessions"
                        description="View all customer sessions"
                      />
                    </Grid>
                  )}
                  {hasPermission("useractions", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconLogs />}
                        to={`/changelog?entityType=customers&entityId=${id}`}
                        title="Changelog"
                        description="View customer profile changes made by users"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CustomersReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("customers", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "customers",
        entityId: params.id,
      })
    ),
  ]);
};

export default CustomersReadPage;
