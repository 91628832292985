import * as React from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconAdd from "@mui/icons-material/Add";
import IconView from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { hasPermission } from "services/permission";
import {
  resourceListReadRequest,
  resourceDeleteRequest,
  notificationSend,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import {
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Confirmation,
} from "components";

const ApiKeysListPage = () => {
  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "apikeys"),
      items: fromEntities.getList(
        state,
        "apikeys",
        fromResource.getList(state, "apikeys")
      ),
      loading: pending(state, "apikeysListRead"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const deleteItem = (id) =>
    dispatch(resourceDeleteRequest("apikeys", id))
      .then(() => {
        dispatch(
          notificationSend("Key deleted successfully", { variant: "success" })
        );
      })
      .catch(() => {
        dispatch(
          notificationSend("Failed to delete key", { variant: "error" })
        );
      });

  const handleClickDelete = (id) => () => {
    deleteItem(id);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="API Keys"
        toolbar={
          <React.Fragment>
            {hasPermission("apikeys", "POST") && (
              <div>
                <Button
                  startIcon={<IconAdd />}
                  component={Link}
                  to="/settings/apikeys/create"
                >
                  Add API Key
                </Button>
              </div>
            )}
            <Button
              startIcon={<IconView />}
              component={Link}
              to="/settings/apikeys/docs"
            >
              View API Docs
            </Button>
          </React.Fragment>
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "5%" }}>
              <TableSortLabel
                active={sort === "name"}
                component={Link}
                direction={order}
                to={getSortLink("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].name || (
                <Typography variant="caption" color="textSecondary">
                  Missing
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "80%" }}>
              <TableSortLabel
                active={sort === "key"}
                component={Link}
                direction={order}
                to={getSortLink("key")}
              >
                Key
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].key}</TableCell>}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "15%" }}>
              <TableSortLabel
                active={sort === "permissions"}
                component={Link}
                direction={order}
                to={getSortLink("permissions")}
              >
                Permissions
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].permissions.join(", ")}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Confirmation>
                  {(show, confirm, hide, open) => (
                    <React.Fragment>
                      <Tooltip enterDelay={500} title="Delete" placement="top">
                        <IconButton
                          onClick={show(handleClickDelete(items[rowIndex].id))}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Dialog open={open}>
                        <DialogTitle>{`Are you sure you want to delete API key #${items[rowIndex].id}?`}</DialogTitle>
                        <DialogActions>
                          <Button onClick={hide}>Cancel</Button>
                          <Button onClick={confirm} color="primary" autoFocus>
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </React.Fragment>
                  )}
                </Confirmation>
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

ApiKeysListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("apikeys", { _limit: 100, ...query })
  );
};

export default ApiKeysListPage;
