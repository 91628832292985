import { Fragment } from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconEdit from "@mui/icons-material/Edit";
import IconLocalAtm from "@mui/icons-material/LocalAtm";
import IconPerson from "@mui/icons-material/Person";
import {
  customReadRequest,
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import {
  ReadonlyTextField,
  ShortCard,
  IconSmartEmpties,
  NotesList,
  Label,
} from "components";
import { hasPermission } from "services/permission";

const SmartemptiesReadPage = () => {
  const params = useParams();
  const { detail, statuses, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      detail: fromEntities.getDenormalizedDetail(
        state,
        "smartempties",
        params.id
      ),
      statuses:
        fromCustom.get(state, "config/smartempties", {}).statusList || [],
    }),
    shallowEqual
  );

  const {
    id,
    amountEmptied,
    amountFloat,
    cryptoCurrencyAmount,
    cryptoCurrencySpotPrice,
    createdAt,
    machine,
    notesEmptied,
    operator,
    operatorId,
    status,
    statusLog,
  } = detail;

  const getColor = (v) => {
    const status = statuses.find(({ name }) => name === v);
    if (status) return status.level;
    return "default";
  };

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Smart empty
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
        action={
          hasPermission("smartempties", "PUT") && (
            <Button
              startIcon={<IconEdit />}
              component={Link}
              to={`/smartempties/${id}/update`}
            >
              Edit
            </Button>
          )
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  General
                </Typography>
                <ReadonlyTextField
                  label="Smart empty id"
                  value={id}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={
                    createdAt ? (
                      moment(createdAt).format("L, LTS")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Atm"
                  value={(machine && machine.name) || <i>[missing]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Opearator"
                  value={
                    (operator && operator.firstName) ||
                    (operatorId && operatorId !== "from-dashboard"
                      ? operatorId
                      : "Dashboard") || <i>[missing]</i>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Financial
                </Typography>
                <ReadonlyTextField
                  label="Amount Emptied"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(amountEmptied || 0)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Amount Float"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(amountFloat || 0)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="BTC Amount"
                  value={`${cryptoCurrencyAmount || 0} BTC`}
                  fullWidth
                />
                <ReadonlyTextField
                  label="BTC Spot"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(cryptoCurrencySpotPrice || 0)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Status"
                  value={<Label color={getColor(status)}>{status}</Label>}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Status log
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "1%" }}>#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell sx={{ width: 115 }}>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statusLog.map(({ status, createdAt }, index) => (
                      <TableRow key={status}>
                        <TableCell>{index}</TableCell>
                        <TableCell sx={{ py: 0 }}>
                          <Label color={getColor(status)}>{status}</Label>
                        </TableCell>
                        <TableCell sx={{ py: 0 }}>
                          {moment(createdAt).format("L, LTS")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              {notesEmptied && notesEmptied.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Cash log
                  </Typography>
                  <Table
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.divider}`,
                    })}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>Denomination</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell>Count</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {notesEmptied.map((row) => (
                        <TableRow key={`${row.denomination}${row.currency}`}>
                          <TableCell>{row.denomination}</TableCell>
                          <TableCell>{row.currency}</TableCell>
                          <TableCell>{row.count}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="smartempties" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {machine && hasPermission("machines", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconLocalAtm />}
                        to={`/machines/${machine.id}`}
                        title="Machine"
                        description="View machine this smartempty is related to"
                      />
                    </Grid>
                  )}
                  {machine && hasPermission("smartempties", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconSmartEmpties />}
                        to={`/smartempties?atmId=${machine.id}`}
                        title="Smart Empties"
                        description="View other smart empties for the machine"
                      />
                    </Grid>
                  )}
                  {operator && hasPermission("operators", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconPerson />}
                        to={`/operators/${operator.id}`}
                        title="Operator"
                        description="View operator information who initiated smart empty"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SmartemptiesReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("smartempties", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "smartempties",
        entityId: params.id,
      })
    ),
    store.dispatch(customReadRequest("config/smartempties")),
  ]);
};

export default SmartemptiesReadPage;
