import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { customReadRequest } from "store/actions";
import { FulfillmentSettingsForm } from "components";

const MachinesSettingsPage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Fulfillment Settings" />
      <Divider />
      <FulfillmentSettingsForm />
    </Card>
  );
};

MachinesSettingsPage.get = ({ store }) => {
  return store.dispatch(customReadRequest("config/fulfillment"));
};

export default MachinesSettingsPage;
