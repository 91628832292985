import { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { trim } from "services/helpers";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { TextField } from "mui-rff";
import { authSigninRequest } from "store/actions";
import { fromAuth, fromCustom } from "store/selectors";
import { createValidator, required, minLength } from "services/validation";

const StyledLinearProgress = styled(LinearProgress)({
  borderRadius: "2px 2px 0 0",
  overflow: "hidden",
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 2,
});

const StyledOverlay = styled("div")({
  backgroundColor: "rgba(255, 255, 255, 0.38)",
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 1,
});

const validate = createValidator({
  email: [required, minLength(4)],
  password: [required, minLength(4)],
});

const SigninForm = () => {
  const dispatch = useDispatch();
  const { user, organization } = useSelector(
    (state) => ({
      organization: fromCustom.get(state, "organization"),
      user: fromAuth.getUser(state),
    }),
    shallowEqual
  );

  const onSubmit = (data, form, callback) => {
    dispatch(authSigninRequest(data))
      .then(() => callback())
      .catch(() => {
        return callback({
          [FORM_ERROR]: "Please check login data and try again",
        });
      });
  };

  const { name } = organization;

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({
        dirtySinceLastSubmit,
        handleSubmit,
        hasSubmitErrors,
        pristine,
        submitError,
        submitting,
        valid,
        hasValidationErrors,
        form: { reset },
      }) => (
        <form onSubmit={handleSubmit}>
          {!user && (
            <Card>
              {submitting && <StyledLinearProgress />}
              {submitting && <StyledOverlay />}
              <CardHeader
                sx={{
                  pb: 0,
                }}
                title="Sign in"
                subheader={`with your ${name} account`}
              />
              <CardContent
                sx={{
                  py: 0,
                }}
              >
                {hasSubmitErrors && (
                  <Typography color="error">{submitError}</Typography>
                )}
                <Fragment>
                  <TextField
                    name="email"
                    label="Username"
                    type="text"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    fieldProps={{ format: trim }}
                    autoFocus
                  />
                  <TextField
                    name="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                    fieldProps={{ format: trim }}
                  />
                  <TextField
                    name="token"
                    label="2-FA Code (If Enabled)"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    fieldProps={{ format: trim }}
                    autoComplete="none"
                    autoFocus
                  />
                </Fragment>
              </CardContent>
              <CardActions
                sx={{ p: 2, pt: 1, display: "flex", justifyItems: "stretch" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  disableElevation
                  color="primary"
                  type="submit"
                  fullWidth
                  disabled={
                    pristine ||
                    submitting ||
                    (!valid && !dirtySinceLastSubmit) ||
                    hasValidationErrors
                  }
                >
                  Sign in
                </Button>
              </CardActions>
            </Card>
          )}
          {user && (
            <Card
              style={{
                height: 284,
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CardContent>
                <Typography variant="h5" align="center">
                  Welcome {user.userName}!
                </Typography>
              </CardContent>
            </Card>
          )}
        </form>
      )}
    />
  );
};

export default SigninForm;
