import { useState } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconEdit from "@mui/icons-material/Edit";
import IconAdd from "@mui/icons-material/Add";
import IconFilter from "@mui/icons-material/FilterList";
import { hasPermission } from "services/permission";
import { resourceListReadRequest } from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import {
  Filter,
  Label,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
} from "components";

const filters = {
  archived: {
    label: "Show archived",
    type: "boolean",
  },
};

const UsersListPage = () => {
  const [filterOpen, setFilterOpen] = useState(false);

  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "users"),
      items: fromEntities.getDenormalizedList(
        state,
        "users",
        fromResource.getList(state, "users")
      ),
      loading: pending(state, "usersListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Users"
        toolbar={
          <>
            {hasPermission("users", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/settings/users/create"
              >
                Add user
              </Button>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </>
        }
        tableBefore={
          <Filter
            filters={filters}
            open={filterOpen}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "98%" }}>
              <TableSortLabel
                active={sort === "userName"}
                component={Link}
                direction={order}
                to={getSortLink("userName")}
              >
                User name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].userName || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "firstName"}
                component={Link}
                direction={order}
                to={getSortLink("firstName")}
              >
                First Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].firstName || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "lastName"}
                component={Link}
                direction={order}
                to={getSortLink("lastName")}
              >
                Last Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].lastName || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "roleId"}
                component={Link}
                direction={order}
                to={getSortLink("roleId")}
              >
                User Role
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Label>{items[rowIndex].role.name}</Label>
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                {hasPermission("users", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/settings/users/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

UsersListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("users", { _limit: 100, ...query })
  );
};

export default UsersListPage;
