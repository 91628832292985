import * as React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconSettings from "@mui/icons-material/Settings";
import IconSmartphone from "@mui/icons-material/Smartphone";
import IconOrders from "@mui/icons-material/InsertDriveFile";
import IconAtms from "@mui/icons-material/LocalAtm";
import IconCustomers from "@mui/icons-material/People";
import IconAverage from "@mui/icons-material/TrendingUp";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import IconRecipients from "@mui/icons-material/AccountBox";
import IconPayments from "@mui/icons-material/Payment";
import IconWallets from "@mui/icons-material/AccountBalanceWallet";
import IconPerformance from "@mui/icons-material/BarChart";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import IconDashboard from "@mui/icons-material/Dashboard";
import IconNotes from "@mui/icons-material/Comment";
import IconLogs from "@mui/icons-material/Dvr";
import IconFlag from "@mui/icons-material/EmojiFlags";
import IconWalletTransaction from "@mui/icons-material/Toc";
import IconAction from "@mui/icons-material/Web";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import IconEvent from "@mui/icons-material/Event";
import {
  IconSmartEmpties,
  IconOperators,
  IconLoansAvailable,
  IconLoansHistory,
} from "components";
import { version, branch } from "config";
import { hasPermission } from "services/permission";

const PREFIX = "AppDrawer";

const classes = {
  paper: `${PREFIX}-paper`,
  title: `${PREFIX}-title`,
  listItem: `${PREFIX}-listItem`,
  listItemSub: `${PREFIX}-listItemSub`,
  listItemIcon: `${PREFIX}-listItemIcon`,
  listItemDot: `${PREFIX}-listItemDot`,
  listHeader: `${PREFIX}-listHeader`,
  toolbar: `${PREFIX}-toolbar`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  nav: `${PREFIX}-nav`,
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    width: 240,
  },
  [`& .${classes.paper}`]: {
    width: 240,
    "&::-webkit-scrollbar": {
      background: "transparent",
      width: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.action.disabled,
      height: 56,
      border: "none",
      display: "none",
    },
    "&:hover::-webkit-scrollbar-thumb": {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "transparent",
      borderRight: "0 !important",
      // flex: '0 0 auto',
      height: "calc(100vh - 56px)",
      top: 56,
      // zIndex: theme.zIndex.menu,
    },
    [theme.breakpoints.up("md")]: {
      top: 64,
      height: "calc(100vh - 64px)",
    },
  },

  [`& .${classes.title}`]: {
    color: theme.palette.text.secondary,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.listItem}`]: {
    color: theme.palette.text.primary,
    [`&.active > .${classes.listItemIcon}`]: {
      color: `${theme.palette.secondary.light} !important`,
    },
    [`&.active > .${classes.listItemIcon} > .${classes.listItemDot}`]: {
      backgroundColor: theme.palette.secondary.light,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
    },
  },

  [`& .${classes.listItemSub}`]: {},

  [`& .${classes.listItemIcon}`]: {
    color: theme.palette.text.secondary,
    width: 24,
  },

  [`& .${classes.listItemDot}`]: {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: "50%",
    margin: "auto",
    width: 8,
    height: 8,
  },

  [`& .${classes.listHeader}`]: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 24,
    },
  },

  [`& .${classes.toolbar}`]: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },

  [`&.${classes.drawer}`]: {
    background: "transparent",
    width: drawerWidth,
    // flexShrink: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("lg")]: {
      top: 64,
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },

  [`&.${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`&.${classes.drawerClose}`]: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },

  [`& .${classes.nav}`]: {
    flex: "1 1 auto",
  },
}));

const drawerWidth = 240;

export {};

const links = [
  [
    {
      to: "/summary",
      label: "Summary",
      icon: IconDashboard,
      permission: "summary",
    },
    {
      to: "/machines",
      label: "Machines",
      icon: IconAtms,
      permission: "machines",
    },
    {
      to: "/orders",
      label: "Orders",
      icon: IconOrders,
      permission: "orders",
    },
  ],
  [
    {
      label: "Wallets",
    },
    {
      to: "/wallets",
      label: "Wallets list",
      icon: IconWallets,
      permission: "wallets",
    },
    {
      to: "/wallettransactions",
      label: "Transactions",
      icon: IconWalletTransaction,
      permission: "wallettransactions",
    },
  ],
  [
    {
      label: "Reports",
    },
    {
      to: "/movingaverage",
      label: "Charts",
      icon: IconAverage,
      permission: "movingaverage",
    },
    {
      to: "/performance",
      label: "Performance",
      icon: IconPerformance,
      permission: "performance",
    },
    {
      to: "/revenue",
      label: "Revenue",
      icon: MultilineChartIcon,
      permission: "revenuereport",
    },
    {
      to: "/machinerevenuereport",
      label: "Machines Revenue",
      icon: TimelineIcon,
      permission: "machinerevenuereport",
    },
    {
      to: "/limitedmachinereport",
      label: "Machines",
      icon: SsidChartIcon,
      permission: "limitedmachinereport",
    },
    {
      to: "/machinerentreport",
      label: "Machines Rent",
      icon: StackedBarChartIcon,
      permission: "limitedmachinereport",
    },
  ],
  [
    {
      label: "Loans",
      permission: "loans",
    },
    {
      to: "/loans/available",
      label: "Available",
      icon: IconLoansAvailable,
      permission: "loans",
    },
    {
      to: "/loans/history",
      label: "History",
      icon: IconLoansHistory,
      permission: "loans",
    },
  ],
  [
    {
      label: "Operation",
    },
    {
      to: "/operators",
      label: "Operators",
      icon: IconOperators,
      permission: "operators",
    },
    {
      to: "/smartempties",
      label: "Smart empties",
      icon: IconSmartEmpties,
      permission: "smartempties",
    },
    {
      to: "/notes",
      label: "Notes",
      icon: IconNotes,
      permission: "notes",
    },
  ],
  [
    {
      label: "Auto Payments",
      permission: "autopaymentsrecipients",
    },
    {
      to: "/autopayments/recipients",
      label: "List of Payees",
      icon: IconRecipients,
      permission: "autopaymentsrecipients",
    },
    {
      to: "/autopayments/payments",
      label: "Payment History",
      icon: IconPayments,
      permission: "autopayments",
    },
  ],
  [
    {
      label: "Expenses",
      permission: "autopaymentsrecipients",
    },
    {
      to: "/expenses/purposes",
      label: "Expense Purposes",
      icon: AssignmentTurnedInIcon,
      permission: "expenses",
    },
    {
      to: "/expenses/logs",
      label: "Expense History",
      icon: AssignmentIcon,
      permission: "expenselogs",
    },
  ],
  [
    {
      label: "Compliance",
    },
    {
      to: "/customers",
      label: "Customers",
      icon: IconCustomers,
      permission: "customers",
    },
    {
      to: "/walletblacklist",
      label: "Wallets Blacklist",
      icon: IconWallets,
      permission: "walletblacklist",
    },
    {
      to: "/phoneblacklist",
      label: "Phone Blacklist",
      icon: IconSmartphone,
      permission: "phoneblacklist",
    },
    {
      to: "/campaigns",
      label: "Campaigns",
      icon: IconFlag,
      permission: "campaigns",
    },
  ],
  [
    {
      label: "Logs",
    },
    {
      to: "/changelog",
      label: "Changelog",
      icon: IconLogs,
      permission: "useractions",
    },
    {
      to: "/sessions",
      label: "Sessions",
      icon: IconAction,
      permission: "trackingsessions",
    },
    {
      to: "/machineevents",
      label: "Machine events",
      icon: IconEvent,
      permission: "machineevents",
    },
  ],
];

const settingsLinks = [
  {
    to: "/settings/organization",
    label: "Organization",
    permission: "organization",
  },
  {
    to: "/settings/machines",
    label: "Machines",
    permission: "config",
  },
  {
    to: "/settings/fulfillment",
    label: "Fulfillment",
    permission: "config",
  },
  {
    to: "/settings/users",
    label: "Users",
    permission: "users",
  },
  {
    to: "settings/userauthsessions",
    label: "User Auth Sessions",
    permission: "config",
  },
  {
    to: "/settings/exchanges",
    label: "Exchanges",
    permission: "exchanges",
  },
  {
    to: "/settings/kyclimits",
    label: "KYC Limits",
    permission: "config",
  },
  {
    to: "/settings/smartempties",
    label: "Smart Empties",
    permission: "config",
  },
  {
    to: "/settings/apikeys",
    label: "API Keys",
    permission: "apikeys",
  },
  {
    to: "/settings/twilio",
    label: "Twilio",
    permission: "config",
  },
];

const AdapterLink = React.forwardRef((props, ref) => (
  <NavLink ref={ref} {...props} />
));

const AppDrawer = (props) => {
  const location = useLocation();

  const menuLinks = React.useMemo(
    () =>
      links
        .map((l) => {
          let label;
          if (!l[0].permission) label = l[0];

          const ls = l.filter(
            ({ permission }) => permission && hasPermission(permission, "GET")
          );
          if (label && ls.length > 0) return [label, ...ls];
          if (ls.length > 0) return [...ls];
          return [];
        })
        .filter((l) => l.length > 0),
    []
  );

  const menuSettingsLinks = settingsLinks.filter(
    ({ permission }) => !permission || hasPermission(permission, "GET")
  );

  const [open, setOpen] = React.useState(/settings/.test(location.pathname));

  const isSettignsActive = React.useMemo(
    () => /settings/.test(location.pathname),
    [location.pathname]
  );

  const handleClickToggleOpen = () => {
    setOpen((state) => !state);
  };

  const { className, mobileOpen, onClose, title, variant } = props;

  return (
    <StyledDrawer
      open={mobileOpen}
      variant={variant}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: mobileOpen,
        [classes.drawerClose]: !mobileOpen,
        className,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: mobileOpen,
          [classes.drawerClose]: !mobileOpen,
        }),
      }}
      onClose={onClose}
    >
      <div className={classes.nav}>
        <Toolbar className={classes.toolbar}>
          {title !== null && (
            <Typography
              variant="h6"
              className={classes.title}
              component={NavLink}
              to="/"
              onClick={onClose}
            >
              {title}
            </Typography>
          )}
          <Divider absolute />
        </Toolbar>
        <List component="div">
          {menuLinks.map((link, index) => {
            if (Array.isArray(link)) {
              return (
                <React.Fragment key={index}>
                  <List component="div" disablePadding>
                    {link.map((l) => {
                      if (l.to) {
                        return (
                          <ListItemButton
                            key={l.to}
                            to={l.to}
                            exact={l.exact}
                            component={AdapterLink}
                            onClick={onClose}
                            className={classes.listItem}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              {l.icon && <l.icon />}
                            </ListItemIcon>
                            <ListItemText primary={l.label} />
                          </ListItemButton>
                        );
                      }
                      return (
                        <ListSubheader
                          key={l.label}
                          className={classes.listHeader}
                          component="div"
                          disableSticky
                        >
                          {l.label}
                        </ListSubheader>
                      );
                    })}
                  </List>
                  <Divider />
                </React.Fragment>
              );
            }
            if (link.to) {
              return (
                <ListItemButton
                  key={link.to}
                  to={link.to}
                  exact={link.exact}
                  component={AdapterLink}
                  onClick={onClose}
                  className={classes.listItem}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {link.icon && <link.icon />}
                  </ListItemIcon>
                  <ListItemText primary={link.label} />
                </ListItemButton>
              );
            }
            return (
              <ListSubheader
                key={link.label}
                className={classes.listHeader}
                component="div"
                disableSticky
              >
                {link.label}
              </ListSubheader>
            );
          })}
        </List>
        {menuSettingsLinks.length > 0 && (
          <List component="div" disablePadding>
            <ListItemButton
              onClick={handleClickToggleOpen}
              className={clsx(classes.listItem, {
                active: isSettignsActive,
              })}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <IconSettings />
              </ListItemIcon>
              <ListItemText primary="Settings" />
              {open ? <IconExpandLess /> : <IconExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {menuSettingsLinks.map((link) => (
                  <ListItemButton
                    key={link.to}
                    to={link.to}
                    exact={link.exact}
                    component={AdapterLink}
                    onClick={onClose}
                    className={clsx(classes.listItem, classes.listItemSub)}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <div className={classes.listItemDot} />
                    </ListItemIcon>
                    <ListItemText secondary={link.label} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
        )}
      </div>
      <div>
        <ListItemButton className={classes.listItem}>
          <ListItemText
            secondary={`Dashboard version: ${version}${
              branch === "master" ? `-${branch}` : ""
            }`}
          />
        </ListItemButton>
      </div>
    </StyledDrawer>
  );
};

AppDrawer.propTypes = {
  className: PropTypes.string,
  mobileOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["permanent", "persistent", "temporary"]),
};

export default AppDrawer;
