import { Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconView from "@mui/icons-material/RemoveRedEye";
import { customReadRequest, resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { SmartEmptyForm } from "components";

const SmartemptiesUpdatePage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "smartempties",
        params.id
      ),
    }),
    shallowEqual
  );

  const { id } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Smart empty
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
        action={
          <Button
            startIcon={<IconView />}
            component={Link}
            to={`/smartempties/${id}`}
          >
            Details
          </Button>
        }
      />
      <Divider />
      <SmartEmptyForm id={id} />
    </Card>
  );
};

SmartemptiesUpdatePage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("smartempties", params.id)),
    store.dispatch(customReadRequest("config/smartempties")),
  ]);
};

export default SmartemptiesUpdatePage;
