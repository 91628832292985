import get from "lodash/get";
import set from "lodash/set";
import isEmail from "validator/lib/isEmail";
import isInt from "validator/lib/isInt";
import isFloat from "validator/lib/isFloat";
import isIn from "validator/lib/isIn";
import isURL from "validator/lib/isURL";
import isBoolean from "validator/lib/isBoolean";
import isAlpha from "validator/lib/isAlpha";
import isUppercase from "validator/lib/isUppercase";
import isMobilePhone from "validator/lib/isMobilePhone";

const isEmpty = (value) =>
  value === undefined || value === null || value === "" || value.length === 0;
const join = (rules) => (value, data) =>
  rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export const email = (value) =>
  !isEmpty(value) && !isEmail(value) && "Invalid email address";

export const url = (value) => !isEmpty(value) && !isURL(value) && "Invalid URL";

export const boolean = (value = "") =>
  !isBoolean(String(value)) && "Must be true or false";

export const required = (value) => isEmpty(value) && "Required field";

export const minLength = (min) => (value) =>
  !isEmpty(value) && value.length < min && `Must be at least ${min} characters`;

export const maxLength = (max) => (value) =>
  !isEmpty(value) &&
  value.length > max &&
  `Must be no more than ${max} characters`;

export const minValue = (min) => (value) =>
  !isEmpty(value) && value < min && `Must be at least ${min}`;

export const maxValue = (max) => (value) =>
  !isEmpty(value) && value > max && `Must be no more than  ${max}`;

export const integer = (value = "") =>
  !isInt(String(value)) && "Must be an integer";

export const float = (value = "") =>
  !isFloat(String(value)) && "Must be an float";

export const alpha = (value = "") =>
  !isAlpha(String(value), undefined, { ignore: " " }) &&
  "Must contain letters only";

export const uppercase = (value = "") =>
  !isUppercase(String(value)) && "Must contain uppercase letters only";

export const oneOf = (values) => (value) =>
  !isIn(value, values) && `Must be one of: ${values.join(", ")}`;

export const match = (field) => (value, data) =>
  data && value !== data[field] && "Must match";

export const phone = (value) =>
  !isEmpty(value) && !isMobilePhone(value) && "Must be a valid phone number";

export const createValidator =
  (rules) =>
  (data = {}) => {
    const errors = {};
    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key]));
      const error = rule(get(data, key), data);
      if (error) {
        set(errors, key, error);
      }
    });
    return errors;
  };
