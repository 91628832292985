import { Fragment, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactReduxContext, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { authUserReadRequest } from "store/actions";
import fetchData from "services/fetchData";
import { ErrorPage } from "components";

const Init = ({ children, routes }) => {
  const [completed, setCompleted] = useState(0);
  const [error, setError] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { store } = useContext(ReactReduxContext);

  const callback = (completed) => {
    setCompleted(completed);
  };

  useEffect(() => {
    const authUserRead = () => dispatch(authUserReadRequest());
    if (!initialized)
      authUserRead()
        .then(() => fetchData(routes, location, store, callback))
        .catch((error) => {
          const errorCodeMatch = error
            .toString()
            .match(/401|402|403|404|50\d/m);
          if (errorCodeMatch) {
            const [errorCode] = errorCodeMatch;

            if (errorCode === "401") {
              const { pathname, search, hash } = location;
              if (pathname !== "/signin")
                navigate(`/signin?refferer=${btoa(pathname + search + hash)}`);
            } else {
              setError(error.toString());
              setErrorCode(errorCode);
            }
          } else {
            setError(error ? error.toString() : " Something went wrong");
            setErrorCode(500);
          }
        })
        .finally(() => {
          setTimeout(() => {
            setInitialized(true);
          }, 425);
        });
  }, [dispatch, initialized, location, navigate, routes, store]);

  if (errorCode) {
    return <ErrorPage code={errorCode} error={error} />;
  }

  return (
    <Fragment>
      <Dialog
        fullScreen
        open={!initialized}
        transitionDuration={{ enter: 0, exit: 195 }}
        maxWidth="sm"
        BackdropProps={{ invisible: true }}
      >
        {!initialized && (
          <Grid
            container
            style={{ width: "100%", height: "100%" }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={6} md={4}>
              <LinearProgress variant="determinate" value={completed} />
            </Grid>
          </Grid>
        )}
      </Dialog>
      {initialized && children}
    </Fragment>
  );
};

Init.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Init;
