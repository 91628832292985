import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { resourceListReadRequest } from "store/actions";
import { ExpenseLogsForm } from "components";

const ExpenseLogsCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Add Expense Payment" />
      <Divider />
      <ExpenseLogsForm />
    </Card>
  );
};

ExpenseLogsCreatePage.get = ({ store }) => {
  return store.dispatch(resourceListReadRequest("expenses", { _limit: 1000 }));
};

export default ExpenseLogsCreatePage;
