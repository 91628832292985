import { Fragment } from "react";
import PropTypes from "prop-types";
import { diff } from "deep-object-diff";
import Typography from "@mui/material/Typography";
import ArrowRightAlt from "@mui/icons-material/KeyboardArrowRight";

const renderString = (props) => {
  const { key, initialValue, diff, value, ...other } = props;

  return (
    <Typography key={key} {...other}>
      {`${key}: `}
      <s style={{ color: "red" }}>{initialValue}</s>{" "}
      <ArrowRightAlt style={{ verticalAlign: "top", fontSize: "22px" }} />{" "}
      <span style={{ color: "green" }}>{value}</span>
    </Typography>
  );
};

renderString.propTypes = {
  key: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  diff: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const renderArray = (props) => {
  const { key, initialValue, diff, value, ...other } = props;

  return (
    <Typography key={key} {...other}>
      {`${key}: `}
      {initialValue.length
        ? initialValue
            .map((iv, index) => {
              if (typeof iv === "string" || typeof iv === "number") {
                if (index in diff) {
                  return (
                    <s key={iv} style={{ color: "red" }}>
                      {iv}
                    </s>
                  );
                }
              } else if (Array.isArray(iv)) {
                if (index in diff) {
                  return (
                    <s key={iv.join()} style={{ color: "red" }}>
                      {iv.sort().join(" ")}
                    </s>
                  );
                }
                return <span key={iv.join()}>{iv.sort().join(" ")}</span>;
              } else if (typeof iv === "object") {
                if (index in diff) {
                  return (
                    <s key={Object.values(iv).join()} style={{ color: "red" }}>
                      {Object.values(iv).sort().join(" ")}
                    </s>
                  );
                }
                return (
                  <span key={Object.values(iv).join()}>
                    {Object.values(iv).sort().join(" ")}
                  </span>
                );
              }

              return <span key={iv}>{iv}</span>;
            })
            .reduce((prev, curr) => [prev, ", ", curr])
        : ""}{" "}
      <ArrowRightAlt style={{ verticalAlign: "top", fontSize: "22px" }} />{" "}
      {value.length
        ? value
            .map((v, index) => {
              if (typeof v === "string" || typeof v === "number") {
                if (index in diff) {
                  return (
                    <span key={v} style={{ color: "green" }}>
                      {v}
                    </span>
                  );
                }
              } else if (Array.isArray(v)) {
                if (index in diff) {
                  return (
                    <span key={v.join()} style={{ color: "green" }}>
                      {v.sort().join(" ")}
                    </span>
                  );
                }
                return <span key={v.join()}>{v.sort().join(" ")}</span>;
              } else if (typeof v === "object") {
                if (index in diff) {
                  return (
                    <span
                      key={Object.values(v).join()}
                      style={{ color: "green" }}
                    >
                      {Object.values(v).sort().join(" ")}
                    </span>
                  );
                }
                return (
                  <span key={Object.values(v).join()}>
                    {Object.values(v).sort().join(" ")}
                  </span>
                );
              }

              return <span key={v}>{v}</span>;
            })
            .reduce((prev, curr) => [prev, ", ", curr])
        : ""}
    </Typography>
  );
};

renderArray.propTypes = {
  key: PropTypes.string,
  initialValue: PropTypes.array,
  diff: PropTypes.object,
  value: PropTypes.array,
};

const renderObject = ({ key }) => {
  return <div key={key} />;
};

renderObject.propTypes = {
  key: PropTypes.string,
};

const DiffBlock = (props) => {
  const { initialValues, values, mappings } = props;
  const difference = diff(initialValues, values);

  return (
    <Fragment>
      {Object.keys(difference).map((key) => {
        const initialValue =
          mappings && mappings[key]
            ? mappings[key][initialValues[key]]
            : initialValues[key];
        const diff = difference[key];
        const value =
          mappings && mappings[key] ? mappings[key][values[key]] : values[key];
        if (Array.isArray(initialValue)) {
          return renderArray({
            key,
            initialValue,
            diff,
            value,
          });
        }
        if (typeof initialValue === "object") {
          return renderObject({
            key,
            initialValue,
            diff,
            value,
          });
        }

        return renderString({
          key,
          initialValue,
          diff,
          value,
        });
      })}
    </Fragment>
  );
};

DiffBlock.propTypes = {
  initialValues: PropTypes.object,
  values: PropTypes.object,
  mappings: PropTypes.object,
};

export default DiffBlock;
