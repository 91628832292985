import { useState } from "react";
import moment from "moment";
import reduce from "lodash/reduce";
import { Link } from "react-router-dom";
import { pending } from "redux-saga-thunk";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import IconView from "@mui/icons-material/RemoveRedEye";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import IconFilter from "@mui/icons-material/FilterList";
import { customReadRequest, resourceListReadRequest } from "store/actions";
import { fromCustom, fromEntities, fromResource } from "store/selectors";
import {
  Filter,
  Label,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
} from "components";

const filters = ({ machines, tags }) => ({
  atmId: {
    label: "ATM",
    type: "select",
    multiple: false,
    options: machines.map((m) => m.id),
    mappings: reduce(
      machines.map((m) => ({ [m.id]: m.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  createdAt: {
    label: "Created At",
    type: "date",
    suffixes: {
      gte: " from",
      lte: " to",
    },
    range: true,
  },
  customerId: {
    label: "Customer",
  },
  tag: {
    label: "Tags",
    type: "select",
    multiple: true,
    options: tags,
  },
  excludeEmpty: {
    label: "Hide empty sessions",
    type: "boolean",
  },
});

const SessionsListPage = (props) => {
  const [open, setOpen] = useState();

  const { count, filtersValues, items, loading, tags } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "trackingsessions"),
      filtersValues: fromCustom.get(state, "trackingsessions/filters") || {
        machines: [],
      },
      items: fromEntities.getDenormalizedList(
        state,
        "trackingsessions",
        fromResource.getList(state, "trackingsessions")
      ),
      tags: fromResource.getList(state, "trackingsessions/taglist") || [],
      loading: pending(state, "trackingsessionsListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Sessions"
        toolbar={
          <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
            Filter
          </Button>
        }
        tableBefore={
          <Filter
            filters={filters({ ...filtersValues, tags })}
            open={open}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "1%" }}>
              Id
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell>Session Key</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].sessionKey}</TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Machine</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              {items[rowIndex].atm ? (
                <MuiLink
                  component={Link}
                  to={`/machines/${items[rowIndex].atm.id}`}
                  underline="hover"
                >
                  {items[rowIndex].atm.name}
                </MuiLink>
              ) : (
                <i>missing</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Order</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              {items[rowIndex].order ? (
                <MuiLink
                  component={Link}
                  to={`/orders/${items[rowIndex].order.id}`}
                  underline="hover"
                >
                  {items[rowIndex].order.orderId}
                </MuiLink>
              ) : (
                <i>no order</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Tags</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              {Array.isArray(items[rowIndex].tags) &&
              items[rowIndex].tags.length > 0 ? (
                <Stack direction="row" spacing={0.5}>
                  {items[rowIndex].tags.sort().map((tag) => {
                    if (!tag) return null;
                    return (
                      <Label key={tag.tag} color={tag.level}>
                        {tag.tag}
                      </Label>
                    );
                  })}
                </Stack>
              ) : (
                <i>no order</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Version</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              {items[rowIndex].appVersion}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ whiteSpace: "nowrap", width: 114 }}>
              Created at
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {moment(items[rowIndex].createdAt).format("L \nLTS")}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }} />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <IconButton
                  to={`/sessions/${items[rowIndex].id}`}
                  component={Link}
                  size="large"
                >
                  <IconView />
                </IconButton>
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

SessionsListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("trackingsessions", { _limit: 100, ...query })
    ),
    store.dispatch(resourceListReadRequest("trackingsessions/taglist")),
    store.dispatch(customReadRequest("trackingsessions/filters")),
  ]);
};

export default SessionsListPage;
