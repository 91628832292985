import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { hasPermission } from "services/permission";

const Redirect = ({ to = "/", permissions = [] }) => {
  let redirectTo;

  let params = useParams();

  if (permissions.length > 0) {
    for (let i = 0; i < permissions.length; i += 1) {
      const [permission, method, path] = permissions[i];
      if (hasPermission(permission, method)) {
        redirectTo = path;
        break;
      }
    }
  }

  let parsedTo = redirectTo || to;
  Object.keys(params).forEach((key) => {
    parsedTo = parsedTo.replace(`:${key}`, params[key]);
  });

  const navigate = useNavigate();
  useEffect(() => {
    navigate(parsedTo, { replace: true });
  }, [parsedTo, navigate]);

  return null;
};

export default Redirect;
