import { Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconView from "@mui/icons-material/RemoveRedEye";
import { resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { CustomerForm } from "components";

const CustomersUpdatePage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDetail(state, "customers", params.id),
    }),
    shallowEqual
  );

  const { id, key } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Customer
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${key}`}</Typography>
          </Fragment>
        }
        action={
          <Button
            startIcon={<IconView />}
            component={Link}
            to={`/customers/${id}`}
          >
            Details
          </Button>
        }
      />
      <Divider />
      <CustomerForm id={id} />
    </Card>
  );
};

CustomersUpdatePage.get = ({ store, params }) => {
  return store.dispatch(resourceDetailReadRequest("customers", params.id));
};

export default CustomersUpdatePage;
