import React, { Fragment } from "react";
import moment from "moment";
import orderBy from "lodash/orderBy";
import Alert from "@mui/material/Alert";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FlagIcon from "@mui/icons-material/Flag";
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Typography from "@mui/material/Typography";

function LogOld({ actions, events }) {
  const sortedActions = React.useMemo(() => {
    const combined = orderBy(
      [
        ...actions.map((a) => ({ ...a, type: "action" })),
        ...events.map((e) => ({ ...e, type: "event" })),
      ],
      ["createdAt", "id"],
      ["asc", "asc"]
    );

    let sortedActions = [];

    let prevPage;
    let index = -1;
    combined.forEach((c) => {
      if (c.type === "action") {
        const { action, page, type, createdAt, positionX, positionY } = c;

        if (prevPage !== page) {
          index += 1;
          prevPage = page;
          sortedActions[index] = [];
        }

        sortedActions[index].push({
          type,
          action,
          page,
          createdAt,
          positionX,
          positionY,
        });
      } else {
        const { category, message, createdAt, type, level } = c;
        if (!prevPage) {
          index += 1;
          prevPage = "/unknown";
          sortedActions[index] = [];
        }

        sortedActions[index].push({
          level,
          type,
          category,
          action: message,
          page: prevPage,
          createdAt,
          positionX: 0,
          positionY: 0,
        });
      }
    });
    return sortedActions;
  }, [actions, events]);

  return (
    <Grid item xs={12}>
      <Timeline align="left">
        {sortedActions.map((set, index) => {
          const [{ page, createdAt }] = set;
          return (
            <Fragment key={`${page}-${createdAt}`}>
              <TimelineItem>
                <TimelineOppositeContent style={{ flex: "0 1 120px" }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ padding: "11px 0" }}
                  >
                    {moment(createdAt).format("LTS")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    {index + 1 !== sortedActions.length ? (
                      <ArrowDownwardIcon />
                    ) : (
                      <FlagIcon />
                    )}
                  </TimelineDot>
                  {index + 1 !== sortedActions.length ? (
                    <TimelineConnector />
                  ) : null}
                </TimelineSeparator>
                <TimelineContent>
                  {/* <Typography variant="h6">Page {String(page).slice(1).replace(/\//igm, ' ')}</Typography> */}
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="caption">Page</Typography>
                      <Typography variant="h6">
                        {String(page).slice(1).replace(/\//gim, " ")}
                      </Typography>
                    </CardContent>
                    <Timeline align="left">
                      {set.map(
                        (
                          {
                            type,
                            category,
                            action,
                            createdAt,
                            positionX,
                            positionY,
                          },
                          index
                        ) => {
                          if (!action) return null;
                          return (
                            <TimelineItem
                              key={`${action}-${createdAt}-${positionX}-${positionY}`}
                              color="primary"
                            >
                              <TimelineOppositeContent
                                style={{ flex: 0, padding: 0 }}
                              />
                              <TimelineSeparator>
                                <TimelineDot />
                                {index + 1 !== set.length && (
                                  <TimelineConnector />
                                )}
                              </TimelineSeparator>
                              <TimelineContent>
                                {type === "event" && (
                                  <Alert severity="error">
                                    <div>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        {moment(createdAt).format("LTS")}
                                      </Typography>
                                      <Typography variant="subtitle2">
                                        {action}
                                      </Typography>
                                      <Typography
                                        variant="title"
                                        sx={{
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {category}
                                      </Typography>
                                    </div>
                                  </Alert>
                                )}
                                {type === "action" && (
                                  <React.Fragment>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {moment(createdAt).format("LTS")}
                                    </Typography>
                                    {/* <Typography variant="caption">Action</Typography> */}
                                    <Typography variant="subtitle2">
                                      {action}
                                    </Typography>
                                  </React.Fragment>
                                )}
                              </TimelineContent>
                            </TimelineItem>
                          );
                        }
                      )}
                    </Timeline>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            </Fragment>
          );
        })}
      </Timeline>
    </Grid>
  );
}

export default LogOld;
