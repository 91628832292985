import { Fragment } from "react";
import clsx from "clsx";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { pending } from "redux-saga-thunk";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import IconLabel from "@mui/icons-material/Label";
import {
  notificationSend,
  resourceUpdateRequest,
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import {
  Label,
  ReadonlyTextField,
  NotesList,
  BlockchainLink,
} from "components";

const OrderQueueReadPage = (props) => {
  const params = useParams();
  const { detail, loading } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "orderqueue",
        params.id
      ),
      loading: pending(state, "orderqueueUpdate"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const updateQueue = (values) => {
    return dispatch(resourceUpdateRequest("orderqueue", params.id, values))
      .then(() => {
        dispatch(
          notificationSend("Queue updated successfully", { variant: "success" })
        );
      })
      .catch(() => {
        dispatch(
          notificationSend("Failed to update queue", { variant: "error" })
        );
      });
  };

  const handleChangeQueueStatus = (status) => () => {
    updateQueue({ status });
  };

  const {
    id,
    orders,
    autoPayments,
    partialAutoPayments,
    status,
    txId,
    txFee,
    currency,
    cryptoCurrencyAmount,
    fiatAmount,
    allowedStatuses = [],
    paidAt,
    createdAt,
  } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Order Queue
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
        action={
          <>
            {hasPermission("orders", "PUT") &&
              allowedStatuses.map((status) => (
                <Button
                  key={status}
                  startIcon={<IconLabel />}
                  onClick={handleChangeQueueStatus(status)}
                  disabled={loading}
                >
                  Mark as {status}
                </Button>
              ))}
          </>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="Fiat Amount"
              value={fiatAmount}
              fullWidth
            />
            <ReadonlyTextField
              label="Cryptocurrency Amount"
              value={`${cryptoCurrencyAmount} ${currency}`}
              fullWidth
            />
            <ReadonlyTextField
              label="Status"
              value={
                <Label
                  color={
                    clsx({
                      error: status === "ERROR" || status === "NO FUNDS",
                      warning: status === "PAYING",
                      info: status === "PENDING",
                      success: status === "FULFILLED",
                    }) || "default"
                  }
                  fullWidth
                >
                  {status}
                </Label>
              }
            />
            <ReadonlyTextField
              label="Transaction Id"
              value={
                txId ? (
                  <BlockchainLink transaction={txId} coinType={currency} />
                ) : (
                  <i>[no transaction]</i>
                )
              }
              fullWidth
            />
            <ReadonlyTextField
              label="Tx Fee"
              value={`${txFee} ${currency}`}
              fullWidth
            />
            <ReadonlyTextField
              label="Created At"
              value={new Date(createdAt).toLocaleString("en-US")}
              fullWidth
            />
            <ReadonlyTextField
              label="Paid At"
              value={
                paidAt ? (
                  new Date(paidAt).toLocaleString("en-US")
                ) : (
                  <i>[missing]</i>
                )
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Orders
          </Typography>
          <div style={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Coin Amount</TableCell>
                  <TableCell>Fiat Amount</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Spot Price</TableCell>
                  <TableCell>Wallet</TableCell>
                  <TableCell>Tx Fee</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {
                        <MuiLink
                          component={Link}
                          to={`/orders/${row.id}`}
                          underline="hover"
                        >
                          {row.orderId}
                        </MuiLink>
                      }
                    </TableCell>
                    <TableCell>{`${row.coinAmount} ${row.coinType}`}</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: row.fiatType,
                      }).format(row.fiatAmount)}
                    </TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: row.fiatType,
                      }).format(row.coinPrice)}
                    </TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: row.fiatType,
                      }).format(row.cryptoCurrencySpotPrice)}
                    </TableCell>
                    <TableCell>
                      <BlockchainLink
                        wallet={row.wallet}
                        coinType={row.coinType}
                      />
                    </TableCell>
                    <TableCell>{`${row.txFee || 0} ${row.coinType}`}</TableCell>
                    <TableCell>
                      {new Date(row.createdAt).toLocaleString("en-US")}
                    </TableCell>
                    <TableCell>
                      <Label
                        color={
                          clsx({
                            error:
                              row.status === "ERROR" ||
                              row.status === "NO FUNDS",
                            warning:
                              row.status === "HOLD" || row.status === "EXPIRED",
                            info:
                              [
                                "PAYING",
                                "BUYING",
                                "RECEIVED",
                                "PENDING",
                                "PEND",
                              ].indexOf(row.status.toUpperCase()) !== -1,
                            success:
                              ["REDEEMED", "FULFILLED"].indexOf(
                                row.status.toUpperCase()
                              ) !== -1,
                          }) || "default"
                        }
                        fullWidth
                      >
                        {row.status}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Grid item xs={12} paddingTop={"12px"}>
          <Typography variant="h6" gutterBottom>
            Auto Payments
          </Typography>
          <div style={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Recipient</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Fiat Amount</TableCell>
                  <TableCell>Tx Fee</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Paid For</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {autoPayments.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {
                        <MuiLink
                          component={Link}
                          to={`/autopayments/payments/${row.id}`}
                          underline="hover"
                        >
                          {row.id}
                        </MuiLink>
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <MuiLink
                          component={Link}
                          to={`/autopayments/recipients/${row.recipient.id}/update`}
                          underline="hover"
                        >
                          {row.recipient.name}
                        </MuiLink>
                      }
                    </TableCell>
                    <TableCell>{`${row.amount} BTC`}</TableCell>
                    <TableCell>
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(row.currencyPrice)}
                    </TableCell>
                    <TableCell>{`${row.txFee || 0} BTC`}</TableCell>
                    <TableCell>
                      {new Date(row.createdAt).toLocaleString("en-US")}
                    </TableCell>
                    <TableCell>
                      {row.shouldBePaidAt ? (
                        new Date(row.shouldBePaidAt).toLocaleString("en-US")
                      ) : (
                        <i>[missing]</i>
                      )}
                    </TableCell>
                    <TableCell>
                      <Label
                        color={
                          clsx({
                            error:
                              row.status === "ERROR" ||
                              row.status === "NO FUNDS",
                            success: row.status === "PAID",
                          }) || "default"
                        }
                        fullWidth
                      >
                        {row.status}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Grid item xs={12} paddingTop={"12px"}>
          <Typography variant="h6" gutterBottom>
            Partial Auto Payments
          </Typography>
          <div style={{ overflow: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Payment Id</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Tx Fee</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partialAutoPayments.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      {
                        <MuiLink
                          component={Link}
                          to={`/autopayments/payments/${row.autoPaymentId}`}
                          underline="hover"
                        >
                          {row.id}
                        </MuiLink>
                      }
                    </TableCell>
                    <TableCell>{`${row.amount} BTC`}</TableCell>
                    <TableCell>{`${row.txFee || 0} BTC`}</TableCell>
                    <TableCell>
                      {new Date(row.createdAt).toLocaleString("en-US")}
                    </TableCell>
                    <TableCell>
                      <Label
                        color={
                          clsx({
                            error:
                              row.status === "ERROR" ||
                              row.status === "NO FUNDS",
                            success: row.status === "PAID",
                          }) || "default"
                        }
                        fullWidth
                      >
                        {row.status}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Notes
            </Typography>
            <NotesList entityType="orderqueue" entityId={id} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

OrderQueueReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("orderqueue", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entityType: "orderqueue",
        entityId: params.id,
      })
    ),
  ]);
};

export default OrderQueueReadPage;
