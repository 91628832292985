import * as React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Skeleton from "@mui/material/Skeleton";
import GlobalStyles from "@mui/material/GlobalStyles";
import map from "./map.svg";

const mapOptions = {
  mapId: "DEMO_MAP_ID",
  zoom: 15,
  center: { lat: 40.608, lng: -75.491 },
  mapTypeControl: false,
  streetViewControl: false,
  rotateControl: false,
};

const MapComponent = React.forwardRef(({ onStartSet }, ref) => {
  const divRef = React.useRef();
  const mapRef = React.useRef();
  const markersRef = React.useRef([]);
  const [blocked, setBlocked] = React.useState(false);

  React.useImperativeHandle(
    ref,
    function () {
      return {
        async addMarkers(locations) {
          const { AdvancedMarkerElement, PinElement } =
            await window.google.maps.importLibrary("marker");
          const bounds = new window.google.maps.LatLngBounds();

          bounds.extend(markersRef.current[0].position);

          locations.slice(1).forEach((location, index) => {
            const pin = new PinElement({
              background: "#f4511e",
              borderColor: "#bf360c",
              glyphColor: "#FFFFFF",
              glyph: `${index + 1}`,
              scale: 1.2,
            });

            const marker = new AdvancedMarkerElement({
              map: mapRef.current,
              position: {
                lat: location.latitude,
                lng: location.longitude,
              },
              content: pin.element,
              title: location.name,
            });

            bounds.extend(marker.position);

            const content = marker.content;
            content.classList.add("drop");

            content.style.opacity = "0";
            content.addEventListener("animationend", () => {
              content.classList.remove("drop");
              content.style.opacity = "1";
            });

            const time = 0.5 + index * 0.1;
            content.style.setProperty("--delay-time", `${time}s`);

            markersRef.current.push(marker);
            marker.setMap(mapRef.current);
          });

          if (markersRef.current.length > 1) mapRef.current.fitBounds(bounds);
        },
        markerMouseEnter(index) {
          markersRef.current[index].content.classList.add("bounce");
        },
        markerMouseLeave(index) {
          markersRef.current[index].content.classList.remove("bounce");
        },
        blockMap(value) {
          setBlocked(value);
        },
        clear() {
          markersRef.current.forEach((marker) => {
            marker.setMap(null);
          });
          markersRef.current = [];
        },
      };
    },
    []
  );

  React.useEffect(() => {
    const setCenterAndMarkers = (lat, lng) => {
      const initialLocation = new window.google.maps.LatLng(lat, lng);
      mapRef.current.setCenter(initialLocation);
    };

    const changeLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setCenterAndMarkers(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            console.error("Error getting geolocation:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported.");
      }
    };

    const initMap = async () => {
      try {
        mapRef.current = new window.google.maps.Map(divRef.current, mapOptions);
        markersRef.current.forEach((marker) => marker.setMap(mapRef.current));
        changeLocation();

        if ("permissions" in navigator) {
          const permission = await navigator.permissions.query({
            name: "geolocation",
          });
          permission.onchange = () => {
            changeLocation();
            console.log(
              "User decided to change his settings. New permission: " +
                permission.state
            );
          };
        }
      } catch (error) {
        console.error("Error initializing map:", error.message);
      }
    };

    initMap();
  }, []);

  React.useEffect(() => {
    const handleClick = async (mapsMouseEvent) => {
      try {
        const { AdvancedMarkerElement, PinElement } =
          await window.google.maps.importLibrary("marker");

        markersRef.current[0]?.setMap(null);

        const pinBackground = new PinElement({
          background: "#43a047",
          borderColor: "#1b5e20",
          glyphColor: "#ffffff",
          glyph: "SL",
          scale: 1.2,
        });

        markersRef.current[0] = new AdvancedMarkerElement({
          map: mapRef.current,
          position: mapsMouseEvent.latLng,
          content: pinBackground.element,
          title: "Starting Location",
          gmpDraggable: true,
        });

        markersRef.current[0]?.setMap(mapRef.current);

        const { lat, lng } = mapsMouseEvent.latLng.toJSON();

        onStartSet?.({
          latitude: lat,
          longitude: lng,
        });
      } catch (error) {
        console.error("Error handling click:", error.message);
      }
    };

    try {
      if (mapRef.current) {
        if (blocked) {
          window.google.maps.event.clearListeners(mapRef.current, "click");
          if (markersRef.current[0]) markersRef.current[0].gmpDraggable = false;
        } else {
          mapRef.current.addListener("click", handleClick);
        }
      }
    } catch (error) {
      console.error("Error setting up click listener:", error.message);
    }
  }, [onStartSet, blocked]);

  return (
    <React.Fragment>
      <GlobalStyles
        styles={{
          "@keyframes drop": {
            "0%": { transform: "translateY(-200px) scaleY(0.9)", opacity: 0 },
            "5%": { opacity: 0.7 },
            "50%": { transform: "translateY(0px) scaleY(1)", opacity: 1 },
            "65%": { transform: "translateY(-17px) scaleY(0.9)", opacity: 1 },
            "75%": { transform: "translateY(-22px) scaleY(0.9)", opacity: 1 },
            "100%": { transform: "translateY(0px) scaleY(1)", opacity: 1 },
          },
          ".drop": { animation: "drop 0.3s linear forwards var(--delay-time)" },
          ".bounce": {
            animation: "bounce 1s ease infinite",
          },
          "@keyframes bounce": {
            "0%": { transform: "translateY(0)" },
            "25%": { transform: "translateY(5px)" },
            "50%": { transform: "translateY(-5px)" },
            "75%": { transform: "translateY(5px)" },
            "100%": { transform: "translateY(0)" },
          },
        }}
      />
      <div
        ref={divRef}
        id="map"
        style={{
          paddingTop: "56.25%",
        }}
      />
    </React.Fragment>
  );
});

const render = (status) => {
  switch (status) {
    case Status.FAILURE:
      return <div>FAILED</div>;
    default:
      return (
        <Skeleton
          variant="rectangular"
          height="auto"
          width="100%"
          sx={{
            "&:before": {
              backgroundImage: `url(${map})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              content: '""',
              display: "block",
              opacity: 0.75,
              paddingTop: "56.25%",
            },
          }}
        ></Skeleton>
      );
  }
};

const Map = React.forwardRef((props, ref) => {
  return (
    <Wrapper apiKey="AIzaSyBo731vIPpnTP3vZE0RPrhOMlxVfKprLAc" render={render}>
      <MapComponent ref={ref} {...props} />
    </Wrapper>
  );
});

export default Map;
