import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { resourceListReadRequest } from "store/actions";
import { UserForm } from "components";

const UsersCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Create User" />
      <Divider />
      <UserForm />
    </Card>
  );
};

UsersCreatePage.get = ({ store }) => {
  return Promise.all([
    store.dispatch(resourceListReadRequest("userroles")),
    store.dispatch(resourceListReadRequest("machines", { _limit: 1000 })),
  ]);
};

export default UsersCreatePage;
