import { useState, useMemo, useRef, Fragment } from "react";
import moment from "moment";
import reduce from "lodash/reduce";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import IconEdit from "@mui/icons-material/Edit";
import IconFilter from "@mui/icons-material/FilterList";
import IconView from "@mui/icons-material/RemoveRedEye";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CancelIcon from "@mui/icons-material/Cancel";
import { hasPermission } from "services/permission";
import {
  resourceListReadRequest,
  customReadRequest,
  customCreateRequest,
  resourceDeleteRequest,
  resourceListReadSuccess,
  notificationSend,
} from "store/actions";
import { fromEntities, fromResource, fromCustom } from "store/selectors";
import {
  Filter,
  Label,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  ReadonlyTextField,
  SmartEmptyRoute,
} from "components";
import { tips } from "services/helpers";

const filters = ({ machines, statuses }) => ({
  atmId: {
    label: "ATM",
    type: "select",
    multiple: false,
    options: machines.map((m) => m.id),
    mappings: reduce(
      machines.map((m) => ({ [m.id]: m.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  status: {
    label: "Status",
    type: "select",
    options: statuses.map(({ name }) => name),
  },
});

const SmartemptiesListPage = (props) => {
  const [open, setOpen] = useState();
  const [reconciliationDialogOpen, setReconciliationDialogOpen] =
    useState(false);
  const [reconciling, setReconciling] = useState(false);
  const [reconciliationItemChecked, setReconciliationItemChecked] = useState(
    []
  );
  const [selectedReconciliation, setSelectedReconciliation] = useState(null);
  const dispatch = useDispatch();
  const reconciliationDescription = useRef("");

  const { count, filtersValues, items, loading, statuses, baseCurrency } =
    useSelector(
      (state = {}) => ({
        baseCurrency: fromCustom.getBaseCurrency(state),
        count: fromResource.getCount(state, "smartempties"),
        filtersValues: fromCustom.get(state, "smartempties/filters"),
        items: fromEntities.getDenormalizedList(
          state,
          "smartempties",
          fromResource.getList(state, "smartempties")
        ),
        statuses:
          fromCustom.get(state, "config/smartempties", {}).statusList || [],
        loading: pending(state, "smartemptiesListRead"),
      }),
      shallowEqual
    );

  const reconcileAmount = useMemo(() => {
    return reconciliationItemChecked.reduce(
      (acc, item) => (acc += item.amount),
      0
    );
  }, [reconciliationItemChecked]);

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleReconciliationView = (reconciliation) => {
    setSelectedReconciliation(reconciliation);
    setReconciliationDialogOpen(true);
  };

  const handleReconciliationDialogClose = () => {
    setReconciliationDialogOpen(false);
    if (selectedReconciliation) setSelectedReconciliation(null);
  };

  const handleReconciliation = () => {
    if (!reconciling) return setReconciling(true);
    setReconciliationDialogOpen(true);
  };

  const handleReconciliationCreate = async () => {
    try {
      const result = await dispatch(
        customCreateRequest("smartempties/reconciliations", {
          description: reconciliationDescription.current.value,
          smartEmpties: reconciliationItemChecked,
        })
      );
      for (const smartempty of reconciliationItemChecked) {
        const index = items.findIndex((item) => item.id === smartempty.id);
        items[index].reconciliation = result;
      }
      dispatch(
        notificationSend("Reconciliation Created", {
          variant: "success",
        })
      );
      setReconciliationDialogOpen(false);
      setReconciling(false);
      setReconciliationItemChecked([]);
      return dispatch(
        resourceListReadSuccess("smartempties", items, {}, null, count)
      );
    } catch (error) {
      dispatch(
        notificationSend("Failed to create reconciliation", {
          variant: "error",
        })
      );
    }
  };

  const handleReconciliationDelete = async (id) => {
    try {
      await dispatch(resourceDeleteRequest("smartempties/reconciliations", id));
      for (const item of items) {
        if (item.reconciliation && item.reconciliation.id === id) {
          item.reconciliation = null;
          item.reconciliationId = null;
        }
      }
      dispatch(
        notificationSend("Reconciliation Deleted", {
          variant: "success",
        })
      );
      handleReconciliationDialogClose();
      return dispatch(
        resourceListReadSuccess("smartempties", items, {}, null, count)
      );
    } catch (error) {
      dispatch(
        notificationSend("Failed to delete reconciliation", {
          variant: "error",
        })
      );
    }
  };

  const handleReconciliationCancel = () => {
    setReconciling(false);
    setReconciliationItemChecked([]);
  };

  const reconciliationItemCheckHandle = (event, value) => {
    const { checked } = event.target;
    const selected = [...reconciliationItemChecked];
    const index = selected.findIndex((item) => item.id === value.id);
    if (checked && index === -1) {
      selected.push({
        id: value.id,
        amount: value.amountEmptied,
      });
      setReconciliationItemChecked(selected);
    } else if (index !== -1) {
      selected.splice(index, 1);
      setReconciliationItemChecked(selected);
    }
  };

  const getColor = (v) => {
    const status = statuses.find(({ name }) => name === v);
    if (status) return status.level;
    return "default";
  };

  return (
    <Paper elevation={0}>
      <Dialog
        open={reconciliationDialogOpen}
        onClose={() => handleReconciliationDialogClose()}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{`${
          reconciling ? "Create" : "View"
        } Reconciliation`}</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={1}>
            <ReadonlyTextField
              label="Amount"
              value={new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: baseCurrency,
              }).format(
                selectedReconciliation
                  ? selectedReconciliation.amount
                  : reconcileAmount
              )}
            />
            {selectedReconciliation && (
              <Fragment>
                <ReadonlyTextField
                  label="Description"
                  value={
                    <Box
                      sx={{
                        wordBreak: "break-word",
                        whiteSpace: "break-spaces",
                      }}
                    >
                      {selectedReconciliation.description}
                    </Box>
                  }
                />
                <ReadonlyTextField
                  label="Creator"
                  value={
                    <MuiLink
                      component={Link}
                      to={`/settings/users/${selectedReconciliation.user.id}/update`}
                      underline="hover"
                    >
                      {selectedReconciliation.user.userName}
                    </MuiLink>
                  }
                />
              </Fragment>
            )}
            {reconciling && (
              <TextField
                inputRef={reconciliationDescription}
                label="Description"
                multiline
                fullWidth
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "left" }}>
          {reconciling && (
            <Button onClick={(e) => handleReconciliationCreate()}>
              {"Create"}
            </Button>
          )}
          {selectedReconciliation && (
            <Button
              onClick={(e) =>
                handleReconciliationDelete(selectedReconciliation.id)
              }
            >
              {"Delete"}
            </Button>
          )}
          <Button
            style={{ marginLeft: "auto" }}
            onClick={(e) => handleReconciliationDialogClose()}
          >
            {"Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title={`Smart Empties ${
          reconciling
            ? `Reconciliation: ${new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: baseCurrency,
              }).format(reconcileAmount)}`
            : ""
        }`}
        toolbar={
          <div>
            <Button
              startIcon={<AssignmentTurnedInIcon />}
              onClick={handleReconciliation}
              disabled={reconciling && reconciliationItemChecked.length === 0}
            >
              {!reconciling ? "Add Reconciliation" : "Reconcile"}
            </Button>
            {reconciling && (
              <Button
                startIcon={<CancelIcon />}
                onClick={handleReconciliationCancel}
                disabled={false}
              >
                {"Cancel"}
              </Button>
            )}
            <SmartEmptyRoute />
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </div>
        }
        tableBefore={
          <Filter
            filters={filters({ ...filtersValues, statuses })}
            open={open}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "98%" }}>
              <Tooltip title={tips.smartEmpties.atm} arrow>
                <TableSortLabel
                  active={sort === "atmId"}
                  component={Link}
                  direction={order}
                  to={getSortLink("atmId")}
                >
                  ATM
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].machine && (
                <MuiLink
                  component={Link}
                  to={`/machines/${items[rowIndex].machine.id}`}
                  underline="hover"
                >
                  {items[rowIndex].machine.name}
                </MuiLink>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.smartEmpties.operator} arrow>
                <TableSortLabel
                  active={sort === "operatorId"}
                  component={Link}
                  direction={order}
                  to={getSortLink("operatorId")}
                >
                  Operator
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              {items[rowIndex].operatorId !== "from-dashboard" ? (
                <MuiLink
                  component={Link}
                  to={`/operators/${items[rowIndex].operatorId}`}
                  underline="hover"
                >
                  {(items[rowIndex].operator &&
                    `${items[rowIndex].operator.firstName} ${items[rowIndex].operator.lastName}`) ||
                    items[rowIndex].operatorId}
                </MuiLink>
              ) : (
                "Dashboard"
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.smartEmpties.btcSpotRate} arrow>
                <TableSortLabel
                  active={sort === "cryptoCurrencySpotPrice"}
                  component={Link}
                  direction={order}
                  to={getSortLink("cryptoCurrencySpotPrice")}
                >
                  BTC Spot Rate
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: baseCurrency,
              }).format(items[rowIndex].cryptoCurrencySpotPrice || 0)}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.smartEmpties.btcAmount} arrow>
                <span>BTC Amount</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {parseFloat(
                (
                  items[rowIndex].amountEmptied /
                  items[rowIndex].cryptoCurrencySpotPrice
                ).toFixed(8) || 0
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.smartEmpties.emptied} arrow>
                <TableSortLabel
                  active={sort === "amountEmptied"}
                  component={Link}
                  direction={order}
                  to={getSortLink("amountEmptied")}
                >
                  Emptied
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: baseCurrency,
              }).format(items[rowIndex].amountEmptied || 0)}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell>
              <Tooltip title={tips.smartEmpties.reconciled} arrow>
                <TableSortLabel
                  active={sort === "status"}
                  component={Link}
                  direction={order}
                  to={getSortLink("status")}
                >
                  Status
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Label color={getColor(items[rowIndex].status)} fullWidth>
                {items[rowIndex].status}
              </Label>
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.smartEmpties.btcAmount} arrow>
                <span>Reconciliation</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ py: 0 }}>
              {items[rowIndex].reconciliation && (
                <Button
                  disabled={reconciling}
                  onClick={(e) =>
                    handleReconciliationView(items[rowIndex].reconciliation)
                  }
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(items[rowIndex].reconciliation.amount)}
                </Button>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: 115 }}>
              <Tooltip title={tips.smartEmpties.date} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              {!reconciling && (
                <TableActions>
                  <Tooltip enterDelay={500} title="View" placement="top">
                    <IconButton
                      to={`/smartempties/${items[rowIndex].id}`}
                      component={Link}
                      size="large"
                    >
                      <IconView />
                    </IconButton>
                  </Tooltip>
                  {hasPermission("smartempties", "PUT") && (
                    <Tooltip enterDelay={500} title="Edit" placement="top">
                      <IconButton
                        to={`/smartempties/${items[rowIndex].id}/update`}
                        component={Link}
                        size="large"
                      >
                        <IconEdit />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableActions>
              )}
              {reconciling && (
                <Checkbox
                  sx={{ mr: "30px" }}
                  onChange={(e) =>
                    reconciliationItemCheckHandle(e, items[rowIndex])
                  }
                  value={items[rowIndex].id}
                  disabled={!!items[rowIndex].reconciliation}
                />
              )}
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

SmartemptiesListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("smartempties", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("smartempties/filters")),
    store.dispatch(customReadRequest("config/smartempties")),
  ]);
};

export default SmartemptiesListPage;
