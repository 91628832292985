import { useState, Fragment } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import IconFilter from "@mui/icons-material/FilterList";
import {
  resourceListReadRequest,
  resourceUpdateRequest,
  notificationSend,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import {
  Filter,
  Label,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Confirmation,
} from "components";

const filters = {
  active: {
    label: "Active Only",
    type: "boolean",
  },
};

const UserAuthSessionsListPage = () => {
  const [filterOpen, setFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "userauthsessions"),
      items: fromEntities.getDenormalizedList(
        state,
        "userauthsessions",
        fromResource.getList(state, "userauthsessions")
      ),
      loading: pending(state, "userauthsessionsListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handleSessionTerminate = (sessionId) => {
    return dispatch(resourceUpdateRequest("userauthsessions", sessionId, {}))
      .then(() => {
        dispatch(
          notificationSend("Session Terminated", {
            variant: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to Terminate Session", {
            variant: "error",
          })
        );
      });
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Users"
        toolbar={
          <>
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </>
        }
        tableBefore={
          <Filter
            filters={filters}
            open={filterOpen}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "30%" }}>
              <TableSortLabel
                active={sort === "userId"}
                component={Link}
                direction={order}
                to={getSortLink("userId")}
              >
                User
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].user.userName}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "30%" }}>
              <TableSortLabel
                active={sort === "ip"}
                component={Link}
                direction={order}
                to={getSortLink("ip")}
              >
                IP
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].ip}</TableCell>}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              <TableSortLabel
                active={sort === "active"}
                component={Link}
                direction={order}
                to={getSortLink("active")}
              >
                Active
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              <Label
                color={items[rowIndex].active ? "success" : "error"}
                fullWidth
              >
                {items[rowIndex].active ? "ACTIVE" : "TERMINATED"}
              </Label>
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell
              align="right"
              sx={{ whiteSpace: "nowrap", width: "10%" }}
            >
              <TableSortLabel
                active={sort === "os"}
                component={Link}
                direction={order}
                to={getSortLink("os")}
              >
                OS
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {items[rowIndex].os ? (
                `${items[rowIndex].os} ${items[rowIndex].osVersion}`
              ) : (
                <Typography variant="caption" color="textSecondary">
                  Missing
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell
              align="right"
              sx={{ whiteSpace: "nowrap", width: "10%" }}
            >
              <TableSortLabel
                active={sort === "browser"}
                component={Link}
                direction={order}
                to={getSortLink("browser")}
              >
                Browser
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {items[rowIndex].browser ? (
                `${items[rowIndex].browser} ${items[rowIndex].browserVersion}`
              ) : (
                <Typography variant="caption" color="textSecondary">
                  Missing
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell
              align="right"
              sx={{ whiteSpace: "nowrap", width: "10%" }}
            >
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell
              align="right"
              sx={{ whiteSpace: "nowrap", width: "10%" }}
            >
              <TableSortLabel
                active={sort === "expiryDate"}
                component={Link}
                direction={order}
                to={getSortLink("expiryDate")}
              >
                Expiry Date
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ py: 0 }}>
              {items[rowIndex].expiryDate ? (
                moment(items[rowIndex].expiryDate).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Confirmation>
                  {(show, confirm, hide, open) => (
                    <Fragment>
                      <Tooltip
                        enterDelay={500}
                        title="Terminate"
                        placement="top"
                      >
                        <IconButton
                          onClick={show(() =>
                            handleSessionTerminate(items[rowIndex].id)
                          )}
                          size="large"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Dialog open={open}>
                        <DialogTitle>{`Are you sure you want to terminate session?`}</DialogTitle>
                        <DialogActions>
                          <Button onClick={hide}>Cancel</Button>
                          <Button onClick={confirm} color="primary" autoFocus>
                            Terminate
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Fragment>
                  )}
                </Confirmation>
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

UserAuthSessionsListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("userauthsessions", { _limit: 100, ...query })
  );
};

export default UserAuthSessionsListPage;
