import { Fragment, useState } from "react";
import PropTypes from "prop-types";

const Confirmation = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [callback, setCallback] = useState(null);

  const show = (callback) => (event) => {
    event.preventDefault();
    event.persist();

    setOpen(true);
    setCallback(() => () => callback(event));
  };

  const hide = () => {
    setOpen(false);
    setCallback(null);
  };

  const confirm = () => {
    if (typeof callback === "function") callback();
    hide();
  };

  return <Fragment>{children(show, confirm, hide, open)}</Fragment>;
};

Confirmation.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Confirmation;
