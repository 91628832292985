import * as React from "react";
import PropTypes from "prop-types";
import { parse } from "qs";
import moment from "moment";
import isEqual from "lodash/isEqual";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useLocalStorage } from "@rehooks/local-storage";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconMore from "@mui/icons-material/MoreVert";
import toHex from "services/toHex";
import { resourceDeleteRequest, notificationSend } from "store/actions";
import { hasPermission } from "services/permission";
import { fromEntities, fromResource, fromAuth } from "store/selectors";
import { NotesForm, confirm } from "components";
import FilePreview from "./FilePreview";

const Message = styled("div", {
  name: "NotesList",
  shouldForwardProp: (prop) => prop !== "highlighted",
})(({ theme, highlighted }) => ({
  padding: "8px 16px",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 4,
  transition: `background 2s ${theme.transitions.easing.easeOut}`,
  ...(highlighted && {
    backgroundColor: theme.palette.primary.light,
  }),
}));

const NotesList = (props) => {
  const {
    entityType,
    entityId,
    notes,
    displayEntityLink = false,
    displayForm = true,
  } = props;
  const storageKey = `CHNBTS_NOTE_${entityType}_${entityId}`;
  const [unsaved] = useLocalStorage(storageKey);

  const location = useLocation();

  const [noteId, setNoteId] = React.useState(unsaved ? unsaved.id : null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [highlightedId, sethighlightedId] = React.useState();

  const { items, user } = useSelector(
    (state = {}) => ({
      items:
        notes ||
        fromEntities
          .getDenormalizedList(
            state,
            "notes",
            fromResource.getList(state, "notes")
          )
          .filter(
            ({ entityId: originalEntityId, entityType: originalEntityType }) =>
              originalEntityId === entityId && originalEntityType === entityType
          )
          .sort((a, b) => a.id - b.id),
      user: fromAuth.getUser(state),
    }),
    isEqual
  );

  const dispatch = useDispatch();

  const isOwner = React.useMemo(() => {
    if (anchorEl) {
      const noteId = Number(anchorEl.dataset.id);

      return (
        items.findIndex(
          ({ id, user: creator }) =>
            id === noteId && creator && user.id === creator.id
        ) !== -1
      );
    }
    return false;
  }, [anchorEl, items, user.id]);

  const showEditError = React.useCallback(
    () =>
      dispatch(
        notificationSend("You have unsaved note!", {
          variant: "error",
        })
      ),
    [dispatch]
  );

  React.useEffect(() => {
    const { noteId: highlightedId } = parse(location.hash.substring(1));

    let timeout;
    if (highlightedId) {
      const element = document.getElementById(`note-${highlightedId}`);
      if (element) {
        sethighlightedId(highlightedId);
        timeout = setTimeout(() => {
          if (typeof window.scrollBy === "function") {
            window.scrollBy({
              top: element.offsetTop - 88,
              left: 0,
              behavior: "smooth",
            });
          } else {
            window.scrollTo(0, element.offsetTop - 88);
          }

          sethighlightedId(undefined);
        }, 275);
      }
    }

    return () => clearTimeout(timeout);
  }, [location]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = React.useCallback(() => {
    setOpen(false);

    if (!!localStorage.getItem(storageKey)) {
      showEditError();
    } else {
      setNoteId(anchorEl ? anchorEl.dataset.id : null);
    }
  }, [anchorEl, showEditError, storageKey]);

  const handleDelete = React.useCallback(async () => {
    setOpen(false);

    if (!(await confirm("Delete note?", "Delete"))) {
      return;
    }

    if (anchorEl) {
      const noteId = Number(anchorEl.dataset.id);
      try {
        await dispatch(resourceDeleteRequest("notes", noteId));
      } catch (error) {}

      setAnchorEl(null);
    }
  }, [anchorEl, dispatch]);

  const handleEditDone = React.useCallback(() => {
    if (!!localStorage.getItem(storageKey)) {
      showEditError();
    } else {
      setNoteId(null);
    }
  }, [showEditError, storageKey]);

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {items.map(
          (
            { id, user, note, tags, attachments, entityId, createdAt },
            index
          ) => {
            const prevUser = items[index - 1] && items[index - 1].user;
            const sameUser = user
              ? index > 0 && prevUser && prevUser.id === user.id
              : false;
            const userName = user ? user.userName || user.email : "Unknown";

            return (
              <Grid key={id} item xs={12} id={`note-${id}`}>
                {/* {(!sameUser && index !== 0) && <Divider variant="inset" component="li" />} */}
                <Grid container spacing={2}>
                  <Grid item sx={{ width: 56 }}>
                    {!sameUser && (
                      <Avatar
                        alt={userName}
                        sx={{ backgroundColor: toHex(userName) }}
                      >
                        {userName.charAt(0)}
                      </Avatar>
                    )}
                  </Grid>
                  <Grid item xs>
                    <Message highlighted={id === Number(highlightedId)}>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          {!sameUser &&
                            (user ? (
                              <MuiLink
                                component={Link}
                                to={`/settings/users/${user.id}/update`}
                                underline="hover"
                              >
                                {userName}
                              </MuiLink>
                            ) : null)}
                          {displayEntityLink && (
                            <MuiLink
                              sx={{ float: "right" }}
                              component={Link}
                              to={`/${entityType}/${entityId}#noteId=${id}`}
                              underline="hover"
                            >
                              {`${entityType}#${entityId}`}
                            </MuiLink>
                          )}
                          <Typography sx={{ whiteSpace: "pre-line" }}>
                            {note}
                          </Typography>
                          {attachments.length > 0 && (
                            <Grid container spacing={1} sx={{ py: 1 }}>
                              {attachments.map(({ id, url }) => (
                                <Grid item key={id}>
                                  <FilePreview id={id} url={url} />
                                </Grid>
                              ))}
                            </Grid>
                          )}
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            gutterBottom
                          >
                            {moment(createdAt).format("L \nLTS")}
                          </Typography>
                          <Grid container direction="row" spacing={1}>
                            {tags.map((tag) => (
                              <Grid item key={tag.id}>
                                <Chip
                                  label={tag.tag}
                                  style={{ borderRadius: 4 }}
                                  clickable
                                  component={Link}
                                  to={`/notes?tag=${tag.tag}`}
                                  size="small"
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                        {displayForm && (
                          <Grid item xs="auto">
                            <IconButton
                              onClick={handleOpen}
                              data-id={id}
                              edge="end"
                              size="large"
                            >
                              <IconMore />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Message>
                  </Grid>
                </Grid>
              </Grid>
            );
          }
        )}
        {hasPermission("notes", "POST") && displayForm && (
          <Grid
            item
            xs
            sx={{ marginLeft: items.length > 0 ? 7 : 0 }}
            id="note-form"
          >
            <NotesForm
              storageKey={storageKey}
              key={noteId}
              entityType={entityType}
              entityId={entityId}
              id={noteId}
              onDone={handleEditDone}
            />
          </Grid>
        )}
      </Grid>
      <React.Fragment>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          open={open}
          onClose={handleClose}
        >
          {isOwner && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
          <MenuItem onClick={handleDelete}>Delete</MenuItem>
        </Menu>
      </React.Fragment>
    </React.Fragment>
  );
};

NotesList.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  entityType: PropTypes.string.isRequired,
};

export default NotesList;
