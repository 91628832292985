import PropTypes from "prop-types";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { Autocomplete, TextField, DatePicker, Checkboxes } from "mui-rff";
import { PhoneField } from "components";

const FilterDialog = (props) => {
  const {
    onClose,
    onSubmit,
    initialValues,
    filters,
    open = false,
    ...other
  } = props;

  return (
    <Dialog fullWidth onClose={onClose} open={open} {...other}>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, values }) => (
          <>
            <DialogTitle>Filters</DialogTitle>
            <DialogContent sx={{ overflow: "auto" }}>
              {Object.keys(filters).map((key) => {
                const {
                  label,
                  options,
                  multiple,
                  type,
                  range,
                  mappings,
                  suffixes,
                } = filters[key];

                if (type === "select") {
                  let v = values[key];
                  if (multiple && !Array.isArray(values[key]) && values[key]) {
                    v = [values[key]];
                  }

                  return (
                    <Autocomplete
                      key={key}
                      label={label}
                      name={key}
                      filterSelectedOptions
                      multiple={multiple}
                      options={options}
                      getOptionLabel={(item) =>
                        mappings && mappings[item] ? mappings[item] : item
                      }
                      value={v || (multiple ? [] : null)}
                      isOptionEqualToValue={(option, value) => {
                        if (!isNaN(option) || !isNaN(value)) {
                          return Number(option) === Number(value);
                        }
                        return option === value;
                      }}
                      fieldProps={{
                        format: (v) => {
                          if (multiple) {
                            if (v && !Array.isArray(v)) return [v];
                            else if (v) return v;
                            else return [];
                          } else {
                            if (v) return v;
                            else return null;
                          }
                        },
                      }}
                      textFieldProps={{
                        fullWidth: true,
                        margin: "normal",
                        placeholder: "",
                      }}
                    />
                  );
                }
                if ((type === "number" && !range) || type === "text") {
                  return (
                    <TextField
                      key={key}
                      name={key}
                      label={label}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      type={type}
                    />
                  );
                }
                if (type === "tel") {
                  return <PhoneField key={key} name={key} label={label} />;
                }
                if (type === "number" && range) {
                  return (
                    <Grid key={key} container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          key={`${key}_gte`}
                          name={`${key}_gte`}
                          label={`${label}${suffixes.gte}`}
                          variant="outlined"
                          margin="normal"
                          type={type}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          key={`${key}_lte`}
                          name={`${key}_lte`}
                          label={`${label}${suffixes.lte}`}
                          variant="outlined"
                          margin="normal"
                          type={type}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  );
                }

                if (
                  (type === "date" ||
                    type === "datetime-local" ||
                    type === "time") &&
                  range
                ) {
                  return (
                    <Grid key={key} container spacing={2}>
                      <Grid item xs={6}>
                        <DatePicker
                          key={`${key}_gte`}
                          name={`${key}_gte`}
                          label={`${label}${suffixes.gte}`}
                          TextFieldProps={{ margin: "normal" }}
                          fullWidth
                          fieldProps={{
                            parse: (date) => date && date.toISOString(),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          key={`${key}_lte`}
                          name={`${key}_lte`}
                          label={`${label}${suffixes.lte}`}
                          TextFieldProps={{ margin: "normal" }}
                          fullWidth
                          fieldProps={{
                            parse: (date) => date && date.toISOString(),
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                }

                if (type === "boolean") {
                  return (
                    <Checkboxes
                      key={key}
                      name={key}
                      data={{ label, value: key }}
                      fieldProps={{ parse: (value) => (value ? 1 : undefined) }}
                      formControlProps={{ fullWidth: true }}
                    />
                  );
                }

                return null;
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                onClick={handleSubmit}
                color="primary"
                disabled={pristine}
                autoFocus
              >
                Filter
              </Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  );
};

FilterDialog.propTypes = {
  filters: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default FilterDialog;
