import React, { useState } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import reduce from "lodash/reduce";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconAdd from "@mui/icons-material/Add";
import IconEdit from "@mui/icons-material/Edit";
import IconFilter from "@mui/icons-material/FilterList";
import IconView from "@mui/icons-material/RemoveRedEye";
import { resourceListReadRequest, customReadRequest } from "store/actions";
import { hasPermission } from "services/permission";
import { fromEntities, fromResource, fromCustom } from "store/selectors";
import { tips } from "services/helpers";
import {
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Label,
  Filter,
} from "components";

const filtersGenerate = ({ expenses }) => ({
  expenseId: {
    label: "Expense Purpose",
    type: "select",
    multiple: false,
    options: expenses.map((m) => m.id),
    mappings: reduce(
      expenses.map((m) => ({ [m.id]: m.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  status: {
    label: "Status",
    type: "select",
    options: ["UNPAID", "PAID"],
    multiple: true,
  },
  createdAt: {
    label: "Created At",
    type: "date",
    suffixes: {
      gte: " from",
      lte: " to",
    },
    range: true,
  },
});

const ExpenseLogsListPage = () => {
  const [open, setOpen] = useState(false);
  const { count, filtersValues, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "expenselogs"),
      filtersValues: fromCustom.get(state, "expenselogs/filters"),
      items: fromEntities.getList(
        state,
        "expenselogs",
        fromResource.getList(state, "expenselogs")
      ),
      loading: pending(state, "expenseLogsListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const filters = React.useMemo(() => {
    return filtersGenerate(filtersValues);
  }, [filtersValues]);

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Expense Log"
        toolbar={
          <>
            {hasPermission("expenselogs", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/expenses/logs/create"
              >
                Add Expense
              </Button>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </>
        }
        tableBefore={
          <Filter filters={filters} open={open} onClose={handleFilterClose} />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "80%" }}>
              <Tooltip title={tips.expenses.name} arrow>
                <TableSortLabel
                  active={sort === "expenseId"}
                  component={Link}
                  direction={order}
                  to={getSortLink("expenseId")}
                >
                  Expense Name
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].expense.name || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "10%" }}>
              <Tooltip title={tips.expenses.amount} arrow>
                <TableSortLabel
                  active={sort === "amount"}
                  component={Link}
                  direction={order}
                  to={getSortLink("amount")}
                >
                  Amount
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].amount}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "10%" }}>
              <TableSortLabel
                active={sort === "status"}
                component={Link}
                direction={order}
                to={getSortLink("status")}
              >
                Status
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {
                <Label
                  color={
                    items[rowIndex].status === "PAID" ? "success" : "error"
                  }
                >
                  {items[rowIndex].status}
                </Label>
              }
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.expenses.paymentDay} arrow>
                <TableSortLabel
                  active={sort === "PaymentDate"}
                  component={Link}
                  direction={order}
                  to={getSortLink("paymentDate")}
                >
                  Payment Date
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].paymentDate ? (
                moment(items[rowIndex].paymentDate).format("L")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.expenses.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip
                  enterDelay={500}
                  title="View Expense Purpose"
                  placement="top"
                >
                  <IconButton
                    to={`/expenses/logs/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("expenselogs", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/expenses/logs/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

ExpenseLogsListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("expenselogs", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("expenselogs/filters")),
  ]);
};

export default ExpenseLogsListPage;
