import * as React from "react";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import LinearProgress from "@mui/material/LinearProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import { notificationSend } from "store/actions";
import api from "api";

const StyledLinearProgress = styled(LinearProgress)({
  borderRadius: "2px 2px 0 0",
  overflow: "hidden",
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 2,
});

const StyledOverlay = styled("div")({
  backgroundColor: "rgba(255, 255, 255, 0.38)",
  height: "100%",
  left: 0,
  position: "absolute",
  top: 0,
  width: "100%",
  zIndex: 1,
});

function SendReceiptDialog({ id, customer = {} }) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [type, setType] = React.useState("sms");
  const [value, setValue] = React.useState(customer.phone || "");

  const handleSend = React.useCallback(() => {
    const message = { type, subject: "Receipt" };
    if (type === "sms") message.phone = value;
    else message.email = value;
    setSubmitting(true);

    api
      .post(`/orders/${id}/sendreceipt`, message)
      .then(() => {
        dispatch(
          notificationSend("Receipt is sent successfully", {
            variant: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Error while sending receipt", { variant: "error" })
        );
      })
      .finally(() => {
        setOpen(false);
        setSubmitting(false);
      });
  }, [dispatch, id, type, value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleTypeChange = React.useCallback(
    (event) => {
      setType(event.target.value);
      if (event.target.value === "sms") setValue(customer.phone || "");
      else setValue(customer.email || "");
    },
    [customer.email, customer.phone]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button startIcon={<SendIcon />} onClick={handleOpen}>
        Send receipt
      </Button>
      <Dialog open={open} onClose={handleClose}>
        {submitting && <StyledLinearProgress />}
        {submitting && <StyledOverlay />}
        <DialogTitle>Send receipt</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can send receipt to customer using form below
          </DialogContentText>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={type}
              onChange={handleTypeChange}
            >
              <FormControlLabel value="sms" control={<Radio />} label="SMS" />
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="Email"
              />
            </RadioGroup>
          </FormControl>
          {type === "email" && (
            <TextField
              autoFocus
              variant="outlined"
              label="Email Address"
              type="email"
              margin="normal"
              placeholder="mail@example.com"
              fullWidth
              value={value}
              onChange={handleChange}
            />
          )}
          {type === "sms" && (
            <TextField
              autoFocus
              variant="outlined"
              label="Phone"
              type="tel"
              margin="normal"
              placeholder="+15555551234"
              fullWidth
              value={value}
              onChange={handleChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSend} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default SendReceiptDialog;
