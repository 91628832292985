import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { WalletTextForm } from "components";

const WalletsBlacklistCreatePage = () => (
  <Card elevation={0}>
    <CardHeader title="Add wallet" />
    <Divider />
    <WalletTextForm />
  </Card>
);

export default WalletsBlacklistCreatePage;
