/* eslint camelcase: ["error", {allow: ["date_gte", "date_lte"]}] */
import * as React from "react";
import clsx from "clsx";
import { parse, stringify } from "qs";
import lodashAt from "lodash/at";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import { pending } from "redux-saga-thunk";
import { shallowEqual, useSelector } from "react-redux";
import { visuallyHidden } from "@mui/utils";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { green, red } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconNavigateBefore from "@mui/icons-material/NavigateBefore";
import IconNavigateNext from "@mui/icons-material/NavigateNext";
import { customReadRequest } from "store/actions";
import { fromCustom } from "store/selectors";

const columns = [
  {
    field: "id",
    headerName: "ID",
    cellClassName: "cell-bg",
  },
  {
    field: "name",
    headerName: "Machine",
    width: "200px",
    cellClassName: "column-sticky",
    headerClassName: "column-sticky",
    valueFormatter: (row) => (
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: 200,
        }}
      >
        <MuiLink
          component={NavLink}
          to={`/machines/${row.id}`}
          underline="hover"
        >
          {row.name}
        </MuiLink>
      </Box>
    ),
  },
  ...Array.from(Array(12).keys()).map((month, index) => ({
    field: `data[${index}].profit`,
    headerName:
      index < 12
        ? new Date(2000, index).toLocaleString("en-US", { month: "long" })
        : "Yearly Totals",
    width: "7%",
    valueFormatter: (row) =>
      [row.data[index].revenue, row.data[index].rent]
        .map((v) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(v)
        )
        .join(" / "),

    style: (row) => ({
      backgroundColor: row.data[index].bgColor,
    }),
    align: "right",
  })),
  {
    field: `data[12].profit`,
    headerName: "Annual",
    cellClassName: "cell-total",
    valueFormatter: (row) =>
      [row.data[12].revenue, row.data[12].rent]
        .map((v) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(v)
        )
        .join(" / "),
    align: "right",
    width: "10%",
  },
];

function mapValue(value, oldMin, oldMax, newMin, newMax, step) {
  const scaledValue =
    ((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin) + newMin;
  return Math.round(scaledValue / step) * step;
}

function descendingComparator(a, b, orderBy) {
  if (lodashAt(a, orderBy)[0] > lodashAt(b, orderBy)[0]) {
    return -1;
  } else if (lodashAt(a, orderBy)[0] < lodashAt(b, orderBy)[0]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const ReportMachinesRentPage = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryYear =
    parseInt(searchParams.get("year")) || new Date().getFullYear();
  const query = parse(location.search.substring(1));

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [featuredOnly, setFeaturedOnly] = React.useState(false);

  const { data, loading } = useSelector(
    (state = {}) => ({
      data: fromCustom.get(state, "machinerentreport"),
      loading: pending(state, "machinerentreportCustomRead"),
    }),
    shallowEqual
  );

  const handleRequestSort = React.useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      const isDesc = orderBy === property && order === "desc";
      if (isDesc) {
        setOrder(undefined);
        setOrderBy(undefined);
      } else {
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
      }
    },
    [order, orderBy]
  );

  const handleDateChange = React.useCallback(
    (value) => {
      searchParams.set("year", new Date(value).getFullYear());
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const { currentDate, prevDate, nextDate, disabledNext } =
    React.useMemo(() => {
      const nextDate = new Date(queryYear + 1, 0, 1);
      return {
        nextDate,
        currentDate: new Date(queryYear, 0, 1),
        disabledNext: nextDate > new Date(),
        prevDate: new Date(queryYear - 1, 0, 1),
      };
    }, [queryYear]);

  const rows = React.useMemo(() => {
    let min = Infinity;
    let max = -Infinity;

    let today = new Date();

    const rows = data
      .map(
        ({
          rent: defaultRent,
          report,
          startDate: defaultStartDate,
          ...rest
        }) => {
          const columnTotal = {
            revenue: 0,
            rent: 0,
            profit: 0,
            bgColor: "transparent",
          };

          const startDate = new Date(defaultStartDate || 1970);

          const data = report.map((revenue, index) => {
            let rent = defaultRent;

            if (
              queryYear > today.getFullYear() ||
              (queryYear === today.getFullYear() && index > today.getMonth()) ||
              queryYear < startDate.getFullYear() ||
              (queryYear === startDate.getFullYear() &&
                index < startDate.getMonth())
            ) {
              rent = 0;
            }

            const profit = revenue + rent;

            columnTotal.revenue += revenue;
            columnTotal.rent += rent;
            columnTotal.profit += profit;

            min = Math.min(min, profit);
            max = Math.max(max, profit);

            return {
              revenue,
              rent,
              profit,
            };
          });

          for (let i = data.length; i < 12; i++) {
            data.push({
              revenue: 0,
              rent: 0,
              profit: 0,
            });
          }

          data.push(columnTotal);

          return { ...rest, data };
        }
      )
      .map(({ data, ...rest }) => {
        const newData = data.map(({ profit, ...rest }, index) => {
          let bgColor = "transparent";

          if (profit !== 0 && index !== 12) {
            const value = profit / (profit > 0 ? max : min);
            const opacity = mapValue(value, 0, 1, 0.1, 0.9, 0.1);
            bgColor = alpha(
              profit > 0 ? green[500] : red[500],
              opacity.toFixed(2)
            );
          }

          return { ...rest, profit, bgColor };
        });

        return { ...rest, data: newData };
      });

    return rows;
  }, [data, queryYear]);

  const rowTotal = React.useMemo(() => {
    const rowTotal = {
      id: "",
      name: "Total",
      data: Array.from(new Array(13)).map(() => ({
        revenue: 0,
        rent: 0,
        profit: 0,
        bgColor: "transparent",
      })),
    };

    let rowsFiltered = rows;

    if (featuredOnly) {
      rowsFiltered = rows.filter((v) => v.featured);
    }

    rowsFiltered.forEach(({ data }) => {
      data.forEach(({ revenue, rent, profit }, index) => {
        rowTotal.data[index].revenue += revenue;
        rowTotal.data[index].rent += rent;
        rowTotal.data[index].profit += profit;
      });
    });

    return rowTotal;
  }, [rows, featuredOnly]);

  const visibleRows = React.useMemo(() => {
    const comparator = getComparator(order, orderBy);
    let rowsFiltered = rows;

    if (featuredOnly) {
      rowsFiltered = rows.filter((v) => v.featured);
    }

    return stableSort(rowsFiltered, comparator);
  }, [order, orderBy, rows, featuredOnly]);

  const prevTo = React.useMemo(
    () => ({
      ...location,
      search: `?${stringify(
        {
          ...query,
          year: new Date(prevDate).getFullYear(),
        },
        { arrayFormat: "repeat", skipNulls: true }
      )}`,
    }),
    [location, prevDate, query]
  );

  const nextTo = React.useMemo(
    () => ({
      ...location,
      search: `?${stringify(
        {
          ...query,
          year: new Date(nextDate).getFullYear(),
        },
        { arrayFormat: "repeat", skipNulls: true }
      )}`,
    }),
    [location, nextDate, query]
  );

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const sourceRef = React.useRef(null);
  const targetRef = React.useRef(null);

  React.useEffect(() => {
    const sourceContainer = sourceRef.current;
    const targetContainer = targetRef.current;

    const sourceCols = sourceContainer.querySelectorAll("col");
    const targetCols = targetContainer.querySelectorAll("col");

    const resizeItemIndexMap = new Map();

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target;
        const index = resizeItemIndexMap.get(target);
        targetCols[index].style.width = `${
          target.getBoundingClientRect().width
        }px`;
      });
    });

    sourceCols.forEach((item, index) => {
      resizeObserver.observe(item);
      resizeItemIndexMap.set(item, index);
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  React.useEffect(() => {
    const sourceContainer = sourceRef.current;
    const targetContainer = targetRef.current;

    const handleScroll = (e) => {
      const { target } = e;
      if (target === sourceContainer) {
        targetContainer.scrollLeft = target.scrollLeft;
      } else if (target === targetContainer) {
        sourceContainer.scrollLeft = target.scrollLeft;
      }
    };

    sourceContainer.addEventListener("scroll", handleScroll);
    targetContainer.addEventListener("scroll", handleScroll);

    return () => {
      sourceContainer.removeEventListener("scroll", handleScroll);
      targetContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Paper elevation={0}>
      <Toolbar>
        <Typography variant="h5">
          {`Machines Rent Report, ${currentDate.getFullYear()}`}
        </Typography>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mr: -1.5,
            "& > * + *": {
              ml: 1,
            },
          }}
        >
          <FormControlLabel
            label="Featured Only"
            disabled={loading}
            control={
              <Checkbox
                checked={featuredOnly}
                onChange={() => setFeaturedOnly(!featuredOnly)}
              />
            }
          />
          <DatePicker
            disableFuture
            disabled={loading}
            onChange={handleDateChange}
            value={currentDate}
            views={["year"]}
            renderInput={(props) => (
              <TextField
                size="small"
                variant="outlined"
                {...props}
                inputProps={{
                  ...props.inputProps,
                  readOnly: true,
                }}
              />
            )}
          />
          <Tooltip
            title={prevDate.toLocaleString("en-US", {
              year: "numeric",
            })}
            placement="top"
          >
            <IconButton
              disabled={loading}
              component={NavLink}
              to={prevTo}
              size="large"
            >
              <IconNavigateBefore />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={nextDate.toLocaleString("en-US", {
              year: "numeric",
            })}
            placement="top"
          >
            <IconButton
              component={NavLink}
              to={nextTo}
              disabled={loading || disabledNext}
              style={disabledNext ? { pointerEvents: "none" } : {}}
              size="large"
            >
              <IconNavigateNext />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
      <Divider />
      <Box
        sx={{
          overflow: "inherit",
          "& table": {
            borderCollapse: "separate",
          },
          "& .sticky-header": {
            position: "sticky",
            top: "64px",
            background: "white",
            zIndex: 2,
            overflow: "hidden",
          },
          "& .sticky-header table": {
            tableLayout: "fixed",
          },
          "& tbody": {
            overflowX: "auto",
          },
          "& tr > *:first-child": {
            pl: { xs: 2, sm: 3 },
            borderLeft: 0,
          },
          "& tr > *:last-child": {
            pr: { xs: 2, sm: 3 },
            borderRight: 0,
          },
          "& td, & th": {
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderLeft: 0,
            borderTop: 0,
            whiteSpace: "nowrap",
          },
          "& .column-sticky": {
            background: "white",
            left: 0,
            position: "sticky",
            zIndex: 1,
          },
          "& .cell-bg": {
            backgroundColor: "white",
          },
          "& .cell-total": {
            backgroundColor: "grey.200",
            fontWeight: "500",
          },
          "& .container": {
            transition: (theme) => theme.transitions.create(["opacity"]),
          },
          "& .container-loeading": {
            opacity: 0.54,
            pointerEvents: "none",
          },
        }}
      >
        <TableContainer
          className={clsx("sticky-header", "container", {
            "container-loeading": loading,
          })}
          ref={targetRef}
        >
          <Table size="small">
            <colgroup>
              <col span="1" style={{ minWidth: "81px" }} />
              {Array.from(Array(columns.length - 1).keys()).map((v) => (
                <col span="1" key={v} />
              ))}
            </colgroup>
            <TableHead>
              <TableRow>
                {columns.map((headCell, index) => (
                  <TableCell
                    className={headCell.headerClassName}
                    key={headCell.field}
                    align={headCell.align}
                    sortDirection={orderBy === headCell.field ? order : false}
                    rowSpan={index < 2 ? 2 : undefined}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.field}
                      direction={orderBy === headCell.field ? order : "asc"}
                      onClick={createSortHandler(headCell.field)}
                    >
                      {headCell.headerName}
                      {orderBy === headCell.field ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow>
                {columns.map(
                  (headCell, index) =>
                    index > 1 && (
                      <TableCell key={headCell.field} align="right">
                        {`Revenue / Rent`}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <TableContainer
          className={clsx("container", {
            "container-loeading": loading,
          })}
          ref={sourceRef}
        >
          <Table size="small">
            <colgroup>
              <col span="1" style={{ minWidth: "81px" }} />
              {Array.from(Array(columns.length - 1).keys()).map((v) => (
                <col span="1" key={v} />
              ))}
            </colgroup>
            <TableBody>
              {visibleRows.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    {columns.map(
                      ({
                        field,
                        valueFormatter,
                        style,
                        cellClassName,
                        align,
                      }) => (
                        <TableCell
                          key={field}
                          sx={style ? style(row) : undefined}
                          className={cellClassName}
                          align={align}
                        >
                          {valueFormatter
                            ? valueFormatter(row)
                            : lodashAt(row, field)}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                );
              })}
              <TableRow hover tabIndex={-1}>
                {columns.map(
                  ({ field, valueFormatter, style, cellClassName, align }) => (
                    <TableCell
                      key={field}
                      sx={style ? style(rowTotal) : undefined}
                      className={clsx(cellClassName, "cell-total")}
                      align={align}
                    >
                      {valueFormatter && field !== "name"
                        ? valueFormatter(rowTotal)
                        : lodashAt(rowTotal, field)}
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

ReportMachinesRentPage.get = ({ store, query }) => {
  return store.dispatch(customReadRequest("machinerentreport", { ...query }));
};

export default ReportMachinesRentPage;
