import * as React from "react";
import { FORM_ERROR } from "final-form";
import merge from "lodash/merge";
import trimValue from "validator/lib/trim";

export const handleError = (error) => {
  if (!error.response) return { [FORM_ERROR]: error.toString() };

  let errors = {};
  if (typeof error.response.error === "string") {
    return { [FORM_ERROR]: error.response.error };
  }
  error.response.error.errors.forEach((element) => {
    errors = merge(errors, element);
  });
  return errors;
};

export const handleEntityLink = (item) => {
  const entities = [
    "autopaymentrecipients",
    "autopayments",
    "campaigns",
    "customerdetails",
    "customers",
    "expenselogs",
    "expenses",
    "loans",
    "machines",
    "notes",
    "operators",
    "orderqueue",
    "orders",
    "phoneblacklist",
    "receipts",
    "smartempties",
    "summary",
    "wallets",
    "walletsblacklist",
  ];

  const settings = [
    "apikeys",
    "organization",
    "exchanges",
    "kycproviders",
    "users",
  ];

  let entity = item.entityType.toLowerCase();

  if (entities.concat(settings).includes(entity)) {
    if (settings.includes(entity)) {
      return `/settings/${entity}`;
    }

    switch (entity) {
      case "autopayments": {
        entity = "autopayments/payments";
        break;
      }

      case "autopaymentrecipients": {
        entity = "autopayments/recipients";
        break;
      }

      case "customerdetails": {
        entity = "customers";
        break;
      }

      case "loans": {
        entity = "loans/history";
        break;
      }

      case "notes": {
        const { before, after } = item;

        if (item.action === "CREATE" || item.action === "UPDATE") {
          entity = `${after.entityType}/${after.entityId}#noteId=${after.id}`;
        } else {
          entity = `${before.entityType}/${before.entityId}`;
        }

        break;
      }

      case "expenses": {
        entity = "expenses/purposes";
        break;
      }

      case "expenselogs": {
        entity = "expenses/logs";
        break;
      }

      case "receipts": {
        entity = `orders`;
        break;
      }

      default:
        break;
    }

    if (entity === "orderqueue") return `/orders?queueId=${item.entityId}`;
    if (item.entityId) return `/${entity}/${item.entityId}`;
    return `/${entity} `;
  }

  return entity;
};

export const handleNotesEntity = (item) => {
  if (item.entityType === "Notes") {
    if (item.action === "CREATE" || item.action === "UPDATE") {
      return `${item.after.entityType} / ${item.after.entityId}`;
    }
  }

  return item.entityType;
};

export const trim = (value) => (value ? trimValue(value) : "");

export const getBlockchainLink = (
  type = "address",
  currency = "BTC",
  hash,
  domain = "blockchain"
) => {
  if (["BTC", "BTCTEST", "BCH", "BCHTEST", "ETH"].indexOf(currency) === -1) {
    return `#${hash}`;
  }

  let link = "https://www.blockchain.com/";

  if (domain === "blockcypher") {
    link = "https://live.blockcypher.com/";
  }

  switch (currency) {
    case "BTC":
      link += "btc";
      break;
    case "BTCTEST":
      link += "btc-testnet";
      break;
    case "BCH":
      link += "btc";
      break;
    case "BCHTEST":
      link += "bch-testnet";
      break;
    case "ETH":
      link += "eth";
      break;

    default:
      break;
  }

  link += `/${type}/${hash}`;
  return link;
};

export const tips = {
  orders: {
    id: "Order number, unique for every order. Order ID is printed on the receipt as well",
    atm: "ATM which facilitated transaction",
    wallet:
      "Wallet address customer scanned on the machine/ wallet where bitcoins will be sent",
    type: "Type of transaction",
    fee: "Assumed fiat value of blockchain confirmation fee. Not exact cost, orientational value, to be used only in orientational purposes.",
    cost: "Assumed cost of bitcoins sold/bought in this transaction. Not exact cost, orientational value, to be used only in orientational purposes. ",
    profit:
      "Assumed amount (what profit could be if you are buying/selling bitcoin back for spot price). Not exact cost, orientational value, to be used only in orientational purposes.",
    spotPrice: "Spot price of bitcoin at time when order took place",
    coinAmount: "Amount of bitcoins purchased/sold",
    coinPrice: "Bitcoin price for which customer bough/sold bitcoin",
    fiatAmount: "Amount of Fiat currency received/dispanced for order",
    createdAt: "Date and time order is created",
    status: "Current order status",
  },
  wallets: {
    id: "Internal use ID",
    name: "Name of the wallet",
    publicAddress: "Bitcoin wallet public address",
    currency: "Wallet currency",
    balance: "Wallet Balance",
    active:
      'Wallet active status. If Active status is "Off" wallet will not be used for operation.',
    qr: "Wallet QR code",
    createdAt: "Date wallet was  added to the Dashboard.",
    priority: "Order of wallet to use",
  },
  summaryMachines: {
    name: "Name of the machine",
    status:
      "Status of the machine. Active - machine is online, Offline - machine is not connected to the internet. If machine status is Offline check if machine is powered on and check your internet connection and cables, you may need to reboot the machine. ",
    recycler: "Amount of cash in the recycler",
    cashbox: "Amount of cash in the cashbox",
    total: "Total amount of cash in the machine",
    upTime: "Machine up time for the last 72 hours",
    notes: "Total notes currently stored in the machine",
  },
  settingsExchanges: {
    name: "Name of the exchange",
    createdAt: "Date and time when exchange was added",
    pair: "Exchange pair",
  },
  settingsOrganization: {
    organizationSettings:
      "Organizational settings (as company name, phone number, city, address etc.) will be displayed on the receipts and must be filled out. Logo will be displayed on the machine UI. Assure to save settings after any change. ",
  },
  settingsMachinesPage: {
    disclaimer:
      'A text that will be displayed on the machine UI under your "Terms and Conditions"',
    maintenanceScreen:
      "Text that will be displayed on the machine UI when machine is placed in the Maintenance mood",
    threshold:
      "Order cash amount over which all orders of unapproved customers will be placed on hold. Does not apply to approved customers.",
    blockchainIntel:
      "A 3rd party service by BlockchainIntel. Usually used for receiving additional informations about order wallets. ",
  },
  phoneBlacklist: {
    phone: "Blacklisted phone numbers",
    createdAt: "Date and time when phone number was blacklisted in Dashboard",
  },
  walletBlacklist: {
    wallet: "Blacklisted wallet address",
    createdAt: "Date and time when wallet address was blacklisted in Dashboard",
  },
  autopaymentsReceipents: {
    name: "Name of the Recipient",
    wallet: "Recipient wallet where autopayments are sent",
    percent: "Percent or amount paid to the Recipient",
    createdAt: "Date and time when autopayment was added to Dahboard. ",
  },
  autopaymentsPayments: {
    recipient: "Recipient name",
    wallet: "Recipient wallet address (where autopayments are sent)",
    price: "Assumed fiat value in the time of transaction.",
    amount: "Amount of Bitcoins sent to recipient",
    currency: "Payment currency",
    status: "Payment status.",
    paidDate: "Date of payment",
    createdAt: "Date and time when payment was created",
  },
  smartEmpties: {
    atm: "ATM name",
    operator: "Name of the operator who initiated Smart Empty",
    btcSpotRate: "Bitcoin spot rate in the time of smart empty",
    btcAmount:
      "Smart empty amount displayed in assumed bitcoin amount, calculated by spot rate in the time of smart empty. Not exact amount, orientational value, to be used only in orientational purposes. ",
    emptied: "Cash amount that is emptied in smart empty. ",
    reconciled: "Internal operator reconciliation",
    date: "Date and time of the smart empty",
  },
  transactions: {
    id: "Transaction Hash ID",
    wallet: "Transaction in/out of Wallet name",
    entity: "Transaction relatione",
    amount: "Transaction amount",
    fee: "Blockchain transaction fee",
    currency: "Transaction currency",
    timestamp: "Date and time of transaction",
  },
  kycLimits: {
    fromTo: "Limit range displayed in fiat currency. Limits can not overlap.",
    providers:
      "Limit providers. SMS - collection of phone number, Goverment ID - collection of ID",
  },
  expenses: {
    name: "Expense purpose name or description",
    amount: "Expense purpose amount(May be differ from payment)",
    paymentDay: "Day of the month on which payment is scheduled",
    paymerntType:
      "AUTO - will create payment automatically, MANUAL requires user action",
    createdAt: "Date and time when expense purpose was added to Dahboard",
  },
};

export function triggerDownload(url, name) {
  const evt = new MouseEvent("click", {
    view: window,
    bubbles: false,
    cancelable: true,
  });

  const a = document.createElement("a");
  a.setAttribute("download", name);
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");

  a.dispatchEvent(evt);
  a.remove();
}

export const saveSvgAsPng = (svgId, options = {}) => {
  try {
    const { width, height, name } = options;

    const svg = document.getElementById(svgId);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const w = width || svg.clientWidth;
    const h = height || svg.clientHeight;

    ctx.canvas.width = w;
    ctx.canvas.height = w;
    ctx.rect(0, 0, w, h);
    ctx.fillStyle = "#FFFFFF";
    ctx.fill();

    const data = new XMLSerializer().serializeToString(svg);
    const DOMUrl = window.URL || window.webkitURL || window;

    const img = new Image();
    const svgBlob = new Blob([data], { type: "image/svg+xml;charset=utf-8" });
    const url = DOMUrl.createObjectURL(svgBlob);

    img.onload = () => {
      ctx.drawImage(img, 0, 0, w, h, 8, 8, w - 16, h - 16);
      DOMUrl.revokeObjectURL(url);

      const imgURI = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      triggerDownload(
        imgURI,
        `${name || "img"}-${Date.now()}-${Math.floor(Math.random() * 21)}.png`
      );

      canvas.remove();
    };

    img.src = url;
  } catch (error) {
    console.error(error);
  }
};

export const useConfirm = (object = false) => {
  const [open, setOpen] = React.useState(false);
  const [callback, setCallback] = React.useState(null);
  const confirm = React.useCallback(
    (callback) => (event) => {
      event.preventDefault();
      event.persist();
      setCallback(() => () => callback(event));
      setOpen(true);
    },
    []
  );

  const proceed = React.useCallback(() => {
    if (typeof callback === "function") callback();
    setOpen(false);
    setCallback(null);
  }, [callback]);

  const cancel = React.useCallback(() => {
    setCallback(null);
    setOpen(false);
  }, []);

  if (object) return { open, confirm, proceed, cancel };

  return [open, confirm, proceed, cancel];
};
