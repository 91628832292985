import { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Checkboxes, TextField } from "mui-rff";
import { handleError, trim } from "services/helpers";
import { createValidator, required } from "services/validation";
import {
  notificationSend,
  resourceCreateRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { DiffBlock, Confirmation } from "components";

const validate = createValidator({
  firstName: [required],
});

const OperatorForm = ({ id }) => {
  const {
    initialValues = {
      firstName: null,
      lastName: null,
    },
  } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "operators", id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    let action = resourceCreateRequest("operators", values);
    if (id) action = resourceUpdateRequest("operators", id, values);

    return dispatch(action)
      .then((resultId) => {
        dispatch(
          notificationSend(
            `Operator ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        if (!id) navigate(`/operators/${resultId}`);
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} operator`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="firstName"
                        label="First name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        autoComplete="given-name"
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="lastName"
                        label="Last name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        autoComplete="family-name"
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <Checkboxes
                        name="archived"
                        helperText="Hide from the main list"
                        data={{ label: "Archived", value: "archived" }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

export default OperatorForm;
