import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { CustomerForm } from "components";

const CustomersCreatePage = () => (
  <Card elevation={0}>
    <CardHeader title="Create Customer" />
    <Divider />
    <CustomerForm />
  </Card>
);

export default CustomersCreatePage;
