import * as React from "react";
import moment from "moment";
// import isEqual from 'lodash/isEqual'
// import omit from 'lodash/omit'
import { QRCodeSVG as QRCode } from "qrcode.react";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconEdit from "@mui/icons-material/Edit";
import IconAdd from "@mui/icons-material/Add";
import IconFilter from "@mui/icons-material/FilterList";
import IconRefresh from "@mui/icons-material/Refresh";
import IconWalletTransaction from "@mui/icons-material/Toc";
import ErrorIcon from "@mui/icons-material/Error";
import { hasPermission } from "services/permission";
import { notificationSend, resourceListReadRequest } from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import { tips } from "services/helpers";
import {
  BlockchainLink,
  Filter,
  Label,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
} from "components";
import { resourceDetailReadRequest } from "store/resource/actions";

const filters = {
  archived: {
    label: "Show archived",
    type: "boolean",
  },
};

const WalletsListPage = (props) => {
  const [open, setOpen] = React.useState(false);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [wallet, setWallet] = React.useState("");

  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "wallets"),
      items: fromEntities.getList(
        state,
        "wallets",
        fromResource.getList(state, "wallets")
      ),
      loading: pending(state, "walletsListRead"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const updateBalance = (id) =>
    dispatch(resourceDetailReadRequest("wallets", id, { update: true }))
      .then(() => {
        dispatch(
          notificationSend(`Wallet balance is updated succesfully`, {
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          notificationSend("Failed to update wallet balance", {
            variant: "error",
          })
        );
      });

  const handleOpen = (wallet) => () => {
    setOpen(true);
    setWallet(wallet);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateBalance = (id) => () => {
    updateBalance(id);
  };

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Wallets"
        toolbar={
          <>
            {hasPermission("wallets", "POST") && (
              <div>
                <Button
                  startIcon={<IconAdd />}
                  component={Link}
                  to="/wallets/create"
                >
                  Add wallet
                </Button>
              </div>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </>
        }
        tableBefore={
          <Filter
            filters={filters}
            open={filterOpen}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <Tooltip title={tips.wallets.id} arrow>
                <TableSortLabel
                  active={sort === "id"}
                  component={Link}
                  direction={order}
                  to={getSortLink("id")}
                >
                  Id
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "98%" }}>
              <Tooltip title={tips.wallets.name} arrow>
                <TableSortLabel
                  active={sort === "name"}
                  component={Link}
                  direction={order}
                  to={getSortLink("name")}
                >
                  Name
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap", py: 0 }}>
              {items[rowIndex].name}

              {!items[rowIndex].initialized && items[rowIndex].active && (
                <Tooltip title="View more in details" placement="top">
                  <Chip
                    size="small"
                    color="error"
                    icon={<ErrorIcon />}
                    label="Failed to initialize"
                    sx={{ ml: 1 }}
                  />
                </Tooltip>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.wallets.publicAddress} arrow>
                <span>Public Address</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              <BlockchainLink
                wallet={items[rowIndex].address}
                coinType={items[rowIndex].currency}
                nowrap
              />
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.wallets.balance} arrow>
                <TableSortLabel
                  active={sort === "balance"}
                  component={Link}
                  direction={order}
                  to={getSortLink("balance")}
                >
                  Balance
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].pendingBalance !== 0
                ? `${items[rowIndex].balance}(${items[rowIndex].pendingBalance})`
                : items[rowIndex].balance}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.wallets.currency} arrow>
                <span>Currency</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].currency}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.wallets.active} arrow>
                <TableSortLabel
                  active={sort === "active"}
                  component={Link}
                  direction={order}
                  to={getSortLink("active")}
                >
                  Active
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].active ? (
                <Label color="success">Yes</Label>
              ) : (
                <Label color="error">No</Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <Tooltip title={tips.wallets.priority} arrow>
                <TableSortLabel
                  active={sort === "priority"}
                  component={Link}
                  direction={order}
                  to={getSortLink("priority")}
                >
                  Priority
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].priority}</TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.wallets.qr} arrow>
                <span>QR</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={handleOpen(items[rowIndex].address)}
                fullWidth
              >
                View&nbsp;QR
              </Button>
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.wallets.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip
                  enterDelay={500}
                  title="Update Balance"
                  placement="top"
                >
                  <span>
                    <IconButton
                      disabled={!items[rowIndex].active}
                      onClick={handleUpdateBalance(items[rowIndex].id)}
                      size="large"
                    >
                      <IconRefresh />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  enterDelay={500}
                  title="View transactions"
                  placement="top"
                >
                  <IconButton
                    to={`/wallettransactions?walletId=${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconWalletTransaction />
                  </IconButton>
                </Tooltip>
                <Tooltip enterDelay={500} title="View details" placement="top">
                  <IconButton
                    to={`/wallets/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("wallets", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/wallets/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Wallet QR</DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {wallet}
          </Typography>
          <QRCode
            id="wallet-qr-code"
            value={wallet}
            width={300}
            height={300}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

WalletsListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("wallets", {
      _limit: 100,
      _sort: "priority",
      _order: "ASC",
      ...query,
    })
  );
};

export default WalletsListPage;
