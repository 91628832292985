import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CallMadeIcon from "@mui/icons-material/CallMade";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const StyledCardActionArea = styled(CardActionArea)({
  alignItems: "normal",
  display: "flex",
  flexWrap: "wrap",
});

const StyledAvatarContent = styled(CardContent)({
  alignItems: "center",
  display: "flex",
  paddingRight: 0,
  flex: 0,
});

const StyledAvatar = styled(Avatar)({
  backgroundColor: "transparent",
  color: "#000000",
});

const StyledContent = styled(CardContent)(({ theme }) => ({
  flex: 1,
}));

const StyledActions = styled(CardContent)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: 8,
  paddingTop: 0,
  width: "100%",
}));

const ShortCard = (props) => {
  const { icon, title, description, to, href, target, ...other } = props;

  const actionProps = { to, href, target };

  let IconComponent = CallMadeIcon;
  if (target === "_blank") IconComponent = OpenInNewIcon;

  return (
    <Card variant="outlined" {...other}>
      <StyledCardActionArea component={to ? Link : "a"} {...actionProps}>
        {icon && (
          <StyledAvatarContent>
            <StyledAvatar>{icon}</StyledAvatar>
          </StyledAvatarContent>
        )}
        <StyledContent>
          <Typography variant="subtitle2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {description}
          </Typography>
        </StyledContent>
        <StyledActions>
          <span>Related link</span> <IconComponent />
        </StyledActions>
      </StyledCardActionArea>
    </Card>
  );
};

ShortCard.propTypes = {
  classes: PropTypes.object,
  description: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.node,
  taget: PropTypes.string,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default ShortCard;
