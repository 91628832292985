import * as React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { unstable_composeClasses as composeClasses } from "@mui/base";
import { styled } from "@mui/material/styles";
import { capitalize } from "@mui/material/utils";
import Box from "@mui/material/Box";
import * as colors from "@mui/material/colors";
import { getAlertUtilityClass } from "./labelClasses";

const useUtilityClasses = (ownerState) => {
  const { classes, color, variant, fullWidth } = ownerState;

  const slots = {
    root: ["root", variant, fullWidth, `color${capitalize(color)}`],
  };

  return composeClasses(slots, getAlertUtilityClass, classes);
};

const LabelRoot = styled(Box, {
  name: "Label",
  slot: "Root",
  overridesResolver: (props, styles) => {
    const { ownerState } = props;
    const { color, variant, fullWidth } = ownerState;

    return [
      styles.root,
      styles[`color${capitalize(color)}`],
      styles[variant],
      styles[fullWidth],
      styles[`${variant}${capitalize(color)}`],
    ];
  },
})(
  ({ theme, ownerState }) => ({
    ...theme.typography.caption,
    lineHeight: "1.5em",
    borderRadius: "2px",
    display: "inline-flex",
    justifyContent: "center",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    ...(ownerState.fullWidth && {
      width: "100%",
    }),
  }),
  ({ theme, ownerState }) => ({
    ...(ownerState.variant === "filled" && {
      border: 0,
      padding: "2px 6px",
      backgroundColor: theme.palette.grey[600],
      color: theme.palette.getContrastText(theme.palette.grey[600]),
    }),
    ...(ownerState.variant === "filled" &&
      ownerState.color !== "default" && {
        backgroundColor:
          theme.palette[ownerState.color]?.main ||
          colors[ownerState.color][500],
        color:
          theme.palette[ownerState.color]?.contrastText ||
          theme.palette.getContrastText(colors[ownerState.color][500]),
      }),
  }),
  ({ theme, ownerState }) => ({
    ...(ownerState.variant === "outlined" && {
      border: "1px solid",
      padding: "1px 5px",
      color: theme.palette.grey[600],
      borderColor: theme.palette.grey[600],
    }),
    ...(ownerState.variant === "outlined" &&
      ownerState.color !== "default" && {
        borderColor:
          theme.palette[ownerState.color]?.main ||
          colors[ownerState.color][500],
        color:
          theme.palette[ownerState.color]?.main ||
          colors[ownerState.color][500],
      }),
  })
);

const Label = React.forwardRef(function Label(props, ref) {
  const {
    children,
    className,
    color = "default",
    component = "span",
    fullWidth = false,
    variant = "filled",
    ...other
  } = props;

  const ownerState = {
    color,
    fullWidth,
    variant,
  };

  const classes = useUtilityClasses(ownerState);

  return (
    <LabelRoot
      ownerState={ownerState}
      className={clsx(classes.root, className)}
      ref={ref}
      component={component}
      {...other}
    >
      {children}
    </LabelRoot>
  );
});

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    ...Object.keys(colors),
  ]),
  component: PropTypes.any,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "filled"]),
};

export default Label;
