import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { MachineForm } from "components";
import { customReadRequest, resourceListReadRequest } from "store/actions";

const MachinesCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Create Machine" />
      <Divider />
      <MachineForm />
    </Card>
  );
};

MachinesCreatePage.get = ({ store }) => {
  return Promise.all([
    store.dispatch(resourceListReadRequest("kycproviders")),
    store.dispatch(customReadRequest("cryptocurrencies/list")),
    store.dispatch(customReadRequest("kioskversions")),
  ]);
};

export default MachinesCreatePage;
