import { Fragment, useState } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Hidden from "@mui/material/Hidden";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconSettings from "@mui/icons-material/Settings";
import IconFilter from "@mui/icons-material/FilterList";
import IconAdd from "@mui/icons-material/Add";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { hasPermission } from "services/permission";
import {
  customReadRequest,
  resourceListReadRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromEntities, fromResource, fromCustom } from "store/selectors";
import {
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Label,
  SmartEmptyRoute,
} from "components";
import { ProgressBar } from "../../common/renderProgress";

const StyledCheckbox = styled(Checkbox)(() => ({
  opacity: 0.26,
  "&:hover, &.Mui-checked": {
    opacity: 1,
  },
}));

const filters = ({ activeCoins, fiatCurrency, softvareVersions }) => ({
  status: {
    label: "Status",
    type: "select",
    options: [0, 1, 2, 3, 4],
    mappings: {
      0: "MAINTENANCE",
      1: "ACTIVE",
      2: "CONFIGURATION",
      3: "OFFLINE",
      4: "ERROR",
    },
    multiple: true,
  },
  fiatCurrency: {
    label: "Fiat Currency",
    type: "select",
    options: fiatCurrency,
    multiple: false,
  },
  activeCoins: {
    label: "Active coins",
    type: "select",
    options: activeCoins,
    multiple: false,
  },
  "softvareVersions.kiosk": {
    label: "Kiosk version",
    type: "select",
    options: softvareVersions.kiosk,
    multiple: false,
  },
  "softvareVersions.backend": {
    label: "Backend version",
    type: "select",
    options: softvareVersions.backend,
    multiple: false,
  },
  archived: {
    label: "Show archived",
    type: "boolean",
  },
});

const MachinesListPage = (props) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { count, filtersValues, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "machines"),
      filtersValues: fromCustom.get(state, "machines/filters"),
      items: fromEntities.getDenormalizedList(
        state,
        "machines",
        fromResource.getList(state, "machines")
      ),
      loading: pending(state, "machinesListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const getStatusColor = (status) => {
    if (/active/i.test(status)) return "success";
    if (/busy/i.test(status)) return "success";
    if (/error/i.test(status)) return "error";
    if (/config/i.test(status)) return "info";
    if (/init/i.test(status)) return "info";
    if (/online/i.test(status)) return "info";
    if (/maintenance/i.test(status)) return "warning";
    return "default";
  };

  const handleMarkAsFeatured = (id) => (event) => {
    dispatch(
      resourceUpdateRequest("machines", id, { featured: event.target.checked })
    );
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Machines"
        toolbar={
          <Fragment>
            {hasPermission("machines", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/machines/create"
              >
                Create Machine
              </Button>
            )}
            <SmartEmptyRoute />
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </Fragment>
        }
        tableBefore={
          <Filter
            filters={filters(filtersValues)}
            open={open}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={() => <TableCell sx={{ width: "0%" }} />}
          cell={({ rowIndex }) => (
            <TableCell padding="none" sx={{ paddingLeft: 1 }}>
              <StyledCheckbox
                color="warning"
                onChange={handleMarkAsFeatured(items[rowIndex].id)}
                defaultChecked={items[rowIndex].featured}
                icon={<StarOutlineIcon />}
                checkedIcon={<StarIcon />}
              />
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%", paddingLeft: 0 }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "auto" }}>
              <TableSortLabel
                active={sort === "name"}
                component={Link}
                direction={order}
                to={getSortLink("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <MuiLink
                component={Link}
                to={`/orders?atmId=${items[rowIndex].id}`}
                underline="hover"
              >
                {items[rowIndex].name}
              </MuiLink>
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <Hidden lgDown>
              <TableCell
                align="right"
                sx={{ width: "1%", whiteSpace: "nowrap" }}
              >
                Buy Fee (%)
              </TableCell>
            </Hidden>
          )}
          cell={({ rowIndex }) => (
            <Hidden lgDown>
              <TableCell align="right">{items[rowIndex].markup}</TableCell>
            </Hidden>
          )}
        />
        <TableColumn
          header={() => (
            <Hidden lgDown>
              <TableCell
                align="right"
                sx={{ width: "1%", whiteSpace: "nowrap" }}
              >
                Sell fee (%)
              </TableCell>
            </Hidden>
          )}
          cell={({ rowIndex }) => (
            <Hidden lgDown>
              <TableCell align="right">{items[rowIndex].markdown}</TableCell>
            </Hidden>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "1%" }}>
              Recycler
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: items[rowIndex].fiatCurrency,
              }).format(items[rowIndex].machineState.recyclerCash)}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "1%" }}>
              Cashbox
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: items[rowIndex].fiatCurrency,
              }).format(items[rowIndex].machineState.cashbox)}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "1%", whiteSpace: "nowrap" }}>
              Cash Total
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: items[rowIndex].fiatCurrency,
              }).format(items[rowIndex].machineState.totalCash)}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ width: "1%" }}>
                Version
              </TableCell>
            </Hidden>
          )}
          cell={({ rowIndex }) => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {items[rowIndex].machineState !== null ? (
                  items[rowIndex].machineState.kioskVersion
                ) : (
                  <i>[missing]</i>
                )}
              </TableCell>
            </Hidden>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ width: "100px", whiteSpace: "nowrap" }}>
              Up time, 72h
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <ProgressBar value={items[rowIndex].machineState.upTime || 0} />
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "lastHeartbeat"}
                component={Link}
                direction={order}
                to={getSortLink("lastHeartbeat")}
              >
                Heartbeat
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].lastHeartbeat ? (
                moment(items[rowIndex].lastHeartbeat).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "status"}
                component={Link}
                direction={order}
                to={getSortLink("status")}
              >
                Status
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Label
                color={getStatusColor(items[rowIndex].machineState.status)}
                fullWidth
              >
                {items[rowIndex].machineState.status}
              </Label>
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "0%" }} />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View details" placement="top">
                  <IconButton
                    to={`/machines/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("machines", "PUT") && (
                  <Tooltip
                    enterDelay={500}
                    title="Edit settings"
                    placement="top"
                  >
                    <IconButton
                      to={`/machines/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconSettings />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

MachinesListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("machines", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("machines/filters")),
  ]);
};

export default MachinesListPage;
