import { Fragment } from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import JSONPretty from "react-json-prettify";
import { github } from "react-json-prettify/dist/themes";
import { Label, ReadonlyTextField } from "components";
import { handleEntityLink, handleNotesEntity } from "services/helpers";

const ChangelogReadPage = (props) => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "useractions",
        params.id
      ),
    }),
    shallowEqual
  );

  const handleGetAction = (action) => {
    switch (action) {
      case "DELETE":
        return "error";

      case "CREATE":
        return "success";

      default:
        return "info";
    }
  };

  const { id, userId, action, entityType, before, after, user, createdAt } =
    detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Changelog
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="User Name"
              value={
                <MuiLink
                  component={Link}
                  to={`/settings/users/${userId}/update`}
                  underline="hover"
                >
                  {user.userName}
                </MuiLink>
              }
              fullWidth
            />
            <ReadonlyTextField
              label="Entity"
              value={
                handleEntityLink(detail) ? (
                  <MuiLink
                    component={Link}
                    to={handleEntityLink(detail)}
                    underline="hover"
                  >
                    {handleNotesEntity(detail)}
                  </MuiLink>
                ) : (
                  entityType
                )
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="Action"
              value={
                <Label variant="outlined" color={handleGetAction(action)}>
                  {action}
                </Label>
              }
              fullWidth
            />
            <ReadonlyTextField
              label="Created At"
              value={
                createdAt ? (
                  moment(createdAt).format("L, LTS")
                ) : (
                  <i>[missing]</i>
                )
              }
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="BEFORE"
              value={<JSONPretty theme={github} json={before} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <ReadonlyTextField
              label="AFTER"
              value={<JSONPretty theme={github} json={after} />}
              fullWidth
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ChangelogReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("useractions", params.id)),
  ]);
};

export default ChangelogReadPage;
