import { schema } from "normalizr";

export const kycproviders = new schema.Entity("kycproviders");

export const customers = new schema.Entity("customers");

export const machines = new schema.Entity("machines", {
  kycProviders: [kycproviders],
});

export const machineevents = new schema.Entity("machineevents", {
  machine: machines,
});

export const orders = new schema.Entity("orders", {
  customer: customers,
  atm: machines,
});

export const orderqueue = new schema.Entity("orderqueue");

export const userroles = new schema.Entity("userroles");

export const users = new schema.Entity("users", {
  role: userroles,
});

export const useractions = new schema.Entity("useractions", {
  user: users,
});

export const usersmesessions = new schema.Entity("users/me/sessions");

export const userauthsessions = new schema.Entity("userauthsessions", {
  user: users,
});

export const usersmeipwhitelist = new schema.Entity("users/me/ipwhitelist");

export const operators = new schema.Entity("operators");

export const exchanges = new schema.Entity("exchanges");

export const expenses = new schema.Entity("expenses");

export const expenselogs = new schema.Entity("expenselogs");

export const wallets = new schema.Entity("wallets");

export const smartempties = new schema.Entity("smartempties", {
  machine: machines,
  operator: operators,
});

export const royalties = new schema.Entity("royalties");

export const autopaymentsrecipients = new schema.Entity(
  "autopaymentsrecipients"
);

export const autopayments = new schema.Entity("autopayments", {
  orders: [orders],
  recipient: autopaymentsrecipients,
});

export const phoneblacklist = new schema.Entity("phoneblacklist");

export const walletblacklist = new schema.Entity("walletblacklist");

export const configipblacklist = new schema.Entity("config/ipblacklist");

export const loans = new schema.Entity("loans");

export const loansavailable = new schema.Entity("loans/available");

export const loanshistory = new schema.Entity("loans/history");

export const notes = new schema.Entity("notes", {
  user: users,
});

export const wallettransactions = new schema.Entity("wallettransactions", {
  wallet: wallets,
});

export const loanswalletwhitelist = new schema.Entity("loans/walletwhitelist");

export const campaigns = new schema.Entity("campaigns");

export const configkyclimits = new schema.Entity("config/kyclimits", {
  kycProviders: [kycproviders],
});

export const notifications = new schema.Entity("notifications");

export const apikeys = new schema.Entity("apikeys");

export const trackingsessionstaglist = new schema.Entity(
  "trackingsessions/taglist",
  {},
  {
    idAttribute: "tag",
  }
);

export const trackingsessions = new schema.Entity("trackingsessions", {
  tags: [trackingsessionstaglist],
});
