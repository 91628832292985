import * as React from "react";
import clsx from "clsx";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: "relative",
  overflow: "hidden",
  width: "100%",
  height: 22,
  borderRadius: 2,
}));

const Value = styled("div")({
  position: "absolute",
  lineHeight: "20px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const Bar = styled("div")({
  height: "100%",
  "&.low": {
    backgroundColor: "#f44336",
  },
  "&.medium": {
    backgroundColor: "#efbb5aa3",
  },
  "&.high": {
    backgroundColor: "#088208a3",
  },
});

export const ProgressBar = React.memo(function ProgressBar(props) {
  const { value } = props;

  return (
    <Root>
      <Value>{`${value.toLocaleString()} %`}</Value>
      <Bar
        className={clsx({
          low: value < 30,
          medium: value >= 30 && value <= 70,
          high: value > 70,
        })}
        style={{ maxWidth: `${value}%` }}
      />
    </Root>
  );
});

export function renderProgress(params) {
  if (params.value == null) {
    return "";
  }

  // If the aggregated value does not have the same unit as the other cell
  // Then we fall back to the default rendering based on `valueGetter` instead of rendering a progress bar.
  if (params.aggregation && !params.aggregation.hasCellUnit) {
    return null;
  }

  return <ProgressBar value={params.value} />;
}
