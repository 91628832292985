import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { customReadRequest } from "store/actions";
import { ExchangeForm } from "components";

const ExchangesCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Add exchange" />
      <Divider />
      <ExchangeForm />
    </Card>
  );
};

ExchangesCreatePage.get = ({ store, params }) => {
  return store.dispatch(customReadRequest("exchanges/available", params.id));
};

export default ExchangesCreatePage;
