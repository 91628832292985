import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import moment from "moment";

export default function RHFTextField({
  name,
  control,
  component: TextFieldComponent = TextField,
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, ref, ...restField },
        fieldState: { error },
      }) => (
        <DatePicker
          name={name}
          {...rest}
          value={value}
          onChange={(newValue) => {
            onChange(
              newValue instanceof moment ? newValue.toISOString() : newValue
            );
            if (typeof rest.onChange === "function") {
              rest.onChange(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              inputRef={ref}
              error={!!error}
              helperText={error ? error.message : rest.helperText}
              {...params}
            />
          )}
        />
      )}
    />
  );
}
