import { Fragment } from "react";
import PropTypes from "prop-types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Checkboxes, TextField } from "mui-rff";
import { handleError, trim } from "services/helpers";
import { createValidator, required, integer } from "services/validation";
import {
  notificationSend,
  resourceUpdateRequest,
  resourceCreateRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { DiffBlock, Confirmation } from "components";

const validate = createValidator({
  name: [required],
  code: [required],
  type: [required],
  // amount: [integer],
  // percent: [integer],
  quantity: [integer, required],
  active: [required],
  disposable: [required],
});

const mappings = {
  disposable: {
    true: "Yes",
    false: "No",
  },
  status: {
    true: "Active",
    false: "Inactive",
  },
  type: {
    CREDIT: "CREDIT",
    DISCOUNT: "DISCOUNT",
  },
};
const CampaignsForm = ({ id }) => {
  const {
    initialValues = {
      archived: false,
      active: true,
      code: "",
      disposable: true,
      id: null,
      name: "",
      type: "CREDIT",
    },
  } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "campaigns", id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    let action = resourceCreateRequest("campaigns", values);
    if (id) action = resourceUpdateRequest("campaigns", id, values);

    return dispatch(action)
      .then((resultId) => {
        dispatch(
          notificationSend(
            `Campaign ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        if (!id) navigate(`/campaigns/${resultId}/update`);
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} campaign`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  const newCampaign = !Boolean(initialValues.id);

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} xl={4}>
                      <TextField
                        InputProps={{ readOnly: !newCampaign }}
                        name="type"
                        label="Type"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                        required
                      >
                        {Object.keys(mappings.type).map((key) => (
                          <MenuItem key={key} value={key}>
                            {mappings.type[key]}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        InputProps={{ readOnly: !newCampaign }}
                        name="name"
                        label="Name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        InputProps={{ readOnly: !newCampaign }}
                        name="code"
                        label="Code"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      {values.type === "CREDIT" && (
                        <TextField
                          InputProps={{ readOnly: !newCampaign }}
                          name="amount"
                          label="Amount, USD"
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                      {values.type === "DISCOUNT" && (
                        <TextField
                          InputProps={{ readOnly: !newCampaign }}
                          name="percent"
                          label="Percent, %"
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                        />
                      )}
                      <TextField
                        name="quantity"
                        label="Quantity"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                      />
                      <Checkboxes
                        name="active"
                        data={{ label: "Active", value: "active" }}
                        formControlProps={{ fullWidth: true }}
                      />
                      <Checkboxes
                        name="disposable"
                        data={{ label: "Disposable", value: "disposable" }}
                        formControlProps={{ fullWidth: true }}
                      />
                      <Checkboxes
                        name="archived"
                        helperText="Hide from the main list"
                        data={{ label: "Archived", value: "archived" }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values, mappings }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

CampaignsForm.defaultProps = {
  initialValues: {
    active: true,
    code: "",
    disposable: true,
    id: "",
    name: "",
    type: "CREDIT",
  },
};

CampaignsForm.propTypes = {
  id: PropTypes.number,
  cryptoСurrencies: PropTypes.array,
};

export default CampaignsForm;
