import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { lighten } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

const StyledToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== "highlight",
})(({ theme, highlight }) => ({
  paddingLeft: 16,
  paddingRight: 16,

  ...(highlight &&
    (theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        })),
}));

const StyledActions = styled("div")(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-flex",
  flex: "0 1 auto",
  "& > *": {
    marginLeft: 8,
  },
}));

const TableToolbar = (props) => {
  const { content, contentAlt, numSelected, title } = props;

  return (
    <StyledToolbar highlight={numSelected > 0}>
      <Box
        sx={{
          flex: "0 0 auto",
        }}
      >
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {`${numSelected} selected`}
          </Typography>
        ) : (
          <Typography color="inherit" variant="h5">
            {title}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          flex: "1 1 auto",
          minHeight: 1,
        }}
      />
      <StyledActions>{numSelected > 0 ? contentAlt : content}</StyledActions>
    </StyledToolbar>
  );
};

TableToolbar.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  contentAlt: PropTypes.any,
  numSelected: PropTypes.number.isRequired,
};

TableToolbar.defaultProps = {
  title: null,
  content: null,
  contentAlt: null,
};

export default TableToolbar;
