/* eslint-disable no-bitwise */
function toHex(str) {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash &= hash;
  }

  let color = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }

  return color;
}
/* eslint-enable no-bitwise */

export default toHex;
