import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import IconView from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import { customReadRequest } from "store/actions";
import { ApiKeysForm } from "components";

const ApiKeysCreatePage = () => (
  <Card>
    <CardHeader
      title="Create API Key"
      action={
        <Button
          startIcon={<IconView />}
          component={Link}
          to="/settings/apikeys/docs"
        >
          View API Docs
        </Button>
      }
    />
    <Divider />
    <ApiKeysForm />
  </Card>
);

ApiKeysCreatePage.get = ({ store }) => {
  return store.dispatch(customReadRequest("apikeys/permissions"));
};

export default ApiKeysCreatePage;
