import { Fragment, useState } from "react";
import moment from "moment";
import reduce from "lodash/reduce";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import IconFilter from "@mui/icons-material/FilterList";
import IconView from "@mui/icons-material/RemoveRedEye";
import { resourceListReadRequest, customReadRequest } from "store/actions";
import { fromEntities, fromResource, fromCustom } from "store/selectors";
import {
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Label,
} from "components";
import { handleEntityLink, handleNotesEntity } from "services/helpers";

const filters = ({ users, action }) => ({
  userId: {
    label: "User Name",
    type: "select",
    multiple: false,
    options: users.map((m) => m.id),
    mappings: reduce(
      users.map((m) => ({ [m.id]: m.userName })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  action: {
    label: "Action",
    type: "select",
    options: action,
    multiple: false,
  },
  createdAt: {
    label: "Created At",
    type: "date",
    suffixes: {
      gte: " from",
      lte: " to",
    },
    range: true,
  },
});

const ChangelogListPage = () => {
  const { count, filtersValues, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "useractions"),
      filtersValues: fromCustom.get(state, "useractions/filters"),
      items: fromEntities.getDenormalizedList(
        state,
        "useractions",
        fromResource.getList(state, "useractions")
      ),
      loading: pending(state, "useractionsListRead"),
    }),
    shallowEqual
  );
  const [open, setOpen] = useState(false);

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleGetAction = (action) => {
    switch (action) {
      case "DELETE":
        return "error";

      case "CREATE":
        return "success";

      default:
        return "info";
    }
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Changelog"
        toolbar={
          <Fragment>
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </Fragment>
        }
        tableBefore={
          <Filter
            filters={filters(filtersValues)}
            open={open}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                #
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].id}</TableCell>}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "91%" }}>
              <TableSortLabel
                active={sort === "userId"}
                component={Link}
                direction={order}
                to={getSortLink("userId")}
              >
                User Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].user ? (
                <MuiLink
                  component={Link}
                  to={`/settings/users/${items[rowIndex].userId}/update`}
                  underline="hover"
                >
                  {items[rowIndex].user.userName}
                </MuiLink>
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "action"}
                component={Link}
                direction={order}
                to={getSortLink("action")}
              >
                Action
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Label color={handleGetAction(items[rowIndex].action)} fullWidth>
                {items[rowIndex].action}
              </Label>
            </TableCell>
          )}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "entityType"}
                component={Link}
                direction={order}
                to={getSortLink("entityType")}
              >
                Entity
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {handleEntityLink(items[rowIndex]) ? (
                <MuiLink
                  component={Link}
                  to={handleEntityLink(items[rowIndex])}
                  style={{ whiteSpace: "nowrap" }}
                  underline="hover"
                >
                  {handleNotesEntity(items[rowIndex])}
                </MuiLink>
              ) : (
                items[rowIndex].entityType
              )}
            </TableCell>
          )}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />

        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View details" placement="top">
                  <IconButton
                    to={`/changelog/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

ChangelogListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("useractions", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("useractions/filters")),
  ]);
};

export default ChangelogListPage;
