import * as React from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import isEqual from "lodash/isEqual";
import uniqBy from "lodash/uniqBy";
import { useDispatch, useSelector } from "react-redux";
import { pending } from "redux-saga-thunk";
import { useLocalStorage } from "@rehooks/local-storage";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import Autocomplete, {
  createFilterOptions,
  autocompleteClasses,
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import OutlinedInput from "@mui/material/OutlinedInput";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import SendIcon from "@mui/icons-material/Send";
import TagIcon from "@mui/icons-material/Tag";
import {
  customFileUploadRequest,
  notificationSend,
  resourceCreateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";

const validationSchema = Yup.object().shape({
  id: Yup.number().nullable(),
  note: Yup.string().required().min(3),
  entityId: Yup.number().required(),
  entityType: Yup.string().required(),
  tags: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().notRequired(),
      tag: Yup.string().required(),
    })
  ),
  attachments: Yup.array(),
});

const filter = createFilterOptions({
  ignoreCase: true,
  stringify: (option) => option.tag,
});

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      borderTop: `1px solid  ${theme.palette.divider}`,
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

function FileChip({ disabled, file, onDelete, ...rest }) {
  const loading = !file.id && !file.error;

  const existingProps = React.useMemo(() => {
    if (!file.id) {
      return {
        label: file.name,
        onDelete: !file.uploading
          ? (event) => onDelete(event, file)
          : undefined,
      };
    } else
      return {
        label: file.url.split("/").pop(),
        component: "a",
        href: file.url,
        target: "_blank",
        clickable: true,
        onDelete: (event) => onDelete(event, file),
        deleteIcon: <DeleteIcon />,
        disabled,
      };
  }, [file, disabled, onDelete]);

  return (
    <div {...rest}>
      <Chip
        icon={
          file.error ? (
            <ErrorIcon color="error" />
          ) : loading ? (
            <CircularProgress size={18} />
          ) : (
            <AttachmentIcon />
          )
        }
        {...existingProps}
      />
    </div>
  );
}

const NotesForm = (props) => {
  const { id, entityId, entityType, storageKey, onDone } = props;
  const [, setCached, deleteCached] = useLocalStorage(storageKey);
  const dispatch = useDispatch();
  const fileInput = React.useRef();
  const [tagMenuanchorEl, setTagMenuanchorEl] = React.useState(null);
  const tagMenuOpen = Boolean(tagMenuanchorEl);

  const { defaultValues, tagsList, tagsLoading, uploading } = useSelector(
    (state = {}) => ({
      tagsList: fromResource.getList(state, "notes/tags") || [],
      tagsLoading: pending(state, "notes/tagsListRead"),
      uploading: pending(state, "files/uploadFileUpload"),
      defaultValues: {
        attachments: [],
        entityId,
        entityType,
        id: undefined,
        note: "",
        tags: [],
        ...(fromEntities.getDetail(state, "notes", id) || {}),
      },
    }),
    isEqual
  );

  const {
    control,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    mode: "all",
    reValidateMode: "all",
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });

  const tagsField = useFieldArray({
    control,
    name: "tags",
    keyName: "key",
  });

  const attachmentsField = useFieldArray({
    control,
    name: "attachments",
    keyName: "key",
  });

  React.useEffect(() => {});

  React.useEffect(() => {
    const cached = JSON.parse(localStorage.getItem(storageKey));

    if (cached) {
      for (const [key, value] of Object.entries(cached)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
    }
  }, [storageKey, setValue]);

  React.useEffect(() => {
    const subscription = watch((values, { name }) => {
      if (name === "attachments") {
        if (id) {
          const { id, attachments = [] } = values;
          const vals = {
            ...values,
            attachments: attachments
              .filter(({ id }) => !!id)
              .map(({ id }) => id),
          };
          dispatch(resourceUpdateRequest("notes", id, vals));
        }
      }
      setCached(values);
    });

    return () => subscription.unsubscribe();
  }, [setCached, watch, id, dispatch]);

  React.useEffect(() => {
    if (!isDirty) deleteCached();
  }, [isDirty, deleteCached]);

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const { id, attachments = [] } = values;
        const vals = {
          ...values,
          attachments: attachments.filter(({ id }) => !!id).map(({ id }) => id),
        };

        if (id) {
          await dispatch(resourceUpdateRequest("notes", id, vals));
        } else {
          await dispatch(resourceCreateRequest("notes", vals));
        }

        reset({
          id: null,
          entityId,
          entityType,
          note: "",
          tags: [],
          attachments: [],
        });
        deleteCached();
      } catch (error) {
        dispatch(
          notificationSend("Failed to save note!", {
            variant: "error",
          })
        );
      }

      if (typeof onDone === "function") onDone();
    },
    [dispatch, entityId, entityType, onDone, reset, deleteCached]
  );

  const handleTagAutoCompleteClick = React.useCallback(
    (event) => {
      setTagMenuanchorEl(event.currentTarget);
      if (tagsList.length > 1) return;
      dispatch(resourceListReadRequest("notes/tags"));
    },
    [dispatch, tagsList.length]
  );

  const handleTagAutoCompleteClose = React.useCallback(() => {
    if (tagMenuanchorEl) {
      tagMenuanchorEl.focus();
    }
    setTagMenuanchorEl(null);
  }, [tagMenuanchorEl]);

  const handleCancelEdit = React.useCallback(
    (event) => {
      event.preventDefault();
      deleteCached();

      if (typeof onDone === "function") onDone();
    },
    [onDone, deleteCached]
  );

  const handleFiles = React.useCallback(
    (event) => {
      event.preventDefault();
      // setUploading(true);
      const fileList = Array.from(fileInput.current.files);

      fileList
        .reduce((previousPromise, file, i) => {
          const index = attachmentsField.fields.length + i;
          attachmentsField.append({
            name: file.name,
            key: uuidv4(),
            uploading: true,
          });
          return previousPromise.finally(() => {
            return new Promise((resolve) => {
              dispatch(customFileUploadRequest("files/upload", file))
                .then((result) => {
                  attachmentsField.update(index, result);
                })
                .catch(() => {
                  attachmentsField.update(index, {
                    name: file.name,
                    error: true,
                    uploading: false,
                  });
                })
                .finally(() => resolve());
            });
          });
        }, Promise.resolve())
        .finally(() => {
          // setUploading(false);
          fileInput.current.value = "";
        });
    },
    [dispatch, attachmentsField]
  );

  const handleDeleteAttachment = React.useCallback(
    (event, file) => {
      event.preventDefault();

      const index = attachmentsField.fields.findIndex(({ key, id }) =>
        id ? id === file.id : key === file.key
      );

      attachmentsField.remove(index);
      if (file.id)
        dispatch(resourceDeleteRequest("files", file.id)).catch(() => {
          attachmentsField.append(file);
          dispatch(
            notificationSend("Failed to delete attachment!", {
              variant: "error",
            })
          );
        });
    },
    [dispatch, attachmentsField]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      {id && (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.06)",
            border: "1px solid rgba(0,0,0,.12)",
            borderBottom: 0,
            borderRadius: "4px 4px 0 0",
            padding: "6px 16px",
            margin: "8px 0 -18px 0",
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Typography
                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {defaultValues.note}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                color="primary"
                edge="end"
                disabled={isSubmitting}
                onClick={handleCancelEdit}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )}

      <Controller
        name="note"
        control={control}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <TextField
            // error={Boolean(error)}
            // helperText={error && error.message}
            fullWidth
            inputRef={ref}
            margin="normal"
            multiline
            placeholder="Enter your note here"
            required
            type="text"
            variant="outlined"
            disabled={isSubmitting}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ alignSelf: "flex-end", paddingBottom: 10 }}
                >
                  {isSubmitting && <CircularProgress size={24} />}
                  {!isSubmitting && (
                    <React.Fragment>
                      <Tooltip
                        enterDelay={1000}
                        title="Add tags"
                        placement="top"
                      >
                        <IconButton
                          component="label"
                          onClick={handleTagAutoCompleteClick}
                          size="large"
                        >
                          <TagIcon />
                        </IconButton>
                      </Tooltip>
                      <div style={{ height: 0, width: 0, overflow: "hidden" }}>
                        <input
                          ref={fileInput}
                          id="file-button"
                          type="file"
                          multiple
                          onChange={handleFiles}
                        />
                      </div>
                      <Tooltip
                        enterDelay={1000}
                        title="Attach files"
                        placement="top"
                      >
                        <IconButton
                          component="label"
                          htmlFor="file-button"
                          size="large"
                          disabled={uploading}
                        >
                          <AttachFileIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton
                        color="primary"
                        edge="end"
                        size="large"
                        type="submit"
                        disabled={
                          uploading || isSubmitting || !isDirty || !isValid
                        }
                      >
                        <SendIcon />
                      </IconButton>
                    </React.Fragment>
                  )}
                </InputAdornment>
              ),
              ...field,
            }}
          />
        )}
      />
      <React.Fragment>
        <Grid container spacing={1} direction="row">
          {tagsField.fields.map((item, index) => (
            <Grid item key={item.tag}>
              <Chip
                label={item.tag}
                sx={{ borderRadius: 1 }}
                onDelete={() => tagsField.remove(index)}
                disabled={isSubmitting}
              />
            </Grid>
          ))}
        </Grid>
        <Popper
          open={tagMenuOpen}
          anchorEl={tagMenuanchorEl}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={handleTagAutoCompleteClose}>
            <Paper elevation={4} sx={{ width: 320 }}>
              <Autocomplete
                open={tagMenuOpen}
                multiple
                onClose={(event, reason) => {
                  if (reason === "escape") {
                    handleTagAutoCompleteClose();
                  }
                }}
                onChange={(event, value) => tagsField.replace(value)}
                defaultValue={tagsField.fields}
                value={tagsField.fields}
                disableCloseOnSelect
                PopperComponent={PopperComponent}
                name="tags"
                loading={tagsLoading}
                loadingText={"Loading..."}
                options={uniqBy([...tagsList, ...tagsField.fields], "tag")}
                isOptionEqualToValue={(option, value) =>
                  option.tag === value.tag
                }
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.title) {
                    return option.title;
                  }
                  return option.tag;
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params).map(
                    ({ title, ...rest }) => rest
                  );

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option) =>
                      inputValue.toLowerCase() === option.tag.toLowerCase()
                  );

                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      tag: inputValue,
                      title: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                renderInput={(params) => (
                  <Box sx={{ p: 1 }} ref={params.InputProps.ref}>
                    <OutlinedInput
                      fullWidth
                      size="small"
                      inputProps={params.inputProps}
                      autoFocus
                      placeholder="Search Tags"
                    />
                  </Box>
                )}
                freeSolo
                handleHomeEndKeys
                autoHighlight
              />
              {tagsLoading && <LinearProgress />}
            </Paper>
          </ClickAwayListener>
        </Popper>
      </React.Fragment>
      <Stack spacing={1} sx={{ py: 1 }}>
        {attachmentsField.fields.map((file) => (
          <FileChip
            key={file.key || file.id || file.url}
            file={file}
            disabled={isSubmitting}
            onDelete={handleDeleteAttachment}
          />
        ))}
      </Stack>
    </form>
  );
};

export default NotesForm;
