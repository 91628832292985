import * as React from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { IMaskInput } from "react-imask";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { notificationSend, resourceCreateRequest } from "store/actions";

const ipRegex = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/g;

const IpMask = React.forwardRef(function (props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={"IP{.}`IP{.}`IP{.}`IP"}
      blocks={{
        IP: {
          mask: Number,
          scale: 0,
          min: 0,
          max: 255,
        },
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

const IpFilterForm = ({ defaultValues = {}, onCancel, onAdd, ipList = [] }) => {
  const dispatch = useDispatch();

  const resolver = (data) => {
    const { ip, description } = data;
    const values = { ip, description };
    if (values.description === "") delete values.description;
    const errors = {};
    if (ipList.includes(ip)) {
      errors.ip = { message: "Should be unique" };
    } else {
      if (!new RegExp(ipRegex).test(ip)) {
        errors.ip = { message: "Incorrect Value" };
      }
    }
    return { values, errors };
  };

  const {
    control,
    formState: { isValid, errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
    mode: "all",
    reValidateMode: "all",
    resolver: resolver,
  });

  const onSubmit = React.useCallback(
    async function (values) {
      try {
        await dispatch(resourceCreateRequest("users/me/ipwhitelist", values));
        reset();
        if (onAdd) onAdd();
        dispatch(
          notificationSend("IP Added to Whitelist", {
            variant: "success",
          })
        );
      } catch (error) {
        dispatch(
          notificationSend("Failed to Add IP Address", {
            variant: "error",
          })
        );
      }
    },
    [dispatch, onAdd, reset]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="ip"
        control={control}
        render={({ field: { ref, ...field }, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            inputRef={ref}
            label="IP"
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            required
            InputProps={{
              inputComponent: IpMask,
            }}
            helperText={errors.ip && `${errors.ip.message}`}
          />
        )}
      />
      <Button type="submit" disabled={!isValid}>
        Add
      </Button>
      <Button
        onClick={() => {
          if (onCancel) onCancel();
        }}
      >
        Cancel
      </Button>
    </form>
  );
};

export default IpFilterForm;
