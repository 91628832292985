import { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { Checkboxes, TextField } from "mui-rff";
import { handleError } from "services/helpers";
import { fromCustom } from "store/selectors";
import { notificationSend, customUpdateRequest } from "store/actions";
import { DiffBlock, Confirmation } from "components";

const txFeePriority = ["low", "medium", "high"];

const FulfillmentSettingsForm = () => {
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    return dispatch(customUpdateRequest("config/fulfillment", data))
      .then(() => {
        dispatch(
          notificationSend("Fulfillment configuration updated successfully", {
            variant: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to fulfillment configuration", {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  const { initialValues = {} } = useSelector(
    (state = {}) => ({
      initialValues: fromCustom.get(state, "config/fulfillment"),
    }),
    shallowEqual
  );

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={{ ...initialValues, blockchainintelApiKey: "" }}
            onSubmit={onSubmit}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6} xl={4}>
                      {/* <TextField name="minOrderCryptoCurrencyAmount" label="Minimum Order Amount, BTC" type="number" variant="outlined" margin="normal" fullWidth /> */}
                      <TextField
                        name="sellOrderExpirationTime"
                        label="Sell Order Expiration Time, minutes"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                      <TextField
                        name="maxTxFeePercent"
                        label="Max Transaction Fee, %"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                      <TextField
                        name="txFeePriority"
                        label="Transaction Fee Priority"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                      >
                        {txFeePriority.map((priority) => (
                          <MenuItem key={priority} value={priority}>
                            {priority}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        name="pendingOrdersScanInterval"
                        label="Orders Scan Interval, seconds"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                      <Checkboxes
                        name="orderQueueEnabled"
                        data={{
                          label: "Order Queue Enabled",
                          value: "orderQueueEnabled",
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock
                      {...{
                        initialValues: {
                          ...initialValues,
                          blockchainintelApiKey: "",
                        },
                        values,
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

export default FulfillmentSettingsForm;
