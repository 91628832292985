import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/HelpOutline";

const Root = styled("div", {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})(({ fullWidth }) => ({
  display: "inline-flex",
  flexDirection: "column",
  padding: "14px 0",

  ...(fullWidth && {
    width: "100%",
  }),
}));

const StyledIcon = styled(InfoIcon)({
  cursor: "help",
  fontSize: "16px",
  marginBottom: 3,
  verticalAlign: "middle",
});

const ReadonlyTextField = (props) => {
  const { label, value, fullWidth, tooltip } = props;

  return (
    <Root fullWidth={fullWidth}>
      {tooltip ? (
        <Tooltip title={tooltip} placement="right" arrow>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ alignSelf: "flex-start" }}
          >
            {label} <StyledIcon />
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ alignSelf: "flex-start" }}
        >
          {label}
        </Typography>
      )}
      <Typography component="div">{value}</Typography>
    </Root>
  );
};

ReadonlyTextField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  fullWidth: PropTypes.bool,
  tooltip: PropTypes.string,
};

ReadonlyTextField.defaultProps = {
  fullWidth: false,
  value: <i>[missing]</i>,
  tooltip: "",
};

export default ReadonlyTextField;
