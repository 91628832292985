import { useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link, NavLink } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconClearAll from "@mui/icons-material/ClearAll";
import IconView from "@mui/icons-material/RemoveRedEye";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  resourceListReadRequest,
  resourceUpdateRequest,
  resourceListReadSuccess,
} from "store/actions";
import { fromAuth, fromEntities, fromResource } from "store/selectors";
import { handleEntityLink } from "services/helpers";
import { TableActions, TableToolbar, TablePagination, Label } from "components";
import api from "api";

const NotificationsListPage = () => {
  const [loading, setLoading] = useState(false);
  const { count, items, listLoading, user } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "notifications"),
      items: fromEntities.getList(
        state,
        "notifications",
        fromResource.getList(state, "notifications")
      ),
      listLoading: pending(state, "notificationsListRead"),
      user: fromAuth.getUser(state),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const notificationRead = (id) =>
    dispatch(resourceUpdateRequest("notifications", id, { isNew: false }));

  const handleClickUserNotification = (notification) => () => {
    const { id, isNew } = notification;

    if (isNew) notificationRead(id);
  };

  const readAllNotification = () => {
    const notifications = items.map((notification) => ({
      ...notification,
      isNew: false,
    }));
    return dispatch(
      resourceListReadSuccess(
        "notifications",
        notifications,
        {},
        "notificationsListRead",
        count
      )
    );
  };

  const handleReadAllNotifications = () => {
    setLoading(true);
    api
      .post("/notifications/markallasread", {})
      .then(() => {
        readAllNotification();
        setLoading(false);
      })
      .catch(() => {});
  };

  const disabled = items.filter(({ isNew }) => isNew).length === 0;

  return (
    <Paper elevation={0}>
      <TableToolbar
        title="Notifications"
        numSelected={0}
        content={
          <>
            <Button
              startIcon={<SettingsIcon />}
              component={NavLink}
              to={`/settings/users/${user.id}/update#notifications`}
            >
              Configure
            </Button>
            <Button
              startIcon={<IconClearAll />}
              onClick={handleReadAllNotifications}
              disabled={disabled}
            >
              Mark all as read
            </Button>
          </>
        }
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "1%" }}>Level</TableCell>
            <TableCell>Message</TableCell>
            <TableCell sx={{ width: "120px" }}>Created At</TableCell>
            <TableCell sx={{ width: "1%" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={
            (loading || listLoading) && {
              opacity: 0.54,
              pointerEvents: "none",
            }
          }
        >
          {items.map((row) => (
            <TableRow
              key={row.id}
              sx={
                row.isNew &&
                ((theme) => ({
                  "& > td": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? theme.palette.primary.light
                        : theme.palette.primary.dark,
                  },
                }))
              }
            >
              <TableCell sx={{ py: 0 }}>
                <Label
                  color={
                    clsx({
                      error: row.level === "error",
                      warning: row.level === "warn",
                      info: row.level === "info",
                      success: row.level === "success",
                    }) || "default"
                  }
                  fullWidth
                >
                  {row.level}
                </Label>
              </TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell sx={{ py: 0, wordWrap: "nowrap" }}>
                {moment(row.createdAt).format("L \nLTS")}
              </TableCell>
              <TableCell padding="none">
                <TableActions>
                  <Tooltip
                    enterDelay={500}
                    title="View details"
                    placement="top"
                  >
                    <IconButton
                      to={handleEntityLink(row)}
                      component={Link}
                      onClick={handleClickUserNotification(row)}
                      size="large"
                    >
                      <IconView />
                    </IconButton>
                  </Tooltip>
                </TableActions>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

NotificationsListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("notifications", { _limit: 100, ...query })
  );
};

export default NotificationsListPage;
