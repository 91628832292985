import { Fragment, useState } from "react";
import moment from "moment";
import reduce from "lodash/reduce";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import IconAdd from "@mui/icons-material/Add";
import IconFilter from "@mui/icons-material/FilterList";
import IconView from "@mui/icons-material/RemoveRedEye";
import { resourceListReadRequest, customReadRequest } from "store/actions";
import { fromResource, fromCustom, fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import { Filter, Table, TableColumn, TablePagination, Label } from "components";

const filters = ({ machines, category, softwareVersions }) => ({
  atmId: {
    label: "ATM",
    type: "select",
    multiple: false,
    options: machines.map((m) => m.id),
    mappings: reduce(
      machines.map((m) => ({ [m.id]: m.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  category: {
    label: "Category",
    type: "select",
    options: category,
    multiple: false,
  },
  softwareVersion: {
    label: "Software Version",
    type: "select",
    options: softwareVersions,
    multiple: false,
  },
  createdAt: {
    label: "Created At",
    type: "date",
    suffixes: {
      gte: " from",
      lte: " to",
    },
    range: true,
  },
  level: {
    label: "Level",
    type: "select",
    options: ["error", "warn", "info", "debug", "trace"],
    mappings: {
      error: "Error",
      warn: "Warn",
      info: "Info",
      debug: "Debug",
      trace: "Trace",
    },
    multiple: false,
  },
});

const MachineEventsListPage = () => {
  const [open, setOpen] = useState(false);

  const { count, items, loading, filtersValues } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "machineevents"),
      items: fromEntities.getDenormalizedList(
        state,
        "machineevents",
        fromResource.getList(state, "machineevents")
      ),
      filtersValues: fromCustom.get(state, "machineevents/filters"),
      loading: pending(state, "machineventsListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const handleGetAction = (level) => {
    switch (level.toLowerCase()) {
      case "info":
        return "info";
      case "warn":
      case "debug":
      case "trace":
        return "warning";
      case "error":
        return "error";
      default:
        return "info";
    }
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Machine events"
        toolbar={
          <Fragment>
            {hasPermission("machineevents", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/machineevents/create"
              >
                Report event
              </Button>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </Fragment>
        }
        tableBefore={
          <Filter
            filters={filters(filtersValues)}
            open={open}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Id</TableCell>}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].id}</TableCell>}
        />

        <TableColumn
          header={() => <TableCell>Message</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].message || <i>[missing]</i>}</TableCell>
          )}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell>
              <TableSortLabel
                active={sort === "category"}
                component={Link}
                direction={order}
                to={getSortLink("category")}
              >
                Category
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].category.toUpperCase()}</TableCell>
          )}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "level"}
                component={Link}
                direction={order}
                to={getSortLink("level")}
              >
                Level
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Label
                variant="outlined"
                color={handleGetAction(items[rowIndex].level)}
              >
                {items[rowIndex].level}
              </Label>
            </TableCell>
          )}
        />

        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>ATM</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell>
              <MuiLink
                component={Link}
                to={`/machines/${items[rowIndex].atmId}`}
                style={{ whiteSpace: "nowrap" }}
                underline="hover"
              >
                {items[rowIndex].machine.name}
              </MuiLink>
            </TableCell>
          )}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "softwareVersion"}
                component={Link}
                direction={order}
                to={getSortLink("softwareVersion")}
              >
                Software Vesion
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].softwareVersion || <i>[missing]</i>}
            </TableCell>
          )}
        />

        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: 115 }}>
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />

        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }} />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <Tooltip enterDelay={500} title="View details" placement="top">
                <IconButton
                  to={`/machineevents/${items[rowIndex].id}`}
                  component={Link}
                  size="large"
                >
                  <IconView />
                </IconButton>
              </Tooltip>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

MachineEventsListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("machineevents", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("machineevents/filters")),
  ]);
};

export default MachineEventsListPage;
