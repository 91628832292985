import { Fragment } from "react";
import moment from "moment";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconLocalAtm from "@mui/icons-material/LocalAtm";
import UpdateIcon from "@mui/icons-material/Update";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import {
  BlockchainLink,
  Label,
  ReadonlyTextField,
  ShortCard,
  NotesList,
} from "components";

const AutoPaymentsReadPage = () => {
  const params = useParams();
  const { detail, baseCurrency } = useSelector((state = {}) => ({
    baseCurrency: fromCustom.getBaseCurrency(state),
    detail: fromEntities.getDenormalizedDetail(
      state,
      "autopayments",
      params.id
    ),
  }));

  const {
    amount,
    partialAmount,
    createdAt,
    currency,
    id,
    orders,
    partialTransactions,
    paymentType,
    paidAt,
    recipient,
    status,
    txid,
    txFee,
    wallet,
    totalOrdersFiatAmount,
    totalOrdersCryptoCurrencyAmount,
    percent,
    shouldBePaidAt,
    queueId,
  } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        title={
          <Fragment>
            Payment
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={4}>
                <Typography variant="h6">General</Typography>
                <ReadonlyTextField
                  label="Payment Type"
                  value={paymentType}
                  fullWidth
                />
                {paymentType === "PERCENT" && (
                  <>
                    <ReadonlyTextField
                      label="Percent"
                      value={`${percent}%`}
                      fullWidth
                    />
                    <ReadonlyTextField
                      label="Estimated Amount"
                      value={`${parseFloat(
                        (
                          (totalOrdersCryptoCurrencyAmount / 100) *
                          percent
                        ).toFixed(8)
                      )}`}
                      fullWidth
                    />
                  </>
                )}
                <ReadonlyTextField
                  label="Status"
                  value={
                    <Label
                      color={
                        clsx({
                          error: status === "ERROR" || status === "NO FUNDS",
                          warning: status === "DELAYED",
                          info: status === "PAYING",
                          success: status === "PAID",
                        }) || "default"
                      }
                    >
                      {status}
                    </Label>
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={
                    createdAt ? (
                      moment(createdAt).format("L, LTS")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Paid Date"
                  value={
                    paidAt ? moment(paidAt).format("L, LTS") : <i>[missing]</i>
                  }
                  fullWidth
                />
                {shouldBePaidAt && (
                  <ReadonlyTextField
                    label="Paid For"
                    value={moment(shouldBePaidAt).format("L, LTS")}
                    fullWidth
                  />
                )}
                {queueId && (
                  <ReadonlyTextField
                    label="Queue Id"
                    value={
                      <MuiLink
                        component={Link}
                        to={`/orderqueue/${queueId}`}
                        underline="hover"
                      >
                        {queueId}
                      </MuiLink>
                    }
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} xl={8}>
                <Typography variant="h6" gutterBottom>
                  Transaction
                </Typography>
                {!partialAmount && (
                  <ReadonlyTextField
                    label="Transaction ID"
                    value={
                      (txid && (
                        <BlockchainLink
                          transaction={txid}
                          coinType={currency.replace(baseCurrency, "BTC")}
                        />
                      )) || <i>[missing]</i>
                    }
                    fullWidth
                  />
                )}
                <ReadonlyTextField
                  label="Tx Fee"
                  value={`${txFee} ${currency.replace(baseCurrency, "BTC")}`}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Wallet"
                  value={
                    (wallet && (
                      <BlockchainLink
                        wallet={wallet}
                        coinType={currency.replace(baseCurrency, "BTC")}
                      />
                    )) || <i>[missing]</i>
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Currency"
                  value={currency}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Amount"
                  value={partialAmount ? `${partialAmount}/${amount}` : amount}
                  fullWidth
                />
              </Grid>
              {partialTransactions && partialTransactions.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Partial Transactions
                  </Typography>
                  <Table
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>amount</TableCell>
                        <TableCell>Transaction ID</TableCell>
                        <TableCell>Fee</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", width: 115 }}>
                          Created At
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {partialTransactions.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            {row.amount}
                            {row.queueId && row.status !== "PAID" && (
                              <IconButton
                                title={`Queue ${row.queueId}`}
                                color="warning"
                                component={Link}
                                to={`/orderqueue/${row.queueId}`}
                              >
                                <UpdateIcon />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell>
                            {row.txId ? (
                              <BlockchainLink
                                transaction={row.txId}
                                coinType={currency.replace(baseCurrency, "BTC")}
                              />
                            ) : (
                              <i>[missing]</i>
                            )}
                          </TableCell>
                          <TableCell>{row.txFee}</TableCell>
                          <TableCell>
                            <Label
                              color={
                                clsx({
                                  error:
                                    row.status === "ERROR" ||
                                    row.status === "NO FUNDS",
                                  warning: row.status === "DELAYED",
                                  info: row.status === "PAYING",
                                  success: row.status === "PAID",
                                }) || "default"
                              }
                            >
                              {row.status}
                            </Label>
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>
                            {row.createdAt ? (
                              moment(row.createdAt).format("L\n LTS")
                            ) : (
                              <i>[missing]</i>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}
              {orders && orders.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Affected orders
                  </Typography>
                  <Table
                    sx={{
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "30%" }}>Order Id</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Coin Amount</TableCell>
                        <TableCell>Fiat Amount</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", width: 115 }}>
                          Created At
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell variant="head" colSpan={2}>
                          Total
                        </TableCell>
                        <TableCell variant="head">{`${totalOrdersCryptoCurrencyAmount} ${currency}`}</TableCell>
                        <TableCell variant="head">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: baseCurrency,
                          }).format(totalOrdersFiatAmount)}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      {orders.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell>
                            <MuiLink
                              component={Link}
                              to={`/orders/${row.id}`}
                              underline="hover"
                            >
                              {row.orderId}
                            </MuiLink>
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>
                            <Label
                              variant="outlined"
                              color={row.type === "BUY" ? "success" : "error"}
                            >
                              {row.type}
                            </Label>
                          </TableCell>
                          <TableCell>{`${row.coinAmount} ${row.coinType}`}</TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: row.fiatType,
                            }).format(row.fiatAmount)}
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>
                            {row.createdAt ? (
                              moment(row.createdAt).format("L\n LTS")
                            ) : (
                              <i>[missing]</i>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="autopayments" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {recipient && hasPermission("autopaymentsrecipients", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconLocalAtm />}
                        to={`/autopayments/recipients/${recipient.id}/update`}
                        title="Recipient"
                        description="View information about the recipient of funds"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

AutoPaymentsReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("autopayments", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "autopayments",
        entityId: params.id,
      })
    ),
  ]);
};

export default AutoPaymentsReadPage;
