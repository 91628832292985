import { Fragment } from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IconWallets from "@mui/icons-material/AccountBalanceWallet";
import IconOrders from "@mui/icons-material/InsertDriveFile";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import IconPayments from "@mui/icons-material/Payment";
import { handleEntityLink } from "services/helpers";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { tips } from "services/helpers";
import { hasPermission } from "services/permission";
import {
  BlockchainLink,
  ReadonlyTextField,
  NotesList,
  ShortCard,
} from "components";

const IconLoansAvailable = () => (
  <SvgIcon>
    <path d="M3,6H21V18H3V6M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M7,8A2,2 0 0,1 5,10V14A2,2 0 0,1 7,16H17A2,2 0 0,1 19,14V10A2,2 0 0,1 17,8H7Z" />
  </SvgIcon>
);

const WalletTransactionsReadPage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "wallettransactions",
        params.id
      ),
    }),
    shallowEqual
  );

  const {
    amount,
    currency,
    entity,
    entityId,
    entityType,
    id,
    timestamp,
    txFee,
    txId,
    wallet,
  } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Wallet Transaction
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  General
                </Typography>
                <ReadonlyTextField
                  label="Id"
                  value={id}
                  fullWidth
                  tooltip={tips.transactions.id}
                />
                <ReadonlyTextField
                  label="Timestamp"
                  value={moment(timestamp).format("L \nLTS")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <ReadonlyTextField
                  label="Currency"
                  value={currency}
                  fullWidth
                  tooltip={tips.transactions.currency}
                />
                <ReadonlyTextField
                  label="Amount"
                  value={amount}
                  fullWidth
                  tooltip={tips.transactions.amount}
                />
                <ReadonlyTextField
                  label="Fee"
                  value={txFee}
                  fullWidth
                  tooltip={tips.transactions.fee}
                />
              </Grid>
              <Grid item xs={12}>
                <ReadonlyTextField
                  label="Transaction"
                  value={
                    currency ? (
                      <BlockchainLink transaction={txId} coinType={currency} />
                    ) : (
                      { txId }
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="wallettransactions" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("wallets", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconWallets />}
                        to={`/wallets/${wallet.id}`}
                        title="Wallet"
                        description="View wallet information for this transaction "
                      />
                    </Grid>
                  )}
                  {entity &&
                    typeof entity === "object" &&
                    hasPermission(entityType, "GET") && (
                      <Grid item xs={12} sm={6} xl={12}>
                        <ShortCard
                          icon={
                            (["orders", "orderQueue"].indexOf(entityType) !==
                              -1 && <IconOrders />) ||
                            (entityType === "loans" && (
                              <IconLoansAvailable />
                            )) ||
                            (entityType === "autopaymens" && <IconPayments />)
                          }
                          to={handleEntityLink({ entityType, entityId })}
                          title={
                            entityType === "orderQueue"
                              ? "Orders"
                              : `${entityType
                                  .charAt(0)
                                  .toUpperCase()}${entityType.slice(1, -1)}`
                          }
                          description={
                            entityType === "orderQueue"
                              ? "View all orders for this transaction"
                              : `View ${entityType.slice(
                                  0,
                                  -1
                                )} information for this transaction`
                          }
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

WalletTransactionsReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("wallettransactions", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "wallettransactions",
        entityId: params.id,
      })
    ),
  ]);
};

export default WalletTransactionsReadPage;
