import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import uniq from "lodash/uniq";
import debounce from "lodash/debounce";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconAdd from "@mui/icons-material/Add";
import IconDelete from "@mui/icons-material/Delete";
import {
  notificationSend,
  resourceListReadRequest,
  customUpdateRequest,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";

const MIN = 0;
const MAX_SLIDER = 10000;
const MAX = 35000;

function reducer(state, action) {
  const index = action.index;

  if (index < 0) {
    return state;
  }

  switch (action.type) {
    case "create": {
      const { from, kycProviders } = state[state.length - 1];
      const difference = (MAX_SLIDER - from) / 2;
      const limit = from + difference - (difference % 100);

      if (difference < 100) return state;

      return [
        ...state.slice(0, state.length - 1),
        { from, to: limit - 1, kycProviders },
        { from: limit, to: MAX, kycProviders: [] },
      ];
    }
    case "update":
      return [
        ...state.slice(0, index),
        { ...state[index], ...action.details },
        ...state.slice(index + 1),
      ];
    case "delete": {
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case "calculate": {
      const values = action.values || state.map(({ from }) => from);

      return state.map(({ kycProviders }, index) => ({
        from: index === 0 ? 0 : values[index],
        to: index === state.length - 1 ? MAX : values[index + 1] - 1,
        kycProviders,
      }));
    }
    case "reset":
      return action.payload;
    default:
      return state;
  }
}

function init(state = []) {
  if (state.length === 0) {
    return [
      {
        from: MIN,
        to: MAX,
        kycProviders: [],
      },
    ];
  }
  return state;
}

const KYCLimitsListPage = (props) => {
  const { items, kycproviders } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "config/kyclimits"),
      items: fromEntities.getDenormalizedList(
        state,
        "config/kyclimits",
        fromResource.getList(state, "config/kyclimits")
      ),
      kycproviders: fromEntities.getDenormalizedList(
        state,
        "kycproviders",
        fromResource.getList(state, "kycproviders")
      ),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const mounted = useRef(false);
  const [limits, dispatchLimits] = useReducer(reducer, items, init);
  const [value, setValue] = useState(limits.map(({ from }) => from).slice(1));
  const [marks, setMarks] = useState([]);

  const handleChange = (event, values) => {
    setValue(values);
  };

  const handleChangeCommitted = (event, values) => {
    if (uniq(value).length !== values.length || values[0] === 0) {
      setValue(limits.map(({ from }) => from).slice(1));
      return;
    }

    setValue(values);
    dispatchLimits({ type: "calculate", values: [0, ...values] });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const update = useCallback(
    debounce((limits) => {
      dispatch(customUpdateRequest("config/kyclimits", limits))
        .then(() => {
          dispatch(
            notificationSend("KYC limits changes are saved", {
              variant: "success",
            })
          );
        })
        .catch(() => {
          dispatch(
            notificationSend("Failed to save KYC limits changes", {
              variant: "error",
            })
          );
        });
    }, 1000),
    []
  );

  useEffect(() => {
    const values = limits.map(({ from }) => from).slice(1);

    let marks = [
      {
        label: 0,
        value: 0,
      },
    ];
    for (let i = 100; i < MAX_SLIDER; i += 100) {
      marks.push({
        label: values.includes(i) ? i : undefined,
        value: i,
      });
    }
    marks.push({
      label: `${MAX_SLIDER}+`,
      value: MAX_SLIDER,
    });

    setMarks(marks);
    setValue(values);

    if (mounted.current) {
      update(limits);
    } else mounted.current = true;
  }, [limits, update]);

  const handleCreateOption = () => {
    dispatchLimits({ type: "create" });
  };

  const handleUpdateOption = (index) => (event, kycProviders) => {
    dispatchLimits({ type: "update", index, details: { kycProviders } });
  };

  const handleDeleteLimit = (index) => () => {
    dispatchLimits({ type: "delete", index });
    dispatchLimits({ type: "calculate", index });
  };

  const handleReset = () => {
    dispatchLimits({ type: "reset", payload: items });
  };

  return (
    <Card elevation={0}>
      <CardHeader title="KYC Limits" />
      <Divider />
      <Box px={4} pb={2} pt={6}>
        <Slider
          track={false}
          step={100}
          marks={marks}
          min={MIN}
          max={10000}
          valueLabelDisplay="auto"
          value={value}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
        />
      </Box>
      <Box pt={2}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ width: "1%" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ textAlign: "center", whiteSpace: "nowrap" }}
                >
                  Limit options
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ whiteSpace: "nowrap" }}>
                  Required providers
                </Typography>
              </TableCell>
              <TableCell sx={{ width: 50 }}></TableCell>
            </TableRow>
            {limits.map(({ from, to, kycProviders }, index) => (
              <TableRow
                key={`${from} — ${to}`}
                hover
                sx={{
                  "& td, & th": { border: 0 },
                  "& .actions": { opacity: 0.26 },
                  "&:hover .actions": { opacity: 1 },
                }}
              >
                <TableCell sx={{ whiteSpace: "nowrap", textAlign: "center" }}>
                  {to === MAX ? `${from}+` : `${from} — ${to}`}
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Autocomplete
                    size="small"
                    filterSelectedOptions
                    multiple
                    options={kycproviders}
                    getOptionLabel={(option) => option.name}
                    value={kycProviders}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    onChange={handleUpdateOption(index)}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Add provider" />
                    )}
                  />
                </TableCell>
                <TableCell sx={{ pl: 0, py: 0 }}>
                  {limits.length > 1 && (
                    <div className="actions">
                      <IconButton
                        onClick={handleDeleteLimit(index)}
                        size="small"
                      >
                        <IconDelete />
                      </IconButton>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ "& td, & th": { border: 0 } }}>
              <TableCell colSpan={3}>
                {MAX_SLIDER - limits[limits.length - 1].from > 199 && (
                  <Button
                    onClick={handleCreateOption}
                    startIcon={<IconAdd />}
                    sx={{ mr: 1 }}
                  >
                    Add limit option
                  </Button>
                )}
                <Button onClick={handleReset}>Reset</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

KYCLimitsListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("config/kyclimits", { _limit: 100, ...query })
    ),
    store.dispatch(resourceListReadRequest("kycproviders")),
  ]);
};

export default KYCLimitsListPage;
