import { Fragment } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { useDispatch } from "react-redux";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconCloudUpload from "@mui/icons-material/CloudUpload";
import { handleError, trim } from "services/helpers";
import { TextField } from "mui-rff";
import {
  createValidator,
  required,
  email,
  url,
  minLength,
} from "services/validation";
import {
  notificationSend,
  customUpdateRequest,
  customFileUploadRequest,
} from "store/actions";
import { tips } from "services/helpers";
import { DiffBlock, Confirmation, PhoneField } from "components";

const StyledLogo = styled("img")({
  height: "auto",
  maxWidth: 320,
  width: "100%",
});

const StyledDropZone = styled("div")(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.action.hover,
  padding: "40px  16px ",
  color: theme.palette.text.secondary,
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

const validate = createValidator({
  name: [required],
  city: [required],
  street: [required],
  phone: [required],
  webSite: [required, url],
  supportEmail: [required, email],
  zapierApiKey: [minLength(8)],
});

const OrganizationForm = ({ hasPutPermission, initialValues }) => {
  const dispatch = useDispatch();

  const organizationLogoUpload = (file) =>
    dispatch(customFileUploadRequest("organization/logoupload", file))
      .then(() => {
        dispatch(
          notificationSend("Logo uploaded successfully", { variant: "success" })
        );
      })
      .catch(() => {
        dispatch(
          notificationSend("Failed to upload logo", { variant: "error" })
        );
      });
  const onSubmit = (values) => {
    return dispatch(customUpdateRequest("organization", values))
      .then(() => {
        dispatch(
          notificationSend("Information updated successfully", {
            variant: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to update information", { variant: "error" })
        );
        return handleError(error);
      });
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        {tips.settingsOrganization.organizationSettings}
                      </Typography>
                      <TextField
                        name="name"
                        label="Company name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="city"
                        label="City state"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="street"
                        label="Address"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <PhoneField name="phone" label="Phone" />
                      <TextField
                        name="webSite"
                        label="Web address"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim }}
                      />
                      <TextField
                        name="supportEmail"
                        label="Support email"
                        type="email"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim }}
                      />
                      <TextField
                        name="homeScreenMessage"
                        label="Atm home screen message"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        multiline
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="zapierApiKey"
                        label="Zapier API Key"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        multiline
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {initialValues.logo && (
                        <StyledLogo src={initialValues.logo} alt="logo" />
                      )}
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          organizationLogoUpload(acceptedFiles[0])
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <StyledDropZone {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Typography variant="body1" component="div">
                              <IconCloudUpload
                                sx={{
                                  marginRight: "16px",
                                  verticalAlign: "middle",
                                }}
                                fontSize="large"
                              />
                              <span>
                                Drag&apos;n&apos;drop logo here, or click to
                                select
                              </span>
                            </Typography>
                          </StyledDropZone>
                        )}
                      </Dropzone>
                    </Grid>
                  </Grid>
                </CardContent>
                {hasPutPermission && (
                  <CardActions>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={
                        pristine ||
                        submitting ||
                        (!valid && !dirtySinceLastSubmit)
                      }
                    >
                      Save
                    </Button>
                    <Button onClick={reset} disabled={pristine || submitting}>
                      Reset
                    </Button>
                  </CardActions>
                )}
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

OrganizationForm.propTypes = {
  initialValues: PropTypes.object,
};

OrganizationForm.defaultProps = {
  initialValues: {},
};

// const { count, items, loading } = useSelector((state, { id }) => ({
//   initialValues: fromEntities.getDetail(state, 'users', id),
// }), shallowEqual)

export default OrganizationForm;
