import store from "../../store";

export const hasPermission = (endpoint, type) => {
  const state = store.getState();
  if (
    state.auth &&
    state.auth.user &&
    state.auth.user.role &&
    state.auth.user.role.permissions &&
    state.auth.user.role.permissions[endpoint] &&
    state.auth.user.role.permissions[endpoint].includes(type)
  )
    return true;

  return false;
};
