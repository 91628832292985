import { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { pending } from "redux-saga-thunk";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconLabel from "@mui/icons-material/Label";
import {
  notificationSend,
  resourceDetailReadRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { handleError } from "services/helpers";
import { hasPermission } from "services/permission";
import { OrderForm } from "components";

const OrdersUpdatePage = () => {
  const params = useParams();
  const { detail, loading } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDetail(state, "orders", params.id),
      loading: pending(state, "ordersUpdate"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const updateOrder = (values) => {
    return dispatch(resourceUpdateRequest("orders", params.id, values))
      .then(() => {
        dispatch(
          notificationSend("Order updated successfully", { variant: "success" })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to update order", { variant: "error" })
        );
        return handleError(error);
      });
  };

  const handleChangeOrderStatus = (status) => () => {
    updateOrder({ status });
  };

  const { id, orderId, allowedStatuses = [] } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Edit Order
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${orderId}`}</Typography>
          </Fragment>
        }
        action={
          <>
            {hasPermission("orders", "PUT") &&
              allowedStatuses.map((status) => (
                <Button
                  key={status}
                  startIcon={<IconLabel />}
                  onClick={handleChangeOrderStatus(status)}
                  disabled={loading}
                >
                  Mark as {status}
                </Button>
              ))}
            <Button
              startIcon={<IconView />}
              component={Link}
              to={`/orders/${id}`}
            >
              Details
            </Button>
          </>
        }
      />
      <Divider />
      <OrderForm id={id} />
    </Card>
  );
};

OrdersUpdatePage.get = ({ store, params }) => {
  return store.dispatch(resourceDetailReadRequest("orders", params.id));
};

export default OrdersUpdatePage;
