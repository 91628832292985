import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import { TextField } from 'mui-rff'
import { PhoneField } from "components";
import { handleError } from "services/helpers";
import { notificationSend, resourceCreateRequest } from "store/actions";
import { createValidator, phone, required } from "services/validation";

const validate = createValidator({
  phone: [required, phone],
});

const PhoneForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    return dispatch(resourceCreateRequest("phoneblacklist", values))
      .then(() => {
        dispatch(
          notificationSend("Phone added successfully", { variant: "success" })
        );
        navigate("/phoneblacklist");
      })
      .catch((error) => {
        dispatch(notificationSend("Failed to add phone", { variant: "error" }));
        return handleError(error);
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({
        dirtySinceLastSubmit,
        form: { reset },
        handleSubmit,
        hasSubmitErrors,
        pristine,
        submitError,
        submitting,
        valid,
      }) => (
        <form onSubmit={handleSubmit}>
          <CardContent>
            {hasSubmitErrors && (
              <Typography color="error">{submitError}</Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <PhoneField name="phone" />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              type="submit"
              disabled={
                pristine || submitting || (!valid && !dirtySinceLastSubmit)
              }
            >
              Save
            </Button>
            <Button onClick={reset} disabled={pristine || submitting}>
              Reset
            </Button>
          </CardActions>
        </form>
      )}
    />
  );
};

export default PhoneForm;
