import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconDelete from "@mui/icons-material/Delete";
import { Label, SmartemptySettingsForm } from "components";
import {
  notificationSend,
  customReadRequest,
  customUpdateRequest,
} from "store/actions";
import { fromCustom } from "store/selectors";
import { hasPermission } from "services/permission";

function reducer(state, action) {
  const index = action.index;

  if (index < 0) {
    return state;
  }

  switch (action.type) {
    case "create": {
      return [
        ...state,
        {
          ...action.details,
          default: false,
        },
      ];
    }
    case "delete": {
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }
    case "default": {
      return state.map((s, i) => ({
        ...s,
        default: i === index,
      }));
    }
    default:
      return state;
  }
}

const SettingsSmartEmpties = () => {
  const { statusList } = useSelector(
    (state = {}) =>
      fromCustom.get(state, "config/smartempties", { statusList: [] }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [statuses, dispatchStatuses] = React.useReducer(reducer, statusList);
  const prevStatusesRef = React.useRef(statuses);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const update = React.useCallback(
    (statusList) => {
      dispatch(customUpdateRequest("config/smartempties", { statusList }))
        .then(() => {
          dispatch(
            notificationSend("Smartempty statuses are saved", {
              variant: "success",
            })
          );
        })
        .catch(() => {
          dispatch(
            notificationSend("Failed to save smartempty statuses", {
              variant: "error",
            })
          );
        });
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (JSON.stringify(prevStatusesRef.current) !== JSON.stringify(statuses)) {
      update(statuses);
      prevStatusesRef.current = statuses;
    }
  }, [statuses, update]);

  const handleCreate = (details) => {
    dispatchStatuses({ type: "create", details });
  };

  const handleDelete = (index) => () => {
    dispatchStatuses({ type: "delete", index });
  };

  const handleSetDefault = (event) => {
    dispatchStatuses({ type: "default", index: parseInt(event.target.value) });
  };

  return (
    <Card elevation={0}>
      <CardHeader title="Smart Empty Statuses" />
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">Status</Typography>
              </TableCell>
              <TableCell sx={{ width: "1%" }}>
                <Typography variant="subtitle2">Default</Typography>
              </TableCell>
              <TableCell sx={{ width: "1%" }}>
                <Typography variant="subtitle2">Level</Typography>
              </TableCell>
              <TableCell sx={{ width: 50 }}></TableCell>
            </TableRow>
            {statuses.map(({ name, level, default: isDefault }, index) => (
              <TableRow key={name} hover>
                <TableCell>{name}</TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Radio
                    checked={isDefault}
                    onChange={handleSetDefault}
                    value={index}
                    name="statuses"
                  />
                </TableCell>
                <TableCell sx={{ py: 0 }}>
                  <Label color={level}>{level}</Label>
                </TableCell>
                <TableCell sx={{ pl: 0, py: 0, width: 50 }}>
                  {statuses.length > 1 && (
                    <div className="actions">
                      {hasPermission("config", "PUT") && (
                        <Tooltip
                          enterDelay={500}
                          title="Delete"
                          placement="top"
                        >
                          <IconButton
                            onClick={handleDelete(index)}
                            size="small"
                            component={isDefault ? "div" : undefined}
                            disabled={isDefault}
                          >
                            <IconDelete />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ "& td, & th": { border: 0 } }}>
              <TableCell colSpan={4}>
                <SmartemptySettingsForm onSubmit={handleCreate} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

SettingsSmartEmpties.get = ({ store }) => {
  return store.dispatch(customReadRequest("config/smartempties"));
};

export default SettingsSmartEmpties;
