import { Fragment } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from '@mui/material/DialogContent'
import DialogTitle from "@mui/material/DialogTitle";
import IconEdit from "@mui/icons-material/Edit";
import IconAdd from "@mui/icons-material/Add";
import IconDelete from "@mui/icons-material/Delete";
import { hasPermission } from "services/permission";
import {
  notificationSend,
  resourceListReadRequest,
  resourceDeleteRequest,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import { tips } from "services/helpers";
import {
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Confirmation,
  Label,
} from "components";

const ExchangesListPage = () => {
  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "exchanges"),
      items: fromEntities.getList(
        state,
        "exchanges",
        fromResource.getList(state, "exchanges")
      ),
      loading: pending(state, "exchangesListRead"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const deleteItem = (id) =>
    dispatch(resourceDeleteRequest("exchanges", id))
      .then(() => {
        dispatch(
          notificationSend("Exchange deleted successfully", {
            variant: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          notificationSend("Failed to delete exchange", { variant: "error" })
        );
      });

  const handleClickExchangeDelete = (id) => () => {
    deleteItem(id);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Exchanges"
        toolbar={
          <div>
            {hasPermission("exchanges", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/settings/exchanges/create"
              >
                Add exchange
              </Button>
            )}
          </div>
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "10%" }}>
              <Tooltip title={tips.settingsExchanges.name} arrow>
                <TableSortLabel
                  active={sort === "name"}
                  component={Link}
                  direction={order}
                  to={getSortLink("name")}
                >
                  Name
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].name}</TableCell>}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "77%" }}>
              API Key
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].apiKey}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "10%" }}>
              <Tooltip title={tips.settingsExchanges.pair} arrow>
                <TableSortLabel
                  active={sort === "pair"}
                  component={Link}
                  direction={order}
                  to={getSortLink("pair")}
                >
                  Pair
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].pair}</TableCell>}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "active"}
                component={Link}
                direction={order}
                to={getSortLink("active")}
              >
                Active
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].active ? (
                <Label color="success">Yes</Label>
              ) : (
                <Label color="error">No</Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.settingsExchanges.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                {hasPermission("exchanges", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/settings/exchanges/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
                {hasPermission("exchanges", "DELETE") && (
                  <Confirmation>
                    {(show, confirm, hide, open) => (
                      <Fragment>
                        <Tooltip
                          enterDelay={500}
                          title="Delete"
                          placement="top"
                        >
                          <IconButton
                            onClick={show(
                              handleClickExchangeDelete(items[rowIndex].id)
                            )}
                            size="large"
                          >
                            <IconDelete />
                          </IconButton>
                        </Tooltip>
                        <Dialog open={open}>
                          <DialogTitle>{`Are you sure you want to delete exchange #${items[rowIndex].id}?`}</DialogTitle>
                          {/* <DialogContent>
                            </DialogContent> */}
                          <DialogActions>
                            <Button onClick={hide}>Cancel</Button>
                            <Button onClick={confirm} color="primary" autoFocus>
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Fragment>
                    )}
                  </Confirmation>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

ExchangesListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("exchanges", { _limit: 100, ...query })
  );
};

export default ExchangesListPage;
