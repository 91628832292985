import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { confirmable, createConfirmation } from "react-confirm";

const Confirmation = ({
  okLabel = "Proceed",
  cancelLabel = "Cancel",
  confirmation,
  show,
  proceed,
}) => {
  return (
    <Dialog open={show} fullWidth maxWidth="xs" onClose={() => proceed(false)}>
      <DialogTitle>{confirmation}</DialogTitle>
      <DialogActions>
        <Button onClick={() => proceed(false)}>{cancelLabel}</Button>
        <Button onClick={() => proceed(true)} color="primary" autoFocus>
          {okLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirmation.propTypes = {
  okLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
};

export default function confirm(
  confirmation,
  okLabel = "Proceed",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    okLabel,
    cancelLabel,
    ...options,
  });
}
