import { useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { ArchiveButtons, CampaignsForm } from "components";

const CampaignsUpdatePage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDetail(state, "campaigns", params.id),
    }),
    shallowEqual
  );

  const { code, id, archived } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        title={code}
        action={
          <ArchiveButtons resource="campaigns" id={id} archived={archived} />
        }
      />
      <Divider />
      <CampaignsForm id={id} />
    </Card>
  );
};

CampaignsUpdatePage.get = ({ store, params }) => {
  return store.dispatch(resourceDetailReadRequest("campaigns", params.id));
};

export default CampaignsUpdatePage;
