/* eslint camelcase: ["error", {allow: ["date_gte", "date_lte"]}] */
import { useState, Fragment } from "react";
import { parse, stringify } from "qs";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconNavigateBefore from "@mui/icons-material/NavigateBefore";
import IconNavigateNext from "@mui/icons-material/NavigateNext";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { customReadRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import { ReadonlyTextField } from "components";

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AutoPaymentRow = (props) => {
  const { item, baseCurrency } = props;
  const [open, setOpen] = useState(false);
  const {
    id,
    name,
    amount,
    fiatAmount,
    txFee,
    txFeeCost,
    totalCost,
    count,
    autoPayments,
  } = item;

  return (
    <Fragment>
      <TableRow key={id}>
        <TableCell>
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{count}</TableCell>
        <TableCell>{`${amount} BTC`}</TableCell>
        <TableCell>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: baseCurrency,
          }).format(fiatAmount)}
        </TableCell>
        <TableCell>{`${txFee} BTC`}</TableCell>
        <TableCell>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: baseCurrency,
          }).format(txFeeCost)}
        </TableCell>
        <TableCell>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: baseCurrency,
          }).format(totalCost)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer sx={{ background: "#f0f0f0" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Tx Fee</TableCell>
                    <TableCell>Tx Fee Cost</TableCell>
                    <TableCell>Total Cost</TableCell>
                    <TableCell>Paid At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {autoPayments.map(
                    ({
                      id,
                      amount,
                      currencyPrice,
                      txFee,
                      txFeeCost,
                      totalCost,
                      paidAt,
                    }) => (
                      <TableRow key={id}>
                        <TableCell>{id}</TableCell>
                        <TableCell>{`${amount} BTC`}</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: baseCurrency,
                          }).format(currencyPrice)}
                        </TableCell>
                        <TableCell>{`${txFee} BTC`}</TableCell>
                        <TableCell>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: baseCurrency,
                          }).format(txFeeCost)}
                        </TableCell>
                        <TableCell>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: baseCurrency,
                          }).format(totalCost)}
                        </TableCell>
                        <TableCell>
                          {new Date(paidAt).toLocaleString("en-US")}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

const ReportRevenuePage = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryDate = searchParams.get("date");
  const query = parse(location.search.substr(1));
  const range = query.range || "month";
  const [machinesExpanded, setMachinesExpanded] = useState(false);
  const [autoPaymenstExpanded, setAutopaymentsExpanded] = useState(false);

  const { monthlyreport, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      monthlyreport: fromCustom.get(state, `revenuereport/${range}`),
    }),
    shallowEqual
  );

  const handleDateChange = (value) => {
    searchParams.set("date", moment(value).format("L").replace(/\//g, "-"));
    setSearchParams(searchParams);
  };

  const handleToggleMode = () => {
    searchParams.set(
      "range",
      !query.range || query.range === "month" ? "year" : "month"
    );
    setSearchParams(searchParams);
  };

  let date = new Date(queryDate ? queryDate : Date.now());
  date.setDate(1);
  let prevDate;
  let currentDate;
  let nextDate;

  if (range === "year") {
    date.setMonth(0);
    prevDate = new Date(date.setFullYear(date.getFullYear() - 1));
    currentDate = new Date(date.setFullYear(date.getFullYear() + 1));
    nextDate = new Date(date.setFullYear(date.getFullYear() + 1));
  } else {
    prevDate = new Date(date.setMonth(date.getMonth() - 1));
    currentDate = new Date(date.setMonth(date.getMonth() + 1));
    nextDate = new Date(date.setMonth(date.getMonth() + 1));
  }

  const today = new Date();
  today.setDate(1);
  const disabledNext = date >= today;

  const { orders, machines, performance, expenses, autoPayments } =
    monthlyreport;
  machines.sort((a, b) => b.performance.revenue - a.performance.revenue);
  const { costOfGoods, fees, grossRevenue, loansCost, netRevenue } =
    performance;
  const { total, buy, sell, averagePerCustomer, averageAmount } = orders;
  const { recipients, totalFiatAmount } = autoPayments;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "-8px -12px -8px 0",
          "@media print": {
            display: "none",
          },
          "& > *": {
            marginLeft: 2,
          },
        }}
        title={`${range === "month" ? "Monthly" : "Yearly"} report`}
        action={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ButtonGroup style={{ marginRight: 8 }}>
              <Button onClick={handleToggleMode} disabled={range === "month"}>
                Monthly
              </Button>
              <Button onClick={handleToggleMode} disabled={range === "year"}>
                Yearly
              </Button>
            </ButtonGroup>
            <DatePicker
              disableFuture
              onChange={handleDateChange}
              openTo={range}
              value={currentDate}
              // variant="inline"
              views={range === "month" ? ["year", "month"] : ["year"]}
              renderInput={(props) => (
                <TextField size="small" variant="outlined" {...props} />
              )}
            />
            <Tooltip
              title={prevDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
              placement="top"
            >
              <IconButton
                component={NavLink}
                to={{
                  ...location,
                  search: `?${stringify(
                    {
                      ...query,
                      date: moment(prevDate).format("L").replace(/\//g, "-"),
                    },
                    { arrayFormat: "repeat", skipNulls: true }
                  )}`,
                }}
                size="large"
              >
                <IconNavigateBefore />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={nextDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
              placement="top"
            >
              <IconButton
                component={NavLink}
                to={{
                  ...location,
                  search: `?${stringify(
                    {
                      ...query,
                      date: moment(nextDate).format("L").replace(/\//g, "-"),
                    },
                    { arrayFormat: "repeat", skipNulls: true }
                  )}`,
                }}
                disabled={disabledNext}
                style={disabledNext ? { pointerEvents: "none" } : {}}
                size="large"
              >
                <IconNavigateNext />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Revenue report
                </Typography>
                <div style={{ margin: "0 -16px -24px", paddingTop: 10 }}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ width: "40%" }}
                          >
                            <b>Gross revenue</b>
                          </TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(grossRevenue)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <b>Cost of goods</b>
                          </TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(costOfGoods)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <b>Expenses</b>
                          </TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(expenses.totalAmount)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <b>Fees</b>
                          </TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(fees)}
                          </TableCell>
                        </TableRow>
                        {loansCost ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <b>Loans Cost</b>
                            </TableCell>
                            <TableCell>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: baseCurrency,
                              }).format(loansCost)}
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow sx={{ "& td, & th": { border: 0 } }}>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ width: "40%" }}
                          >
                            <b>Net revenue</b>
                          </TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(netRevenue)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total orders: {total}
                </Typography>
                <ReadonlyTextField
                  label={`Buy orders: ${buy}`}
                  value={
                    <LinearProgress
                      variant="determinate"
                      value={(buy * 100) / total || 0}
                      style={{ margin: "10px 0" }}
                    />
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label={`Sell orders: ${sell}`}
                  value={
                    <LinearProgress
                      variant="determinate"
                      value={(sell * 100) / total || 0}
                      style={{ margin: "10px 0" }}
                    />
                  }
                  fullWidth
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Auto Payments
                </Typography>
                <div style={{ margin: "0 -16px -24px", paddingTop: 10 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Recipient</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Fee</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {recipients.map(
                          ({ name, fiatAmount, txFeeCost, totalCost }) => (
                            <TableRow>
                              <TableCell>{name}</TableCell>
                              <TableCell>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: baseCurrency,
                                }).format(fiatAmount * -1)}
                              </TableCell>
                              <TableCell>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: baseCurrency,
                                }).format(txFeeCost * -1)}
                              </TableCell>
                              <TableCell>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: baseCurrency,
                                }).format(totalCost * -1)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                        <TableRow>
                          <TableCell>
                            <b>Total</b>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <b>
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: baseCurrency,
                              }).format(totalFiatAmount)}
                            </b>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Customers
                </Typography>
                <ReadonlyTextField
                  label="Orders per customer"
                  value={`${averagePerCustomer}`}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Avg. amount per order"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(averageAmount)}
                  fullWidth
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={machinesExpanded}
              onChange={() => setMachinesExpanded(!machinesExpanded)}
            >
              <AccordionSummary>
                <Typography>Machines performance</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Total orders</TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", width: "15%" }}
                        >
                          Buy orders
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", width: "15%" }}
                        >
                          Sell orders
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", width: "15%" }}
                        >
                          Avg. order
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", width: "15%" }}
                        >
                          Net Revenue
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", width: "15%" }}
                        >
                          Gross Revenue
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {machines.map(({ id, name, performance, orders }) => (
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">
                            <b>{name}</b>
                          </TableCell>
                          <TableCell align="right">{orders.total}</TableCell>
                          <TableCell align="right">{orders.buy}</TableCell>
                          <TableCell align="right">{orders.sell}</TableCell>
                          <TableCell
                            align="right"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(orders.averageAmount)}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(performance.revenue)}
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(performance.grossRevenue)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={autoPaymenstExpanded}
              onChange={() => setAutopaymentsExpanded(!autoPaymenstExpanded)}
            >
              <AccordionSummary>
                <Typography>Auto Payments</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Payments</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Cost</TableCell>
                        <TableCell>Tx Fee</TableCell>
                        <TableCell>Tx Fee Cost</TableCell>
                        <TableCell>Total Cost</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recipients.map((item, index) => (
                        <AutoPaymentRow
                          key={index}
                          item={item}
                          baseCurrency={baseCurrency}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ReportRevenuePage.get = ({ store, query }) => {
  const range = query.range || "month";
  return store.dispatch(
    customReadRequest(`revenuereport/${range}`, { ...query })
  );
};

export default ReportRevenuePage;
