import PropTypes from "prop-types";
import { Form } from "react-final-form";
import * as colors from "@mui/material/colors";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "mui-rff";
import {
  createValidator,
  alpha,
  required,
  uppercase,
} from "services/validation";

const validate = createValidator({
  name: [required, uppercase],
  level: [required, alpha],
});

const SmartemptySettingsForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    initialValues={{ name: "", level: "default" }}
    render={({
      dirtySinceLastSubmit,
      form: { restart },
      handleSubmit,
      pristine,
      submitting,
      valid,
    }) => (
      <form
        autoComplete="off"
        onSubmit={(v) => {
          handleSubmit(v);
          restart();
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm>
            <TextField
              name="name"
              label="Status Name"
              type="text"
              variant="outlined"
              size="small"
              onBlur={(event) => !event.target.value && restart()}
              fullWidth
              fieldProps={{
                format: (v) => v.toUpperCase(),
                parse: (v) => v.toUpperCase(),
              }}
            />
          </Grid>
          <Grid item xs={12} sm="auto" minWidth={160}>
            <TextField
              name="level"
              label="Level"
              variant="outlined"
              size="small"
              fullWidth
              select
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 504,
                  },
                },
              }}
            >
              {[
                "default",
                "info",
                "success",
                "warning",
                "error",
                "primary",
                "secondary",
                "divider",
                ...Object.keys(colors).filter((n) => n !== "common"),
              ].map((level) =>
                level === "divider" ? (
                  <Divider key={level} />
                ) : (
                  <MenuItem key={level} value={level}>
                    <Box sx={{ whiteSpace: "nowrap" }}>
                      <Box
                        mr={2}
                        sx={{
                          verticalAlign: "middle",
                          fontSize: 0,
                          display: "inline-block",
                          padding: 0,
                          height: 16,
                          width: 16,
                          borderRadius: "50%",
                          backgroundColor:
                            level === "default"
                              ? "grey.600"
                              : colors[level]
                              ? colors[level][500]
                              : `${level}.main`,
                        }}
                      />
                      {level}
                    </Box>
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              type="submit"
              disabled={
                pristine || submitting || (!valid && !dirtySinceLastSubmit)
              }
            >
              Add status
            </Button>
          </Grid>
        </Grid>
      </form>
    )}
  />
);

SmartemptySettingsForm.propsTypes = {
  onSubmit: PropTypes.func.required,
};

export default SmartemptySettingsForm;
