import { Fragment, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import IconCopy from "@mui/icons-material/FileCopy";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import MuiTextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { QRCodeSVG as QRCode } from "qrcode.react";
import copy from "copy-to-clipboard";
import { Checkboxes, TextField } from "mui-rff";
import { handleError, trim } from "services/helpers";
import {
  createValidator,
  required,
  boolean,
  minLength,
  minValue,
} from "services/validation";
import {
  notificationSend,
  resourceCreateRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import {
  BlockchainLink,
  DiffBlock,
  Confirmation,
  ReadonlyTextField,
} from "components";

const validate = (values) => {
  let rules = {
    active: [boolean],
    name: [required, minLength(3)],
    priority: [minValue(1)],
  };

  if (!values.id) {
    if (
      values.provider === "Chainbytes" ||
      values.provider === "NativeImport"
    ) {
      rules = Object.assign(rules, {
        privateKey: [required],
      });
    }

    if (values.provider === "BlockIo") {
      rules = Object.assign(rules, {
        apiKey: [required],
        secret: [required],
      });
    }

    if (values.provider === "Bitgo") {
      rules = Object.assign(rules, {
        currency: [required],
        apiKey: [required],
        secret: [required],
      });
    }
  }
  return createValidator(rules)(values);
};

const WalletForm = ({ id }) => {
  const inputRef = useRef();

  const [openDialog, setOpenDialog] = useState(true);
  const [copied, setCopied] = useState(false);

  const {
    initialValues = {
      name: "",
      active: true,
      priority: 1,
    },
    providersOriginal = [],
  } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "wallets", id),
      providersOriginal: fromCustom.get(state, "wallets/providers"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    if (values.provider === "NativeImport") values.provider = "Native";

    let action = resourceCreateRequest("wallets", values);
    if (id) action = resourceUpdateRequest("wallets", id, values);

    return dispatch(action)
      .then((resultId) => {
        dispatch(
          notificationSend(
            `Wallet ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        if (!id) navigate(`/wallets/${resultId}/update`);
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} wallet`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  const handleCloseDialog = () => {
    delete initialValues.wif;
    setOpenDialog(false);
  };

  const handleClick = () => {
    const { wif } = initialValues;
    copy(wif);

    inputRef.current.select();
    setCopied(true);
  };

  const handleClose = () => {
    setCopied(false);
  };

  const {
    apiKey,
    secret,
    wallets,
    wif = "",
    ...otherInitialValues
  } = initialValues;

  const newWallet = !initialValues.id;
  const pi = providersOriginal.findIndex(
    ({ provider }) => provider === "Native"
  );
  const providers = [...providersOriginal];

  if (pi !== -1) {
    providers.splice(pi + 1, 0, {
      ...providers[pi],
      provider: "NativeImport",
    });
  }

  return (
    <Fragment>
      <Confirmation>
        {(show, confirm, hide, open) => (
          <Fragment>
            <Form
              initialValues={{
                provider:
                  providers && providers.length > 0
                    ? providers[0].provider
                    : undefined,
                ...otherInitialValues,
              }}
              onSubmit={onSubmit}
              validate={validate}
              render={({
                dirtySinceLastSubmit,
                form: { reset },
                handleSubmit,
                hasSubmitErrors,
                pristine,
                submitError,
                submitting,
                valid,
                values,
              }) => (
                <form onSubmit={show(handleSubmit)} noValidate>
                  <CardContent>
                    {hasSubmitErrors && (
                      <Typography color="error">{submitError}</Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        {!newWallet && (
                          <>
                            <ReadonlyTextField
                              label="Provider"
                              value={initialValues.provider}
                              fullWidth
                            />
                            <ReadonlyTextField
                              label="Address"
                              value={
                                <BlockchainLink
                                  wallet={initialValues.address}
                                  coinType={initialValues.currency}
                                  nowrap
                                />
                              }
                              fullWidth
                            />
                            {initialValues.currency && (
                              <ReadonlyTextField
                                label="Currency"
                                value={initialValues.currency}
                                fullWidth
                              />
                            )}
                          </>
                        )}

                        {newWallet && (
                          <TextField
                            name="provider"
                            label="Provider"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select
                          >
                            {providers.map(({ provider }) => {
                              let label = provider;
                              if (provider === "NativeImport")
                                label = "Native - Import existing WIF key";
                              return (
                                <MenuItem key={provider} value={provider}>
                                  {label}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        )}

                        <TextField
                          name="name"
                          label="Name"
                          type="text"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          required
                          fieldProps={{ format: trim, formatOnBlur: true }}
                        />
                        <TextField
                          name="priority"
                          label="Priority"
                          type="number"
                          variant="outlined"
                          margin="normal"
                          helperText="1 is highest priority"
                          fullWidth
                        />

                        {newWallet &&
                          (values.provider === "Chainbytes" ||
                            values.provider === "NativeImport") && (
                            <TextField
                              name="privateKey"
                              label="WIF/Private Key"
                              type="text"
                              variant="outlined"
                              margin="normal"
                              helperText={
                                <div>
                                  Leave empty to create new wallet, <br />
                                  fill in to import existing
                                </div>
                              }
                              fullWidth
                              fieldProps={{ format: trim, formatOnBlur: true }}
                            />
                          )}

                        {newWallet && values.provider === "BlockIo" && (
                          <>
                            <TextField
                              name="apiKey"
                              label="API key"
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              fieldProps={{ format: trim }}
                              required
                            />
                            <TextField
                              name="secret"
                              label="API PIN"
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              fieldProps={{ format: trim }}
                              required
                            />
                          </>
                        )}

                        {newWallet && values.provider === "Bitgo" && (
                          <>
                            <TextField
                              name="currency"
                              label="Currency"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              select
                              required
                            >
                              {providers
                                .find(({ provider }) => provider === "Bitgo")
                                .currencies.map((currency) => (
                                  <MenuItem key={currency} value={currency}>
                                    {currency}
                                  </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                              name="apiKey"
                              label="API key"
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              fieldProps={{ format: trim }}
                              required
                            />
                            <TextField
                              name="secret"
                              label="Wallet password"
                              type="text"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              fieldProps={{ format: trim }}
                              required
                            />
                          </>
                        )}

                        <Checkboxes
                          name="active"
                          data={{ label: "Active", value: "active" }}
                          formControlProps={{ fullWidth: true }}
                        />
                        <Checkboxes
                          name="archived"
                          helperText="Hide from the main list"
                          data={{ label: "Archived", value: "archived" }}
                          formControlProps={{ fullWidth: true }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={
                        pristine ||
                        submitting ||
                        (!valid && !dirtySinceLastSubmit)
                      }
                    >
                      {newWallet ? "Add wallet" : "Save changes"}
                    </Button>
                    <Button onClick={reset} disabled={pristine || submitting}>
                      Reset
                    </Button>
                  </CardActions>
                  <Dialog open={open}>
                    <DialogTitle>
                      {newWallet
                        ? "Are you sure you want to add this wallet?"
                        : "Are you sure you want to save these changes?"}
                    </DialogTitle>
                    <DialogContent>
                      <DiffBlock
                        {...{
                          initialValues: otherInitialValues,
                          values,
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={hide}>Cancel</Button>
                      <Button onClick={confirm} color="primary" autoFocus>
                        {newWallet ? "Add wallet" : "Save changes"}
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={openDialog && Boolean(wif)}
                    fullWidth
                    disableEscapeKeyDown
                    maxWidth="sm"
                  >
                    <DialogTitle>Save WIF in safe place</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <span style={{ color: "red" }}>
                          WIF is shown only once!
                        </span>
                        <br />
                        Please make sure you copy it before leaving this page
                      </DialogContentText>
                      <QRCode
                        style={{ maxWidth: 200 }}
                        value={wif}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      />
                      <ClickAwayListener onClickAway={handleClose}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={copied}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title="Successfully copied"
                          onClose={handleClose}
                        >
                          <MuiTextField
                            type="text"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            readOnly
                            onClick={handleClick}
                            inputRef={inputRef}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    color="primary"
                                    edge="end"
                                    size="large"
                                  >
                                    <IconCopy />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              value: wif,
                            }}
                          />
                        </Tooltip>
                      </ClickAwayListener>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseDialog}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </form>
              )}
            />
          </Fragment>
        )}
      </Confirmation>
    </Fragment>
  );
};

export default WalletForm;
