import { Controller } from "react-hook-form";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export default function RHFAutocomplete({
  textFieldProps,
  name,
  control,
  options,
  loading,
  showCheckbox,
  loadingIndicator,
  required,
  multiple,
  matchId,
  label,
  ...autocompleteProps
}) {
  const loadingElement = loadingIndicator || (
    <CircularProgress color="inherit" size={20} />
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        let currentValue = multiple ? value || [] : value ?? null;
        if (matchId) {
          currentValue = multiple
            ? (value || []).map((i) => options.find((j) => (j.id ?? j) === i))
            : options.find((i) => (i.id ?? i) === value) ?? null;
        }
        return (
          <Autocomplete
            {...autocompleteProps}
            value={currentValue}
            loading={loading}
            multiple={multiple}
            options={options}
            disableCloseOnSelect={
              typeof autocompleteProps?.disableCloseOnSelect === "boolean"
                ? autocompleteProps.disableCloseOnSelect
                : !!multiple
            }
            isOptionEqualToValue={
              autocompleteProps?.isOptionEqualToValue
                ? autocompleteProps.isOptionEqualToValue
                : (option, value) => {
                    return value ? option.id === (value?.id ?? value) : false;
                  }
            }
            getOptionLabel={
              autocompleteProps?.getOptionLabel
                ? autocompleteProps.getOptionLabel
                : (option) => {
                    return `${option?.label ?? option}`;
                  }
            }
            onChange={(event, value, reason, details) => {
              let changedVal = value;
              if (matchId) {
                changedVal = Array.isArray(value)
                  ? value.map((i) => i?.id ?? i)
                  : value?.id ?? value;
              }
              onChange(changedVal);
              if (autocompleteProps?.onChange) {
                autocompleteProps.onChange(event, value, reason, details);
              }
            }}
            renderOption={
              autocompleteProps?.renderOption ??
              (showCheckbox
                ? (props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox sx={{ marginRight: 1 }} checked={selected} />
                      {autocompleteProps?.getOptionLabel?.(option) ||
                        option.label ||
                        option}
                    </li>
                  )
                : undefined)
            }
            onBlur={(event) => {
              onBlur();
              if (typeof autocompleteProps?.onBlur === "function") {
                autocompleteProps.onBlur(event);
              }
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                margin="normal"
                variant="outlined"
                name={name}
                required={required}
                label={label}
                {...textFieldProps}
                {...params}
                error={!!error}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  ...textFieldProps?.InputLabelProps,
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? loadingElement : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  ...textFieldProps?.InputProps,
                }}
                inputProps={{
                  ...params.inputProps,
                  ...textFieldProps?.inputProps,
                }}
                helperText={error ? error.message : textFieldProps?.helperText}
                inputRef={ref}
              />
            )}
          />
        );
      }}
    />
  );
}
