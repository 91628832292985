import { forwardRef, memo, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import MuiSnackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { notificationDismiss } from "store/actions";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = memo((props) => {
  const { actionComponent, className, id, message, variant, ...other } = props;

  const [open, setOpen] = useState(true);

  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    dispatch(notificationDismiss(id));
  };

  let action;
  if (actionComponent) {
    const Action = actionComponent;
    action = (
      <Fragment>
        <Action />
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Fragment>
    );
  }

  return (
    <MuiSnackbar
      open={open}
      onClose={handleClose}
      action={action}
      message={message}
      {...other}
    >
      {!action && (
        <Alert onClose={handleClose} severity={variant} sx={{ width: "100%" }}>
          {message}
        </Alert>
      )}
    </MuiSnackbar>
  );
});

Snackbar.propTypes = {
  actionComponent: PropTypes.any,
  id: PropTypes.any,
  className: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
};

Snackbar.defaultProps = {
  variant: "info",
};

export default Snackbar;
