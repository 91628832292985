import { Fragment, useState } from "react";
import reduce from "lodash/reduce";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconFilter from "@mui/icons-material/FilterList";
import { TableSortLabel } from "@mui/material";
import { resourceListReadRequest } from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import { handleEntityLink } from "services/helpers";
import {
  BlockchainLink,
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
} from "components";
import { tips } from "services/helpers";

const entities = {
  orders: "Orders",
  loans: "Loans",
  autopaymens: "Auto Payments",
  general: "General",
};

const filters = ({ wallets }) => ({
  walletId: {
    label: "Wallet",
    type: "select",
    multiple: false,
    options: wallets.map((m) => m.id),
    mappings: reduce(
      wallets.map((m) => ({ [m.id]: m.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  timestamp: {
    label: "Timestamp",
    type: "date",
    suffixes: {
      gte: " from",
      lte: " to",
    },
    range: true,
  },
  entityType: {
    label: "Purpose of Payment",
    type: "select",
    multiple: false,
    options: Object.keys(entities),
    mappings: entities,
  },
});

const WalletTransactionsListPage = () => {
  const [open, setOpen] = useState(false);

  const { count, items, loading, wallets } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "wallettransactions"),
      items: fromEntities.getDenormalizedList(
        state,
        "wallettransactions",
        fromResource.getList(state, "wallettransactions")
      ),
      loading: pending(state, "wallettransactionsListRead"),
      wallets: fromEntities.getList(
        state,
        "wallets",
        fromResource.getList(state, "wallets")
      ),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Wallet Transactions"
        toolbar={
          <Fragment>
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </Fragment>
        }
        tableBefore={
          <Filter
            filters={filters({ wallets })}
            open={open}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={() => (
            <TableCell align="left" sx={{ whiteSpace: "nowrap", width: "90%" }}>
              <Tooltip title={tips.transactions.id} arrow>
                <span>Transaction ID</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="left">
              <BlockchainLink
                transaction={items[rowIndex].txId}
                coinType={items[rowIndex].currency}
              />
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell>
              <Tooltip title={tips.transactions.wallet} arrow>
                <span>Wallet</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              <MuiLink
                component={Link}
                to={`/wallets/${items[rowIndex].walletId}`}
                underline="hover"
              >
                {items[rowIndex].wallet
                  ? items[rowIndex].wallet.name
                  : "Wallet"}
              </MuiLink>
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.transactions.entity} arrow>
                <span>Entity</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0, whiteSpace: "nowrap", width: "1%" }}>
              {items[rowIndex].entityType &&
              items[rowIndex].entityId &&
              items[rowIndex].entityType !== null ? (
                <MuiLink
                  component={Link}
                  to={handleEntityLink(items[rowIndex])}
                  underline="hover"
                >
                  {`${items[rowIndex].entityType} / ${items[rowIndex].entityId}`}
                </MuiLink>
              ) : (
                <i>No record</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="center" sx={{ width: "1%" }}>
              <Tooltip title={tips.transactions.amount} arrow>
                <span>Amount</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].amount}</TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="center" sx={{ width: "1%" }}>
              <Tooltip title={tips.transactions.fee} arrow>
                <span>Fee</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].txFee}</TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.transactions.currency} arrow>
                <span>Currency</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].currency}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.transactions.timestamp} arrow>
                <TableSortLabel
                  active={sort === "timestamp"}
                  component={Link}
                  direction={order}
                  to={getSortLink("timestamp")}
                >
                  Timestamp
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].timestamp).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View" placement="top">
                  <IconButton
                    to={`/wallettransactions/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

WalletTransactionsListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(resourceListReadRequest("wallets")),
    store.dispatch(
      resourceListReadRequest("wallettransactions", { _limit: 100, ...query })
    ),
  ]);
};

export default WalletTransactionsListPage;
