import { useState } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import { AsYouType, getExampleNumber, getCountries } from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import countryData from "i18n-iso-countries/langs/en.json";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FixedSizeList } from "react-window";
import { TextField } from "mui-rff";
import Flag, { SVGSource } from "../FlagIcon";

const { countries } = countryData;

const countriesF = getCountries()
  .map((code) => ({
    code,
    name: Array.isArray(countries[code])
      ? countries[code][0]
      : countries[code]
      ? countries[code]
      : code,
  }))
  .sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

const StyledFlag = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  lineHeight: 0,
  display: "block",
  height: 16,
  width: 24,
}));

function renderRow(props) {
  const { index, style, data } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton onClick={() => data.onClick(countriesF[index].code)}>
        <ListItemIcon>
          <StyledFlag>
            <Flag
              id={countriesF[index].code.toLowerCase()}
              width={24}
              height={16}
            />
          </StyledFlag>
        </ListItemIcon>
        <ListItemText primary={countriesF[index].name} />
      </ListItemButton>
    </ListItem>
  );
}

const PhoneField = ({ name, ...other }) => {
  const [country, setCountry] = useState("US");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRowClick = (country) => {
    setCountry(country);
    setAnchorEl(null);
  };

  const parse = React.useCallback(
    (value = "") => {
      const asYouType = new AsYouType(country);
      if (value) {
        asYouType.input(value);

        if (asYouType.getNumber()) return asYouType.getNumber().number;
      }

      return value;
    },
    [country]
  );

  const format = React.useCallback(
    (value = "") => {
      const asYouType = new AsYouType(country);
      if (value) {
        asYouType.input(value);

        if (asYouType.getCountry()) {
          setCountry(asYouType.getCountry());
        }

        if (asYouType.getNumber())
          return asYouType.getNumber().formatInternational();
      }

      return value;
    },
    [country]
  );

  const example = React.useMemo(
    () => getExampleNumber(country, examples),
    [country]
  );

  return (
    <React.Fragment>
      <TextField
        name={name}
        placeholder={example ? example.formatInternational() : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={handleOpen}
                edge="start"
                style={{ marginRight: 16 }}
              >
                <span
                  style={{
                    border: "1px solid rgba(0,0,0,0.12)",
                    lineHeight: 0,
                    display: "block",
                    height: 16,
                    width: 24,
                  }}
                >
                  <Flag id={country.toLowerCase()} width={24} height={16} />
                </span>
                <ArrowDropDownIcon style={{ marginRight: -24 }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        margin="normal"
        fieldProps={{
          parse,
          format,
        }}
        {...other}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ mt: 1 }}
      >
        <FixedSizeList
          height={480}
          width={360}
          itemSize={46}
          itemCount={countriesF.length}
          overscanCount={10}
          itemData={{
            onClick: handleRowClick,
          }}
        >
          {renderRow}
        </FixedSizeList>
      </Popover>
      <SVGSource />
    </React.Fragment>
  );
};

PhoneField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default PhoneField;
