import {
  unstable_generateUtilityClass as generateUtilityClass,
  unstable_generateUtilityClasses as generateUtilityClasses,
} from "@mui/utils";

export function getAlertUtilityClass(slot) {
  return generateUtilityClass("MuiAlert", slot);
}

const alertClasses = generateUtilityClasses("MuiAlert", [
  "root",
  "colorError",
  "colorInfo",
  "colorPrimary",
  "colorSecondary",
  "colorSuccess",
  "colorWarning",
  "disabled",
  "outlined",
  "filled",
  "outlinedPrimary",
  "outlinedSecondary",
  "filledPrimary",
  "filledSecondary",
  "fullWidth",
]);

export default alertClasses;
