import { shallowEqual, useSelector } from "react-redux";
import { Snackbar } from "components";
import { fromNotifications } from "store/selectors";

const Notifications = () => {
  const { notifications } = useSelector(
    (state) => ({
      notifications: fromNotifications.getList(state),
    }),
    shallowEqual
  );

  if (notifications.length === 0) return null;

  return notifications.map(({ id, message, options }) => (
    <Snackbar
      key={id}
      id={id}
      message={message}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      autoHideDuration={5000}
      {...options}
    />
  ));
};

export default Notifications;
