import { Fragment } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import { ReadonlyTextField, ShortCard, NotesList, Label } from "components";

const ExpenseLogsReadPage = () => {
  const params = useParams();
  const { detail } = useSelector((state = {}) => ({
    detail: fromEntities.getDenormalizedDetail(state, "expenselogs", params.id),
  }));

  const { amount, createdAt, paymentDate, expense, id, status, statusLog } =
    detail;

  return (
    <Card elevation={0}>
      <CardHeader
        action={
          <Button
            startIcon={<EditIcon />}
            component={Link}
            to={`/expenses/logs/${id}/update`}
          >
            Edit
          </Button>
        }
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Expense Payment
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={4}>
                <Typography variant="h6">Information</Typography>
                <ReadonlyTextField
                  label="Expense Purpose"
                  value={expense.name}
                  fullWidth
                />
                <ReadonlyTextField label="Amount" value={amount} fullWidth />
                <ReadonlyTextField
                  label="Status"
                  value={
                    <Label color={status === "PAID" ? "success" : "error"}>
                      {status}
                    </Label>
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Payment Date"
                  value={moment(paymentDate).format("L")}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={moment(createdAt).format("L\n LTS")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Status log
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "1%" }}>#</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">User</TableCell>
                      <TableCell align="right">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {statusLog.map(({ status, createdAt, user }, index) => (
                      <TableRow key={status}>
                        <TableCell>{index}</TableCell>
                        <TableCell sx={{ py: 0 }}>
                          <Label
                            color={status === "PAID" ? "success" : "error"}
                          >
                            {status}
                          </Label>
                        </TableCell>
                        <TableCell align="right">
                          {user ? (
                            <MuiLink
                              component={Link}
                              to={`/settings/users/${user.id}/update`}
                              underline="hover"
                            >
                              {user.userName}
                            </MuiLink>
                          ) : (
                            "System"
                          )}
                        </TableCell>
                        <TableCell sx={{ width: "1%" }} align="right">
                          {moment(createdAt).format("L, LTS")}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="expenselogs" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("expenses", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<AssignmentIcon />}
                        to={`/expenses/purposes/${expense.id}`}
                        title="Expense Purpose"
                        description="View Expense Purpose"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ExpenseLogsReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("expenselogs", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "expenselogs",
        entityId: params.id,
      })
    ),
  ]);
};

export default ExpenseLogsReadPage;
