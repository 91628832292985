import { Fragment, useState } from "react";
import moment from "moment";
// import isEqual from 'lodash/isEqual'
import reduce from "lodash/reduce";
// import omit from 'lodash/omit'
import { pending } from "redux-saga-thunk";
import { Link, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconEdit from "@mui/icons-material/Edit";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconExport from "@mui/icons-material/SaveAlt";
import IconAdd from "@mui/icons-material/Add";
import IconFilter from "@mui/icons-material/FilterList";
import { hasPermission } from "services/permission";
import { resourceListReadRequest, customReadRequest } from "store/actions";
import { fromEntities, fromResource, fromCustom } from "store/selectors";
import {
  Filter,
  SearchField,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Label,
} from "components";
import { apiUrl } from "config";

const filters = ({ campaigns }) => ({
  campaignId: {
    label: "Campaigns",
    type: "select",
    multiple: false,
    options: campaigns.map((c) => c.id),
    mappings: reduce(
      campaigns.map((c) => ({ [c.id]: c.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  phone: {
    label: "Phone",
    type: "tel",
  },
  approved: {
    label: "Approved",
    type: "select",
    options: [true, false],
    mappings: {
      true: "YES",
      false: "No",
    },
    multiple: false,
  },
});

const CustomersListPage = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const { count, filtersValues, baseCurrency, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "customers"),
      filtersValues: fromCustom.get(state, "customers/filters"),
      baseCurrency: fromCustom.getBaseCurrency(state),
      items: fromEntities.getList(
        state,
        "customers",
        fromResource.getList(state, "customers")
      ),
      loading: pending(state, "customersListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Customers"
        toolbar={
          <Fragment>
            <SearchField param="search" placeholder="Search customer" />
            {hasPermission("customers", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/customers/create"
              >
                Create Customer
              </Button>
            )}
            <Button
              startIcon={<IconExport />}
              component="a"
              target="_blank"
              href={`${apiUrl}/customers/export${location.search}`}
            >
              Export .xls
            </Button>
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </Fragment>
        }
        tableBefore={
          <Filter
            filters={filters(filtersValues)}
            open={open}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "50%" }}>
              <TableSortLabel
                active={sort === "firstName"}
                component={Link}
                direction={order}
                to={getSortLink("firstName")}
              >
                Full Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].firstName || items[rowIndex].lastName ? (
                `${items[rowIndex].firstName} ${items[rowIndex].lastName}`
              ) : (
                <Typography variant="caption" color="textSecondary">
                  Not provided
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "30%" }}>
              <TableSortLabel
                active={sort === "phone"}
                component={Link}
                direction={order}
                to={getSortLink("phone")}
              >
                Phone
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].phone || (
                <Typography variant="caption" color="textSecondary">
                  Not provided
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "10%" }}>
              <TableSortLabel
                active={sort === "orderCount"}
                component={Link}
                direction={order}
                to={getSortLink("orderCount")}
              >
                Orders Count
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].orderCount}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "10%" }}>
              <TableSortLabel
                active={sort === "totalFiatAmount"}
                component={Link}
                direction={order}
                to={getSortLink("totalFiatAmount")}
              >
                Total Spent
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: baseCurrency,
              }).format(items[rowIndex].totalFiatAmount)}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "approved"}
                component={Link}
                direction={order}
                to={getSortLink("approved")}
              >
                Approved
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].approved ? (
                <Label color="success" fullWidth>
                  Yes
                </Label>
              ) : (
                <Label color="error" fullWidth>
                  No
                </Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              Customer Key
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>
              {items[rowIndex].key}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "idExpirationDate"}
                component={Link}
                direction={order}
                to={getSortLink("idExpirationDate")}
              >
                ID Expiration Date
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].idExpirationDate ? (
                moment(items[rowIndex].idExpirationDate).format("MM/DD/YYYY")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }} />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View details" placement="top">
                  <IconButton
                    to={`/customers/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("customers", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/customers/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

CustomersListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("customers", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("customers/filters")),
  ]);
};

export default CustomersListPage;
