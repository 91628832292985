/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["socket"] }] */
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { eventChannel } from "redux-saga";
import { call, put, take, fork, takeEvery } from "redux-saga/effects";
import Cookies from "js-cookie";
import { apiUrl } from "config";
import * as actions from "./actions";
import * as notificationsActions from "../notifications/actions";
import * as resourceActions from "../resource/actions";

const ActionButton = () => {
  let navigate = useNavigate();

  function handleClick() {
    navigate(
      `${window.location.pathname}${window.location.search}${window.location.hash}`
    );
  }

  return (
    <Button color="primary" size="small" onClick={handleClick}>
      Refresh
    </Button>
  );
};

function connect() {
  return new WebSocket(`${apiUrl.replace("https", "wss")}/socket`, null, {
    minReconnectionDelay: 500,
    maxReconnectionDelay: 1500,
    maxReconnectAttempts: 10,
  });
}

// this function creates an event channel from a given socket
// Setup subscription to incoming `ping` events
function createSocketChannel(socket) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel((emit) => {
    const errorHandler = (errorEvent) => {
      // create an Error object and put it into the channel
      emit(new Error(errorEvent.reason));
    };

    socket.onopen = function onopen(connection) {
      const token = Cookies.get("token");
      if (token) {
        const message = {
          type: "authentication",
          payload: { token },
        };
        socket.send(JSON.stringify(message));
      }
    };

    // setup the subscription
    socket.onerror = errorHandler;

    socket.close = () => {
      emit({ type: "START_WEBSOCKET" });
    };

    socket.onmessage = function onmessage(event) {
      try {
        const { type, payload } = JSON.parse(event.data);

        if (
          typeof payload === "object" &&
          (type === "update" || type === "create")
        ) {
          const { entityType } = payload;
          if (typeof entityType === "string") {
            const pathname = `/${entityType}`;
            // const regexp = new RegExp(entityType, 'i')
            if (
              entityType === "notifications" &&
              window.location.pathname !== "/notifications"
            ) {
              emit(
                resourceActions.resourceListReadRequest("notifications", {
                  isNew: true,
                })
              );
            }
            if (pathname === window.location.pathname) {
              emit(
                notificationsActions.notificationSend(
                  `This page is updated, tap to refresh`,
                  {
                    actionComponent: ActionButton,
                    autoHideDuration: 10000,
                  }
                )
              );
            }
          }
        }
      } catch (error) {
        errorHandler(error);
      }
    };

    // socket.onclose = function onclose() {
    //   emit({
    //     type: 'APP_UPDATE',
    //     payload: {
    //       state: 'maintenance',
    //       isWebsocketConnected: true,
    //     },
    //   })
    // }

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      socket.close();
    };

    return unsubscribe;
  });
}

export function* write(socket) {
  while (true) {
    const { payload } = yield take(actions.WEBSOCKET_EMIT);
    yield call([socket, socket.send], JSON.stringify(payload));
  }
}

export function* startWebsocket() {
  const socket = yield call(connect);
  const socketChannel = yield call(createSocketChannel, socket);
  yield fork(write, socket);

  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const action = yield take(socketChannel);
      yield put(action);
    } catch {
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      socketChannel.close();
      yield call(startWebsocket);
    }
  }
}

export default function* watch() {
  yield fork(startWebsocket);
  yield takeEvery("START_WEBSOCKET", startWebsocket);
}
