import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Checkboxes, TextField } from "mui-rff";
import { handleError, trim } from "services/helpers";
import {
  createValidator,
  required,
  minValue,
  maxValue,
  integer,
  float,
} from "services/validation";
import {
  notificationSend,
  resourceCreateRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromEntities, fromCustom } from "store/selectors";
import { DiffBlock, Confirmation } from "components";

const paymentTypeVariants = ["AUTO", "MANUAL"];
const paymentStatusVariants = ["UNPAID", "PAID"];
const paymentDays = Array.from({ length: 31 }, (v, k) => k + 1);

const validate = createValidator({
  paymentDay: [integer, minValue(1), maxValue(31)],
  name: [required],
  amount: [float, minValue(1)],
});

const ExpensesForm = ({ id }) => {
  const {
    baseCurrency,
    initialValues = {
      archvied: false,
      active: true,
      amount: 1,
      paymentDay: 1,
      paymentType: paymentTypeVariants[0],
      defaultStatus: paymentStatusVariants[0],
    },
  } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      initialValues: fromEntities.getDetail(state, "expenses", id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    let action = resourceCreateRequest("expenses", values);
    if (id) action = resourceUpdateRequest("expenses", id, values);

    return dispatch(action)
      .then(() => {
        dispatch(
          notificationSend(
            `Expense Purpose ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        navigate("/expenses/purposes");
      })
      .catch((error) => {
        dispatch(
          notificationSend(
            `Failed to  ${id ? "update" : "create"} Expense Purpose`,
            { variant: "error" }
          )
        );
        return handleError(error);
      });
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="name"
                        label="Name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <Checkboxes
                        name="active"
                        data={{ label: "Active", value: "active" }}
                        formControlProps={{ fullWidth: true }}
                      />
                      <TextField
                        name="amount"
                        label="Amount"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        inputProps={{ inputMode: "numeric", step: "1" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {baseCurrency}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Grid container md={2} spacing={2} direction="column">
                        <Grid item md={2}>
                          <TextField
                            name="paymentType"
                            label="Payment Type"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select
                          >
                            {paymentTypeVariants.map((paymentType) => (
                              <MenuItem key={paymentType} value={paymentType}>
                                {paymentType}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            name="paymentDay"
                            label="Payment Day"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select
                          >
                            {paymentDays.map((paymentDay) => (
                              <MenuItem key={paymentDay} value={paymentDay}>
                                {paymentDay}
                              </MenuItem>
                            ))}
                          </TextField>
                          <TextField
                            name="defaultStatus"
                            label="Default Status"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select
                          >
                            {paymentStatusVariants.map((status) => (
                              <MenuItem key={status} value={status}>
                                {status}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </>
      )}
    </Confirmation>
  );
};

export default ExpensesForm;
