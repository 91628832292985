import { useRef, useState } from "react";
import parseHTML from "html-react-parser";
import { pending } from "redux-saga-thunk";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { authSignoutRequest, customCreateRequest } from "store/actions";
import { fromAuth, fromCustom } from "store/selectors";

export default function AgreementDialog() {
  const {
    detail: { userAgreement = "" },
    user,
    loading,
  } = useSelector(
    (state = {}) => ({
      detail: fromCustom.get(state, "organization"),
      user: fromAuth.getUser(state),
      loading: pending(state, "users/acceptagreementCreate"),
    }),
    shallowEqual
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const signOut = () => dispatch(authSignoutRequest());
  const acceptAgreement = () =>
    dispatch(customCreateRequest("users/acceptagreement", {}));

  const { agreementAccepted = false } = user;
  const initialStatus = useRef(agreementAccepted);
  const [open, setOpen] = useState(!agreementAccepted);

  const handleAgree = () => {
    acceptAgreement()
      .then(() => {
        setOpen(false);
      })
      .catch(() => {});
  };

  const handleClose = () => {
    signOut().finally(() => {
      navigate("/signin");
    });
  };

  if (initialStatus.current) return null;

  return (
    <Dialog open={open} maxWidth="lg" sx={{ zIndex: 999999 }}>
      <DialogTitle>
        {"Agree to the Software as a Service Agreement"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{parseHTML(userAgreement)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Disagree
        </Button>
        <LoadingButton
          onClick={handleAgree}
          loading={loading}
          disabled={loading}
        >
          Agree
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
