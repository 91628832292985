import * as React from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import semver from "semver";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconLocalAtm from "@mui/icons-material/LocalAtm";
import IconOrders from "@mui/icons-material/InsertDriveFile";
import IconPerson from "@mui/icons-material/Person";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromResource, fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import { Label, ReadonlyTextField, ShortCard } from "components";
import LogNew from "./LogNew";
import LogOld from "./LogOld";

const SessionsReadPage = () => {
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "trackingsessions",
        fromResource.getDetail(state, "trackingsessions")
      ),
    }),
    shallowEqual
  );

  const {
    actions,
    appVersion,
    atm,
    atmId,
    createdAt,
    customerId,
    id,
    order,
    screenResolution,
    sessionKey,
    events = [],
    tags = [],
  } = detail;

  const newAppVersion =
    appVersion.indexOf("dev") > -1 || semver.gt(appVersion, "4.15.0");

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <React.Fragment>
            Session
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </React.Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  General
                </Typography>
                <ReadonlyTextField label="Id" value={id} fullWidth />
                <ReadonlyTextField label="Key" value={sessionKey} fullWidth />
                <ReadonlyTextField
                  label="Created at"
                  value={moment(createdAt).format("L \nLTS")}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Tags"
                  value={
                    <Stack direction="row" spacing={0.5}>
                      {tags.sort().map((tag) => {
                        if (!tag) return null;
                        return (
                          <Label key={tag.tag} color={tag.level}>
                            {tag.tag}
                          </Label>
                        );
                      })}
                    </Stack>
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Session
                </Typography>
                <ReadonlyTextField
                  label="Atm"
                  value={(atm && atm.name) || atmId || <i>[missing]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Order"
                  value={(order && order.orderId) || <i>[no order]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="App Version"
                  value={appVersion}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Screen Resolution"
                  value={screenResolution}
                  fullWidth
                />
              </Grid>

              {actions && actions.length >= 0 && newAppVersion && (
                <LogNew actions={actions} />
              )}
              {actions && actions.length >= 0 && !newAppVersion && (
                <LogOld actions={actions} events={events} />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {atmId && hasPermission("machines", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconLocalAtm />}
                        to={`/machines/${atmId}`}
                        title="Machine"
                        description="View machine this order was placed on"
                      />
                    </Grid>
                  )}
                  {order && hasPermission("orders", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconOrders />}
                        to={`/orders/${order.id}`}
                        title="Order"
                        description="View order for this session"
                      />
                    </Grid>
                  )}
                  {customerId && hasPermission("customers", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconPerson />}
                        to={`/customers/${customerId}`}
                        title="Customer"
                        description="View customer  for this session"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SessionsReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("trackingsessions", params.id)),
    store.dispatch(resourceListReadRequest("trackingsessions/taglist")),
  ]);
};

export default SessionsReadPage;
