import { Fragment } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { customReadRequest } from "store/actions";
import { OtcOrderForm } from "components";

const OtcOrdersCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader
        sx={{
          "& .MuiCardHeader-action": {
            margin: "-3px 0",
          },
        }}
        title={<Fragment>Create OTC Order</Fragment>}
      />
      <Divider />
      <OtcOrderForm />
    </Card>
  );
};

OtcOrdersCreatePage.get = ({ store }) => {
  return store.dispatch(customReadRequest("orders/otcdata"));
};

export default OtcOrdersCreatePage;
