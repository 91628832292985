import * as React from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { parse } from "qs";
import moment from "moment";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import IconClear from "@mui/icons-material/Clear";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import FilterDialog from "./FilterDialog";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-start",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.grey[50]
      : theme.palette.grey[900],
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const shouldSHowFilter = (searchParams, filters) => {
  for (const [key, value] of Object.entries(filters)) {
    if (
      value.type === "date" &&
      (searchParams.has(`${key}_gte`) || searchParams.has(`${key}_lte`))
    ) {
      return true;
    } else if (searchParams.has(key)) {
      return true;
    }
  }

  return false;
};

const Filter = (props) => {
  const { open, onClose, filters } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const initialValues = React.useMemo(
    () => parse(searchParams.toString()),
    [searchParams]
  );
  const showFilter = React.useMemo(
    () => shouldSHowFilter(searchParams, filters),
    [searchParams, filters]
  );

  const handleDelete = React.useCallback(
    (data) => () => {
      const { key, value } = data;
      const params = searchParams.getAll(key);
      // reset page
      searchParams.delete("_page");
      searchParams.delete(key);

      if (params.length > 0)
        params
          .filter((p) => p !== value)
          .forEach((p) => {
            searchParams.append(key, p);
          });

      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const handleOnSubmit = React.useCallback(
    (values) => {
      if (typeof onClose === "function") onClose();

      const searchParams = new URLSearchParams();

      // add new filter values to searchParams
      for (const [key, value] of Object.entries(values)) {
        if (Array.isArray(value))
          value.forEach((v) => searchParams.append(key, v));
        else searchParams.set(key, value);
      }

      // reset page
      searchParams.delete("_page");

      setSearchParams(searchParams);
    },
    [onClose, setSearchParams]
  );

  const handleReset = React.useCallback(() => {
    // reset page
    searchParams.delete("_page");

    // clean up filter values from searchParams
    for (const [key, value] of Object.entries(filters)) {
      if (value.type === "date") {
        searchParams.delete(`${key}_gte`);
        searchParams.delete(`${key}_lte`);
      } else {
        searchParams.delete(key);
      }
    }

    setSearchParams(searchParams);
  }, [filters, searchParams, setSearchParams]);

  return (
    <React.Fragment>
      <Collapse in={showFilter}>
        <StyledToolbar>
          <Box
            sx={{
              flex: 1,
              py: 1.5,
              pr: 1.5,
            }}
          >
            {Array.from(searchParams.entries()).map(([key, value]) => {
              const rangeKey = key.match(/gte|lte/);
              const parsedKey = rangeKey ? key.substr(0, key.length - 4) : key;
              if (Object.keys(filters).indexOf(parsedKey) !== -1) {
                let item = value;
                if (!Array.isArray(item)) item = [item];
                const { label, mappings, suffixes, type } = filters[parsedKey];
                const suffix = suffixes ? suffixes[rangeKey[0]] : "";
                return item.map((value) => {
                  let v = mappings ? mappings[value] : value;
                  if (type === "date") v = moment(v).format("L");
                  if (type === "boolean") v = Boolean(v);

                  return (
                    <Chip
                      sx={{ margin: "4px" }}
                      key={`${parsedKey}-${value}`}
                      label={`${label}${suffix}: ${v}`}
                      onDelete={handleDelete({ key, value })}
                    />
                  );
                });
              }
              return null;
            })}
          </Box>
          <Box sx={{ py: 1 }}>
            <Tooltip title="Clear filters" placement="top">
              <IconButton onClick={handleReset} size="large">
                <IconClear />
              </IconButton>
            </Tooltip>
          </Box>
        </StyledToolbar>
      </Collapse>
      <FilterDialog
        open={open}
        onSubmit={handleOnSubmit}
        onClose={onClose}
        initialValues={initialValues}
        filters={filters}
      />
    </React.Fragment>
  );
};

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default Filter;
