export const WEBSOCKET_START = "WEBSOCKET_START";
export const WEBSOCKET_STOP = "WEBSOCKET_STOP";

export const websocketStart = (token) => ({
  type: WEBSOCKET_START,
  payload: { token },
});

export const WEBSOCKET_EMIT = "WEBSOCKET_EMIT";

export const websocketEmit = (type, payload) => ({
  type: WEBSOCKET_EMIT,
  payload: { type, payload },
});
