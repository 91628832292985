import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { resourceDetailReadRequest, customReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { ExchangeForm } from "components";

const ExchangesUpdatePage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDetail(state, "exchanges", params.id),
    }),
    shallowEqual
  );

  const { id, name } = detail;

  return (
    <Card elevation={0}>
      <CardHeader title={name} />
      <Divider />
      <ExchangeForm id={id} />
    </Card>
  );
};

ExchangesUpdatePage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(customReadRequest("exchanges/available", params.id)),
    store.dispatch(resourceDetailReadRequest("exchanges", params.id)),
  ]);
};

export default ExchangesUpdatePage;
