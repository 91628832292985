import PropTypes from "prop-types";
// import isEqual from 'lodash/isEqual'
// import omit from 'lodash/omit'
import { parse, stringify } from "qs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MuiTablePagination from "@mui/material/TablePagination";

function toInteger(v) {
  return Number.isNaN(v) ? 0 : parseInt(v, 10);
}

function calculateTotalPages(totalItemsInCollection, itemsPerPage) {
  const e = toInteger(totalItemsInCollection);
  const p = toInteger(itemsPerPage);
  return Math.ceil(e / p);
}

function pageLinkPath(location, currentPageNumber) {
  const query = parse(location.search.substr(1));
  currentPageNumber === 1
    ? delete query._page
    : (query._page = currentPageNumber);

  return {
    ...location,
    search: `?${stringify(
      {
        ...query,
      },
      { arrayFormat: "repeat" }
    )}`,
  };
}

const TableFooter = (props) => {
  const { count, rowsPerPageOptions } = props;

  const location = useLocation();
  const navigate = useNavigate();

  // const startIndex = (limit, pageNumber) => {
  //   return (limit * (pageNumber - 1)) + 1
  // }

  // const endIndex = (limit, pageNumber, count) => {
  //   return (count >= limit * pageNumber) ? (limit * pageNumber) : count
  // }

  const hasReversePageLink = (pageNumber) => pageNumber - 1 > 0;

  const hasForwardPageLink = (pageNumber, totalPages) =>
    pageNumber + 1 <= totalPages;

  const reversePageLinkItem = (pageNumber) => {
    const disabled = !hasReversePageLink(pageNumber);
    const n = pageNumber - 1;
    return {
      component: disabled ? "span" : Link,
      to: pageLinkPath(location, n),
      disabled,
    };
  };

  const forwardPageLinkItem = (pageNumber, totalPages) => {
    const disabled = !hasForwardPageLink(pageNumber, totalPages);
    const n = pageNumber + 1;

    return {
      component: disabled ? "span" : Link,
      to: pageLinkPath(location, n),
      disabled,
    };
  };

  // const limitLink = (limit) => {
  //   const query = parse(location.search.substr(1))
  //   limit === rowsPerPageOptions[0] ? delete query._limit : query._limit = limit
  //   delete query._page

  //   return {
  //     ...location,
  //     search: `?${stringify({
  //       ...query,
  //     }, { arrayFormat: 'repeat' })}`,
  //   }
  // }

  const handleChangePage = () => {};

  const handleChangeRowsPerPage = (event) => {
    const limit = event.target.value;
    const query = parse(location.search.substr(1));
    limit === rowsPerPageOptions[0]
      ? delete query._limit
      : (query._limit = limit);
    delete query._page;

    navigate({
      ...location,
      search: `?${stringify(
        {
          ...query,
        },
        { arrayFormat: "repeat" }
      )}`,
    });
  };

  const query = parse(location.search.substr(1));
  const limit = toInteger(query._limit) || rowsPerPageOptions[0];
  const page = toInteger(query._page) || 1;

  const totalPages = toInteger(calculateTotalPages(count, limit));

  return (
    <MuiTablePagination
      backIconButtonProps={reversePageLinkItem(page, totalPages)}
      component="div"
      count={count}
      nextIconButtonProps={forwardPageLinkItem(page, totalPages)}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      page={page - 1}
      rowsPerPage={limit}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

TableFooter.propTypes = {
  count: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

TableFooter.defaultProps = {
  rowsPerPageOptions: [100, 50, 20],
};

export default TableFooter;
