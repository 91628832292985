import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const NotFoundPage = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      <Grid item>
        <Typography
          component="h2"
          variant="h1"
          gutterBottom
          sx={{ color: "text.secondary" }}
        >
          <Box
            sx={{
              color: "text.primary",
              fontWeight: 600,
            }}
          >
            404
          </Box>
          Not Found
        </Typography>
        <Button variant="text" color="primary" component={Link} to="/machines">
          Go Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
