import { Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconView from "@mui/icons-material/RemoveRedEye";
import { resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { ArchiveButtons, OperatorForm } from "components";

const OperatorsReadPage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDetail(state, "operators", params.id),
    }),
    shallowEqual
  );
  const { id, archived } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Operator
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
        action={
          <>
            <ArchiveButtons resource="operators" id={id} archived={archived} />
            <Button
              startIcon={<IconView />}
              component={Link}
              to={`/operators/${id}`}
            >
              Details
            </Button>
          </>
        }
      />
      <Divider />
      <OperatorForm id={id} />
    </Card>
  );
};

OperatorsReadPage.get = ({ store, params }) => {
  return store.dispatch(resourceDetailReadRequest("operators", params.id));
};

export default OperatorsReadPage;
