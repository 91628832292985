import * as React from "react";
import * as Yup from "yup";
// import "yup-phone";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import fiatCurrencies from "world-currencies";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Masonry from "@mui/lab/Masonry";
import { useConfirm } from "services/helpers";
import {
  notificationSend,
  resourceUpdateRequest,
  resourceCreateRequest,
} from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import {
  DiffBlock,
  RHFAutocomplete,
  RHFCheckbox,
  RHFDatePicker,
  RHFTextField,
} from "components";

const displayNames = new Intl.DisplayNames(["en"], {
  type: "language",
  languageDisplay: "standard",
});

const supportedLanguages = [
  "da-DK",
  "en-US",
  "es-US",
  "hu-HU",
  "ja-JP",
  "pt-BR",
  "ro-RO",
  "ru-RU",
  "zh-CN",
  "zh-TW",
];

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(6),
  mode: Yup.number().required().oneOf([0, 1, 2]),
  language: Yup.string()
    .matches(/^[a-z]{2}-[A-Z]{2}$/)
    .required(),
  kioskVersion: Yup.string().required(),
  featured: Yup.boolean(),
  archived: Yup.boolean(),
  activeCoins: Yup.array().of(Yup.string()).min(1).required(),
  markup: Yup.number().integer().required(),
  markdown: Yup.number().integer().negative().required(),
  recycle: Yup.array()
    .of(
      Yup.object().shape({
        currency: Yup.string().required(),
        denomination: Yup.number().required().positive(),
      })
    )
    .min(0)
    .nullable(),
  location: Yup.object().shape({
    address: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    country: Yup.string().nullable(),
    latitude: Yup.number().min(-90).max(90).nullable(),
    longitude: Yup.number().min(-180).max(180).nullable(),
    operationHours: Yup.string().nullable(),
    name: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    // phone: Yup.lazy((value) =>
    //   !value || value.length === 0
    //     ? Yup.string().nullable()
    //     : Yup.string().phone().nullable()
    // ),
    businessName: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
  }),
  customData: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().trim(),
        value: Yup.string().trim(),
      })
    ) // TODO
    .notRequired(),
  rentStartDate: Yup.string().nullable(),
  rentAmount: Yup.number().nullable(),
});

const mappings = {
  mode: {
    0: "Maintenance",
    1: "Active",
    2: "Configuration",
  },
};

const recycleSuggestions = (fiatCurrency) => {
  let denominations = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000];

  if (fiatCurrencies[fiatCurrency]) {
    const banknotes = [
      ...fiatCurrencies[fiatCurrency].banknotes.frequent,
      ...fiatCurrencies[fiatCurrency].banknotes.rare,
    ];
    denominations = banknotes.map((note) =>
      parseInt(note.replace(/\D/g, ""), 10)
    );
  }

  return denominations.map((denomination) => ({
    currency: fiatCurrency,
    denomination,
  }));
};

const MachineForm = ({ id }) => {
  const { cryptoCurrencies, kioskversionsRaw, defaultValues, fiatCurrency } =
    useSelector((state) => {
      const kioskversionsRaw = fromCustom.get(state, "kioskversions");

      const machineDetails =
        fromEntities.getDetail(state, "machines", id) || {};
      const {
        name,
        mode = 0,
        kioskVersion = kioskversionsRaw[0],
        language = "en-US",
        featured,
        archived,
        activeCoins = ["BTC"],
        markup = 5,
        markdown = -2,
        recycle = [],
        location = {},
        fiatCurrency,
        customData = [],
        rentAmount = 0,
        rentStartDate = null,
      } = machineDetails;

      return {
        fiatCurrency,
        kioskversionsRaw,
        cryptoCurrencies: fromCustom.get(state, "cryptocurrencies/list"),
        defaultValues: {
          name,
          mode,
          language,
          kioskVersion,
          featured,
          archived,
          activeCoins,
          markup,
          markdown,
          recycle,
          location,
          customData,
          rentAmount,
          rentStartDate,
        },
      };
    }, shallowEqual);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, confirm, proceed, cancel] = useConfirm();

  const {
    control,
    formState: { isDirty, isSubmitting, isValid },
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "all",
    criteriaMode: "all",
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });

  const customData = useFieldArray({
    control,
    name: "customData",
    keyName: "id",
  });

  const onSubmit = React.useCallback(
    async (values) => {
      let action = resourceCreateRequest("machines", values);
      if (id) action = resourceUpdateRequest("machines", id, values);

      if (values.rent?.startDate === null) {
        values.rent = null;
      }

      try {
        const resultId = await dispatch(action);
        reset(values);
        await dispatch(
          notificationSend(
            `Machine ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );

        if (!id) navigate(`/machines/${resultId}/update`);
      } catch (error) {
        await dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} machine`, {
            variant: "error",
          })
        );
      }
    },
    [id, dispatch, navigate, reset]
  );

  const recycleOtions = React.useMemo(
    () => recycleSuggestions(fiatCurrency),
    [fiatCurrency]
  );

  const kioskversions = React.useMemo(() => {
    const versions = [...kioskversionsRaw];
    const version = versions.find((v) => v === defaultValues.kioskVersion);
    if (!version) versions.push(defaultValues.kioskVersion);
    return versions;
  }, [kioskversionsRaw, defaultValues.kioskVersion]);

  const values = getValues();

  return (
    <Container maxWidth="xl">
      <form
        onSubmit={confirm(handleSubmit(onSubmit))}
        noValidate
        autoComplete="off"
        onReset={() => reset()}
      >
        <CardContent sx={{ px: 0 }}>
          <Masonry columns={{ xs: 1, md: 2, lg: 3 }} spacing={3}>
            <div>
              <Typography variant="h6" gutterBottom>
                General
              </Typography>

              <RHFTextField
                control={control}
                name="name"
                label="Name"
                type="text"
                disabled={isSubmitting}
                required
              />

              <RHFTextField
                control={control}
                name="mode"
                label="Mode"
                type="number"
                disabled={isSubmitting}
                required
                select
              >
                {Object.keys(mappings.mode).map((key) => (
                  <MenuItem key={key} value={key}>
                    {mappings.mode[key]}
                  </MenuItem>
                ))}
              </RHFTextField>

              <RHFTextField
                control={control}
                name="language"
                label="Language"
                type="text"
                disabled={isSubmitting}
                required
                select
              >
                {supportedLanguages.map((key) => (
                  <MenuItem key={key} value={key}>
                    {displayNames.of(key)}
                  </MenuItem>
                ))}
              </RHFTextField>

              {kioskversions && (
                <RHFTextField
                  control={control}
                  name="kioskVersion"
                  label="Kiosk Version"
                  type="text"
                  disabled={isSubmitting}
                  required
                  select
                >
                  {kioskversions.map((version) => (
                    <MenuItem key={version} value={version}>
                      {version}
                    </MenuItem>
                  ))}
                </RHFTextField>
              )}

              <RHFCheckbox
                name="featured"
                label="Featured"
                control={control}
                helperText="Machine is displayed on the summary page and has sorting priority."
              />

              <RHFCheckbox
                name="archived"
                label="Archived"
                control={control}
                helperText="Hide machine from the main list."
              />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Financial
              </Typography>

              <RHFAutocomplete
                name="activeCoins"
                label="Active Coins"
                control={control}
                multiple
                defaultValue={[]}
                options={cryptoCurrencies}
                disabled={isSubmitting}
                isOptionEqualToValue={(option, value) => option === value}
                filterSelectedOptions
                textFieldProps={{
                  placeholder: "Add coin",
                }}
              />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <RHFTextField
                    control={control}
                    name="markup"
                    label="Buying Fee (%)"
                    type="number"
                    disabled={isSubmitting}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField
                    control={control}
                    name="markdown"
                    label="Selling Fee (%)"
                    type="number"
                    disabled={isSubmitting}
                    required
                  />
                </Grid>
              </Grid>
              {!!id && (
                <RHFAutocomplete
                  name="recycle"
                  label="Recycle"
                  control={control}
                  multiple
                  defaultValue={[]}
                  options={recycleOtions}
                  disabled={isSubmitting}
                  isOptionEqualToValue={(option, value) =>
                    option.denomination === value.denomination
                  }
                  getOptionLabel={({ currency, denomination }) =>
                    new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency,
                    }).format(denomination)
                  }
                  filterSelectedOptions
                  textFieldProps={{
                    placeholder: "Add bill",
                  }}
                />
              )}
            </div>

            <div>
              <Typography variant="h6" gutterBottom>
                Location
              </Typography>

              <RHFTextField
                control={control}
                name="location.address"
                label="Address"
                type="text"
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.city"
                label="City"
                type="text"
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.state"
                label="State"
                type="text"
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.zipCode"
                label="Zip Code"
                type="text"
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.country"
                label="Country"
                type="text"
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.latitude"
                label="Latitude"
                type="number"
                inputProps={{
                  step: "0.0000001",
                }}
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.longitude"
                label="Longitude"
                type="number"
                inputProps={{
                  step: "0.0000001",
                }}
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.operationHours"
                label="Operation Hours"
                type="text"
                disabled={isSubmitting}
              />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Contacts
              </Typography>

              <RHFTextField
                control={control}
                name="location.businessName"
                label="Business Name"
                type="text"
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.name"
                label="Contact Name"
                type="text"
                disabled={isSubmitting}
              />

              <RHFTextField
                control={control}
                name="location.phone"
                label="Contact Phone"
                type="text"
                disabled={isSubmitting}
              />
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Custom data
              </Typography>
              {customData.fields.map(({ key }, index) => (
                <RHFTextField
                  key={key}
                  control={control}
                  name={`customData.${index}.value`}
                  label={key}
                  type="text"
                  disabled={isSubmitting}
                />
              ))}
            </div>
            <div>
              <Typography variant="h6" gutterBottom>
                Rent Payments
              </Typography>
              <RHFDatePicker
                control={control}
                name="rentStartDate"
                label="Start Date"
                helperText="This can't be changed once set"
                disabled={isSubmitting}
              />
              <RHFTextField
                control={control}
                name="rentAmount"
                label="Amount, USD"
                type="number"
                disabled={isSubmitting}
              />
            </div>
          </Masonry>
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            color="primary"
            disabled={!isValid || !isDirty || isSubmitting}
          >
            Save
          </Button>
          <Button type="reset" disabled={!isDirty || isSubmitting}>
            Reset
          </Button>
        </CardActions>
        <Dialog open={open}>
          <DialogTitle>
            Are you sure you want to save these changes?
          </DialogTitle>
          <DialogContent>
            <DiffBlock
              {...{ initialValues: defaultValues, values, mappings }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={cancel}>Cancel</Button>
            <Button onClick={proceed} color="primary" autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Container>
  );
};

export default MachineForm;
