import { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import throttle from "lodash/throttle";
import { styled } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import { fromAuth, fromCustom } from "store/selectors";
import { AppBar, AppContent, AppDrawer, AgreementDialog } from "components";
import { customReadRequest, resourceListReadRequest } from "store/actions";
import { hasPermission } from "services/permission";

const Root = styled("div")({
  alignItems: "stretch",
  display: "flex",
  minHeight: "100vh",
  width: "100%",
});

const injectAppFrameStyles = (
  <GlobalStyles
    styles={(theme) => ({
      html: {
        minHeight: "100%",
        width: "100%",
      },
      body: {
        backgroundColor: theme.palette.background.paper,
        minHeight: "100%",
        width: "100%",
      },
    })}
  />
);

function AppFrame() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerVariant, setDrawerVariant] = useState(
    window.innerWidth >= 1280 ? "permanent" : "temporary"
  );

  const location = useLocation();
  useEffect(() => {
    if (window.zE)
      ZendeskAPI("webWidget", "helpCenter:setSuggestions", { url: true });
  }, [location]);

  const { organization, user } = useSelector(
    (state = {}) => ({
      organization: fromCustom.get(state, "organization"),
      user: fromAuth.getUser(state),
    }),
    shallowEqual
  );

  const handleResize = throttle(() => {
    setDrawerVariant(window.innerWidth >= 1280 ? "permanent" : "temporary");
  }, 100);

  useEffect(() => {
    window.addEventListener("resize", handleResize, true);

    return () => {
      window.removeEventListener("resize", handleResize, true);
    };
  }, [handleResize]);

  const handleDrawerToggle = () => {
    setMobileOpen((mobileOpen) => !mobileOpen);
  };

  const handleDrawerOpen = () => {
    setMobileOpen(true);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  const { firstName, lastName, userName, email, phone } = user;
  const settings = {
    webWidget: {
      contactForm: {
        fields: [
          {
            id: "name",
            prefill: {
              "*":
                firstName || lastName ? `${firstName} ${lastName}` : userName,
            },
          },
          { id: "email", prefill: { "*": email } },
          { id: "phone", prefill: { "*": phone } },
        ],
      },
    },
  };

  return (
    <Root>
      <AppBar
        title={organization.name}
        handleDrawerToggle={handleDrawerToggle}
      />
      {/* <Notifications /> */}
      <AppDrawer
        mobileOpen={mobileOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        title={organization.name}
        variant={drawerVariant}
      />
      <AppContent />
      <Zendesk
        defer
        zendeskKey="2d769360-9fef-4c88-8423-72bca93f232d"
        {...settings}
      />
      <AgreementDialog />
      {injectAppFrameStyles}
    </Root>
  );
}

AppFrame.get = ({ store }) => {
  const { custom, resource } = store.getState();
  const promises = [];
  if (
    hasPermission("notifications", "GET") &&
    (!resource.notifications || resource.notifications.list.length === 0)
  )
    promises.push(
      store.dispatch(resourceListReadRequest("notifications", { isNew: true }))
    );
  if (
    !custom ||
    !custom.organization ||
    !Object.keys(custom.organization).length
  )
    promises.push(store.dispatch(customReadRequest("organization")));
  return Promise.all(promises);
};

export default AppFrame;
