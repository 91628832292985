/* eslint camelcase: ["error", {allow: ["date_gte", "date_lte"]}] */
import { useState } from "react";
import { parse } from "qs";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { resourceListReadRequest } from "store/actions";
import { fromCustom, fromResource } from "store/selectors";
import IconFilter from "@mui/icons-material/FilterList";
import { Filter } from "components";
import { Chart } from "react-google-charts";

const filterValues = {
  date: {
    label: "Date",
    type: "date",
    suffixes: {
      lte: " to",
      gte: " from",
    },
    range: true,
  },
  maPeriod: {
    label: "Period",
    type: "number",
  },
};

const PerformanceMovingAveragePage = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const { movingAverageReport, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      movingAverageReport: fromResource.getList(
        state,
        "performance/movingaverage"
      ),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const getStats = () => {
    const stat = [];
    stat.push(["", `SMA, ${baseCurrency}`, `Daily, ${baseCurrency}`]);

    // eslint-disable-next-line array-callback-return
    movingAverageReport.map((item) => {
      stat.push([
        moment(item.date).format("MMM D, Y"),
        item.movingAverage,
        item.total,
      ]);
    });

    return stat;
  };

  const { maPeriod = 7, date_gte, date_lte } = parse(location.search);
  let days = 30;
  if (date_gte) {
    days = moment(date_lte).diff(moment(date_gte), "days");
  }

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{
          margin: "-3px 0",
          "@media print": {
            display: "none",
          },
        }}
        title="Moving Average"
        action={
          <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
            Filter
          </Button>
        }
      />
      <Filter filters={filterValues} open={open} onClose={handleFilterClose} />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Chart
              width="100%"
              height="70vh"
              style={{ minHeight: 450 }}
              chartType="Line"
              loader={<div>Loading Chart</div>}
              data={getStats()}
              options={{
                chart: {
                  title: `${maPeriod} day simple moving average for the past ${days} days`,
                  subtitle: `in ${baseCurrency}`,
                },
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PerformanceMovingAveragePage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("performance/movingaverage", { ...query })
    ),
  ]);
};

export default PerformanceMovingAveragePage;
