import { useState } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import IconDelete from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import IpFilterForm from "components/forms/IpFilterForm";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
  resourceUpdateRequest,
  resourceDeleteRequest,
  notificationSend,
} from "store/actions";
import { fromEntities, fromAuth, fromResource } from "store/selectors";
import { ArchiveButtons, UserForm } from "components";

const UsersUpdatePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const tab = location.hash.replace("#", "") || "profile";
  const params = useParams();
  const isSelfEdit = params.id === "me";
  const { detail, sessions, ipWhitelist } = useSelector(
    (state = {}) => ({
      sessions: fromEntities.getList(
        state,
        "users/me/sessions",
        fromResource.getList(state, "users/me/sessions")
      ),
      ipWhitelist: fromEntities.getList(
        state,
        "users/me/ipwhitelist",
        fromResource.getList(state, "users/me/ipwhitelist")
      ),
      detail: isSelfEdit
        ? fromAuth.getUser(state)
        : fromEntities.getDetail(state, "users", params.id),
    }),
    shallowEqual
  );
  const { id, archived } = detail;

  const handleChange = (event, newValue) => {
    navigate({
      ...location,
      hash: `#${newValue}`,
    });
  };

  const handleSessionTerminate = (sessionId) => {
    return dispatch(resourceUpdateRequest("users/me/sessions", sessionId, {}))
      .then(() => {
        dispatch(
          notificationSend("Session Terminated", {
            variant: "success",
          })
        );
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to Terminate Session", {
            variant: "error",
          })
        );
      });
  };

  const handleIpDelete = (id) => {
    setProcessing(true);
    dispatch(resourceDeleteRequest("users/me/ipwhitelist", id))
      .then(() => {
        dispatch(
          notificationSend("IP deleted successfully", { variant: "success" })
        );
      })
      .catch(() => {
        dispatch(notificationSend("Failed to delete IP", { variant: "error" }));
      })
      .finally(() => setProcessing(false));
  };

  const terminateButtonClick = (sessionsId) => {
    setProcessing(true);
    handleSessionTerminate(sessionsId).finally(() => setProcessing(false));
  };

  return (
    <Card elevation={0}>
      <Dialog fullWidth open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Add IP Address</DialogTitle>
        <DialogContent>
          <IpFilterForm
            ipList={ipWhitelist.map(({ ip }) => ip)}
            onAdd={() => setDialogOpen(false)}
            onCancel={() => setDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <CardHeader
        title={
          <Grid container>
            <Grid item>
              User
              <Typography
                variant="inherit"
                color="textSecondary"
                display="inline"
              >{`#${id}`}</Typography>
            </Grid>
            <Grid item xs></Grid>
            {isSelfEdit && (
              <Grid item>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  style={{ marginBottom: -16 }}
                >
                  <Tab label="Profile" value="profile" />
                  <Tab label="Notifications" value="notifications" />
                  <Tab label="Security" value="security" />
                </Tabs>
              </Grid>
            )}
            <Grid item xs></Grid>
          </Grid>
        }
        action={
          params.id !== "me" ? (
            <ArchiveButtons resource="users" id={id} archived={archived} />
          ) : null
        }
      />
      <Divider />
      {tab !== "security" && <UserForm id={id} tab={tab} />}
      {tab === "security" && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Card elevation={0}>
              <CardHeader title="Sessions" />
              <CardContent>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>IP Address</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Browser</TableCell>
                      <TableCell>OS</TableCell>
                      <TableCell align="right">Creation Date</TableCell>
                      <TableCell align="right">Expiry Date</TableCell>
                      <TableCell width={"1%"} align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sessions.map((session) => (
                      <TableRow key={session.id}>
                        <TableCell>
                          {session.current
                            ? `${session.id} (current)`
                            : session.id}
                        </TableCell>
                        <TableCell>{session.ip}</TableCell>
                        <TableCell
                          sx={{
                            color: session.active
                              ? "success.main"
                              : "error.main",
                          }}
                        >
                          {session.active ? "ACTIVE" : "TERMINATED"}
                        </TableCell>
                        <TableCell>
                          {`${session.browser || ""} ${
                            session.browserVersion || ""
                          }`}
                        </TableCell>
                        <TableCell>
                          {`${session.os || ""} ${session.osVersion || ""}`}
                        </TableCell>
                        <TableCell align="right">
                          {new Date(session.createdAt).toLocaleString("en-US")}
                        </TableCell>
                        <TableCell align="right">
                          {new Date(session.expiryDate).toLocaleString("en-US")}
                        </TableCell>
                        <TableCell width={"1%"} align="right">
                          {session.active && (
                            <Button
                              color="error"
                              size="small"
                              variant="outlined"
                              startIcon={<CancelIcon />}
                              disabled={processing}
                              onClick={(e) => terminateButtonClick(session.id)}
                            >
                              Terminate
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card elevation={0}>
              <CardHeader title="IP Whitelist" />
              <CardContent>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>IP Address</TableCell>
                      <TableCell align="right">Created At</TableCell>
                      <TableCell width={"1%"} align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ipWhitelist.map(({ id, ip, createdAt }) => (
                      <TableRow key={id}>
                        <TableCell>{ip}</TableCell>
                        <TableCell align="right">
                          {new Date(createdAt).toLocaleString("en-US")}
                        </TableCell>
                        <TableCell width={"1%"} align="right">
                          <Button
                            color="error"
                            size="small"
                            variant="outlined"
                            startIcon={<IconDelete />}
                            disabled={processing}
                            onClick={() => handleIpDelete(id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
              <CardActions>
                <Button
                  disabled={processing}
                  startIcon={<AddIcon />}
                  onClick={() => setDialogOpen(true)}
                >
                  Add IP Address
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      )}
    </Card>
  );
};

UsersUpdatePage.get = ({ store, params }) => {
  const promises = [
    store.dispatch(resourceDetailReadRequest("users", params.id)),
    store.dispatch(resourceListReadRequest("machines", { _limit: 1000 })),
  ];
  if (params.id !== "me") {
    promises.push(store.dispatch(resourceListReadRequest("userroles")));
  } else {
    promises.push(store.dispatch(resourceListReadRequest("users/me/sessions")));
    promises.push(
      store.dispatch(resourceListReadRequest("users/me/ipwhitelist"))
    );
  }
  return Promise.all(promises);
};

export default UsersUpdatePage;
