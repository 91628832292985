import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useLocation, useOutlet } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

const Root = styled("div")(({ theme }) => ({
  boxSizing: "border-box",
  position: "relative",
  flex: "1 1 auto",
  margin: "0 auto",
  width: "100%",
  paddingTop: 56,
  [theme.breakpoints.up("sm")]: {
    paddingTop: 64,
  },
  [theme.breakpoints.up("xl")]: {
    maxWidth: "calc(100% - 240px)",
  },
  "&:after": {
    borderTop: `1px solid ${theme.palette.divider}`,
    content: '""',
    display: "block",
    height: 80,
  },
  [`& .toss-enter`]: {
    opacity: 0,
    transform: "translateY(20px)",
    pointerEvents: "none",
  },

  [`& .toss-enter-active`]: {
    opacity: 1,
    transform: "translateY(0)",
    transition: `all 210ms`,
  },

  [`& .toss-exit`]: {
    opacity: 1,
    pointerEvents: "none",
  },

  [`& .toss-exit-active`]: {
    transition: `opacity 90ms`,
    opacity: 0,
  },
}));

// function getmatches(element) {
//   if(element.props.value.outlet) return getmatches(element.props.value.outlet)
//   else return element.props.value.matches[element.props.value.matches.length-1].pathname
// }

const AppContent = (props) => {
  const { className } = props;

  const element = useOutlet();
  // const key = getmatches(element) // TODO more efficient way
  const location = useLocation();

  return (
    <Root className={className}>
      <SwitchTransition>
        <CSSTransition
          in
          key={location.pathname}
          timeout={{
            enter: 210,
            exit: 90,
          }}
          classNames="toss"
          onEntered={() => {
            window.scrollTo(0, 0);
          }}
        >
          <div>{element}</div>
        </CSSTransition>
      </SwitchTransition>
    </Root>
  );
};

AppContent.propTypes = {
  routes: PropTypes.array,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AppContent;
