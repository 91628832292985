import { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import * as colors from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { TextField } from "mui-rff";
import { notificationSend, resourceUpdateRequest } from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import { handleError } from "services/helpers";
import { DiffBlock, Confirmation } from "components";

const SmartEmptyForm = ({ id }) => {
  const { initialValues, statuses } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "smartempties", id),
      statuses:
        fromCustom.get(state, "config/smartempties", {}).statusList || [],
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (values) => {
    return dispatch(resourceUpdateRequest("smartempties", id, values))
      .then(() => {
        dispatch(
          notificationSend("Smart empty updated successfully", {
            variant: "success",
          })
        );
        navigate("/smartempties");
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to update smart empty", { variant: "error" })
        );
        return handleError(error);
      });
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)}>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="status"
                        label="Status"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        select
                      >
                        {statuses.map(({ name, level }) => (
                          <MenuItem key={name} value={name}>
                            <Box sx={{ whiteSpace: "nowrap" }}>
                              <Box
                                mr={2}
                                sx={{
                                  verticalAlign: "middle",
                                  fontSize: 0,
                                  display: "inline-block",
                                  padding: 0,
                                  height: 16,
                                  width: 16,
                                  borderRadius: "50%",
                                  backgroundColor:
                                    level === "default"
                                      ? "grey.600"
                                      : colors[level]
                                      ? colors[level][500]
                                      : `${level}.main`,
                                }}
                              />
                              {name}
                            </Box>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

export default SmartEmptyForm;
