import React, { Fragment, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import reduce from "lodash/reduce";
import isEqual from "lodash/isEqual";
import { pending } from "redux-saga-thunk";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import IconEdit from "@mui/icons-material/Edit";
import IconFilter from "@mui/icons-material/FilterList";
import IconAdd from "@mui/icons-material/Add";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconExport from "@mui/icons-material/SaveAlt";
import UpdateIcon from "@mui/icons-material/Update";
import { hasPermission } from "services/permission";
import { resourceListReadRequest, customReadRequest } from "store/actions";
import { fromEntities, fromResource, fromCustom } from "store/selectors";
import { tips } from "services/helpers";
import {
  BlockchainLink,
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Label,
  SearchField,
} from "components";
import { apiUrl } from "config";

const StyledMuiLink = styled(MuiLink)({
  display: "block",
  maxWidth: "150px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const filtersGenerate = ({
  coinType,
  fiatType,
  machines,
  campaigns,
  status,
}) => ({
  orderId: {
    label: "Order Id",
    type: "text",
  },
  atmId: {
    label: "ATM",
    type: "select",
    multiple: false,
    options: machines.map((m) => m.id),
    mappings: reduce(
      machines.map((m) => ({ [m.id]: m.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  status: {
    label: "Status",
    type: "select",
    options: status,
    multiple: true,
  },
  type: {
    label: "Type",
    type: "select",
    options: ["BUY", "SELL"],
    multiple: false,
  },
  otc: {
    label: "OTC",
    type: "select",
    options: ["exclude", "only"],
    multiple: false,
  },
  campaignId: {
    label: "Campaigns",
    type: "select",
    multiple: false,
    options: campaigns.map((c) => c.id),
    mappings: reduce(
      campaigns.map((c) => ({ [c.id]: c.name })),
      (res, val) => Object.assign(res, val),
      {}
    ),
  },
  fiatType: {
    label: "Fiat Currency",
    type: "select",
    options: fiatType,
    multiple: false,
  },
  coinType: {
    label: "Crypto Currency",
    type: "select",
    options: coinType,
    multiple: false,
  },
  fiatAmount: {
    label: "Fiat Amount",
    type: "number",
    suffixes: {
      gte: " from",
      lte: " to",
    },
    range: true,
  },
  createdAt: {
    label: "Created At",
    type: "date",
    suffixes: {
      gte: " from",
      lte: " to",
    },
    range: true,
  },
  snapshot: {
    label: "Snapshot",
    type: "select",
    mappings: {
      0: "Missing",
      1: "Present",
    },
    options: [0, 1],
    multiple: false,
  },
  customerKey: {
    label: "Customer Key",
    type: "string",
  },
});

const OrdersListPage = () => {
  const [open, setOpen] = useState(false);
  const { count, filtersValues, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "orders"),
      filtersValues: fromCustom.get(state, "orders/filters"),
      items: fromEntities.getDenormalizedList(
        state,
        "orders",
        fromResource.getList(state, "orders")
      ),
      loading: pending(state, "ordersListRead"),
    }),
    isEqual
  );

  const location = useLocation();

  const handleFilterOpen = () => {
    setOpen(true);
  };

  const handleFilterClose = () => {
    setOpen(false);
  };

  const filters = React.useMemo(() => {
    return filtersGenerate(filtersValues);
  }, [filtersValues]);

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Orders"
        toolbar={
          <Fragment>
            <SearchField param="orderId" placeholder="Search order" />
            <Button
              startIcon={<IconExport />}
              component="a"
              target="_blank"
              href={`${apiUrl}/orders/export${location.search}`}
            >
              Export .xls
            </Button>
            {hasPermission("orders/otc", "POST") && (
              <Button
                startIcon={<IconAdd />}
                to={`/orders/create/otc`}
                component={Link}
                size="large"
              >
                Add OTC Order
              </Button>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </Fragment>
        }
        tableBefore={
          <Filter filters={filters} open={open} onClose={handleFilterClose} />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "91%" }}>
              <Tooltip title={tips.orders.id} arrow>
                <TableSortLabel
                  active={sort === "orderId"}
                  component={Link}
                  direction={order}
                  to={getSortLink("orderId")}
                >
                  OrderId
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap", py: 0 }}>
              <MuiLink
                component={Link}
                to={`/orders/${items[rowIndex].id}`}
                underline="hover"
              >
                {items[rowIndex].orderId}
              </MuiLink>
              {items[rowIndex].queueId &&
                items[rowIndex].status !== "FULFILLED" && (
                  <IconButton
                    title={`Queue ${items[rowIndex].queueId}`}
                    color="warning"
                    component={Link}
                    to={`/orderqueue/${items[rowIndex].queueId}`}
                  >
                    <UpdateIcon />
                  </IconButton>
                )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <Hidden xlDown>
              <TableCell sx={{ width: "1%" }}>
                <Tooltip title={tips.orders.atm} arrow>
                  <TableSortLabel
                    active={sort === "atmId"}
                    component={Link}
                    direction={order}
                    to={getSortLink("atmId")}
                  >
                    ATM
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            </Hidden>
          )}
          cell={({ rowIndex }) => (
            <Hidden xlDown>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                {items[rowIndex].atm ? (
                  <StyledMuiLink
                    component={Link}
                    to={`/machines/${items[rowIndex].atm.id}`}
                    underline="hover"
                  >
                    {items[rowIndex].atm.name}
                  </StyledMuiLink>
                ) : (
                  `OTC Order`
                )}
              </TableCell>
            </Hidden>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.orders.wallet} arrow>
                <TableSortLabel
                  active={sort === "wallet"}
                  component={Link}
                  direction={order}
                  to={getSortLink("wallet")}
                >
                  Wallet
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              <BlockchainLink
                wallet={items[rowIndex].wallet}
                coinType={items[rowIndex].coinType}
                short
              />
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.orders.type} arrow>
                <TableSortLabel
                  active={sort === "type"}
                  component={Link}
                  direction={order}
                  to={getSortLink("type")}
                >
                  Type
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Label
                variant="outlined"
                color={items[rowIndex].type === "BUY" ? "success" : "error"}
              >
                {items[rowIndex].type}
              </Label>
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ width: "1%" }}>
                <Tooltip title={tips.orders.fee} arrow>
                  <span>Fee</span>
                </Tooltip>
              </TableCell>
            </Hidden>
          )}
          cell={({ rowIndex }) => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {items[rowIndex].type === "BUY" ? (
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: items[rowIndex].fiatType,
                  }).format(items[rowIndex].txFeeCost)
                ) : (
                  <i>[not applied]</i>
                )}
              </TableCell>
            </Hidden>
          )}
        />
        <TableColumn
          header={() => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ width: "1%" }}>
                <Tooltip title={tips.orders.cost} arrow>
                  <span>Cost</span>
                </Tooltip>
              </TableCell>
            </Hidden>
          )}
          cell={({ rowIndex }) => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: items[rowIndex].fiatType,
                }).format(items[rowIndex].cost)}
              </TableCell>
            </Hidden>
          )}
        />
        <TableColumn
          header={() => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ width: "1%" }}>
                <Tooltip title={tips.orders.profit} arrow>
                  <span>Profit</span>
                </Tooltip>
              </TableCell>
            </Hidden>
          )}
          cell={({ rowIndex }) => (
            <Hidden xlDown>
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: items[rowIndex].fiatType,
                }).format(items[rowIndex].profit)}
              </TableCell>
            </Hidden>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.orders.spotPrice} arrow>
                <span>Spot Price</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: items[rowIndex].fiatType,
              }).format(items[rowIndex].cryptoCurrencySpotPrice)}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.orders.coinAmount} arrow>
                <TableSortLabel
                  active={sort === "coinAmount"}
                  component={Link}
                  direction={order}
                  to={getSortLink("coinAmount")}
                >
                  Coin Amount
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {`${items[rowIndex].coinAmount} ${items[rowIndex].coinType}`}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.orders.fiatAmount} arrow>
                <TableSortLabel
                  active={sort === "fiatAmount"}
                  component={Link}
                  direction={order}
                  to={getSortLink("fiatAmount")}
                >
                  Fiat Amount
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: items[rowIndex].fiatType,
              }).format(items[rowIndex].fiatAmount)}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.orders.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {moment(items[rowIndex].createdAt).format("L \nLTS")}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.orders.status} arrow>
                <TableSortLabel
                  active={sort === "status"}
                  component={Link}
                  direction={order}
                  to={getSortLink("status")}
                >
                  Status
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].status && (
                <Label
                  color={
                    clsx({
                      error:
                        items[rowIndex].status === "ERROR" ||
                        items[rowIndex].status === "NO FUNDS",
                      warning:
                        items[rowIndex].status === "HOLD" ||
                        items[rowIndex].status === "EXPIRED",
                      info:
                        [
                          "PAYING",
                          "BUYING",
                          "RECEIVED",
                          "PENDING",
                          "PEND",
                        ].indexOf(items[rowIndex].status.toUpperCase()) !== -1,
                      success:
                        ["REDEEMED", "FULFILLED"].indexOf(
                          items[rowIndex].status.toUpperCase()
                        ) !== -1,
                    }) || "default"
                  }
                  fullWidth
                >
                  {items[rowIndex].status}
                </Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View details" placement="top">
                  <IconButton
                    to={`/orders/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("orders", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/orders/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

OrdersListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("orders", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("orders/filters")),
  ]);
};

export default OrdersListPage;
