import * as React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import moment from "moment";
import isEqual from "lodash/isEqual";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { pending } from "redux-saga-thunk";
import { VariableSizeList as List } from "react-window";
import { lightBlue, red, orange } from "@mui/material/colors";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconError from "@mui/icons-material/Error";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import IconInfo from "@mui/icons-material/Info";
import IconNotifications from "@mui/icons-material/Notifications";
import IconWarning from "@mui/icons-material/Warning";
import SettingsIcon from "@mui/icons-material/Settings";
import { handleEntityLink } from "services/helpers";
import { resourceUpdateRequest, resourceListReadSuccess } from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import api from "api";

const StyledPopover = styled(Popover)({
  "& .MuiPopover-paper": {
    display: "flex",
    maxHeight: 480,
    width: 480,
    flexDirection: "column",
    position: "relative",
  },
});

const Body = styled("div")(({ theme }) => ({
  flex: "1 1 auto",
  overflow: "auto",
  overflowX: "hidden",
  overflowY: "auto",
  width: "100%",
  "& .Virualized-List::-webkit-scrollbar": {
    background: "transparent",
    width: 8,
  },
  "& .Virualized-List::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.action.disabled,
    height: 56,
    border: "none",
    display: "none",
  },
  "& .Virualized-List:hover::-webkit-scrollbar-thumb": {
    display: "block",
  },
}));

const Footer = styled("div")(({ theme }) => ({
  flex: "0 1 auto",
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: 8,
  textAlign: "right",
}));

const StyledAccordion = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.active": {
    opacity: 0.26,
    pointerEvents: "none",
    userSelect: "none",
  },
}));

const NotificationBody = React.memo(({ createdAt, level, message }) => {
  const { IconComponent, color, text } = React.useMemo(() => {
    let IconComponent;
    let color;
    let text;

    switch (level) {
      case "info":
        IconComponent = IconInfo;
        color = lightBlue[500];
        text = "information";
        break;
      case "warn":
        IconComponent = IconWarning;
        color = orange[500];
        text = "warning";
        break;
      case "error":
        IconComponent = IconError;
        color = red[500];
        text = "error";
        break;

      default:
        IconComponent = IconInfo;
        color = "inherit";
        text = "notice";
        break;
    }

    return {
      IconComponent,
      color,
      text,
    };
  }, [level]);

  return (
    <div style={{ color }}>
      <Stack direction="row" spacing={1}>
        <IconComponent color="inherit" />
        <Typography variant="body2" color="inherit">
          {text}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          • {moment(createdAt).fromNow()}
        </Typography>
      </Stack>
      <Typography sx={{ flex: "1 1 auto", mt: 1 }} color="textSecondary">
        {message}
      </Typography>
    </div>
  );
});

const NotificationsCenter = () => {
  const { notifications, count, loading } = useSelector(
    (state) => ({
      notifications: fromEntities
        .getDenormalizedList(state, "notifications")
        .filter(({ isNew }) => isNew)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
      count: fromResource.getCount(state, "notifications"),
      loading: pending(state, "notificationsUpdate"),
    }),
    isEqual
  );
  const listRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [active, setActive] = React.useState(null);
  const [expanded, setExpanded] = React.useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const readNotification = React.useCallback(
    (id) =>
      dispatch(resourceUpdateRequest("notifications", id, { isNew: false })),
    [dispatch]
  );

  const readAllNotification = React.useCallback(() => {
    const notificationsRead = notifications.map((notification) => ({
      ...notification,
      isNew: false,
    }));
    return dispatch(
      resourceListReadSuccess(
        "notifications",
        notificationsRead,
        {},
        "notificationsListReadCustom",
        count
      )
    );
  }, [notifications, count, dispatch]);

  const handleOpen = (event) => {
    setExpanded(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReadAllNotifications = React.useCallback(() => {
    setAnchorEl(null);
    api
      .post("/notifications/markallasread", {})
      .then(() => {
        readAllNotification();
      })
      .catch(() => {});
  }, [readAllNotification]);

  const Row = React.useCallback(
    ({ index, style }) => {
      const notification = notifications[index];

      const handleReadNotification = () => {
        setActive(notification.id);
        readNotification(notification.id)
          .then(() => {
            setExpanded(false);
            listRef.current.resetAfterIndex(index, true);
          })
          .catch(() => {});
      };

      const handleNotificationDetails = () => {
        setAnchorEl(null);
        setActive(notification.id);
        readNotification(notification.id).catch(() => {});
        listRef.current.resetAfterIndex(index, true);
      };

      const handleExpand = (event, expanded) => {
        setExpanded(expanded && index);
        listRef.current.resetAfterIndex(index, true);
      };

      return (
        <StyledAccordion
          key={notification.id}
          square
          className={clsx({
            active: active === notification.id && loading,
          })}
          style={style}
          onChange={handleExpand}
          expanded={expanded === index}
        >
          <AccordionSummary
            expandIcon={<IconExpandMore />}
            sx={{
              ".MuiAccordionSummary-content": {
                flexWrap: "wrap",
              },
            }}
          >
            <NotificationBody
              level={notification.level}
              createdAt={notification.createdAt}
              message={notification.message}
            />
          </AccordionSummary>
          <AccordionActions>
            <Button color="primary" onClick={handleReadNotification}>
              Mark as read
            </Button>
            {notification.entityType && notification.entityId && (
              <Button
                color="primary"
                component={NavLink}
                to={handleEntityLink(notification)}
                onClick={handleNotificationDetails}
              >
                View details
              </Button>
            )}
          </AccordionActions>
        </StyledAccordion>
      );
    },
    [active, loading, notifications, readNotification, expanded]
  );

  const getItemSize = React.useCallback(
    (index) => (index === expanded ? 133 : 80),
    [expanded]
  );

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" onClick={handleOpen} size="large">
          <Badge
            badgeContent={
              notifications.length > 9 ? "9+" : notifications.length
            }
            color="secondary"
          >
            <IconNotifications />
          </Badge>
        </IconButton>
      </Tooltip>
      <StyledPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Body>
          {notifications.length > 0 && (
            <List
              className="Virualized-List"
              ref={listRef}
              height={426}
              itemCount={notifications.length}
              width={480}
              itemSize={getItemSize}
            >
              {Row}
            </List>
          )}
          {notifications.length === 0 && (
            <div style={{ padding: 16 }}>
              <Typography color="textSecondary">
                No new notifications
              </Typography>
            </div>
          )}
        </Body>
        <Footer>
          <Grid container>
            <Grid item>
              <IconButton
                sx={{ my: -0.75, ml: -0.75 }}
                component={NavLink}
                to={`/settings/users/me/update#notifications`}
                onClick={handleClose}
                size="large"
              >
                <SettingsIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              {notifications.length !== 0 && (
                <Button onClick={handleReadAllNotifications}>Clear all</Button>
              )}
              <Button
                color="primary"
                component={NavLink}
                to="/notifications"
                onClick={handleClose}
                fullWidth={notifications.length === 0}
              >
                View all
              </Button>
            </Grid>
          </Grid>
        </Footer>
      </StyledPopover>
    </React.Fragment>
  );
};

export default NotificationsCenter;
