import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "mui-rff";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import { handleError, trim } from "services/helpers";
import { notificationSend, resourceCreateRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import {
  createValidator,
  required,
  float,
  minValue,
  minLength,
  maxLength,
} from "services/validation";
import { Confirmation } from "components";
import api from "api";

const calculators = {};

const calculate = (form) => {
  let prevValues = { ...form.getState().initialValues };
  return form.subscribe(
    ({ values }) => {
      form.batch(() => {
        for (const key in calculators) {
          const current = values[key];
          const prev = prevValues[key];
          if (prev !== current) {
            calculators[key](values);
            prevValues = { ...values };
          }
        }
      });
    },
    { values: true }
  );
};

const OrderForm = ({ id, otc = false }) => {
  const { initialValues, otcData } = useSelector(
    (state) => ({
      initialValues: {
        cryptoCurrencySpotPrice: 0,
        type: "BUY",
        fee: 10,
        coinAmount: 0,
        fiatAmount: 0,
        txFee: 0,
        transactionId: "",
        wallet: "",
      },
      otcData: fromCustom.get(state, "orders/otcdata"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    return dispatch(resourceCreateRequest("orders/otc", values))
      .then(({ id }) => {
        dispatch(
          notificationSend("Order updated successfully", { variant: "success" })
        );
        navigate(`/orders/${id}`);
      })
      .catch((error) => {
        dispatch(
          notificationSend("Failed to update order", { variant: "error" })
        );
        return handleError(error);
      });
  };

  const startValues = {};
  const [txStatus, setTxStatus] = useState({
    validating: false,
    isValid: false,
    validated: false,
    validationFailed: false,
    txData: { recipients: {} },
  });
  const { recipients } = txStatus.txData;
  const walletsDetected = Object.keys(recipients).length > 0;

  const validate = createValidator({
    cryptoCurrencySpotPrice: [required, float],
    coinPrice: [required, float],
    coinAmount: [required, float],
    fiatAmount: [required, float, minValue(0)],
    transactionId: [
      minLength(64),
      maxLength(64),
      () => !txStatus.isValid && txStatus.validated && "Invalid Transaction Id",
    ],
    status: [required],
    txFee: [float],
  });

  startValues.coinType = otcData.cryptoCurrencies[0];
  startValues.cryptoCurrencySpotPrice =
    otcData.spotPrices[startValues.coinType];
  startValues.coinPrice = parseFloat(
    (
      startValues.cryptoCurrencySpotPrice +
      startValues.cryptoCurrencySpotPrice * 0.1
    ).toFixed(2)
  );
  startValues.txFee = txStatus.txData.txFee || 0;
  startValues.wallet = walletsDetected ? Object.keys(recipients)[0] || "" : "";
  startValues.status = otcData.statuses.BUY[0];

  calculators.transactionId = (values) => {
    let { transactionId, wallet } = values;
    if (
      !transactionId ||
      transactionId.length < 64 ||
      transactionId.length > 64
    )
      return;
    setTxStatus((txStatus) => ({
      ...txStatus,
      validating: true,
      isValid: false,
      validated: false,
      validationFailed: false,
    }));
    api
      .get(`/wallets/gettxdata/${transactionId}`)
      .then((txData) => {
        const defWallet = Object.keys(txData.recipients)[0];
        if (!txData.recipients[wallet] && defWallet) {
          values.wallet = defWallet;
          wallet = values.wallet;
        }
        if (txData.recipients[wallet]) {
          values.coinAmount = txData.recipients[wallet];
        }
        setTxStatus((txStatus) => ({
          ...txStatus,
          validating: false,
          isValid: true,
          validated: true,
          validationFailed: false,
          txData,
        }));
      })
      .catch(() =>
        setTxStatus((txStatus) => ({
          ...txStatus,
          validating: false,
          isValid: false,
          validated: true,
          txData: { recipients: {} },
        }))
      );
  };

  calculators.wallet = (values) => {
    const { wallet } = values;
    if (txStatus.txData.recipients[wallet]) {
      values.coinAmount = txStatus.txData.recipients[wallet];
      const { coinPrice, coinAmount } = values;
      const fiatAmount = parseFloat((coinPrice * coinAmount).toFixed(2));
      values.fiatAmount = isNaN(fiatAmount) ? 0 : fiatAmount;
    }
  };

  calculators.fiatAmount = (values) => {
    const fiatAmount = parseFloat(values.fiatAmount);
    if (isNaN(fiatAmount)) return;
    values.fiatAmount = fiatAmount;
    if (!txStatus.isValid) {
      const { coinPrice } = values;
      const coinAmount = parseFloat((fiatAmount / coinPrice).toFixed(8));
      if (!isNaN(coinAmount)) values.coinAmount = coinAmount;
    } else {
      const { coinAmount } = values;
      const coinPrice = parseFloat((fiatAmount / coinAmount).toFixed(2));
      if (!isNaN(coinPrice)) {
        values.coinPrice = coinPrice;
        const { fee } = values;
        const spotPrice = parseFloat(
          (coinPrice - coinPrice * (fee / 100)).toFixed(2)
        );
        if (!isNaN(spotPrice)) values.cryptoCurrencySpotPrice = spotPrice;
      }
    }
  };

  calculators.cryptoCurrencySpotPrice = (values) => {
    const spotPrice = parseFloat(values.cryptoCurrencySpotPrice);
    if (!isNaN(spotPrice)) {
      const { fiatAmount, fee } = values;
      values.cryptoCurrencySpotPrice = spotPrice;
      const coinPrice = parseFloat(
        (spotPrice + spotPrice * (fee / 100)).toFixed(2)
      );
      if (!isNaN(coinPrice)) {
        values.coinPrice = coinPrice;
        const coinAmount = parseFloat((fiatAmount / coinPrice).toFixed(8));
        if (!isNaN(coinAmount)) values.coinAmount = coinAmount;
      }
    }
  };

  calculators.fee = (values) => {
    const fee = parseFloat(values.fee);
    if (!isNaN(fee)) {
      const { coinPrice } = values;
      values.fee = fee;
      const cryptoCurrencySpotPrice = parseFloat(
        (coinPrice - coinPrice * (fee / 100)).toFixed(2)
      );
      if (!isNaN(cryptoCurrencySpotPrice)) {
        values.cryptoCurrencySpotPrice = cryptoCurrencySpotPrice;
      }
    }
  };

  calculators.coinPrice = (values) => {
    const coinPrice = parseFloat(values.coinPrice);
    if (!isNaN(coinPrice)) {
      const { fiatAmount, fee } = values;
      values.coinPrice = coinPrice;
      const spotPrice = parseFloat(
        (coinPrice - coinPrice * (fee / 100)).toFixed(2)
      );
      const coinAmount = parseFloat((fiatAmount / coinPrice).toFixed(8));
      if (!isNaN(coinAmount)) values.coinAmount = coinAmount;
      if (!isNaN(spotPrice)) values.cryptoCurrencySpotPrice = spotPrice;
    }
  };

  calculators.coinAmount = (values) => {
    const coinAmount = parseFloat(values.coinAmount);
    const { fee, fiatAmount } = values;
    if (!isNaN(coinAmount)) {
      values.coinAmount = coinAmount;
      const coinPrice = parseFloat((fiatAmount / coinAmount).toFixed(2));
      const cryptoCurrencySpotPrice = parseFloat(
        (coinPrice - coinPrice * (fee / 100)).toFixed(2)
      );
      if (!isNaN(coinPrice)) values.coinPrice = coinPrice;
      if (!isNaN(cryptoCurrencySpotPrice))
        values.cryptoCurrencySpotPrice = cryptoCurrencySpotPrice;
    }
  };

  calculators.type = (values) => {
    const { type, coinPrice } = values;
    values.status = otcData.statuses[type][0];
    let fee = parseFloat(values.fee);
    if (!isNaN(fee)) {
      if (type === "SELL") fee = fee * -1;
      values.fee = fee;
      const cryptoCurrencySpotPrice = parseFloat(
        (coinPrice - coinPrice * (fee / 100)).toFixed(2)
      );
      if (!isNaN(cryptoCurrencySpotPrice)) {
        values.cryptoCurrencySpotPrice = cryptoCurrencySpotPrice;
      }
    }
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <React.Fragment>
          <Form
            initialValues={{ ...initialValues, ...startValues }}
            keepDirtyOnReinitialize
            onSubmit={onSubmit}
            validate={validate}
            decorators={[calculate]}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
              errors,
            }) => (
              <form onSubmit={show(handleSubmit)}>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled={txStatus.validating}
                        name="transactionId"
                        label="Transaction Id"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim }}
                        helperText={
                          txStatus.validated && txStatus.isValid
                            ? `Detected Transaction Fee: ${txStatus.txData.txFee}`
                            : ""
                        }
                        FormHelperTextProps={{ sx: { color: "green" } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {txStatus.validating ? (
                                <CircularProgress size={24} />
                              ) : (
                                ""
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        name="wallet"
                        label="Wallet"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select={walletsDetected}
                        fieldProps={{ format: trim, formatOnBlur: true }}
                        helperText={
                          values.wallet &&
                          txStatus.validated &&
                          txStatus.isValid
                            ? `Detected Transaction Amount: ${
                                txStatus.txData.recipients[values.wallet] ||
                                "missing"
                              }`
                            : ""
                        }
                        FormHelperTextProps={{ sx: { color: "green" } }}
                      >
                        {walletsDetected &&
                          Object.keys(txStatus.txData.recipients).map(
                            (address) => (
                              <MenuItem key={address} value={address}>
                                {address}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                      <TextField
                        name="type"
                        label="Type"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                      >
                        <MenuItem key={"BUY"} value={"BUY"}>
                          {"BUY"}
                        </MenuItem>
                        <MenuItem key={"SELL"} value={"SELL"}>
                          {"SELL"}
                        </MenuItem>
                      </TextField>
                      <TextField
                        name="coinType"
                        label="Cryptocurrency"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        select
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      >
                        {otcData.cryptoCurrencies.map((cryptoCurrency) => (
                          <MenuItem key={cryptoCurrency} value={cryptoCurrency}>
                            {cryptoCurrency}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        name="fiatAmount"
                        label="Fiat Amount"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: "decimal" }}
                      />
                      <TextField
                        name="coinAmount"
                        label="Coin Amount"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: "decimal" }}
                      />
                      <TextField
                        name="cryptoCurrencySpotPrice"
                        label="Spot Price"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        fieldProps={{ formatOnBlur: true }}
                        inputProps={{ inputMode: "decimal" }}
                      />
                      <TextField
                        name="fee"
                        label="Fee(%)"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        inputProps={{ inputMode: "decimal" }}
                      />
                      <TextField
                        name="coinPrice"
                        label="Coin Price"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: "decimal" }}
                      />
                      <TextField
                        name="txFee"
                        label="Transaction Fee"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      />
                      <TextField
                        name="customerKey"
                        label="customerKey"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="status"
                        label="Status"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      >
                        {otcData.statuses[values.type].map((status) => (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open} fullWidth>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    {Object.keys(values).map((key) => (
                      <div key={key}>{`${key}: ${values[key]}`}</div>
                    ))}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </React.Fragment>
      )}
    </Confirmation>
  );
};

export default OrderForm;
