import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { customReadRequest, resourceListReadRequest } from "store/actions";
import { MachinesSettingsForm } from "components";

const MachinesSettingsPage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Machines Settings" />
      <Divider />
      <MachinesSettingsForm />
    </Card>
  );
};

MachinesSettingsPage.get = ({ store }) => {
  return Promise.all([
    store.dispatch(customReadRequest("config/machines")),
    store.dispatch(resourceListReadRequest("systemstats")),
  ]);
};

export default MachinesSettingsPage;
