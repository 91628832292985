import { Fragment, useState } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IpFilterForm from "components/forms/IpFilterForm";
import IconAdd from "@mui/icons-material/Add";
import IconDelete from "@mui/icons-material/Delete";
import {
  notificationSend,
  resourceListReadRequest,
  resourceDeleteRequest,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import {
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Confirmation,
} from "components";

const IpBlacklistListPage = (props) => {
  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "config/ipblacklist"),
      items: fromEntities.getList(
        state,
        "config/ipblacklist",
        fromResource.getList(state, "config/ipblacklist")
      ),
      loading: pending(state, "config/ipblacklistRead"),
    }),
    shallowEqual
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const deleteItem = (id) =>
    dispatch(resourceDeleteRequest("config/ipblacklist", id))
      .then(() => {
        dispatch(
          notificationSend("IP deleted successfully", { variant: "success" })
        );
      })
      .catch(() => {
        dispatch(notificationSend("Failed to delete IP", { variant: "error" }));
      });

  const handleClickIpDelete = (id) => () => {
    deleteItem(id);
  };

  return (
    <Paper elevation={0}>
      <Dialog fullWidth open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Add IP Address</DialogTitle>
        <DialogContent>
          <IpFilterForm
            filterType="blacklist"
            onAdd={() => setDialogOpen(false)}
            onCancel={() => setDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="IP Blacklist"
        toolbar={
          <div>
            <Button startIcon={<IconAdd />} onClick={() => setDialogOpen(true)}>
              Add IP
            </Button>
          </div>
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "70%" }}>
              <TableSortLabel
                active={sort === "ip"}
                component={Link}
                direction={order}
                to={getSortLink("ip")}
              >
                IP
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].ip}</TableCell>}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "20%" }}>
              <TableSortLabel
                active={sort === "description"}
                component={Link}
                direction={order}
                to={getSortLink("description")}
              >
                Description
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].description ? (
                items[rowIndex].description
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Confirmation>
                  {(show, confirm, hide, open) => (
                    <Fragment>
                      <Tooltip enterDelay={500} title="Delete" placement="top">
                        <IconButton
                          onClick={show(
                            handleClickIpDelete(items[rowIndex].id)
                          )}
                          size="large"
                        >
                          <IconDelete />
                        </IconButton>
                      </Tooltip>
                      <Dialog open={open}>
                        <DialogTitle>{`Are you sure you want to delete IP ${items[rowIndex].ip}?`}</DialogTitle>
                        <DialogActions>
                          <Button onClick={hide}>Cancel</Button>
                          <Button onClick={confirm} color="primary" autoFocus>
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Fragment>
                  )}
                </Confirmation>
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

IpBlacklistListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("config/ipblacklist", { _limit: 100, ...query })
  );
};

export default IpBlacklistListPage;
