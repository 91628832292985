import { Fragment, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { pending as pendingFunc } from "redux-saga-thunk";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { hasPermission } from "services/permission";
import { resourceUpdateRequest } from "store/actions";
import { Confirmation } from "components";

const ArchiveButtons = (props) => {
  const { archived, id, resource } = props;
  const [loading, setLoading] = useState(false);

  const { pending } = useSelector(
    (state) => ({
      pending: pendingFunc(state, `${resource}Update`),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const changeArhivedValue = (archived) => {
    setLoading(true);
    dispatch(resourceUpdateRequest(resource, id, { archived }));
  };

  useEffect(() => {
    if (!pending) setLoading(false);
  }, [archived, pending]);

  return (
    <Fragment>
      {hasPermission(resource, "PUT") &&
        (archived ? (
          <LoadingButton
            loading={loading && pending}
            loadingPosition="start"
            startIcon={<UnarchiveIcon />}
            onClick={() => changeArhivedValue(false)}
          >
            Unarchive
          </LoadingButton>
        ) : (
          <Confirmation>
            {(show, confirm, hide, open) => (
              <Fragment>
                <LoadingButton
                  loading={loading && pending}
                  loadingPosition="start"
                  startIcon={<ArchiveIcon />}
                  onClick={show(() => changeArhivedValue(true))}
                >
                  Archive
                </LoadingButton>
                <Dialog open={open}>
                  <DialogTitle>Archive this item?</DialogTitle>
                  <DialogContent>
                    This item will be send to the archive.
                    <br />
                    You won't be able to see it in the list until{" "}
                    <b>Show archived</b> filter is activated.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Archive
                    </Button>
                  </DialogActions>
                </Dialog>
              </Fragment>
            )}
          </Confirmation>
        ))}
    </Fragment>
  );
};

export default ArchiveButtons;
