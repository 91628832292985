import { Fragment, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import IconEdit from "@mui/icons-material/Edit";
import IconOrders from "@mui/icons-material/InsertDriveFile";
import IconCustomers from "@mui/icons-material/People";
import IconAdd from "@mui/icons-material/Add";
import IconFilter from "@mui/icons-material/FilterList";
import { hasPermission } from "services/permission";
import { resourceListReadRequest } from "store/actions";
import { fromCustom, fromEntities, fromResource } from "store/selectors";
import {
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Label,
} from "components";

const filters = {
  archived: {
    label: "Show archived",
    type: "boolean",
  },
};

const CampaignsListPage = () => {
  const [filterOpen, setFilterOpen] = useState(false);

  const { count, items, loading, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      count: fromResource.getCount(state, "campaigns"),
      items: fromEntities.getDenormalizedList(
        state,
        "campaigns",
        fromResource.getList(state, "campaigns")
      ),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Campaigns"
        toolbar={
          <Fragment>
            {hasPermission("campaigns", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/campaigns/create"
              >
                Create Campaign
              </Button>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </Fragment>
        }
        tableBefore={
          <Filter
            filters={filters}
            open={filterOpen}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>ID</TableCell>}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].id}</TableCell>}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "40%" }}>Name</TableCell>}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].name}</TableCell>}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "30%" }}>Code</TableCell>}
          cell={({ rowIndex }) => <TableCell>{items[rowIndex].code}</TableCell>}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Type</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Label
                color={items[rowIndex].type === "CREDIT" ? "success" : "info"}
                fullWidth
              >
                {items[rowIndex].type}
              </Label>
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              Quantity Left
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {`${
                items[rowIndex].quantity - items[rowIndex].quantityIssued
              } of ${items[rowIndex].quantity}`}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              Amount / Percent
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {items[rowIndex].type === "CREDIT"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(items[rowIndex].amount)
                : `${items[rowIndex].percent} %`}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Disposable</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].disposable ? (
                <Label color="success">Yes</Label>
              ) : (
                <Label color="error">No</Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Active</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].active ? (
                <Label color="success">Yes</Label>
              ) : (
                <Label color="error">No</Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell sx={{ whiteSpace: "nowrap", minWidth: 115 }}>
              Created At
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View Orders" placement="top">
                  <IconButton
                    to={`/orders?campaignId=${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconOrders />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  enterDelay={500}
                  title="View Customers"
                  placement="top"
                >
                  <IconButton
                    to={`/customers?campaignId=${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconCustomers />
                  </IconButton>
                </Tooltip>
                {hasPermission("campaigns", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/campaigns/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

CampaignsListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("campaigns", { _limit: 100, ...query })
  );
};

export default CampaignsListPage;
