import * as React from "react";
import throttle from "lodash/throttle";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import { handleEntityLink } from "services/helpers";

const event = new Event("localStorage");
const originalSetItem = localStorage.setItem;
localStorage.setItem = function () {
  window.dispatchEvent(event);
  originalSetItem.apply(this, arguments);
};
const originalRemoveItem = localStorage.removeItem;
localStorage.removeItem = function () {
  window.dispatchEvent(event);
  originalRemoveItem.apply(this, arguments);
};

function DraftsMenu() {
  const [drafts, setDrafts] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    const handleDrafts = throttle(
      () =>
        setDrafts(
          Object.entries(localStorage)
            .filter(([key]) => key.includes("CHNBTS_NOTE_"))
            .map(([, value]) => JSON.parse(value))
        ),
      200
    );

    handleDrafts();
    window.addEventListener("localStorage", handleDrafts, true);

    return () => {
      window.removeEventListener("localStorage", handleDrafts, true);
    };
  }, []);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClear = React.useCallback(() => {
    setAnchorEl(null);
    drafts.forEach(({ entityType, entityId }) => {
      localStorage.removeItem(`CHNBTS_NOTE_${entityType}_${entityId}`);
    });
  }, [drafts]);

  return (
    <React.Fragment>
      <Grow in={Boolean(drafts.length)}>
        <Tooltip title="Unsaved Drafts">
          <IconButton color="inherit" onClick={handleOpen} size="large">
            <Badge badgeContent={drafts.length} color="secondary">
              <DescriptionIcon />
            </Badge>
          </IconButton>
        </Tooltip>
      </Grow>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {drafts.map(({ note, entityType, entityId }) => (
          <MenuItem
            key={`${entityType}-${entityId}`}
            component={Link}
            onClick={handleClose}
            to={`${handleEntityLink({ entityType, entityId })}#noteId=form`}
          >
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 300,
              }}
            >
              {note || <i>no message</i>}
            </ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={handleClear}>Clear all drafts</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default DraftsMenu;
