import * as React from "react";
import moment from "moment";
import orderBy from "lodash/orderBy";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import FlagIcon from "@mui/icons-material/Flag";
import Grid from "@mui/material/Grid";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Typography from "@mui/material/Typography";

function toTitleCase(str) {
  return str
    .split("/")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

function LogNew({ actions }) {
  const groupedActions = React.useMemo(() => {
    const result = [];
    const groupIndexMap = {};

    orderBy(actions, ["id"], ["asc"]).forEach((item) => {
      const group = toTitleCase(item.page);
      const [actor, type, ...other] = item.action.split(":");
      if (groupIndexMap[group] !== undefined) {
        result[groupIndexMap[group]].data.push({
          actor,
          type,
          other,
          ...item,
        });
      } else {
        groupIndexMap[group] = result.length;
        result.push({
          group,
          data: [
            {
              actor,
              type,
              other,
              ...item,
            },
          ],
          createdAt: item.createdAt,
        });
      }
    });
    return result;
  }, [actions]);

  console.log({
    groupedActions,
  });

  return (
    <Grid item xs={12}>
      <Timeline align="left">
        {groupedActions.map(({ group, createdAt, data }, index) => (
          <React.Fragment key={group}>
            <TimelineItem>
              <TimelineOppositeContent style={{ flex: "0 1 120px" }}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ padding: "11px 0" }}
                >
                  {moment(createdAt).format("LTS")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  {index + 1 !== groupedActions.length ? (
                    <ArrowDownwardIcon />
                  ) : (
                    <FlagIcon />
                  )}
                </TimelineDot>
                {index + 1 !== groupedActions.length ? (
                  <TimelineConnector />
                ) : null}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  variant="h5"
                  style={{ marginTop: 9 }}
                  component="div"
                  gutterBottom
                >
                  {`${group} Page`}
                </Typography>

                <div style={{ maxWidth: 600, minWidth: 400 }}>
                  {data.map(
                    ({ action, createdAt, actor, type, other }, index) => {
                      let chipColor = "default";
                      switch (actor) {
                        case "USER":
                          chipColor = "primary";
                          break;
                        case "CASH_DEVICE":
                          chipColor = "success";
                          break;
                        case "KIOSK":
                          chipColor = "secondary";
                          break;
                        case "CAMERA":
                        case "PRINTER":
                          chipColor = "warning";
                          break;

                        default:
                          break;
                      }

                      return (
                        <TimelineItem
                          key={`${actor}-${action}-${createdAt}`}
                          color="primary"
                        >
                          <TimelineOppositeContent
                            style={{ flex: 0, padding: 0 }}
                          />
                          <TimelineSeparator>
                            <TimelineDot />
                            {index < data.length - 1 &&
                              data[index].actor === data[index + 1].actor && (
                                <TimelineConnector />
                              )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <Card variant="outlined">
                              <CardContent style={{ padding: "8px 12px" }}>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  marginBottom={1}
                                >
                                  <Typography
                                    fontSize={14}
                                    color="text.secondary"
                                    fontWeight="bold"
                                    style={{ marginRight: 8 }}
                                  >
                                    {moment(createdAt).format("LTS")}
                                  </Typography>
                                  <Chip
                                    label={actor}
                                    size="small"
                                    color={chipColor}
                                  />
                                </Box>
                                {type === "ERROR" && (
                                  <Alert severity="error">
                                    <AlertTitle>{type}</AlertTitle>
                                    {other.map((s) => (
                                      <Typography component="div">
                                        {s}
                                      </Typography>
                                    ))}
                                  </Alert>
                                )}
                                {type !== "ERROR" && (
                                  <React.Fragment>
                                    <Typography variant="title" component="div">
                                      {type}
                                    </Typography>
                                    {type === "SCANNED" && (
                                      <TextField
                                        variant="outlined"
                                        value={other.join()}
                                        readOnly
                                        fullWidth
                                      />
                                    )}
                                    {type !== "SCANNED" &&
                                      other.map((s) => (
                                        <Typography component="div">
                                          {s}
                                        </Typography>
                                      ))}
                                  </React.Fragment>
                                )}
                              </CardContent>
                            </Card>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    }
                  )}
                </div>
              </TimelineContent>
            </TimelineItem>
          </React.Fragment>
        ))}
      </Timeline>
    </Grid>
  );
}

export default LogNew;
