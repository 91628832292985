import { Fragment } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
// import DialogContent from '@mui/material/DialogContent'
import DialogTitle from "@mui/material/DialogTitle";
import IconAdd from "@mui/icons-material/Add";
import IconDelete from "@mui/icons-material/Delete";
import IconView from "@mui/icons-material/RemoveRedEye";
import { hasPermission } from "services/permission";
import {
  notificationSend,
  resourceListReadRequest,
  resourceDeleteRequest,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import {
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Confirmation,
} from "components";
import { tips } from "services/helpers";

const PhoneBlacklistListPage = (props) => {
  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "phoneblacklist"),
      items: fromEntities.getList(
        state,
        "phoneblacklist",
        fromResource.getList(state, "phoneblacklist")
      ),
      loading: pending(state, "PhoneBlacklistListRead"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const deleteItem = (id) =>
    dispatch(resourceDeleteRequest("phoneblacklist", id))
      .then(() => {
        dispatch(
          notificationSend("Phone deleted successfully", { variant: "success" })
        );
      })
      .catch(() => {
        dispatch(
          notificationSend("Failed to delete phone", { variant: "error" })
        );
      });

  const handleClickNumbersDelete = (id) => () => {
    deleteItem(id);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Phone Blacklist"
        toolbar={
          <div>
            {hasPermission("phoneblacklist", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/phoneblacklist/create"
              >
                Add phone number
              </Button>
            )}
          </div>
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "88%" }}>
              <Tooltip title={tips.phoneBlacklist.phone} arrow>
                <TableSortLabel
                  active={sort === "phone"}
                  component={Link}
                  direction={order}
                  to={getSortLink("phone")}
                >
                  Phone
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].phone || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "10%" }}>Creator</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].creator ? (
                items[rowIndex].creator.userName
              ) : (
                <Typography variant="caption" color="textSecondary">
                  Unknown
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.phoneBlacklist.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View" placement="top">
                  <IconButton
                    to={`/phoneblacklist/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("phoneblacklist", "DELETE") && (
                  <Confirmation>
                    {(show, confirm, hide, open) => (
                      <Fragment>
                        <Tooltip
                          enterDelay={500}
                          title="Delete"
                          placement="top"
                        >
                          <IconButton
                            onClick={show(
                              handleClickNumbersDelete(items[rowIndex].id)
                            )}
                            size="large"
                          >
                            <IconDelete />
                          </IconButton>
                        </Tooltip>
                        <Dialog open={open}>
                          <DialogTitle>{`Are you sure you want to delete phone number #${items[rowIndex].id}?`}</DialogTitle>
                          {/* <DialogContent>
                            </DialogContent> */}
                          <DialogActions>
                            <Button onClick={hide}>Cancel</Button>
                            <Button onClick={confirm} color="primary" autoFocus>
                              Delete
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Fragment>
                    )}
                  </Confirmation>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

PhoneBlacklistListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("phoneblacklist", { _limit: 100, ...query })
  );
};

export default PhoneBlacklistListPage;
