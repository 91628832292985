import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { CampaignsForm } from "components";

const CampaignsCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Create Campaign" />
      <Divider />
      <CampaignsForm />
    </Card>
  );
};

export default CampaignsCreatePage;
