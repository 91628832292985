import { useState } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { QRCodeSVG as QRCode } from "qrcode.react";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconEdit from "@mui/icons-material/Edit";
import IconAdd from "@mui/icons-material/Add";
import IconFilter from "@mui/icons-material/FilterList";
import IconView from "@mui/icons-material/RemoveRedEye";
import { hasPermission } from "services/permission";
import { saveSvgAsPng } from "services/helpers";
import { resourceListReadRequest } from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import {
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
} from "components";

const filters = {
  archived: {
    label: "Show archived",
    type: "boolean",
  },
};

const OperatorsListPage = () => {
  const [open, setOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [operator, setoperator] = useState(null);

  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "operators"),
      items: fromEntities.getList(
        state,
        "operators",
        fromResource.getList(state, "operators")
      ),
      loading: pending(state, "operatorsListRead"),
    }),
    shallowEqual
  );

  const handleOpen = (operator) => () => {
    setoperator(operator);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveImage = () => {
    saveSvgAsPng("operator-qr-code", { name: "operator" });
    setOpen(false);
  };

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Operators"
        toolbar={
          <>
            {hasPermission("operators", "POST") && (
              <div>
                <Button
                  startIcon={<IconAdd />}
                  component={Link}
                  to="/operators/create"
                >
                  Add operator
                </Button>
              </div>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </>
        }
        tableBefore={
          <Filter
            filters={filters}
            open={filterOpen}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "firstName"}
                component={Link}
                direction={order}
                to={getSortLink("firstName")}
              >
                First Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].firstName || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "98%" }}>
              <TableSortLabel
                active={sort === "lastName"}
                component={Link}
                direction={order}
                to={getSortLink("lastName")}
              >
                Last Name
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].lastName || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>QR</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <Button
                size="small"
                variant="outlined"
                onClick={handleOpen(items[rowIndex])}
                fullWidth
              >
                View&nbsp;QR
              </Button>
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <TableSortLabel
                active={sort === "createdAt"}
                component={Link}
                direction={order}
                to={getSortLink("createdAt")}
              >
                Created At
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View" placement="top">
                  <IconButton
                    to={`/operators/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("operators", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/operators/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
      {!!operator && (
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
          <DialogTitle>{`Operator: ${operator.firstName} ${operator.lastName}`}</DialogTitle>
          <DialogContent>
            <QRCode
              id="operator-qr-code"
              value={`operator://${operator.key}`}
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSaveImage}>Save QR as Image</Button>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
};

OperatorsListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("operators", { _limit: 100, ...query })
  );
};

export default OperatorsListPage;
