import React, { Fragment } from "react";
import config from "config";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const LabeledLink = (props) => {
  return (
    <React.Fragment>
      <b
        style={{
          backgroundColor: "lightgreen",
          borderRadius: "2px",
          padding: "2px",
        }}
      >
        GET
      </b>{" "}
      {config.apiUrl}/{props.query}
    </React.Fragment>
  );
};

const ApiDocsReadPage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title={"Basic API Docs"} />
      <Divider />
      <CardContent>
        <List>
          <ListItemText
            primary="URL"
            secondary={config.apiUrl}
            primaryTypographyProps={{ variant: "h6" }}
            secondaryTypographyProps={{ variant: "body2" }}
          />
          <ListItemText
            primary="Authorization"
            secondary={
              <>
                Add{" "}
                <b style={{ backgroundColor: "lightGray" }}>API-Key=YOUR-KEY</b>{" "}
                header
              </>
            }
            primaryTypographyProps={{ variant: "h6" }}
            secondaryTypographyProps={{ variant: "body2" }}
          />
          <ListItemText
            primary="Specific Endpoints"
            primaryTypographyProps={{ variant: "h6" }}
          />
          <ListItem>
            <ListItemText
              primary="Notes"
              secondary={<LabeledLink query="notes?entity=orders&entityId=1" />}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Customer Data"
              secondary={<LabeledLink query="customers/alldata/export" />}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Fragment>
                  <p>Customer Data search</p>
                  <p>
                    {"Allowed fields: "}
                    <b>
                      key, phone, socialSecurityNumber, firstName, lastName,
                      customerKey, orderId
                    </b>
                  </p>
                </Fragment>
              }
              secondary={<LabeledLink query="customers/data?phone=+12345678" />}
            />
          </ListItem>
          <ListItemText
            primary="List"
            primaryTypographyProps={{ variant: "h6" }}
          />
          <ListItem alignItems="flex-start" sx={{ flexDirection: "column" }}>
            <ListItemText
              primary="Machines"
              secondary={<LabeledLink query="machines" />}
            />
            <ListItemText
              primary="Orders"
              secondary={<LabeledLink query="orders" />}
            />
            <ListItemText
              primary="Auto Payments"
              secondary={<LabeledLink query="autopayments" />}
            />
            <ListItemText
              primary="Customers"
              secondary={<LabeledLink query="customers" />}
            />
          </ListItem>
          <ListItemText
            primary="Single"
            primaryTypographyProps={{ variant: "h6" }}
          />
          <ListItem alignItems="flex-start" sx={{ flexDirection: "column" }}>
            <ListItemText
              primary="Machine"
              secondary={<LabeledLink query="machines/machineId" />}
            />
            <ListItemText
              primary="Order"
              secondary={<LabeledLink query="orders/orderId" />}
            />
            <ListItemText
              primary="Auto Payment"
              secondary={<LabeledLink query="autopayments/paymentId" />}
            />
            <ListItemText
              primary="Customer"
              secondary={<LabeledLink query="customers/customerId" />}
            />
          </ListItem>
          <ListItemText
            primary="Pagination"
            primaryTypographyProps={{ variant: "h6" }}
          />
          <ListItem alignItems="flex-start" sx={{ flexDirection: "column" }}>
            <ListItemText
              primary="Count"
              secondary={<LabeledLink query="machines?_limit=20" />}
            />
            <ListItemText
              primary="Page"
              secondary={<LabeledLink query="orders?_page=2" />}
            />
          </ListItem>
          <ListItemText
            primary="Sorting/Filtering"
            primaryTypographyProps={{ variant: "h6" }}
          />
          <ListItem alignItems="flex-start" sx={{ flexDirection: "column" }}>
            <ListItemText
              primary="Sort"
              secondary={
                <LabeledLink query="machines?_order=ASC&_sort=status" />
              }
            />
            <ListItemText
              primary="Filter"
              secondary={
                <LabeledLink query="orders?status=FULFILLED&type=BUY&atmId=109" />
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default ApiDocsReadPage;
