import { Fragment } from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { QRCodeSVG as QRCode } from "qrcode.react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconEdit from "@mui/icons-material/Edit";
import { resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import { saveSvgAsPng } from "services/helpers";
import {
  ArchiveButtons,
  IconSmartEmpties,
  Label,
  ReadonlyTextField,
  ShortCard,
} from "components";

const OperatorsReadPage = (props) => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(state, "operators", params.id),
    }),
    shallowEqual
  );

  const handleSaveImage = (element) => () => {
    saveSvgAsPng(element, { name: "operator" });
  };

  const {
    archived,
    createdAt,
    firstName,
    id,
    key,
    lastName,
    pinCode,
    updatedAt,
  } = detail;

  const code = pinCode.split("");
  const coordinates = [];
  code.forEach((n) => {
    const num = Number(n);
    let x = num % 3;
    let y = (num + 3 - x) / 3 - 1;
    x *= 100;
    y *= 100;

    x += 50;
    y += 50;

    coordinates.push({ num, x, y });
  });

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Operator
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
        action={
          <>
            <ArchiveButtons resource="operators" id={id} archived={archived} />
            {hasPermission("operators", "PUT") && (
              <Button
                startIcon={<IconEdit />}
                component={Link}
                to={`/operators/${id}/update`}
              >
                Edit
              </Button>
            )}
          </>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">General</Typography>
                <ReadonlyTextField
                  label="First Name"
                  value={firstName || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Last Name"
                  value={lastName || <i>[not provided]</i>}
                  fullWidth
                />
                <ReadonlyTextField label="Key" value={key} fullWidth />
                <ReadonlyTextField
                  label="Archived"
                  value={
                    archived ? (
                      <Label color="error">Yes</Label>
                    ) : (
                      <Label color="info">No</Label>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={moment(createdAt).format("L, LTS")}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Updated At"
                  value={moment(updatedAt).format("L, LTS")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">QR Code</Typography>
                <Typography>{`${firstName} ${lastName}`}</Typography>
                <Typography>{`operator://${key}`}</Typography>
                <div style={{ margin: "16px 0" }}>
                  <QRCode
                    id="operator-qr-code"
                    value={`operator://${key}`}
                    width={300}
                    height={300}
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  />
                </div>
                <Button
                  onClick={handleSaveImage("operator-qr-code")}
                  variant="outlined"
                >
                  Save QR as image
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Pattern password</Typography>
                <Typography>
                  Pin code <b>{pinCode}</b>
                </Typography>
                <Typography>
                  <span
                    style={{
                      display: "inline-block",
                      width: 10,
                      height: 10,
                      borderRadius: "50%",
                      backgroundColor: "#32CD32",
                    }}
                  ></span>{" "}
                  - Starting point
                </Typography>
                <div style={{ margin: "16px 0" }}>
                  <svg id="operator-pin-code" height="300" width="300">
                    <rect x="0" y="0" width="300" height="300" fill="#CCCCCC" />
                    <circle cx="50" cy="50" r="15" fill="#FFFFFF" />
                    <circle cx="150" cy="50" r="15" fill="#FFFFFF" />
                    <circle cx="250" cy="50" r="15" fill="#FFFFFF" />
                    <circle cx="50" cy="150" r="15" fill="#FFFFFF" />
                    <circle cx="150" cy="150" r="15" fill="#FFFFFF" />
                    <circle cx="250" cy="150" r="15" fill="#FFFFFF" />
                    <circle cx="50" cy="250" r="15" fill="#FFFFFF" />
                    <circle cx="150" cy="250" r="15" fill="#FFFFFF" />
                    <circle cx="250" cy="250" r="15" fill="#FFFFFF" />
                    {coordinates.map(
                      ({ x, y }, i) =>
                        i < coordinates.length - 1 && (
                          <line
                            key={`${x}${y}`}
                            x1={x}
                            y1={y}
                            x2={coordinates[i + 1].x}
                            y2={coordinates[i + 1].y}
                            stroke="#FFFFFF"
                            strokeWidth="6"
                          />
                        )
                    )}
                    <circle
                      cx={coordinates[0].x}
                      cy={coordinates[0].y}
                      r="15"
                      fill="#32CD32"
                    />
                  </svg>
                </div>
                <Button
                  onClick={handleSaveImage("operator-pin-code")}
                  variant="outlined"
                >
                  Save Pattern as image
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("smartempties", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconSmartEmpties />}
                        to={`/smartempties?operatorId=${id}`}
                        title="Smart Empties"
                        description="View all smart empties done by operator"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

OperatorsReadPage.get = ({ store, params }) => {
  return store.dispatch(resourceDetailReadRequest("operators", params.id));
};

export default OperatorsReadPage;
