import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";

const HomePage = () => {
  return (
    <Paper elevation={0} className="home" style={{ padding: 20, height: 500 }}>
      <p>Hello World</p>
      <Link to="/test">To test page</Link>
    </Paper>
  );
};

HomePage.get = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, 1000);
  });
};

export default HomePage;
