import * as React from "react";
import * as Yup from "yup";
import isEqual from "lodash/isEqual";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import copy from "copy-to-clipboard";
import Autocomplete from "@mui/material/Autocomplete";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { notificationSend, customCreateRequest } from "store/actions";
import { fromCustom } from "store/selectors";

const validationSchema = Yup.object().shape({
  name: Yup.string().required().min(6),
  permissions: Yup.array().required().min(1),
});

const ApiKeysForm = (props) => {
  const { permissions } = useSelector(
    (state = {}) => ({
      permissions: fromCustom.get(state, "apikeys/permissions") || [],
    }),
    isEqual
  );

  const dispatch = useDispatch();
  const [apiKey, setApiKey] = React.useState("");
  const [copied, setCopied] = React.useState(false);

  const {
    control,
    formState: { isDirty, isSubmitting, isValid, errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: { name: "", permissions: [] },
    mode: "onChange",
    reValidateMode: "all",
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });

  const onSubmit = async (values) => {
    try {
      const result = await dispatch(customCreateRequest("apikeys", values));
      setApiKey(result.key);
    } catch (error) {
      dispatch(
        notificationSend("Failed to create API key!", { variant: "error" })
      );
    }
  };

  const handleCopy = () => {
    copy(apiKey);
    setCopied(true);
  };

  const handleNewKeyClick = () => {
    reset();
    setApiKey("");
    setCopied(false);
  };

  return (
    <Container maxWidth="md" style={{ marginLeft: 0 }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
        onReset={() => reset()}
      >
        <Controller
          name="name"
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              label="Name"
              inputRef={ref}
              margin="normal"
              required
              type="text"
              variant="outlined"
              disabled={isSubmitting || Boolean(apiKey)}
              error={Boolean(error)}
              helperText={errors.name && `${errors.name.message}`}
            />
          )}
        />
        <Controller
          name="permissions"
          control={control}
          render={({
            field: { ref, onChange, ...field },
            fieldState: { error },
          }) => (
            <Autocomplete
              multiple
              defaultValue={[]}
              onChange={(e, v) => onChange(v)}
              options={permissions}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              {...field}
              disabled={isSubmitting || Boolean(apiKey)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={Boolean(error)}
                  helperText={error && error.message}
                  inputRef={ref}
                  label="Permissions"
                  required
                />
              )}
            />
          )}
        />
        {apiKey && (
          <TextField
            fullWidth
            margin="normal"
            value={apiKey}
            required
            helperText={copied ? "Copied" : "Please copy key before exit"}
            FormHelperTextProps={{ style: { color: copied ? "green" : "red" } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton title="COPY" onClick={handleCopy}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type="text"
            variant="outlined"
          />
        )}
        {!apiKey && (
          <Button
            sx={{ mb: "8px" }}
            disabled={isSubmitting || !isDirty || !isValid}
            type="submit"
          >
            Save
          </Button>
        )}
        {!apiKey && (
          <Button
            sx={{ mb: "8px" }}
            disabled={!isDirty || isSubmitting}
            type="reset"
          >
            Reset
          </Button>
        )}
        {apiKey && (
          <Button sx={{ mb: "8px" }} onClick={handleNewKeyClick}>
            Add New Key
          </Button>
        )}
      </form>
    </Container>
  );
};

export default ApiKeysForm;
