import {
  ApiDocsReadPage,
  ApiKeysCreatePage,
  ApiKeysListPage,
  AppFrame,
  AutoPaymentsListPage,
  AutoPaymentsReadPage,
  AutoPaymentsRecipientsCreatePage,
  AutoPaymentsRecipientsListPage,
  AutoPaymentsRecipientsUpdatePage,
  CampaignsCreatePage,
  CampaignsListPage,
  CampaignsUpdatePage,
  ChangelogListPage,
  ChangelogReadPage,
  CustomersCreatePage,
  CustomersListPage,
  CustomersReadPage,
  CustomersUpdatePage,
  ExchangesCreatePage,
  ExchangesListPage,
  ExchangesUpdatePage,
  ExpenseLogsCreatePage,
  ExpenseLogsListPage,
  ExpenseLogsReadPage,
  ExpenseLogsUpdatePage,
  ExpensesCreatePage,
  ExpensesListPage,
  ExpensesReadPage,
  ExpensesUpdatePage,
  FulfillmentSettingsPage,
  KYCLimitsListPage,
  LoansAvailableListPage,
  LoansCurrentListPage,
  LoansReadPage,
  MachineEventsCreatePage,
  MachineEventsListPage,
  MachineEventsReadPage,
  MachinesCreatePage,
  MachinesListPage,
  MachinesReadPage,
  MachinesSettingsPage,
  MachinesUpdatePage,
  NotesListPage,
  NotFoundPage,
  NotificationsListPage,
  OperatorsCreatePage,
  OperatorsListPage,
  OperatorsReadPage,
  OperatorsUpdatePage,
  OrderQueueReadPage,
  OrdersListPage,
  OrdersReadPage,
  OrdersUpdatePage,
  OtcOrdersCreatePage,
  PerformanceMovingAveragePage,
  PerformanceReportPage,
  PhoneBlacklistCreatePage,
  PhoneBlacklistListPage,
  PhoneBlacklistReadPage,
  ReportMachineRevenuePage,
  ReportMachinesPage,
  ReportMachinesRentPage,
  ReportRevenuePage,
  SessionsListPage,
  SessionsReadPage,
  SettingsOrganizationPage,
  SettingsSmartEmpties,
  SettingsTwilioPage,
  SignInPage,
  SmartemptiesListPage,
  SmartemptiesReadPage,
  SmartemptiesUpdatePage,
  SummaryPage,
  UserAuthSessionsListPage,
  UsersCreatePage,
  UsersListPage,
  UsersUpdatePage,
  WalletsBlacklistCreatePage,
  WalletsBlacklistListPage,
  WalletsBlacklistReadPage,
  WalletsCreatePage,
  WalletsListPage,
  WalletsReadPage,
  WalletsUpdatePage,
  WalletTransactionsListPage,
  WalletTransactionsReadPage,
  Redirect,
} from "components";

const routes = [
  {
    path: "/",
    exact: true,
    element: (
      <Redirect
        to="summary"
        permissions={[
          ["summary", "GET", "summary"],
          ["machines", "GET", "machines"],
          ["orders", "GET", "orders"],
          ["limitedmachinereport", "GET", "limitedmachinereport"],
        ]}
      />
    ),
  },
  {
    path: "/signin",
    element: <SignInPage />,
    exact: true,
  },
  {
    element: <AppFrame />,
    children: [
      {
        path: "/summary",
        element: <SummaryPage />,
      },
      {
        path: "/machines",
        children: [
          {
            element: <MachinesListPage />,
            index: true,
          },
          {
            path: "create",
            element: <MachinesCreatePage />,
          },
          {
            path: ":id",
            children: [
              {
                element: <MachinesReadPage />,
                index: true,
              },
              {
                path: "update",
                element: <MachinesUpdatePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/notes",
        element: <NotesListPage />,
      },
      {
        path: "/orders",
        children: [
          {
            element: <OrdersListPage />,
            index: true,
          },
          {
            path: "create/otc",
            element: <OtcOrdersCreatePage />,
          },
          {
            path: ":id",
            children: [
              {
                element: <OrdersReadPage />,
                index: true,
              },
              {
                path: ":update",
                element: <OrdersUpdatePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/orderqueue",
        props: { ignore: true },
        children: [
          {
            path: ":id",
            children: [
              {
                element: <OrderQueueReadPage />,
                index: true,
              },
            ],
          },
        ],
      },
      {
        path: "/movingaverage",
        element: <PerformanceMovingAveragePage />,
      },
      {
        path: "/monthly",
        element: <Redirect to="/revenue" />,
      },
      {
        path: "/revenue",
        element: <ReportRevenuePage />,
      },
      {
        path: "/performance",
        element: <PerformanceReportPage />,
      },
      {
        path: "/limitedmachinereport",
        element: <ReportMachinesPage />,
      },
      {
        path: "/machinerevenuereport",
        element: <ReportMachineRevenuePage />,
      },
      {
        path: "/machinerentreport",
        element: <ReportMachinesRentPage />,
      },
      {
        path: "/customers",
        children: [
          {
            element: <CustomersListPage />,
            index: true,
          },
          {
            path: "create",
            element: <CustomersCreatePage />,
          },
          {
            path: ":id",
            children: [
              {
                element: <CustomersReadPage />,
                index: true,
              },
              {
                path: "update",
                element: <CustomersUpdatePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/phoneblacklist",
        children: [
          {
            element: <PhoneBlacklistListPage />,
            index: true,
          },
          {
            path: "create",
            element: <PhoneBlacklistCreatePage />,
          },
          {
            path: ":id",
            element: <PhoneBlacklistReadPage />,
          },
        ],
      },
      {
        path: "/wallets",
        children: [
          {
            index: true,
            element: <WalletsListPage />,
          },
          {
            path: "create",
            element: <WalletsCreatePage />,
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: <WalletsReadPage />,
              },
              {
                path: "update",
                element: <WalletsUpdatePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/smartempties",
        children: [
          {
            element: <SmartemptiesListPage />,
            index: true,
          },
          {
            path: ":id",
            children: [
              {
                element: <SmartemptiesReadPage />,
                index: true,
              },
              {
                path: "update",
                element: <SmartemptiesUpdatePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/operators",
        children: [
          {
            element: <OperatorsListPage />,
            index: true,
          },
          {
            path: "create",
            element: <OperatorsCreatePage />,
          },
          {
            path: ":id",
            children: [
              {
                element: <OperatorsReadPage />,
                index: true,
              },
              {
                path: "update",
                element: <OperatorsUpdatePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/loans",
        children: [
          {
            element: <Redirect to="history" />,
            index: true,
          },
          {
            path: "current",
            children: [
              {
                element: <Redirect to="../../history" />,
                index: true,
              },
              {
                path: ":id",
                element: <Redirect to="../../history/:id" />,
              },
            ],
          },
          {
            path: "available",
            element: <LoansAvailableListPage />,
          },
          {
            path: "history",
            children: [
              {
                element: <LoansCurrentListPage />,
                index: true,
              },
              {
                path: ":id",
                element: <LoansReadPage />,
              },
            ],
          },
          {
            path: ":id",
            element: <Redirect to="../history/:id" />,
          },
        ],
      },
      {
        path: "/campaigns",
        children: [
          {
            element: <CampaignsListPage />,
            index: true,
          },
          {
            path: "create",
            element: <CampaignsCreatePage />,
          },
          {
            path: ":id",
            children: [
              {
                element: <Redirect to="update" />,
                index: true,
              },
              {
                path: "update",
                element: <CampaignsUpdatePage />,
              },
            ],
          },
        ],
      },
      {
        path: "/autopayments",
        children: [
          {
            element: <Redirect to="payments" />,
            index: true,
          },
          {
            path: "payments",
            children: [
              {
                element: <AutoPaymentsListPage />,
                index: true,
              },
              {
                path: ":id",
                element: <AutoPaymentsReadPage />,
              },
            ],
          },
          {
            path: "recipients",
            children: [
              {
                element: <AutoPaymentsRecipientsListPage />,
                index: true,
              },
              {
                path: "create",
                element: <AutoPaymentsRecipientsCreatePage />,
              },
              {
                path: ":id",
                children: [
                  {
                    element: <Redirect to="update" />,
                    index: true,
                  },
                  {
                    path: "update",
                    element: <AutoPaymentsRecipientsUpdatePage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/expenses",
        children: [
          {
            element: <Redirect to="purposes" />,
            index: true,
          },
          {
            path: "purposes",
            children: [
              {
                element: <ExpensesListPage />,
                index: true,
              },
              {
                path: "create",
                element: <ExpensesCreatePage />,
              },
              {
                path: ":id",
                children: [
                  {
                    element: <ExpensesReadPage />,
                    index: true,
                  },
                  {
                    path: "update",
                    element: <ExpensesUpdatePage />,
                  },
                ],
              },
            ],
          },
          {
            path: "logs",
            children: [
              {
                element: <ExpenseLogsListPage />,
                index: true,
              },
              {
                path: "create",
                element: <ExpenseLogsCreatePage />,
              },
              {
                path: ":id",
                children: [
                  {
                    element: <ExpenseLogsReadPage />,
                    index: true,
                  },
                  {
                    path: "update",
                    element: <ExpenseLogsUpdatePage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/settings",
        children: [
          {
            element: (
              <Redirect
                to="organization"
                permissions={[
                  "organization",
                  "GET",
                  "organization",
                  "users",
                  "GET",
                  "users",
                  "config",
                  "GET",
                  "kyclimits",
                  "GET",
                  "ipblacklist",
                  "GET",
                  "apikeys",
                ]}
              />
            ),
            index: true,
          },
          {
            path: "organization",
            element: <SettingsOrganizationPage />,
          },
          {
            path: "fulfillment",
            element: <FulfillmentSettingsPage />,
          },
          {
            path: "kyclimits",
            children: [
              {
                element: <KYCLimitsListPage />,
                index: true,
              },
            ],
          },
          {
            path: "apikeys",
            children: [
              {
                element: <ApiKeysListPage />,
                index: true,
              },
              {
                path: "create",
                element: <ApiKeysCreatePage />,
              },
              {
                path: "docs",
                element: <ApiDocsReadPage />,
              },
            ],
          },
          {
            path: "smartempties",
            children: [
              {
                element: <SettingsSmartEmpties />,
                index: true,
              },
            ],
          },
          {
            path: "userauthsessions",
            children: [
              {
                element: <UserAuthSessionsListPage />,
                index: true,
              },
            ],
          },
          {
            path: "exchanges",
            children: [
              {
                element: <ExchangesListPage />,
                index: true,
              },
              {
                path: "create",
                element: <ExchangesCreatePage />,
              },
              {
                path: ":id",
                children: [
                  {
                    element: <Redirect to="update" />,
                    index: true,
                  },
                  {
                    path: "update",
                    element: <ExchangesUpdatePage />,
                  },
                ],
              },
            ],
          },
          {
            path: "users",
            children: [
              {
                element: <UsersListPage />,
                index: true,
              },
              {
                path: "create",
                element: <UsersCreatePage />,
              },
              {
                path: ":id",
                children: [
                  {
                    element: <Redirect to="update" />,
                    index: true,
                  },
                  {
                    path: "update",
                    element: <UsersUpdatePage />,
                  },
                ],
              },
            ],
          },
          {
            path: "machines",
            element: <MachinesSettingsPage />,
          },
          {
            path: "twilio",
            element: <SettingsTwilioPage />,
          },
        ],
      },
      {
        path: "/machineevents",
        children: [
          {
            element: <MachineEventsListPage />,
            index: true,
          },
          {
            path: "create",
            element: <MachineEventsCreatePage />,
          },
          {
            path: ":id",
            element: <MachineEventsReadPage />,
          },
        ],
      },
      {
        path: "/wallettransactions",
        children: [
          {
            element: <WalletTransactionsListPage />,
            index: true,
          },
          {
            path: ":id",
            element: <WalletTransactionsReadPage />,
          },
        ],
      },
      {
        path: "/sessions",
        children: [
          {
            element: <SessionsListPage />,
            index: true,
          },
          {
            path: ":id",
            element: <SessionsReadPage />,
          },
        ],
      },
      {
        path: "/walletblacklist",
        children: [
          {
            element: <WalletsBlacklistListPage />,
            index: true,
          },
          {
            path: "create",
            element: <WalletsBlacklistCreatePage />,
          },
          {
            path: ":id",
            element: <WalletsBlacklistReadPage />,
          },
        ],
      },
      {
        path: "/changelog",
        children: [
          {
            element: <ChangelogListPage />,
            index: true,
          },
          {
            path: ":id",
            element: <ChangelogReadPage />,
          },
        ],
      },
      {
        path: "/notifications",
        element: <NotificationsListPage />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];
export default routes;
