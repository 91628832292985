import { shallowEqual, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { hasPermission } from "services/permission";
import { customReadRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import { OrganizationForm } from "components";

const SettingsOrganizationPage = () => {
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromCustom.get(state, "organization"),
    }),
    shallowEqual
  );

  return (
    <Card elevation={0}>
      <CardHeader title="Organization settings" />
      <Divider />
      <OrganizationForm
        initialValues={detail}
        hasPutPermission={hasPermission("organization", "PUT")}
      />
    </Card>
  );
};

SettingsOrganizationPage.get = ({ store }) => {
  return store.dispatch(customReadRequest("organization"));
};

export default SettingsOrganizationPage;
