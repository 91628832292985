import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Skeleton from "@mui/material/Skeleton";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { IconPdf, IconWord, IconFile, IconText, IconExcel } from "components";

const Title = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  borderTop: `1px solid ${theme.palette.divider}`,
  color: theme.palette.text.secondary,
  fontSize: "12px",
  overflow: "hidden",
  padding: "4px 8px",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const Image = styled("div")(({ theme }) => ({
  width: 88,
  height: 88,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "rgba(0,0,0,.26)",
}));

const File = styled("div", {
  shouldForwardProp: (prop) => prop !== "color",
})(({ color }) => ({
  width: 88,
  height: 88,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color,
}));

const FilePreview = (props) => {
  const { id, url } = props;

  const { IconComponent, type, color } = React.useMemo(() => {
    const contentType = url.substring(url.lastIndexOf(".") + 1);
    let IconComponent;
    let type;
    let color;
    switch (contentType) {
      case "png":
      case "jpg":
      case "jpeg":
      case "gif":
      case "application_png":
      case "application_jpg":
      case "application_jpeg":
      case "application_gif":
      case "image_png":
      case "image_jpg":
      case "image_jpeg":
      case "image_gif":
        type = "image";
        break;

      case "pdf":
      case "application_pdf":
        IconComponent = IconPdf;
        type = "file";
        color = "#F40F02";
        break;

      case "doc":
      case "docx":
        IconComponent = IconWord;
        type = "file";
        color = "#1651AA";
        break;

      case "csv":
      case "ods":
      case "xls":
      case "xlsb":
      case "xlsm":
      case "xlsx":
        IconComponent = IconExcel;
        type = "file";
        color = "#1D6F42";
        break;

      case "odt":
      case "rtf":
      case "tex":
      case "txt":
      case "wpd":
        IconComponent = IconText;
        type = "file";
        break;

      default:
        IconComponent = IconFile;
        type = "file";
        break;
    }

    return { IconComponent, type, color };
  }, [url]);

  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setLoaded(true);
    setError(true);
  };

  return (
    <Card variant="outlined">
      <CardActionArea
        component="a"
        href={url}
        target="_blank"
        style={{ width: 88 }}
      >
        {type === "image" && !error && (
          <>
            {loaded && (
              <CardMedia
                image={url}
                title={id}
                style={{ width: 88, height: 88 }}
              />
            )}
            {!loaded && (
              <>
                <Skeleton variant="rectangular" width={88} height={88} />
                <img
                  style={{ widht: "100%", height: 0, display: "block" }}
                  onLoad={onLoad}
                  src={url}
                  alt="loading"
                  onError={onError}
                />
              </>
            )}
          </>
        )}
        {type === "image" && loaded && error && (
          <Image>
            <BrokenImageIcon fontSize="large" />
          </Image>
        )}
        {type === "file" && (
          <File color={color}>
            <IconComponent fontSize="large" />
          </File>
        )}
        <Title>{url.substring(url.lastIndexOf("/") + 1)}</Title>
      </CardActionArea>
    </Card>
  );
};

export default FilePreview;
