import { useEffect } from "react";
import { parse } from "qs";
import { useSelector, shallowEqual } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { fromAuth } from "store/selectors";
import { hasPermission } from "services/permission";

const PrivateRoutes = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector(
    (state) => ({
      user: fromAuth.getUser(state),
    }),
    shallowEqual
  );

  const { pathname } = location;

  useEffect(() => {
    const { pathname, search, hash } = location;

    if (isEmpty(user) && pathname !== "/signin") {
      navigate({
        pathname: "/signin",
        search: `?refferer=${btoa(pathname + search + hash)}`,
        state: { from: location },
      });
    } else if (!isEmpty(user) && pathname === "/signin") {
      const { refferer } = parse(search.substring(1));
      let redirect = "/";

      if (refferer) {
        const [pathname, rest] = (refferer ? atob(refferer) : "/").split("?");

        let [search, hash] = rest ? rest.split("#") : [];
        if (search) search = `?${search}`;
        if (hash) hash = `#${hash}`;

        if (hasPermission(pathname.substring(1).split("/")[0], "GET"))
          redirect = { pathname, search, hash };
      } else if (hasPermission("summary", "GET")) redirect = "/summary";
      else if (hasPermission("machines", "GET")) redirect = "/machines";
      else if (hasPermission("orders", "GET")) redirect = "/orders";
      else if (hasPermission("limitedmachinereport", "GET"))
        redirect = "/limitedmachinereport";

      navigate(redirect);
    }
  }, [user, navigate, location]);

  if (isEmpty(user) && pathname !== "/signin") return null;

  return children;
};

export default PrivateRoutes;
