import { useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import {
  resourceListReadRequest,
  resourceDetailReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { ArchiveButtons, AutoPaymentsRecipientsForm } from "components";

const AutoPaymentsRecipientsUpdatePage = () => {
  const params = useParams();

  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "autopaymentsrecipients",
        params.id
      ),
    }),
    shallowEqual
  );

  const { id, archived } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        title="Update Auto Payment"
        action={
          <ArchiveButtons
            resource="autopaymentsrecipients"
            id={id}
            archived={archived}
          />
        }
      />
      <Divider />
      <AutoPaymentsRecipientsForm id={id} />
    </Card>
  );
};

AutoPaymentsRecipientsUpdatePage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(
      resourceDetailReadRequest("autopaymentsrecipients", params.id)
    ),
    store.dispatch(resourceListReadRequest("machines", { _limit: 1000 })),
  ]);
};

export default AutoPaymentsRecipientsUpdatePage;
