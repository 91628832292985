import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import IconClear from "@mui/icons-material/Clear";
import IconSearch from "@mui/icons-material/Search";

function useDebouncedFunction(func, delay) {
  const ref = useRef(null);

  return (...args) => {
    clearTimeout(ref.current);
    ref.current = setTimeout(() => func(...args), delay);
  };
}

const SearchField = ({ param, ...other }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get(param) || "";

  const [searchString, setSearchString] = useState(searchTerm);

  const handleSearch = (value) => {
    if (!value) searchParams.delete(param);
    else searchParams.set(param, value);

    setSearchParams(searchParams);
  };

  const debouncedSearch = useDebouncedFunction(handleSearch, 500);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchString(value);
    debouncedSearch(value);
  };

  const handleClear = () => {
    setSearchString("");
    handleSearch("");
  };

  useEffect(() => {
    if (!searchTerm) setSearchString("");
  }, [searchTerm]);

  return (
    <div>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch color="action" />
            </InputAdornment>
          ),
          endAdornment:
            searchString.length > 0 ? (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} size="small">
                  <IconClear />
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        size="small"
        placeholder=""
        variant="outlined"
        value={searchString}
        onChange={handleChange}
        {...other}
      />
    </div>
  );
};

export default SearchField;
