import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { WalletForm } from "components";
import { customReadRequest } from "store/actions";

const WalletsCreatePage = () => (
  <Card elevation={0}>
    <CardHeader title="Add wallet" />
    <Divider />
    <WalletForm />
  </Card>
);

WalletsCreatePage.get = ({ store }) => {
  return store.dispatch(customReadRequest("wallets/providers"));
};

export default WalletsCreatePage;
