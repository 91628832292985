import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const routes = [
  {
    path: "/settings",
    props: { ignore: true },
  },
  {
    path: "/orderqueue",
    props: { ignore: true },
  },
];

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <MuiBreadcrumbs
      maxItems={window.innerWidth >= 720 ? 8 : 3}
      itemsAfterCollapse={3}
      separator={<NavigateNextIcon fontSize="small" />}
      style={{ color: "inherit", marginTop: 2 }}
    >
      {breadcrumbs.map(({ match, key, breadcrumb, ignore }, index) => {
        if (index > 0 && index < breadcrumbs.length - 1 && !ignore)
          return (
            <MuiLink
              key={key}
              color="inherit"
              component={NavLink}
              to={match.pathname}
              underline="hover"
            >
              {breadcrumb}
            </MuiLink>
          );
        if (index > 0)
          return (
            <Typography key={key} color="inherit">
              {breadcrumb}
            </Typography>
          );
        return <span key={key} />;
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
