import { Fragment } from "react";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField, Checkboxes, DatePicker } from "mui-rff";
import { handleError, trim } from "services/helpers";
import {
  notificationSend,
  resourceUpdateRequest,
  resourceCreateRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { DiffBlock, Confirmation, PhoneField } from "components";

const CustomerForm = ({ id }) => {
  const {
    initialValues = {
      address: "",
      approved: false,
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      idNumber: "",
      occupation: "",
      phone: "",
      socialSecurityNumber: "",
      userName: "",
      zip: "",
    },
  } = useSelector(
    (state) => ({
      initialValues: fromEntities.getDetail(state, "customers", id),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    let action = resourceCreateRequest("customers", values);
    if (id) action = resourceUpdateRequest("customers", id, values);

    return dispatch(action)
      .then((resultId) => {
        dispatch(
          notificationSend(
            `Customer ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        if (!id) navigate(`/customers/${resultId}/update`);
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} customer`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)}>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="h6" gutterBottom>
                        Details
                      </Typography>
                      <TextField
                        name="firstName"
                        label="First name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="middleName"
                        label="Middle Name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="lastName"
                        label="Last name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <PhoneField name="phone" label="Phone" />
                      <TextField
                        name="address"
                        label="Address"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="zip"
                        label="ZIP"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="userName"
                        label="Username"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim }}
                      />
                      <TextField
                        name="email"
                        label="Email"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="idNumber"
                        label="ID Number"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="socialSecurityNumber"
                        label="Social Security Number"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="occupation"
                        label="Occupation"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <DatePicker
                        name="idExpirationDate"
                        label="ID Expiration Date"
                        TextFieldProps={{ margin: "normal" }}
                        fullWidth
                        fieldProps={{
                          parse: (date) =>
                            date && moment(date).format("MM/DD/YYYY"),
                        }}
                      />
                      <Checkboxes
                        name="approved"
                        data={{ label: "Approved", value: "approved" }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

export default CustomerForm;
