import * as React from "react";
import * as Yup from "yup";
import { useReactToPrint } from "react-to-print";
import { pending } from "redux-saga-thunk";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import PrintIcon from "@mui/icons-material/Print";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MapIcon from "@mui/icons-material/Map";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { customCreateRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import { ReadonlyTextField, RHFTextField } from "components";
import Map from "./map";

const validationSchema = Yup.object().shape({
  minCashAmount: Yup.number().integer().required(),
  startLocation: Yup.object().shape({
    latitude: Yup.number().required(),
    longitude: Yup.number().required(),
  }),
});

/**
 * Converts the given number of seconds into a human-readable time format.
 *
 * @param {number} seconds - The total number of seconds to be formatted.
 * @returns {string} The formatted time string.
 */
function formatSeconds(seconds) {
  // Calculate days, hours, minutes, and remaining seconds
  const days = Math.floor(seconds / (60 * 60 * 24));
  const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const remainingSeconds = seconds % 60;

  // Build the formatted string
  const formattedTime = [
    days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "",
    hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}` : "",
    minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "",
    remainingSeconds > 0
      ? `${remainingSeconds} second${remainingSeconds > 1 ? "s" : ""}`
      : "",
  ]
    .filter(Boolean) // Remove empty strings
    .join(" "); // Join the non-empty strings with a space

  return formattedTime || "0 seconds"; // Return the formatted time or a default value
}

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const printRef = React.useRef();
  const mapRef = React.useRef();

  const handlePrint = useReactToPrint({
    documentTitle: "Smart Empties Route",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  const { detail, loading } = useSelector(
    (state = {}) => ({
      detail: fromCustom.get(state, "machines/smartemptyroute") || {},
      loading: pending(state, "machines/smartemptyrouteCreate"),
    }),
    shallowEqual
  );

  const {
    locations = [],
    routeDuration = 0,
    totalDistance = 0,
    totalDuration = 0,
    gasConsumed = 0,
  } = detail;

  const smartEmptyDuration = totalDuration - routeDuration;

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      minCashAmount: 1000,
      startLocation: null,
    },
    mode: "onChange",
    criteriaMode: "all",
    resolver: yupResolver(validationSchema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });

  const handleClickOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const handleExited = React.useCallback(() => {
    setLoaded(false);
    reset();
    mapRef.current.blockMap(false);
    mapRef.current.clear();
  }, [reset]);

  const handleStartSet = React.useCallback(
    (position) => {
      setValue("startLocation", position, { shouldValidate: true });
    },
    [setValue]
  );

  const hadleRowMouseEnter = React.useCallback(
    (index) => () => {
      mapRef.current.markerMouseEnter(index);
    },
    []
  );

  const hadleRowMouseLeave = React.useCallback(
    (index) => () => {
      mapRef.current.markerMouseLeave(index);
    },
    []
  );

  const dispatch = useDispatch();

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const response = await dispatch(
          customCreateRequest("machines/smartemptyroute", values)
        );
        setLoaded(true);

        mapRef.current.blockMap(true);

        mapRef.current.addMarkers(response.locations.slice(0, -1));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  const googleUrl = React.useMemo(() => {
    if (locations.length < 3) return "#";

    const waypoints = locations
      .slice(1, -1)
      .map(({ latitude, longitude }) => `${latitude},${longitude}`)
      .join("|");

    const origin = `${locations[0].latitude},${locations[0].longitude}`;
    const url = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${origin}&waypoints=${waypoints}`;

    return url;
  }, [locations]);

  return (
    <React.Fragment>
      <Button onClick={handleClickOpen} startIcon={<MapIcon />}>
        Cash Collection Map
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        TransitionProps={{
          onExited: handleExited,
        }}
        fullWidth
        maxWidth="md"
      >
        <form
          ref={printRef}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          autoComplete="off"
          onReset={() => reset()}
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              flex: "0 0 auto",
            }}
          >
            <Map ref={mapRef} onStartSet={handleStartSet} />
          </Box>
          <DialogContent sx={{ position: "relative" }}>
            {loading && (
              <Box
                sx={{
                  width: "100%",
                  position: "absolute",
                  zIndex: 999,
                  top: 0,
                  left: 0,
                }}
              >
                <LinearProgress />
              </Box>
            )}
            <Collapse in={!loaded}>
              <Typography variant="subtitle" gutterBottom>
                Please set a <b>minimum amount</b> and choose a{" "}
                <b>starting location</b> on the map to proceed.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    control={control}
                    name="minCashAmount"
                    label="Minimum amount"
                    helperText="Required cash amount that should be at least present in the ATM to be included in the report."
                    type="number"
                    disabled={isSubmitting}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">USD</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTextField
                    control={control}
                    name="startLocation.latitude"
                    label="Latitude"
                    helperText="Starting location latitude, enter or click on the map"
                    type="number"
                    disabled={isSubmitting}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <RHFTextField
                    control={control}
                    name="startLocation.longitude"
                    label="Longitude"
                    helperText="Starting location longitude, enter or click on the map"
                    type="number"
                    disabled={isSubmitting}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Collapse>

            <Collapse in={loaded}>
              <React.Fragment>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <ReadonlyTextField
                      label="Smart Empties Duration"
                      value={`≈${formatSeconds(smartEmptyDuration)}`}
                      fullWidth
                    />
                    <ReadonlyTextField
                      label="Travel Duration"
                      value={`≈${formatSeconds(routeDuration)}`}
                      fullWidth
                    />
                    <ReadonlyTextField
                      label="Total Duration"
                      value={`≈${formatSeconds(totalDuration)}`}
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <ReadonlyTextField
                      label="Total Distance Approx., miles"
                      value={totalDistance}
                      fullWidth
                    />
                    <ReadonlyTextField
                      label="Gas consumed Approx., gallons"
                      value={gasConsumed}
                      fullWidth
                    />
                    <ReadonlyTextField
                      label="Goodgle Maps Link"
                      value={
                        <MuiLink href={googleUrl} target="_blank">
                          Open Google Maps Directions{" "}
                          <OpenInNewIcon
                            sx={{
                              fontSize: "inherit",
                              verticalAlign: "middle",
                            }}
                          />
                        </MuiLink>
                      }
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <TableContainer
                  sx={{ mx: -3, width: "auto", breakBefore: "page" }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "0%" }}>Index</TableCell>
                        <TableCell sx={{ width: "auto" }}>Name</TableCell>
                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                          Cash amount
                        </TableCell>
                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                          Cumulative Cash Amount
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locations.slice(0, -1).map((row, index) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={index}
                          onMouseEnter={hadleRowMouseEnter(index)}
                          onMouseLeave={hadleRowMouseLeave(index)}
                        >
                          <TableCell>{index}</TableCell>
                          <TableCell>
                            {index === 0 ? "Starting Location" : row.name}
                          </TableCell>
                          <TableCell align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(row.totalCash)}
                          </TableCell>
                          <TableCell align="right">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(row.cumulativeCashAmount)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </React.Fragment>
            </Collapse>
          </DialogContent>
          <DialogActions
            sx={{
              "@media print": {
                "&": {
                  display: "none",
                },
              },
            }}
          >
            {loaded && (
              <Button startIcon={<ArrowBackIcon />} onClick={handleExited}>
                Back
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <Button onClick={handleClose}>Close</Button>
            {!loaded && (
              <Button
                type="submit"
                disabled={!isValid || isSubmitting}
                variant="outlined"
              >
                Build route
              </Button>
            )}
            {loaded && (
              <Button
                startIcon={<PrintIcon />}
                variant="outlined"
                onClick={() => {
                  handlePrint(null, () => printRef.current);
                }}
              >
                Print Route
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
