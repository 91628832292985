import { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { DatePicker, TextField } from "mui-rff";
import { handleError } from "services/helpers";
import {
  createValidator,
  required,
  minValue,
  float,
} from "services/validation";
import {
  notificationSend,
  resourceCreateRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import { DiffBlock, Confirmation } from "components";
import { parse } from "qs";

const validate = createValidator({
  expenseId: [required],
  amount: [float, minValue(1)],
});

const statusVariants = ["UNPAID", "PAID"];

const ExpenseLogsForm = ({ id }) => {
  let {
    expenses,
    initialValues = {
      paymentDate: new Date(),
      status: statusVariants[0],
    },
  } = useSelector(
    (state = {}) => ({
      initialValues: fromEntities.getDetail(state, "expenselogs", id),
      expenses: fromEntities.getList(
        state,
        "expenses",
        fromResource.getList(state, "expenses")
      ),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let expenseTargetSelectable = true;
  const newPayment = !!id;

  if (!initialValues.expenseId) {
    const { expenseId: qsExpenseId } = parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (qsExpenseId) initialValues.expenseId = parseInt(qsExpenseId);
  }

  if (initialValues.expenseId) {
    expenses = expenses.filter((item) => item.id === initialValues.expenseId);
    expenseTargetSelectable = false;
  }

  const [expenseTarget, setExpenseTarget] = useState(expenses[0]);

  if (!initialValues.amount) initialValues.amount = expenseTarget.amount;

  const handleExpenseChange = ({ target }) => {
    const { value } = target;
    setExpenseTarget(expenses.find(({ id }) => id === value));
  };

  const expenseTargetHelperText = `Amount indicated in the purpose of payment: ${expenseTarget.amount}`;

  initialValues.expenseId = expenseTarget.id;

  const onSubmit = (values) => {
    let action = resourceCreateRequest("expenselogs", values);
    if (id) action = resourceUpdateRequest("expenselogs", id, values);

    return dispatch(action)
      .then(() => {
        dispatch(
          notificationSend(
            `Expense Payment ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        navigate(`/expenses/logs${id ? "/" + id : ""}`);
      })
      .catch((error) => {
        dispatch(
          notificationSend(
            `Failed to  ${id ? "update" : "create"} expense payment`,
            { variant: "error" }
          )
        );
        return handleError(error);
      });
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2} xs={6} direction="column">
                    <Grid item>
                      <TextField
                        onChange={handleExpenseChange}
                        name="expenseId"
                        label="Expense Purpose"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                        inputProps={{ readOnly: !expenseTargetSelectable }}
                        helperText={expenseTargetHelperText}
                      >
                        {expenses.map(({ id, name }) => (
                          <MenuItem key={id} value={id}>
                            {name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        name="amount"
                        label="Amount"
                        type="number"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        inputProps={{ inputMode: "numeric", step: "1" }}
                      />
                      <Grid container spacing={2}>
                        <Grid item>
                          <DatePicker
                            name="paymentDate"
                            label="Payment Date"
                            TextFieldProps={{ margin: "normal" }}
                            fullWidth
                            fieldProps={{
                              parse: (date) => date && date.toISOString(),
                            }}
                          />
                          <TextField
                            name="status"
                            label="Status"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select
                          >
                            {statusVariants.map((status) => (
                              <MenuItem key={status} value={status}>
                                {status}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      newPayment &&
                      (pristine ||
                        submitting ||
                        (!valid && !dirtySinceLastSubmit))
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </>
      )}
    </Confirmation>
  );
};

export default ExpenseLogsForm;
