import { useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { ArchiveButtons, ExpensesForm } from "components";

const ExpensesUpdatePage = () => {
  const params = useParams();

  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(state, "expenses", params.id),
    }),
    shallowEqual
  );

  const { id, archived } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        title="Update Expense"
        action={
          <ArchiveButtons resource="expenses" id={id} archived={archived} />
        }
      />
      <Divider />
      <ExpensesForm id={id} />
    </Card>
  );
};

ExpensesUpdatePage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("expenses", params.id)),
  ]);
};

export default ExpensesUpdatePage;
