import { Fragment } from "react";
import clsx from "clsx";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import IconExport from "@mui/icons-material/SaveAlt";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import IconView from "@mui/icons-material/RemoveRedEye";
import { resourceListReadRequest } from "store/actions";
import { fromCustom, fromEntities, fromResource } from "store/selectors";
import { tips } from "services/helpers";
import {
  BlockchainLink,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
  Label,
} from "components";
import { apiUrl } from "config";

const AutoPaymentsListPage = () => {
  const location = useLocation();

  const { count, items, loading, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      count: fromResource.getCount(state, "autopayments"),
      items: fromEntities.getDenormalizedList(
        state,
        "autopayments",
        fromResource.getList(state, "autopayments")
      ),
      loading: pending(state, "autopaymentsListRead"),
    }),
    shallowEqual
  );

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Payments"
        toolbar={
          <Fragment>
            <Button
              startIcon={<IconExport />}
              component="a"
              target="_blank"
              href={`${apiUrl}/autopayments/export${location.search}`}
            >
              Export .xls
            </Button>
          </Fragment>
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsPayments.recipient} arrow>
                <TableSortLabel
                  active={sort === "recipientId"}
                  component={Link}
                  direction={order}
                  to={getSortLink("recipientId")}
                >
                  Recipient
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].recipient ? (
                <MuiLink
                  component={Link}
                  to={`/autopayments/recipients/${items[rowIndex].recipient.id}/update`}
                  underline="hover"
                  whiteSpace="nowrap"
                >
                  {items[rowIndex].recipient.name}
                </MuiLink>
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "98%" }}>
              <Tooltip title={tips.autopaymentsPayments.wallet} arrow>
                <TableSortLabel
                  active={sort === "wallet"}
                  component={Link}
                  direction={order}
                  to={getSortLink("wallet")}
                >
                  Wallet
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              <BlockchainLink
                wallet={items[rowIndex].wallet}
                coinType={items[rowIndex].currency.replace(baseCurrency, "BTC")}
              />
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsPayments.price} arrow>
                <TableSortLabel
                  active={sort === "currencyPrice"}
                  component={Link}
                  direction={order}
                  to={getSortLink("currencyPrice")}
                >
                  Price
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: baseCurrency,
              }).format(items[rowIndex].currencyPrice)}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsPayments.amount} arrow>
                <TableSortLabel
                  active={sort === "amount"}
                  component={Link}
                  direction={order}
                  to={getSortLink("amount")}
                >
                  Amount
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {items[rowIndex].partialAmount
                ? `${items[rowIndex].partialAmount}/${items[rowIndex].amount}`
                : items[rowIndex].amount}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsPayments.currency} arrow>
                <TableSortLabel
                  active={sort === "currency"}
                  component={Link}
                  direction={order}
                  to={getSortLink("currency")}
                >
                  Currency
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].currency}</TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell>Tx Fee</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell sx={{ whiteSpace: "nowrap" }}>{`${
              items[rowIndex].txFee
            } ${items[rowIndex].currency.replace(
              baseCurrency,
              "BTC"
            )}`}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsPayments.status} arrow>
                <TableSortLabel
                  active={sort === "status"}
                  component={Link}
                  direction={order}
                  to={getSortLink("status")}
                >
                  Status
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].status && (
                <Label
                  color={
                    clsx({
                      error:
                        items[rowIndex].status === "ERROR" ||
                        items[rowIndex].status === "NO FUNDS",
                      warning: items[rowIndex].status === "DELAYED",
                      info: items[rowIndex].status === "PAYING",
                      success: items[rowIndex].status === "PAID",
                    }) || "default"
                  }
                  fullWidth
                >
                  {items[rowIndex].status}
                </Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.autopaymentsPayments.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.autopaymentsPayments.paidDate} arrow>
                <TableSortLabel
                  active={sort === "paidAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("paidAt")}
                >
                  Paid Date
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].paidAt ? (
                moment(items[rowIndex].paidAt).format("L \nLTS")
              ) : (
                <i>[not paid]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip enterDelay={500} title="View details" placement="top">
                  <IconButton
                    to={`/autopayments/payments/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

AutoPaymentsListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("autopayments", { _limit: 100, ...query })
  );
};

export default AutoPaymentsListPage;
