import { useState } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconAdd from "@mui/icons-material/Add";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconEdit from "@mui/icons-material/Edit";
import IconFilter from "@mui/icons-material/FilterList";
import { resourceListReadRequest } from "store/actions";
import { hasPermission } from "services/permission";
import { fromEntities, fromResource } from "store/selectors";
import { tips } from "services/helpers";
import {
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
} from "components";

const filters = {
  archived: {
    label: "Show archived",
    type: "boolean",
  },
};

const ExpensesListPage = () => {
  const [filterOpen, setFilterOpen] = useState(false);

  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "expenses"),
      items: fromEntities.getList(
        state,
        "expenses",
        fromResource.getList(state, "expenses")
      ),
      loading: pending(state, "expensesListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Expense Purposes"
        toolbar={
          <>
            {hasPermission("expenses", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/expenses/purposes/create"
              >
                Add Expense Purpose
              </Button>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </>
        }
        tableBefore={
          <Filter
            filters={filters}
            open={filterOpen}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "80%" }}>
              <Tooltip title={tips.expenses.name} arrow>
                <TableSortLabel
                  active={sort === "name"}
                  component={Link}
                  direction={order}
                  to={getSortLink("name")}
                >
                  Name
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].name || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "10%", align: "left" }}>
              <Tooltip title={tips.expenses.amount} arrow>
                <TableSortLabel
                  active={sort === "amount"}
                  component={Link}
                  direction={order}
                  to={getSortLink("amount")}
                >
                  Amount
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].amount}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.expenses.paymentDay} arrow>
                <TableSortLabel
                  active={sort === "paymentDay"}
                  component={Link}
                  direction={order}
                  to={getSortLink("paymentDay")}
                >
                  Payment Day
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].paymentDay ? (
                items[rowIndex].paymentDay
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "10%" }}>
              <Tooltip title={tips.expenses.paymerntType}>
                <span>Payment Type</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].paymentType}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.expenses.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                <Tooltip
                  enterDelay={500}
                  title="View Expense Purpose"
                  placement="top"
                >
                  <IconButton
                    to={`/expenses/purposes/${items[rowIndex].id}`}
                    component={Link}
                    size="large"
                  >
                    <IconView />
                  </IconButton>
                </Tooltip>
                {hasPermission("expenses", "PUT") && (
                  <Tooltip
                    enterDelay={500}
                    title="Edit Expense Purpose"
                    placement="top"
                  >
                    <IconButton
                      to={`/expenses/purposes/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
                {hasPermission("expenselogs", "POST") && (
                  <Tooltip
                    enterDelay={500}
                    title="Add Expense Payment"
                    placement="top"
                  >
                    <IconButton
                      to={`/expenses/logs/create?expenseId=${items[rowIndex].id}`}
                      component={Link}
                      size="large"
                    >
                      <IconAdd />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

ExpensesListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("expenses", { _limit: 100, ...query })
  );
};

export default ExpensesListPage;
