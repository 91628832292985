import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
// import orderBy from 'lodash/orderBy'
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import MuiLink from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";
import { customReadRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import { BlockchainLink, Label } from "components";
import { ProgressBar } from "../../common/renderProgress";
import { tips } from "services/helpers";
import { CardContent } from "@mui/material";

const getStatusColor = (status) => {
  if (/active/i.test(status)) return "success";
  if (/busy/i.test(status)) return "success";
  if (/error/i.test(status)) return "error";
  if (/config/i.test(status)) return "info";
  if (/init/i.test(status)) return "info";
  if (/online/i.test(status)) return "info";
  if (/maintenance/i.test(status)) return "warning";
  return "default";
};

const SummaryPage = () => {
  const {
    ipAddress,
    machines,
    machineStats,
    coins,
    latestOrders,
    wallets,
    baseCurrency,
  } = useSelector((state = {}) => {
    const { ipAddress, machines, machineStats, coins, latestOrders, wallets } =
      fromCustom.get(state, "summary");

    return {
      baseCurrency: fromCustom.getBaseCurrency(state),
      ipAddress,
      machines, //: orderBy(machines, ['status', 'totalNotesCount'], ['desc', 'desc']),
      machineStats,
      coins,
      latestOrders,
      wallets,
    };
  }, shallowEqual);

  const machinesTotalCash = useMemo(() => {
    const result = [];

    machines.forEach(({ fiatCurrency, recyclerCash, cashbox }) => {
      const totals = result.find(
        (total) => total.fiatCurrency === fiatCurrency
      );
      if (totals) totals.summ += recyclerCash + cashbox;
      else {
        result.push({
          fiatCurrency,
          summ: recyclerCash + cashbox,
        });
      }
    });

    return result;
  }, [machines]);

  const walletsCurrencies = useMemo(
    () =>
      wallets.reduce((accum, { currency }) => {
        if (accum.indexOf(currency) === -1) accum.push(currency);
        return accum;
      }, []),
    [wallets]
  );

  const walletsBalances = useMemo(() => {
    const result = walletsCurrencies.map((currency) => ({
      currency,
      balance: 0,
      pendingBalance: 0,
    }));
    wallets.forEach(({ balance, currency, pendingBalance }) => {
      const walletBalance = result.find(
        ({ currency: cur }) => currency === cur
      );
      walletBalance.balance += parseFloat(balance);
      walletBalance.pendingBalance = parseFloat(
        (
          parseFloat(walletBalance.pendingBalance) + parseFloat(pendingBalance)
        ).toFixed(8)
      );
    });
    return result;
  }, [walletsCurrencies, wallets]);

  return (
    <Box padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardHeader
                      title="Machines Count"
                      titleTypographyProps={{ variant: "subtitle1" }}
                      style={{ paddingBottom: 0 }}
                    />
                    <CardContent
                      sx={{ display: "flex", gap: "3px", flexWrap: "wrap" }}
                    >
                      <Label
                        component={Link}
                        to="/machines?generalStatus=ACTIVE"
                        color={"success"}
                      >{`ACTIVE: ${machineStats.active}`}</Label>
                      <Label
                        component={Link}
                        to="/machines?generalStatus=ERROR"
                        color={"error"}
                      >{`ERROR: ${machineStats.error}`}</Label>
                      <Label
                        component={Link}
                        to="/machines?generalStatus=MAITENANCE"
                        color={"warning"}
                      >{`MAITENANCE: ${machineStats.maintenance}`}</Label>
                      <Label
                        component={Link}
                        to="/machines?generalStatus=OFFLINELT24H"
                        color={"error"}
                      >{`OFFLINE<24H: ${machineStats.offline}`}</Label>
                      <Label
                        component={Link}
                        to="/machines?generalStatus=OFFLINEGT24H"
                      >{`OFFLINE>24H: ${machineStats.offline24h}`}</Label>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardHeader
                      title="Machines Total Cash"
                      titleTypographyProps={{ variant: "subtitle1" }}
                      style={{ paddingBottom: 0 }}
                    />
                    <CardContent>
                      {machinesTotalCash.map(({ fiatCurrency, summ }) => (
                        <Typography key={fiatCurrency} variant="h6">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: fiatCurrency,
                          }).format(summ)}
                        </Typography>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card variant="outlined" style={{ height: "100%" }}>
                    <CardHeader
                      title="Rates"
                      titleTypographyProps={{ variant: "subtitle1" }}
                      style={{ paddingBottom: 0 }}
                    />
                    <List>
                      {coins.map(({ name, symbol, price }) => (
                        <ListItem key={symbol}>
                          <ListItemAvatar>
                            <Avatar
                              src={`svg/color/${symbol.toLowerCase()}.svg`}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={`1 ${name} ≈ `}
                            primaryTypographyProps={{
                              color: "textSecondary",
                              component: "span",
                              display: "inline",
                            }}
                            secondary={new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(price)}
                            secondaryTypographyProps={{
                              color: "textPrimary",
                              component: "span",
                              display: "inline",
                              variant: "subtitle1",
                            }}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card variant="outlined" style={{ height: "100%" }}>
                    <CardHeader
                      title="Fiat Amount"
                      titleTypographyProps={{ variant: "subtitle1" }}
                      style={{ paddingBottom: 0 }}
                    />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>{"Day"}</TableCell>
                          <TableCell>{"Week"}</TableCell>
                          <TableCell>{"Month"}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{"Total"}</TableCell>
                          <TableCell component={Link} to="/orders?latest=day">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.day.totalFiatAmount)}
                          </TableCell>
                          <TableCell component={Link} to="/orders?latest=week">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.week.totalFiatAmount)}
                          </TableCell>
                          <TableCell component={Link} to="/orders?latest=month">
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.month.totalFiatAmount)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Fulfilled"}</TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_fulfilled=day"
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.day.fulfilledFiatAmount)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_fulfilled=week"
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.week.fulfilledFiatAmount)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_fulfilled=month"
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.month.fulfilledFiatAmount)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Pending"}</TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_unresolved=day"
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.day.pendingFiatAmount)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_unresolved=week"
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.week.pendingFiatAmount)}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_unresolved=month"
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(latestOrders.month.pendingFiatAmount)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card variant="outlined" style={{ height: "100%" }}>
                    <CardHeader
                      title="Latest Orders"
                      titleTypographyProps={{ variant: "subtitle1" }}
                      style={{ paddingBottom: 0 }}
                    />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>{"Day"}</TableCell>
                          <TableCell>{"Week"}</TableCell>
                          <TableCell>{"Month"}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{"Total"}</TableCell>
                          <TableCell component={Link} to="/orders?latest=day">
                            {latestOrders.day.count}
                          </TableCell>
                          <TableCell component={Link} to="/orders?latest=week">
                            {latestOrders.week.count}
                          </TableCell>
                          <TableCell component={Link} to="/orders?latest=month">
                            {latestOrders.month.count}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Fulfilled"}</TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_fulfilled=day"
                          >
                            {latestOrders.day.fulfilled}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_fulfilled=week"
                          >
                            {latestOrders.week.fulfilled}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_fulfilled=month"
                          >
                            {latestOrders.month.fulfilled}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{"Unresolved"}</TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_unresolved=day"
                          >
                            {latestOrders.day.unresolved}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_unresolved=week"
                          >
                            {latestOrders.week.unresolved}
                          </TableCell>
                          <TableCell
                            component={Link}
                            to="/orders?latest_unresolved=month"
                          >
                            {latestOrders.month.unresolved}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader
                  title="Information"
                  titleTypographyProps={{ variant: "subtitle1" }}
                  style={{ paddingBottom: 0 }}
                />
                <Table>
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>Fulfillment server IP:</TableCell>
                      <TableCell>{ipAddress}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: "1%" }}>Wallet</TableCell>
                      <TableCell>Address</TableCell>
                      <TableCell sx={{ width: "1%" }}>Balance</TableCell>
                      <TableCell sx={{ width: "1%" }}>Pending</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {wallets.map(
                      ({
                        id,
                        name,
                        currency,
                        balance,
                        pendingBalance,
                        address,
                        initialized,
                      }) => (
                        <TableRow key={id}>
                          <TableCell sx={{ py: 0, whiteSpace: "nowrap" }}>
                            <MuiLink
                              to={`/wallets/${id}`}
                              component={Link}
                              title="View wallet details"
                              underline="hover"
                            >
                              {name}
                            </MuiLink>
                            <Typography
                              color="text.secondary"
                              display="inline"
                              variant="body2"
                            >{`, ${currency}`}</Typography>
                            {!initialized && (
                              <Tooltip
                                title="View more in details"
                                placement="top"
                              >
                                <Chip
                                  size="small"
                                  color="error"
                                  icon={<ErrorIcon />}
                                  label="Failed to initialize"
                                  sx={{ ml: 1 }}
                                />
                              </Tooltip>
                            )}
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>
                            <BlockchainLink
                              wallet={address}
                              coinType={currency}
                            />
                          </TableCell>
                          <TableCell align="right">{balance}</TableCell>
                          <TableCell align="right">{pendingBalance}</TableCell>
                        </TableRow>
                      )
                    )}
                    {walletsBalances.map(
                      ({ balance, currency, pendingBalance }) => (
                        <TableRow
                          key={currency}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            colSpan={2}
                            variant="head"
                            size="small"
                            sx={{ paddingRight: 2 }}
                          >
                            Total, {currency}
                          </TableCell>
                          <TableCell
                            align="right"
                            size="small"
                            sx={{ paddingRight: 2 }}
                          >
                            {balance}
                          </TableCell>
                          <TableCell
                            align="right"
                            size="small"
                            sx={{ paddingRight: 2 }}
                          >
                            {pendingBalance}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardHeader
                  title="Machines stats"
                  titleTypographyProps={{ variant: "subtitle1" }}
                  style={{ paddingBottom: 0 }}
                />
                <div style={{ overflow: "auto" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>
                          <Tooltip title={tips.summaryMachines.name} arrow>
                            <span>Name</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ width: "1%" }}>
                          <Tooltip title={tips.summaryMachines.status} arrow>
                            <span>Status</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
                          <Tooltip title={tips.summaryMachines.upTime} arrow>
                            <span>Up Time(72h)</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right" sx={{ width: "1%" }}>
                          <Tooltip title={tips.summaryMachines.notes} arrow>
                            <span>Notes</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ whiteSpace: "nowrap", width: "1%" }}
                        >
                          <Tooltip title={tips.summaryMachines.recycler} arrow>
                            <span>Recycler</span>
                          </Tooltip>
                          {` / `}
                          <Tooltip title={tips.summaryMachines.cashbox} arrow>
                            <span>Cashbox</span>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right" sx={{ width: "1%" }}>
                          <Tooltip title={tips.summaryMachines.total} arrow>
                            <span>Total</span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {machines.map(
                        ({
                          id,
                          name,
                          status,
                          upTime,
                          recyclerCash,
                          cashbox,
                          fiatCurrency,
                          totalNotesCount,
                        }) => (
                          <TableRow
                            key={id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{id}</TableCell>
                            <TableCell sx={{ py: 0 }}>
                              <MuiLink
                                component={Link}
                                to={`/machines/${id}`}
                                underline="hover"
                              >
                                {name}
                              </MuiLink>
                            </TableCell>
                            <TableCell sx={{ py: 0 }}>
                              <Label color={getStatusColor(status)} fullWidth>
                                {status}
                              </Label>
                            </TableCell>
                            <TableCell sx={{ py: 0 }}>
                              {<ProgressBar value={upTime || 0} />}
                            </TableCell>
                            <TableCell align="right">
                              {totalNotesCount}
                            </TableCell>
                            <TableCell align="right">{`${recyclerCash} / ${cashbox}`}</TableCell>
                            <TableCell
                              align="right"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: fiatCurrency,
                              }).format(recyclerCash + cashbox)}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

SummaryPage.get = ({ store }) => {
  return store.dispatch(customReadRequest("summary"));
};

export default SummaryPage;
