import { Fragment } from "react";
import clsx from "clsx";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconWallets from "@mui/icons-material/AccountBalanceWallet";
import IconWalletTransaction from "@mui/icons-material/Toc";
import { resourceDetailReadRequest } from "store/actions";
import { fromCustom, fromEntities } from "store/selectors";
import { hasPermission } from "services/permission";
import {
  BlockchainLink,
  Label,
  ReadonlyTextField,
  ShortCard,
} from "components";

const LoansReadPage = () => {
  const params = useParams();
  const { detail, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      detail: fromEntities.getDetail(state, "loans", params.id),
    }),
    shallowEqual
  );

  const {
    amount,
    borrowPrice,
    borrowCost,
    borrowTx,
    borrowTxFee,
    createdAt,
    currency,
    externalId,
    id,
    lenderWallet,
    percent,
    period,
    repaidAt,
    repaidFromWalletId,
    repaidFromWallet,
    repayDate,
    repayPrice,
    repayCost,
    repayTx,
    status,
    updatedAt,
  } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Loan
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >
              {`#${id}`}
            </Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} xl={4}>
                <Typography variant="h6">General</Typography>
                <ReadonlyTextField label="Loan Id" value={id} fullWidth />
                <ReadonlyTextField
                  label="External Id"
                  value={externalId}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Amount"
                  value={`${amount} ${currency}`}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Status"
                  value={
                    <Label
                      color={clsx({
                        error: status === "ACTIVE" || status === "NO FUNDS",
                        warning: status === "REPAYING",
                        info: status === "REPAID",
                        success: status === "CLOSED",
                      })}
                    >
                      {status}
                    </Label>
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Percent"
                  value={`${percent} %`}
                  fullWidth
                />
                <ReadonlyTextField label="Period" value={period} fullWidth />
                <ReadonlyTextField
                  label="Created At"
                  value={
                    createdAt ? (
                      moment(createdAt).format("L, LTS")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Updated At"
                  value={
                    updatedAt ? (
                      moment(updatedAt).format("L, LTS")
                    ) : (
                      <i>[missing]</i>
                    )
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} xl={4}>
                <Typography variant="h6">Borrow information</Typography>
                <ReadonlyTextField
                  label="Lender Wallet"
                  value={
                    <BlockchainLink wallet={lenderWallet} coinType={currency} />
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label={
                    ["CLOSED", "REPAID"].includes(status)
                      ? "Loan Cost"
                      : "Loan Cost(Borrow)"
                  }
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(
                    ["CLOSED", "REPAID"].includes(status)
                      ? repayCost - borrowCost
                      : borrowCost
                  )}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Borrow Price"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(borrowPrice)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Borrow Cost"
                  value={new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: baseCurrency,
                  }).format(borrowCost)}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Borrow Transaction"
                  value={
                    <BlockchainLink
                      transaction={borrowTx}
                      coinType={currency}
                    />
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Borrow Transaction Fee"
                  value={borrowTxFee}
                  fullWidth
                />
                <Typography variant="h6">Repay information</Typography>
                {repaidFromWalletId && (
                  <ReadonlyTextField
                    label="Repaid From Wallet"
                    value={
                      <BlockchainLink
                        wallet={repaidFromWallet.address}
                        coinType={currency}
                      />
                    }
                    fullWidth
                  />
                )}
                {!!repayPrice && (
                  <ReadonlyTextField
                    label="Repay Price"
                    value={new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: baseCurrency,
                    }).format(repayPrice)}
                    fullWidth
                  />
                )}
                {!!repayCost && (
                  <ReadonlyTextField
                    label="Repay Cost"
                    value={new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: baseCurrency,
                    }).format(repayCost)}
                    fullWidth
                  />
                )}
                {repayTx && (
                  <ReadonlyTextField
                    label="Repay Transaction"
                    value={
                      <BlockchainLink
                        transaction={repayTx}
                        coinType={currency}
                      />
                    }
                    fullWidth
                  />
                )}
                {repayDate && (
                  <ReadonlyTextField
                    label="Scheduled Repay Date"
                    value={moment(repayDate).format("L, LTS")}
                    fullWidth
                  />
                )}
                {repaidAt && (
                  <ReadonlyTextField
                    label="Repaid At"
                    value={
                      repaidAt ? (
                        moment(repaidAt).format("L, LTS")
                      ) : (
                        <i>[missing]</i>
                      )
                    }
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("wallettransactions", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconWalletTransaction />}
                        to={`/wallettransactions?entityType=loans&entityId=${id}`}
                        title="Transactions"
                        description="View transactions related to this loan"
                      />
                    </Grid>
                  )}
                  {repaidFromWalletId && hasPermission("wallets", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconWallets />}
                        to={`/wallets/${repaidFromWalletId}`}
                        title="Wallet"
                        description="View the wallet this loan was repaid from"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

LoansReadPage.get = ({ store, params }) => {
  return store.dispatch(resourceDetailReadRequest("loans", params.id));
};

export default LoansReadPage;
