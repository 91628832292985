import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { resourceListReadRequest } from "store/actions";
import { AutoPaymentsRecipientsForm } from "components";

const AutoPaymentsRecipientsCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Add Auto Payments Recipient" />
      <Divider />
      <AutoPaymentsRecipientsForm />
    </Card>
  );
};

AutoPaymentsRecipientsCreatePage.get = ({ store }) => {
  return store.dispatch(resourceListReadRequest("machines", { _limit: 1000 }));
};

export default AutoPaymentsRecipientsCreatePage;
