import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { resourceListReadRequest } from "store/actions";
import { MachineEventForm } from "components";

const MachineEventsCreatePage = () => {
  return (
    <Card elevation={0}>
      <CardHeader title="Create machine event" />
      <Divider />
      <MachineEventForm />
    </Card>
  );
};

MachineEventsCreatePage.get = ({ store }) => {
  return store.dispatch(resourceListReadRequest("machines", { _limit: 1000 }));
};

export default MachineEventsCreatePage;
