import { Fragment, useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { QRCodeSVG as QRCode } from "qrcode.react";
import { createMachine, state, transition, invoke, reduce } from "robot3";
import { createUseMachine } from "robot-hooks";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { TextField } from "mui-rff";
import {
  createValidator,
  required,
  minLength,
  maxLength,
  integer,
} from "services/validation";
import api from "api";

const useMachine = createUseMachine(useEffect, useState);

const machine = createMachine({
  start: state(
    transition("enable", "gettingOtpUrl"),
    transition("disable", "entercode"),
    transition("cancel", "start")
  ),
  gettingOtpUrl: invoke(
    () => api.get("/users/twowayauthenable"),
    transition(
      "done",
      "instruction",
      reduce((ctx, ev) => ({
        ...ctx,
        otpAuthUrl: ev.data.otpAuthUrl,
      }))
    ),
    transition("error", "error")
  ),
  instruction: state(
    transition("cancel", "start"),
    transition(
      "verify",
      "verifyingOtpCode",
      reduce((ctx) => ({
        ...ctx,
        error: false,
      }))
    )
  ),
  verifyingOtpCode: invoke(
    (ctx, ev) => api.post("/users/twowayauthverify", ev.data),
    transition(
      "done",
      "done",
      reduce((ctx) => ({
        ...ctx,
        error: false,
      }))
    ),
    transition(
      "error",
      "instruction",
      reduce((ctx) => ({
        ...ctx,
        error: true,
      }))
    )
  ),
  error: state(
    transition("retry", "verifyOtpCode"),
    transition("cancel", "start")
  ),

  entercode: state(
    transition("cancel", "start"),
    transition(
      "verify",
      "disableOtp",
      reduce((ctx) => ({
        ...ctx,
        error: false,
      }))
    )
  ),
  disableOtp: invoke(
    (ctx, ev) => api.post("/users/twowayauthdisable", ev.data),
    transition(
      "done",
      "done",
      reduce((ctx) => ({
        ...ctx,
        error: false,
      }))
    ),
    transition(
      "error",
      "entercode",
      reduce((ctx) => ({
        ...ctx,
        error: true,
      }))
    )
  ),

  done: state(transition("cancel", "start")),
});

function UserOTPDialog({ open, variant, onClose }) {
  const [current, send] = useMachine(machine);

  useEffect(() => {
    if (open) send(variant);
    else send("cancel");
  }, [open, send, variant]);

  const handleCancel = () => {
    send("cancel");
    onClose(variant !== "enable");
  };

  const handleRetry = () => {
    send("retry");
  };

  const promiseRef = useRef();
  useEffect(() => {
    if (
      (current.name === "instruction" || current.name === "entercode") &&
      typeof promiseRef.current === "function"
    ) {
      promiseRef.current(
        current.context.error ? { token: "Invalid token" } : {}
      );
    }
  }, [current.name, current.context.error]);

  useEffect(() => {
    if (current.name === "done") {
      onClose(variant === "enable");
    }
  }, [onClose, current.name, variant]);

  const handleSubmit = (data) =>
    new Promise((resolve) => {
      send({ type: "verify", data });
      promiseRef.current = resolve;
    });

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      {["gettingOtpUrl", "verifyingOtpCode", "disableOtp"].includes(
        current.name
      ) && (
        <Fragment>
          {current.name === "gettingOtpUrl" && (
            <DialogTitle>Generating 2-FA url</DialogTitle>
          )}
          {current.name === "verifyingOtpCode" && (
            <DialogTitle>Verifying 2-FA code</DialogTitle>
          )}
          {current.name === "disableOtp" && (
            <DialogTitle>Disabling 2-FA code</DialogTitle>
          )}
          <DialogContent>
            <Box textAlign="center">
              <CircularProgress />
            </Box>
          </DialogContent>
        </Fragment>
      )}

      {current.name === "error" && (
        <Fragment>
          <DialogTitle color="error">Error generation 2-FA url</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can retry or contact support if issue persists,
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleRetry}>Retry</Button>
          </DialogActions>
        </Fragment>
      )}

      <Form
        initialValues={{
          token: "",
        }}
        validate={createValidator({
          token: [integer, minLength(6), maxLength(6), required],
        })}
        onSubmit={handleSubmit}
        render={({
          handleSubmit,
          pristine,
          submitting,
          valid,
          dirtySinceLastSubmit,
          hasValidationErrors,
        }) => (
          <Fragment>
            {current.name === "instruction" && (
              <Fragment>
                <DialogTitle>Enable 2-factor authentification</DialogTitle>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <DialogContent>
                    <Typography variant="subtitle1" gutterBottom>
                      1. Scan QR with authenticator app
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", my: 2 }}
                    >
                      <QRCode
                        style={{ maxWidth: 128 }}
                        value={current.context.otpAuthUrl}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      />
                    </Box>
                    <Typography variant="subtitle1" gutterBottom>
                      2. Send authentification code you get in an app
                    </Typography>
                    <TextField
                      name="token"
                      placeholder="Code"
                      fullWidth
                      autoFocus
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                      type="submit"
                      disabled={
                        pristine ||
                        submitting ||
                        (!valid && !dirtySinceLastSubmit) ||
                        hasValidationErrors
                      }
                    >
                      Send
                    </Button>
                  </DialogActions>
                </form>
              </Fragment>
            )}

            {current.name === "entercode" && (
              <Fragment>
                <DialogTitle>Disable 2-factor authentification</DialogTitle>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <DialogContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Send authentification code you get in an app
                    </Typography>
                    <TextField
                      name="token"
                      placeholder="Code"
                      fullWidth
                      autoFocus
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button
                      type="submit"
                      disabled={
                        pristine ||
                        submitting ||
                        (!valid && !dirtySinceLastSubmit) ||
                        hasValidationErrors
                      }
                    >
                      Send
                    </Button>
                  </DialogActions>
                </form>
              </Fragment>
            )}
          </Fragment>
        )}
      />
    </Dialog>
  );
}

export default UserOTPDialog;
