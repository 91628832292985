import mergeWith from "lodash/mergeWith";
import { initialState } from "./selectors";
import { ENTITIES_RECEIVE } from "./actions";

const reducer = (state = initialState, { type, payload }) => {
  if (type === ENTITIES_RECEIVE) {
    return mergeWith({}, state, payload, (objValue, srcValue) => {
      if (Array.isArray(srcValue)) {
        return srcValue;
      }
      return undefined;
    });
  }
  return state;
};

export default reducer;
