import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { Autocomplete, Checkboxes, DatePicker, TextField } from "mui-rff";
import { handleError, trim } from "services/helpers";
import {
  createValidator,
  required,
  minValue,
  maxValue,
  integer,
  float,
} from "services/validation";
import {
  notificationSend,
  resourceCreateRequest,
  resourceUpdateRequest,
} from "store/actions";
import { fromEntities, fromResource, fromCustom } from "store/selectors";
import { DiffBlock, Confirmation } from "components";

const paymentTypeVariants = ["PERCENT", "FLAT"];
const periodVariants = ["DAILY", "WEEKLY", "MONTHLY"];
const currencyHelperText =
  "* Payments in fiat currency will be converted at the exchange rate at the time of creation";

const validate = createValidator({
  flatAmount: [float, minValue(0)],
  name: [required],
  percent: [integer, minValue(1), maxValue(99)],
  wallet: [required],
});

const AutoPaymentsRecipientsForm = ({ id }) => {
  const {
    atms = [],
    baseCurrency,
    initialValues = {
      archvied: false,
      active: true,
      atms: [],
      currency: "BTC",
      flatAmount: 0,
      minAmount: 0.1,
      paymentType: paymentTypeVariants[0],
      percent: 1,
      period: periodVariants[1],
      startDate: Date.now(),
    },
  } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      atms: fromEntities.getList(
        state,
        "machines",
        fromResource.getList(state, "machines")
      ),
      initialValues: fromEntities.getDetail(
        state,
        "autopaymentsrecipients",
        id
      ),
    }),
    shallowEqual
  );

  const currencyVariants = ["BTC", baseCurrency];
  if (baseCurrency !== "USD") currencyVariants.push("USD");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (values) => {
    let action = resourceCreateRequest("autopaymentsrecipients", values);
    if (id)
      action = resourceUpdateRequest("autopaymentsrecipients", id, values);

    return dispatch(action)
      .then(() => {
        dispatch(
          notificationSend(
            `Recipient ${id ? "updated" : "created"} successfully`,
            { variant: "success" }
          )
        );
        navigate("/autopayments/recipients");
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  ${id ? "update" : "create"} recipient`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate>
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="name"
                        label="Name"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <Checkboxes
                        name="active"
                        data={{ label: "Active", value: "active" }}
                        formControlProps={{ fullWidth: true }}
                      />
                      <TextField
                        name="currency"
                        label="Currency"
                        variant="outlined"
                        margin="normal"
                        helperText={
                          values.paymentType === "FLAT"
                            ? currencyHelperText
                            : ""
                        }
                        fullWidth
                        select
                        disabled={values.paymentType === "PERCENT"}
                      >
                        {currencyVariants.map((currencyType) => (
                          <MenuItem key={currencyType} value={currencyType}>
                            {currencyType}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        name="wallet"
                        label="Wallet Address"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        fieldProps={{ format: trim }}
                      />
                      <TextField
                        name="period"
                        label="Period"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                      >
                        {periodVariants.map((period) => (
                          <MenuItem key={period} value={period}>
                            {period}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Grid container spacing={2}>
                        <Grid item sm={6}>
                          <TextField
                            name="paymentType"
                            label="Payment Type"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            select={!id}
                            inputProps={{ readOnly: !!id }}
                          >
                            {paymentTypeVariants.map((paymentType) => (
                              <MenuItem key={paymentType} value={paymentType}>
                                {paymentType}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item sm={6}>
                          {values.paymentType === "PERCENT" && (
                            <TextField
                              name="percent"
                              label="Percent"
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                            />
                          )}
                          {values.paymentType === "FLAT" && (
                            <TextField
                              name="flatAmount"
                              label="Flat Amount"
                              type="number"
                              variant="outlined"
                              margin="normal"
                              fullWidth
                              inputProps={{
                                inputMode: "decimal",
                                step: "0.0001",
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {values.currency}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                      {values.paymentType === "PERCENT" && (
                        <>
                          <TextField
                            name="minAmount"
                            label="Minimal Amount"
                            type="number"
                            variant="outlined"
                            margin="normal"
                            fullWidth
                          />
                          <Checkboxes
                            name="includeAll"
                            data={{
                              label: "Include all ATMs",
                              value: "includeAll",
                            }}
                            formControlProps={{ fullWidth: true }}
                          />
                          <Checkboxes
                            name="otcOrdersInclude"
                            data={{
                              label: "Include OTC Orders",
                              value: "otcOrdersInclude",
                            }}
                            formControlProps={{ fullWidth: true }}
                          />
                          {values.includeAll !== true && (
                            <Autocomplete
                              filterSelectedOptions
                              getOptionLabel={(item) =>
                                `${item.name} (${item.id || item.atmId})`
                              }
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              label="ATMs"
                              multiple
                              name="atms"
                              options={atms}
                              textFieldProps={{
                                fullWidth: true,
                                margin: "normal",
                                placeholder: "Add atm",
                                variant: "outlined",
                              }}
                            />
                          )}
                        </>
                      )}
                      <DatePicker
                        name="startDate"
                        label="Start Date"
                        TextFieldProps={{ margin: "normal" }}
                        fullWidth
                        fieldProps={{
                          parse: (date) => date && date.toISOString(),
                        }}
                      />
                      <Checkboxes
                        name="archived"
                        helperText="Hide from the main list"
                        data={{ label: "Archived", value: "archived" }}
                        formControlProps={{ fullWidth: true }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </>
      )}
    </Confirmation>
  );
};

export default AutoPaymentsRecipientsForm;
