/* eslint camelcase: ["error", {allow: ["date_gte", "date_lte"]}] */
import { parse, stringify } from "qs";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { DataGrid } from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import IconNavigateBefore from "@mui/icons-material/NavigateBefore";
import IconNavigateNext from "@mui/icons-material/NavigateNext";
import { customReadRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import { Label, ReadonlyTextField } from "components";
import { TableHead } from "@mui/material";

const columns = [
  { field: "orderId", headerName: "OrderID", flex: 1 },
  {
    field: "atmId",
    headerName: "ATM",
    flex: 1,
    valueGetter: (params) => `${params.row.atm.name} (${params.row.atm.atmId})`,
  },
  {
    field: "type",
    headerName: "Type",
    flex: 0.3,
    renderCell: (params) => (
      <Label
        variant="outlined"
        color={params.value === "BUY" ? "success" : "error"}
        fullWidth
      >
        {params.value}
      </Label>
    ),
  },
  {
    field: "coinAmount",
    headerName: "Coin Amount",
    flex: 0.5,
    type: "number",
    valueGetter: (params) => `${params.row.coinAmount} ${params.row.coinType}`,
  },
  {
    field: "fiatAmount",
    headerName: "Fiat Amount",
    flex: 0.5,
    type: "number",
    valueGetter: (params) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: params.row.fiatType,
      }).format(params.row.fiatAmount),
  },
  {
    field: "profit",
    headerName: "Profit",
    flex: 0.5,
    type: "number",
    valueGetter: (params) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: params.row.fiatType,
      }).format(params.row.profit),
  },
  {
    field: "createdAt",
    headerName: "Created At",
    type: "dateTime",
    width: 125,
    renderCell: (params) => (
      <div style={{ whiteSpace: "break-spaces" }}>
        {moment(params.value).format("L \nLTS")}
      </div>
    ),
  },
];

const ReportMachinesPage = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryDate = searchParams.get("date");
  const query = parse(location.search.substr(1));
  // const range = query.range || 'month'

  const { report, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      report: fromCustom.get(state, "limitedmachinereport"),
    }),
    shallowEqual
  );

  const handleDateChange = (value) => {
    searchParams.set("date", moment(value).format("L").replace(/\//g, "-"));
    setSearchParams(searchParams);
  };

  let date = new Date(queryDate ? queryDate : Date.now());
  date.setDate(1);
  const prevDate = new Date(date.setMonth(date.getMonth() - 1));
  const currentDate = new Date(date.setMonth(date.getMonth() + 1));
  const nextDate = new Date(date.setMonth(date.getMonth() + 1));

  const today = new Date();
  today.setDate(1);
  const disabledNext = date >= today;

  const { orders, machines, stats } = report;
  machines.sort((a, b) => b.performance.revenue - a.performance.revenue);

  const { total, buy, sell, grossRevenue } = stats;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "-8px -12px -8px 0",
          "@media print": {
            display: "none",
          },
          "& > *": {
            marginLeft: 2,
          },
        }}
        title={"Machines Monthly report"}
        action={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <DatePicker
              disableFuture
              onChange={handleDateChange}
              openTo="month"
              value={currentDate}
              // variant="inline"
              views={["year", "month"]}
              renderInput={(props) => (
                <TextField size="small" variant="outlined" {...props} />
              )}
            />
            <Tooltip
              title={prevDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
              placement="top"
            >
              <IconButton
                component={NavLink}
                to={{
                  ...location,
                  search: `?${stringify(
                    {
                      ...query,
                      date: moment(prevDate).format("L").replace(/\//g, "-"),
                    },
                    { arrayFormat: "repeat", skipNulls: true }
                  )}`,
                }}
                size="large"
              >
                <IconNavigateBefore />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={nextDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
              placement="top"
            >
              <IconButton
                component={NavLink}
                to={{
                  ...location,
                  search: `?${stringify(
                    {
                      ...query,
                      date: moment(nextDate).format("L").replace(/\//g, "-"),
                    },
                    { arrayFormat: "repeat", skipNulls: true }
                  )}`,
                }}
                disabled={disabledNext}
                style={disabledNext ? { pointerEvents: "none" } : {}}
                size="large"
              >
                <IconNavigateNext />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Revenue report
                </Typography>
                <div style={{ margin: "0 -16px -24px", paddingTop: 10 }}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ width: "40%" }}
                          >
                            <b>Gross revenue</b>
                          </TableCell>
                          <TableCell>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: baseCurrency,
                            }).format(grossRevenue)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Total orders: {total}
                </Typography>
                <ReadonlyTextField
                  label={`Buy orders: ${buy}`}
                  value={
                    <LinearProgress
                      variant="determinate"
                      value={(buy * 100) / total || 0}
                      style={{ margin: "10px 0" }}
                    />
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label={`Sell orders: ${sell}`}
                  value={
                    <LinearProgress
                      variant="determinate"
                      value={(sell * 100) / total || 0}
                      style={{ margin: "10px 0" }}
                    />
                  }
                  fullWidth
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Machines performance
            </Typography>
            <div style={{ paddingTop: 10 }}>
              <Card variant="outlined" component={TableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Total orders</TableCell>
                      <TableCell
                        align="right"
                        sx={{ whiteSpace: "nowrap", width: "15%" }}
                      >
                        Buy orders
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ whiteSpace: "nowrap", width: "15%" }}
                      >
                        Sell orders
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ whiteSpace: "nowrap", width: "15%" }}
                      >
                        Net Revenue
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ whiteSpace: "nowrap", width: "15%" }}
                      >
                        Gross Revenue
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& > tr:last-child > td, & > tr:last-child > th": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    {machines.map(({ id, name, performance, orders }) => (
                      <TableRow key={id}>
                        <TableCell component="th" scope="row">
                          <b>{name}</b>
                        </TableCell>
                        <TableCell align="right">{orders.total}</TableCell>
                        <TableCell align="right">{orders.buy}</TableCell>
                        <TableCell align="right">{orders.sell}</TableCell>
                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: baseCurrency,
                          }).format(performance.revenue)}
                        </TableCell>
                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: baseCurrency,
                          }).format(performance.grossRevenue)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Orders
            </Typography>
            <div
              style={{
                paddingTop: 10,
                height: "calc(100vh - 224px)",
                minHeight: 400,
              }}
            >
              <DataGrid
                disableColumnMenu
                rows={orders}
                columns={columns}
                pageSize={100}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ReportMachinesPage.get = ({ store, query }) => {
  return store.dispatch(
    customReadRequest("limitedmachinereport", { ...query })
  );
};

export default ReportMachinesPage;
