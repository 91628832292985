/* eslint camelcase: ["error", {allow: ["date_gte", "date_lte"]}] */
import { useCallback, useMemo, useState } from "react";
import { parse, stringify } from "qs";
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MuiLink from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconNavigateBefore from "@mui/icons-material/NavigateBefore";
import IconNavigateNext from "@mui/icons-material/NavigateNext";
import { customReadRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import { TableHead } from "@mui/material";

const StyledMuiLink = styled(MuiLink)({
  display: "block",
  maxWidth: "150px",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const ReportMachineRevenuePage = () => {
  const [sortOrder, setSortOrder] = useState("asc");
  const [sort, setSort] = useState("");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryDate = searchParams.get("date");
  const query = parse(location.search.substr(1));

  const { report, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      report: fromCustom.get(state, `machinerevenuereport`),
    }),
    shallowEqual
  );

  const handleDateChange = useCallback(
    (value) => {
      searchParams.set("date", new Date(value).toISOString());
      setSearchParams(searchParams);
    },
    [setSearchParams, searchParams]
  );

  const handleFeaturedChange = useCallback(() => {
    const current = searchParams.get("featured");
    current
      ? searchParams.delete("featured")
      : searchParams.set("featured", true);
    setSearchParams(searchParams);
  }, [setSearchParams, searchParams]);

  const { prevDate, currentDate, nextDate, disabledNext } = useMemo(() => {
    let date = new Date(queryDate ? queryDate : Date.now());
    date.setDate(1);
    date.setMonth(0);

    const prevDate = new Date(date.setFullYear(date.getFullYear() - 1));
    const currentDate = new Date(date.setFullYear(date.getFullYear() + 1));
    const nextDate = new Date(date.setFullYear(date.getFullYear() + 1));

    const today = new Date();
    today.setDate(1);
    const disabledNext = date >= today;

    return {
      prevDate,
      currentDate,
      nextDate,
      disabledNext,
    };
  }, [queryDate]);

  const handleOrganizationsSortChange = useCallback((props) => {
    const { sort, order } = props;
    setSort(sort);
    setSortOrder(order);
  }, []);

  const sortComparator = useCallback(
    (report) => {
      if (!sort) return report;
      const compareDirection = sortOrder === "desc" ? -1 : 1;
      switch (sort) {
        case "name":
          report.machineReport.sort(
            (a, b) => a.name.localeCompare(b.name) * compareDirection
          );
          break;
        case "total":
          report.machineReport.sort(
            (a, b) => (a.total - b.total) * compareDirection
          );
          break;
        default:
          const index = report.dates.findIndex((item) => item === sort);
          if (index === -1) return report;
          report.machineReport.sort((a, b) => {
            return (
              (a.report[index].grossRevenue - b.report[index].grossRevenue) *
              compareDirection
            );
          });
          break;
      }
      return report;
    },
    [sort, sortOrder]
  );

  const sortedReport = useMemo(() => {
    return sortComparator(report);
  }, [report, sortComparator]);

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "-8px -12px -8px 0",
          "@media print": {
            display: "none",
          },
          "& > *": {
            marginLeft: 2,
          },
        }}
        title={`Machines Gross Revenue Report(${new Date(
          report.startDate
        ).toLocaleDateString("en-US")}-${new Date(
          report.endDate
        ).toLocaleDateString("en-US")})`}
        action={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControlLabel
              label="Featured Only"
              control={
                <Checkbox
                  checked={!!searchParams.get("featured")}
                  onChange={() => handleFeaturedChange()}
                />
              }
            />
            <DatePicker
              disableFuture
              onChange={handleDateChange}
              openTo="year"
              value={currentDate}
              views={["year"]}
              renderInput={(props) => (
                <TextField size="small" variant="outlined" {...props} />
              )}
            />
            <Tooltip
              title={prevDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
              placement="top"
            >
              <IconButton
                component={NavLink}
                to={{
                  ...location,
                  search: `?${stringify(
                    {
                      ...query,
                      date: new Date(prevDate).toISOString(),
                    },
                    { arrayFormat: "repeat", skipNulls: true }
                  )}`,
                }}
                size="large"
              >
                <IconNavigateBefore />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={nextDate.toLocaleString("en-US", {
                month: "long",
                year: "numeric",
              })}
              placement="top"
            >
              <IconButton
                component={NavLink}
                to={{
                  ...location,
                  search: `?${stringify(
                    {
                      ...query,
                      date: new Date(nextDate).toISOString(),
                    },
                    { arrayFormat: "repeat", skipNulls: true }
                  )}`,
                }}
                disabled={disabledNext}
                style={disabledNext ? { pointerEvents: "none" } : {}}
                size="large"
              >
                <IconNavigateNext />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <div style={{ margin: "0 -16px -24px", paddingTop: 10 }}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              direction={sortOrder}
                              active={sort === "id"}
                              onClick={(e) =>
                                handleOrganizationsSortChange({
                                  sort: "id",
                                  order: sortOrder === "asc" ? "desc" : "asc",
                                })
                              }
                            >
                              ID
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              direction={sortOrder}
                              active={sort === "name"}
                              onClick={(e) =>
                                handleOrganizationsSortChange({
                                  sort: "name",
                                  order: sortOrder === "asc" ? "desc" : "asc",
                                })
                              }
                            >
                              Name
                            </TableSortLabel>
                          </TableCell>
                          {report.dates.map((date) => (
                            <TableCell key={date}>
                              <TableSortLabel
                                direction={sortOrder}
                                active={sort === date}
                                onClick={(e) =>
                                  handleOrganizationsSortChange({
                                    sort: date,
                                    order: sortOrder === "asc" ? "desc" : "asc",
                                  })
                                }
                              >
                                {date}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                          <TableCell>
                            <TableSortLabel
                              direction={sortOrder}
                              active={sort === "total"}
                              onClick={(e) =>
                                handleOrganizationsSortChange({
                                  sort: "total",
                                  order: sortOrder === "asc" ? "desc" : "asc",
                                })
                              }
                            >
                              Total
                            </TableSortLabel>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sortedReport.machineReport.map(
                          ({ id, name, report, total }) => (
                            <TableRow key={id}>
                              <TableCell>{id}</TableCell>
                              <TableCell>
                                <StyledMuiLink
                                  component={Link}
                                  to={`/machines/${id}`}
                                  underline="hover"
                                >
                                  {name}
                                </StyledMuiLink>
                              </TableCell>
                              {report.map(({ grossRevenue, date }) => (
                                <TableCell key={date}>
                                  {new Intl.NumberFormat("en-US", {
                                    style: "currency",
                                    currency: baseCurrency,
                                  }).format(grossRevenue)}
                                </TableCell>
                              ))}
                              <TableCell>
                                {new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: baseCurrency,
                                }).format(total)}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ReportMachineRevenuePage.get = ({ store, query }) =>
  store.dispatch(customReadRequest(`machinerevenuereport`, { ...query }));

export default ReportMachineRevenuePage;
