import * as React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import throttle from "lodash/throttle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MuiAppBar from "@mui/material/AppBar";
import MuiLink from "@mui/material/Link";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Breadcrumbs,
  DraftsMenu,
  NotificationsCenter,
  UserMenu,
} from "components";
import { hasPermission } from "services/permission";

const AppBar = ({ title = "", handleDrawerToggle }) => {
  const [disableShadow, setDisableShadow] = React.useState(
    window.scrollY === 0
  );

  React.useEffect(() => {
    const handleScroll = throttle(
      () => setDisableShadow(window.scrollY === 0),
      100
    );

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
      handleScroll.cancel();
    };
  }, []);

  return (
    <MuiAppBar
      color="primary"
      sx={{
        transition: (theme) =>
          theme.transitions.create(["box-shadow", "width"]),
        ...(disableShadow && {
          boxShadow: "none",
        }),
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={handleDrawerToggle}
          size="large"
          sx={(theme) => ({
            marginLeft: -1.5,
            marginRight: 2.5,
            [theme.breakpoints.up("lg")]: {
              display: "none",
            },
          })}
        >
          <MenuIcon />
        </IconButton>
        {title !== null && (
          <MuiLink
            component={NavLink}
            variant="h6"
            color="inherit"
            noWrap
            to="/"
            underline="hover"
            sx={{ flex: "0 1 auto" }}
          >
            {title}
          </MuiLink>
        )}
        <Breadcrumbs />
        <Box sx={{ flex: "1 1 auto" }} />
        {/* <AppSearch /> */}
        <DraftsMenu />
        {hasPermission("notifications", "GET") && <NotificationsCenter />}
        <UserMenu />
      </Toolbar>
    </MuiAppBar>
  );
};

AppBar.propTypes = {
  handleDrawerToggle: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default AppBar;
