import { shallowEqual, useSelector } from "react-redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import { hasPermission } from "services/permission";
import { customReadRequest } from "store/actions";
import { fromCustom } from "store/selectors";
import { TwilioForm } from "components";

const SettingsTwilioPage = () => {
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromCustom.get(state, "config/twilio"),
    }),
    shallowEqual
  );

  return (
    <Card elevation={0}>
      <CardHeader title="Set up Twilio" />
      <Divider />
      <TwilioForm
        initialValues={detail}
        hasPutPermission={hasPermission("config", "PUT")}
      />
    </Card>
  );
};

SettingsTwilioPage.get = ({ store }) => {
  return store.dispatch(customReadRequest("config/twilio"));
};

export default SettingsTwilioPage;
