import * as React from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { QRCodeSVG as QRCode } from "qrcode.react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import IconEdit from "@mui/icons-material/Edit";
import IconWalletTransaction from "@mui/icons-material/Toc";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { tips } from "services/helpers";
import { hasPermission } from "services/permission";
import {
  ArchiveButtons,
  BlockchainLink,
  Label,
  ReadonlyTextField,
  NotesList,
  ShortCard,
} from "components";

const WalletsReadPage = () => {
  const [open, setOpen] = React.useState(false);
  const [wallet, setWallet] = React.useState("");

  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDetail(state, "wallets", params.id),
    }),
    shallowEqual
  );

  const handleOpen = (wallet) => () => {
    setOpen(true);
    setWallet(wallet);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    active,
    address,
    archived,
    balance,
    createdAt,
    currency,
    id,
    name,
    provider,
    initialized,
    initializationError,
    updatedAt,
    wallets,
  } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={name}
        action={
          <>
            <ArchiveButtons resource="wallets" id={id} archived={archived} />
            {hasPermission("wallets", "PUT") && (
              <Button
                startIcon={<IconEdit />}
                component={Link}
                to={`/wallets/${id}/update`}
              >
                Edit
              </Button>
            )}
          </>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            {!initialized && (
              <Alert severity="error" sx={{ mb: 2 }}>
                <AlertTitle>Failed to initialize</AlertTitle>
                {initializationError}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  General
                </Typography>
                <ReadonlyTextField
                  label="Id"
                  value={id}
                  fullWidth
                  tooltip={tips.wallets.id}
                />
                <ReadonlyTextField
                  label="Name"
                  value={name}
                  fullWidth
                  tooltip={tips.wallets.name}
                />
                <ReadonlyTextField
                  label="Active"
                  value={
                    active ? (
                      <Label color="success">Yes</Label>
                    ) : (
                      <Label color="error">No</Label>
                    )
                  }
                  fullWidth
                  tooltip={tips.wallets.active}
                />
                <ReadonlyTextField
                  label="Archived"
                  value={
                    archived ? (
                      <Label color="error">Yes</Label>
                    ) : (
                      <Label color="info">No</Label>
                    )
                  }
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={moment(createdAt).format("L, LTS")}
                  fullWidth
                  tooltip={tips.wallets.createdAt}
                />
                <ReadonlyTextField
                  label="Updated At"
                  value={moment(updatedAt).format("L, LTS")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  Financial
                </Typography>
                <ReadonlyTextField
                  label="Provider"
                  value={provider}
                  fullWidth
                  tooltip={tips.wallets.provider}
                />
                <ReadonlyTextField
                  label="Currency"
                  value={currency}
                  fullWidth
                  tooltip={tips.wallets.currency}
                />
                <ReadonlyTextField
                  label="Public Address"
                  value={
                    <BlockchainLink wallet={address} coinType={currency} />
                  }
                  fullWidth
                  tooltip={tips.wallets.publicAddress}
                />
                <ReadonlyTextField
                  label="Balance"
                  value={balance}
                  fullWidth
                  tooltip={tips.wallets.balance}
                />
              </Grid>
              {wallets && wallets.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    Wallets
                  </Typography>
                  <Table
                    sx={(theme) => ({
                      border: `1px solid ${theme.palette.divider}`,
                    })}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "98%" }}>Address</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          Pending Balance
                        </TableCell>
                        <TableCell sx={{ width: "1%" }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {wallets.map((row) => (
                        <TableRow key={row.address}>
                          <TableCell>
                            <BlockchainLink
                              wallet={row.address}
                              coinType={row.currency}
                            />
                          </TableCell>
                          <TableCell>{row.balance}</TableCell>
                          <TableCell>{row.currency}</TableCell>
                          <TableCell>{row.pendingBalance}</TableCell>
                          <TableCell>
                            <Button onClick={handleOpen(row.address)} fullWidth>
                              View&nbsp;QR
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Wallet QR</DialogTitle>
                    <DialogContent>
                      <Typography variant="body1" gutterBottom>
                        {wallet}
                      </Typography>
                      <QRCode
                        id="wallet-qr-code"
                        value={wallet}
                        width={300}
                        height={300}
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="wallets" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Related links
                </Typography>
                <Grid container spacing={2}>
                  {hasPermission("wallettransactions", "GET") && (
                    <Grid item xs={12} sm={6} xl={12}>
                      <ShortCard
                        icon={<IconWalletTransaction />}
                        to={`/wallettransactions?walletId=${id}`}
                        title="Transactions"
                        description="View transactions related to this wallet"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

WalletsReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("wallets", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "wallets",
        entityId: params.id,
      })
    ),
  ]);
};

export default WalletsReadPage;
