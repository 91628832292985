import { Fragment } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form } from "react-final-form";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { handleError, trim } from "services/helpers";
import { Autocomplete, TextField, DateTimePicker } from "mui-rff";
import { createValidator, required, minLength } from "services/validation";
import { notificationSend, resourceCreateRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { DiffBlock, Confirmation } from "components";

const validate = createValidator({
  atmId: [required],
  message: [minLength(5), required],
  level: [required],
  category: [required],
  softwareVersion: [required],
});

const MachineEventForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { atms = [] } = useSelector(
    (state = {}) => ({
      atms: fromEntities.getList(state, "machines"),
    }),
    shallowEqual
  );

  const onSubmit = (values) => {
    const action = resourceCreateRequest("machineevents", values);

    return dispatch(action)
      .then(() => {
        dispatch(
          notificationSend(`Machine event created successfully`, {
            variant: "success",
          })
        );
        navigate("/machineevents");
      })
      .catch((error) => {
        dispatch(
          notificationSend(`Failed to  create machine event`, {
            variant: "error",
          })
        );
        return handleError(error);
      });
  };

  const initialValues = {
    atmId: null,
    category: "GENERAL",
    level: "error",
  };

  return (
    <Confirmation>
      {(show, confirm, hide, open) => (
        <Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({
              dirtySinceLastSubmit,
              form: { reset },
              handleSubmit,
              hasSubmitErrors,
              pristine,
              submitError,
              submitting,
              valid,
              values,
            }) => (
              <form onSubmit={show(handleSubmit)} noValidate autoComplete="off">
                <CardContent>
                  {hasSubmitErrors && (
                    <Typography color="error">{submitError}</Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        filterSelectedOptions
                        getOptionLabel={(item) =>
                          item ? `${item.name} (${item.id || item.atmId})` : ""
                        }
                        getOptionValue={(item) =>
                          item ? item.id || item.atmId : ""
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value
                        }
                        label="ATM"
                        name="atmId"
                        options={atms}
                        textFieldProps={{
                          fullWidth: true,
                          margin: "normal",
                          variant: "outlined",
                          required: true,
                        }}
                      />
                      <TextField
                        name="category"
                        label="Category"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        select
                      >
                        {[
                          "GENERAL",
                          "2D SCANNER",
                          "CAMERA",
                          "NV200",
                          "PRINTER",
                          "TOUCH SCREEN",
                        ].map((v) => (
                          <MenuItem key={v} value={v}>
                            {v}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        name="level"
                        label="Level"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        required
                        select
                      >
                        {["error", "warn", "info", "debug", "trace"].map(
                          (v) => (
                            <MenuItem key={v} value={v}>
                              {v.toUpperCase()}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      <TextField
                        name="message"
                        label="Message"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        multiline
                        required
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <TextField
                        name="softwareVersion"
                        label="Software Version"
                        placeholder="4.2.0"
                        type="text"
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        fieldProps={{ format: trim, formatOnBlur: true }}
                      />
                      <DateTimePicker
                        name="createdAt"
                        label="Occured on"
                        TextFieldProps={{ margin: "normal" }}
                        fullWidth
                        fieldProps={{
                          parse: (date) => new Date(date).getTime(),
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      pristine ||
                      submitting ||
                      (!valid && !dirtySinceLastSubmit)
                    }
                  >
                    Save
                  </Button>
                  <Button onClick={reset} disabled={pristine || submitting}>
                    Reset
                  </Button>
                </CardActions>
                <Dialog open={open}>
                  <DialogTitle>
                    Are you sure you want to save these changes?
                  </DialogTitle>
                  <DialogContent>
                    <DiffBlock {...{ initialValues, values }} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={hide}>Cancel</Button>
                    <Button onClick={confirm} color="primary" autoFocus>
                      Save
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            )}
          />
        </Fragment>
      )}
    </Confirmation>
  );
};

export default MachineEventForm;
