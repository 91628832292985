import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function RHFTextField({
  type,
  name,
  control,
  component: TextFieldComponent = TextField,
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, ref, ...restField },
        fieldState: { error },
      }) => (
        <TextFieldComponent
          {...restField}
          fullWidth
          margin="normal"
          variant="outlined"
          {...rest}
          value={value ?? ""}
          onChange={(ev) => {
            onChange(
              type === "number" && ev.target.value
                ? +ev.target.value
                : ev.target.value
            );
            if (typeof rest.onChange === "function") {
              rest.onChange(ev);
            }
          }}
          type={type}
          error={!!error}
          helperText={error ? error.message : rest.helperText}
          inputRef={ref}
        />
      )}
    />
  );
}
