import { Children, isValidElement, cloneElement } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import MuiTableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const StyledTableBody = styled(MuiTableBody, {
  shouldForwardProp: (prop) => prop !== "loading",
})(({ loading, theme }) => ({
  ...(loading && {
    transition: theme.transitions.create(["opacity"]),
    opacity: 0.54,
    pointerEvents: "none",
  }),
}));

const TableBody = (props) => {
  const {
    children,
    handleRowClick,
    isSelected,
    rows,
    TableRowProps,
    staticContext,
    ...other
  } = props;

  return (
    <StyledTableBody {...other}>
      {rows.map((row, index) => {
        const isRowSelected = isSelected(row.id);
        return (
          <TableRow key={row.id} selected={isRowSelected} {...TableRowProps}>
            {Children.map(children, (child) => {
              if (child.type.muiName === "TableColumn") {
                const { cell, header, ...other } = child.props;

                const cellProps = {
                  handleRowClick,
                  isSelected,
                  rowIndex: index,
                  ...other,
                };

                let content;
                if (isValidElement(child.props.cell)) {
                  content = cloneElement(child.props.cell, cellProps);
                } else if (typeof child.props.cell === "function") {
                  content = child.props.cell(cellProps);
                } else {
                  content = (
                    <TableCell {...other}>{child.props.cell}</TableCell>
                  );
                }
                return content;
              }
              return null;
            })}
          </TableRow>
        );
      })}
    </StyledTableBody>
  );
};

TableBody.propTypes = {
  children: PropTypes.array.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  staticContext: PropTypes.any,
  TableRowProps: PropTypes.object,
};

export default TableBody;
