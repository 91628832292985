import { useState } from "react";
import { pending } from "redux-saga-thunk";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconBorrow from "@mui/icons-material/MonetizationOn";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import MuiTable from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { customReadRequest, resourceListReadRequest } from "store/actions";
import { fromEntities, fromResource } from "store/selectors";
import { BlockchainLink, LoansForm, Table, TableColumn } from "components";

const LoansAvailableListPage = (props) => {
  const { items, loading } = useSelector(
    (state = {}) => ({
      // count: fromResource.getCount(state, 'loans/available'),
      items: fromEntities.getDenormalizedList(
        state,
        "loans/available",
        fromResource.getList(state, "loans/available")
      ),
      loading: pending(state, "loansAvailableListPage"),
    }),
    shallowEqual
  );

  // const dispatch = useDispatch()
  // const borrow = ({ amount, ruleId }) => dispatch(resourceCreateRequest('loans', { amount, ruleId }))

  const [open, setOpen] = useState(false);
  const [ruleId, setRuleId] = useState(null);

  const handleDialogOpen = (ruleId) => () => {
    setOpen(true);
    setRuleId(ruleId);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const amountsTotal = {};

  items.forEach(({ currency, amount }) => {
    if (amountsTotal[currency] !== undefined) amountsTotal[currency] += amount;
    else amountsTotal[currency] = amount || 0;
  });

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={items.length}
        rows={items}
        title="Available Loans"
      >
        <TableColumn
          header={() => (
            <TableCell sx={{ width: "98%" }}>Lender wallet</TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              <BlockchainLink
                wallet={items[rowIndex].lenderWallet}
                coinType={items[rowIndex].currency}
              />
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "1%" }}>
              Amount
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {Number(items[rowIndex].amount)}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Currency</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].currency}</TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ whiteSpace: "nowrap", width: "1%" }}>
              Fee (%)
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{`${
              items[rowIndex].percent * 100
            }%`}</TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell sx={{ width: "1%" }}>Period</TableCell>}
          cell={({ rowIndex }) => (
            <TableCell>{items[rowIndex].period}</TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              <div>
                {!items[rowIndex].active && (
                  <Tooltip enterDelay={500} title="Borrow" placement="top">
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<IconBorrow />}
                      onClick={handleDialogOpen(items[rowIndex].id)}
                    >
                      Borrow
                    </Button>
                  </Tooltip>
                )}
              </div>
            </TableCell>
          )}
        />
      </Table>
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
              Available Total:
            </TableCell>
            {Object.keys(amountsTotal).map((currency) => (
              <TableCell key={currency}>{`${parseFloat(
                amountsTotal[currency].toFixed(8)
              )} ${currency}`}</TableCell>
            ))}
          </TableRow>
        </TableHead>
      </MuiTable>
      {ruleId && (
        <LoansForm
          open={open}
          id={ruleId}
          handleDialogClose={handleDialogClose}
        />
      )}
    </Paper>
  );
};

LoansAvailableListPage.get = ({ store, query }) => {
  return Promise.all([
    store.dispatch(
      resourceListReadRequest("loans/available", { _limit: 100, ...query })
    ),
    store.dispatch(customReadRequest("loans/walletwhitelist")),
  ]);
};

export default LoansAvailableListPage;
