import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import intersection from "lodash/intersection";
import union from "lodash/union";
import MuiTable from "@mui/material/Table";
import { TableBody, TableHead, TableToolbar } from "components";

const Table = (props) => {
  const {
    children,
    loading,
    rows,
    tableBefore,
    title,
    toolbar,
    toolbarAlt,
    TableRowProps,
    TableBodyProps,
  } = props;

  const [selected, setSelected] = useState([]);

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      setSelected((selected) =>
        union(
          selected,
          rows.map((n) => n.id)
        )
      );
      return;
    }
    setSelected([]);
  };

  // handleKeyDown = (event, id) => {
  //   if (keycode(event) === 'space') {
  //     this.handleRowClick(event, id)
  //   }
  // }

  const handleRowClick = (id) => () => {
    setSelected((selected) => {
      const selectedIndex = selected.indexOf(id);
      const selectedCopy = selected.slice(0);

      if (selectedIndex === -1) {
        selectedCopy.push(id);
      }
      if (selectedIndex >= 0 && selectedIndex <= selected.length - 1) {
        selectedCopy.splice(selectedIndex, 1);
      }

      return selectedCopy;
    });
  };

  const isSelected = (id) => {
    return selected.indexOf(id) !== -1;
  };

  const rowsIds = rows.map((row) => row.id);
  const intersectionIds = intersection(rowsIds, selected);

  const checked =
    intersectionIds.length > 0 && intersectionIds.length === rows.length;
  const indeterminate =
    intersectionIds.length > 0 && intersectionIds.length < rows.length;

  return (
    <Fragment>
      <TableToolbar
        title={title}
        numSelected={selected.length}
        content={toolbar}
        contentAlt={toolbarAlt}
      />
      {tableBefore}
      <div style={{ overflow: "auto" }}>
        <MuiTable>
          <TableHead
            checked={checked}
            indeterminate={indeterminate}
            handleSelectAllClick={handleSelectAllClick}
            TableRowProps={TableRowProps}
          >
            {children}
          </TableHead>
          <TableBody
            handleRowClick={handleRowClick}
            isSelected={isSelected}
            rows={rows}
            loading={loading}
            TableRowProps={TableRowProps}
            {...TableBodyProps}
          >
            {children}
          </TableBody>
        </MuiTable>
      </div>
    </Fragment>
  );
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  rows: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  tableBefore: PropTypes.node,
  toolbar: PropTypes.node,
  toolbarAlt: PropTypes.node,
  TableRowProps: PropTypes.object,
  TableBodyProps: PropTypes.object,
};

export default Table;
