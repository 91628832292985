import React, { Fragment, useState } from "react";
import clsx from "clsx";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconRepay from "@mui/icons-material/MonetizationOn";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import MuiTable from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import IconView from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import {
  resourceListReadRequest,
  resourceCreateRequest,
  notificationSend,
} from "store/actions";
import { fromCustom, fromEntities, fromResource } from "store/selectors";
import {
  BlockchainLink,
  Table,
  TableActions,
  TableColumn,
  Label,
  TablePagination,
} from "components";

const LoansCurrentListPage = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [repaying, setRepaying] = useState(false);

  const { count, items, loading, baseCurrency } = useSelector(
    (state = {}) => ({
      baseCurrency: fromCustom.getBaseCurrency(state),
      count: fromResource.getCount(state, "loans/history"),
      items: fromEntities.getDenormalizedList(
        state,
        "loans/history",
        fromResource.getList(state, "loans/history")
      ),
      loading: pending(state, "loans/historyListRead"),
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const repayLoan = (id) =>
    dispatch(resourceCreateRequest(`loans/${id}/repay`));
  const loadLons = () =>
    dispatch(resourceListReadRequest("loans/history", { _limit: 100 }));
  const notification = (message, options) =>
    dispatch(notificationSend(message, options));

  const handleDialogOpen = (selected) => () => {
    setSelected(selected);
    setOpen(true);
  };

  const handleRepayLoan = () => {
    setRepaying(true);
    repayLoan(selected)
      .then(() => {
        notification(`Loan #${selected} repaid successfuly`, {
          variant: "success",
        });
      })
      .catch(() => {
        notification(`Failed to repay loan #${selected}`, { variant: "error" });
      })
      .finally(() => {
        setOpen(false);
        loadLons();
      });
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleExitedDialog = () => {
    setOpen(false);
    setSelected(null);
    setRepaying(false);
  };

  const amountsTotal = {};
  items.forEach(({ status, currency, amount }) => {
    if (status === "ACTIVE" || status === "NO FUNDS") {
      if (amountsTotal[currency] !== undefined)
        amountsTotal[currency] += amount;
      else amountsTotal[currency] = amount;
    }
  });

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Table
          TableRowProps={{ hover: true }}
          loading={loading}
          totalRows={items.length}
          rows={items}
          title="Current Loans"
          tableBefore={
            Object.keys(amountsTotal).length > 0 && (
              <MuiTable>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                      Borrowed Total:
                    </TableCell>
                    {Object.keys(amountsTotal).map((currency) => (
                      <TableCell key={currency}>
                        {`${parseFloat(
                          amountsTotal[currency].toFixed(8)
                        )} ${currency}`}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
              </MuiTable>
            )
          }
        >
          <TableColumn
            header={() => <TableCell sx={{ width: "1%" }}>Id</TableCell>}
            cell={({ rowIndex }) => <TableCell>{items[rowIndex].id}</TableCell>}
          />
          <TableColumn
            header={() => (
              <TableCell
                align="right"
                sx={{ whiteSpace: "nowrap", width: "5%" }}
              >
                Borrow Price
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: baseCurrency,
                }).format(items[rowIndex].borrowPrice)}
              </TableCell>
            )}
          />
          <TableColumn
            header={() => (
              <TableCell
                align="right"
                sx={{ whiteSpace: "nowrap", width: "5%" }}
              >
                Repay Price
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: baseCurrency,
                }).format(items[rowIndex].repayPrice)}
              </TableCell>
            )}
          />
          <TableColumn
            header={() => (
              <TableCell align="right" sx={{ width: "9%" }}>
                Cost
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: baseCurrency,
                }).format(
                  ["CLOSED", "REPAID"].includes(items[rowIndex].status)
                    ? (
                        items[rowIndex].repayCost - items[rowIndex].borrowCost
                      ).toFixed(2)
                    : items[rowIndex].borrowCost
                )}
              </TableCell>
            )}
          />
          <TableColumn
            header={() => (
              <TableCell
                align="right"
                sx={{ whiteSpace: "nowrap", width: "5%" }}
              >
                Borrowed Amount
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                <BlockchainLink
                  value={items[rowIndex].amount}
                  transaction={items[rowIndex].borrowTx}
                  coinType={items[rowIndex].currency}
                  nowrap
                />
              </TableCell>
            )}
          />
          <TableColumn
            header={() => (
              <TableCell sx={{ width: "15%" }}>Lender Wallet</TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell>
                <BlockchainLink
                  wallet={items[rowIndex].lenderWallet}
                  coinType={items[rowIndex].currency}
                  short
                />
              </TableCell>
            )}
          />
          <TableColumn
            header={() => <TableCell align="right">Repay Amount</TableCell>}
            cell={({ rowIndex }) => (
              <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                {items[rowIndex].repayTx ? (
                  <BlockchainLink
                    value={
                      items[rowIndex].amount * (1 + items[rowIndex].percent)
                    }
                    transaction={items[rowIndex].repayTx}
                    coinType={items[rowIndex].currency}
                    nowrap
                  />
                ) : (
                  items[rowIndex].amount * (1 + items[rowIndex].percent)
                )}
              </TableCell>
            )}
          />
          <TableColumn
            header={() => <TableCell sx={{ width: "1%" }}>Currency</TableCell>}
            cell={({ rowIndex }) => (
              <TableCell>{items[rowIndex].currency}</TableCell>
            )}
          />
          <TableColumn
            header={() => (
              <TableCell
                align="right"
                sx={{ whiteSpace: "nowrap", width: "1%" }}
              >
                Fee (%)
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell align="right">{`${
                items[rowIndex].percent * 100
              }%`}</TableCell>
            )}
          />
          <TableColumn
            header={() => <TableCell sx={{ width: "1%" }}>Period</TableCell>}
            cell={({ rowIndex }) => (
              <TableCell>{items[rowIndex].period}</TableCell>
            )}
          />
          <TableColumn
            header={({ getSortLink, order, sort }) => (
              <TableCell sx={{ width: "1%" }}>
                <TableSortLabel
                  active={sort === "status"}
                  component={Link}
                  direction={order}
                  to={getSortLink("status")}
                >
                  Status
                </TableSortLabel>
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell sx={{ py: 0 }}>
                <Label
                  color={clsx({
                    error:
                      items[rowIndex].status === "ACTIVE" ||
                      items[rowIndex].status === "NO FUNDS" ||
                      items[rowIndex].status === "ERROR",
                    warning: items[rowIndex].status === "REPAYING",
                    info: items[rowIndex].status === "REPAID",
                    success: items[rowIndex].status === "CLOSED",
                  })}
                  fullWidth
                >
                  {items[rowIndex].status}{" "}
                </Label>
              </TableCell>
            )}
          />

          <TableColumn
            header={({ getSortLink, order, sort }) => (
              <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Borrowed At
                </TableSortLabel>
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell sx={{ py: 0, whiteSpace: "break-spaces" }}>
                {items[rowIndex].createdAt ? (
                  moment(items[rowIndex].createdAt).format("L \nLTS")
                ) : (
                  <i>[N/A]</i>
                )}
              </TableCell>
            )}
          />
          <TableColumn
            header={({ getSortLink, order, sort }) => (
              <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
                <TableSortLabel
                  active={sort === "repayDate"}
                  component={Link}
                  direction={order}
                  to={getSortLink("repayDate")}
                >
                  Repay date
                </TableSortLabel>
              </TableCell>
            )}
            cell={({ rowIndex }) => (
              <TableCell sx={{ py: 0, whiteSpace: "break-spaces" }}>
                {items[rowIndex].repayDate ? (
                  moment(items[rowIndex].repayDate).format(`L \nLTS`)
                ) : (
                  <i>[N/A]</i>
                )}
              </TableCell>
            )}
          />
          <TableColumn
            header={() => <TableCell sx={{ padding: 0, width: "1%" }} />}
            cell={({ rowIndex }) => (
              <TableCell sx={{ padding: 0 }}>
                {(items[rowIndex].status === "ACTIVE" ||
                  items[rowIndex].status === "NO FUNDS" ||
                  items[rowIndex].status === "ERROR") && (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<IconRepay />}
                    onClick={handleDialogOpen(items[rowIndex].id)}
                  >
                    Repay
                  </Button>
                )}
              </TableCell>
            )}
          />
          <TableColumn
            header={() => <TableCell sx={{ width: "1%" }} />}
            cell={({ rowIndex }) => (
              <TableCell padding="none">
                <TableActions>
                  <Tooltip
                    enterDelay={500}
                    title="View details"
                    placement="top"
                  >
                    <IconButton
                      to={`/loans/history/${items[rowIndex].id}`}
                      component={Link}
                      size="large"
                    >
                      <IconView />
                    </IconButton>
                  </Tooltip>
                </TableActions>
              </TableCell>
            )}
          />
        </Table>
        <TablePagination count={count} />
      </Paper>
      <Dialog
        open={open}
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
        TransitionProps={{
          onExited: handleExitedDialog,
        }}
      >
        <DialogTitle>
          Repay loan <b>#{selected}</b>?
          {repaying && (
            <CircularProgress size={32} style={{ float: "right" }} />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ marign: 0 }}>
            {!repaying && (
              <div>
                You are about to repay loan.
                <br />
                Would you like to proceed?
              </div>
            )}
            {repaying && (
              <div>
                Repaying loan.
                <br />
                Please wait while it process.
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!repaying && (
            <Fragment>
              <Button onClick={handleDialogClose}>Cancel</Button>
              <Button onClick={handleRepayLoan} color="primary" autoFocus>
                Repay
              </Button>
            </Fragment>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

LoansCurrentListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("loans/history", { _limit: 100, ...query })
  );
};

export default LoansCurrentListPage;
