import { Fragment } from "react";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  resourceDetailReadRequest,
  resourceListReadRequest,
} from "store/actions";
import { fromEntities } from "store/selectors";
import { NotesList, ReadonlyTextField } from "components";

const PhoneBlacklistReadPage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDenormalizedDetail(
        state,
        "phoneblacklist",
        params.id
      ),
    }),
    shallowEqual
  );

  const { id, createdAt, updatedAt, phone, creator } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={
          <Fragment>
            Phone Blacklist
            <Typography
              variant="inherit"
              color="textSecondary"
              display="inline"
            >{`#${id}`}</Typography>
          </Fragment>
        }
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6">General</Typography>
                <ReadonlyTextField label="Id" value={id} fullWidth />
                <ReadonlyTextField label="Phone" value={phone} fullWidth />
                <ReadonlyTextField
                  label="Creator"
                  value={creator ? creator.userName : "missing"}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Created At"
                  value={moment(createdAt).format("L, LTS")}
                  fullWidth
                />
                <ReadonlyTextField
                  label="Updated At"
                  value={moment(updatedAt).format("L, LTS")}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <NotesList entityType="phoneblacklist" entityId={id} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={3}></Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PhoneBlacklistReadPage.get = ({ store, params }) => {
  return Promise.all([
    store.dispatch(resourceDetailReadRequest("phoneblacklist", params.id)),
    store.dispatch(
      resourceListReadRequest("notes", {
        entity: "phoneblacklist",
        entityId: params.id,
      })
    ),
  ]);
};

export default PhoneBlacklistReadPage;
