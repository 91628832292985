import { useSelector, shallowEqual } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import IconView from "@mui/icons-material/RemoveRedEye";
import { resourceDetailReadRequest } from "store/actions";
import { fromEntities } from "store/selectors";
import { ArchiveButtons, WalletForm } from "components";

const WalletsUpdatePage = () => {
  const params = useParams();
  const { detail } = useSelector(
    (state = {}) => ({
      detail: fromEntities.getDetail(state, "wallets", params.id),
    }),
    shallowEqual
  );
  const { id, name, archived } = detail;

  return (
    <Card elevation={0}>
      <CardHeader
        sx={{ margin: "-3px 0" }}
        title={name}
        action={
          <>
            <ArchiveButtons resource="wallets" id={id} archived={archived} />
            <Button
              startIcon={<IconView />}
              component={Link}
              to={`/wallets/${id}`}
            >
              View
            </Button>
          </>
        }
      />
      <Divider />
      <WalletForm id={id} />
    </Card>
  );
};

WalletsUpdatePage.get = ({ store, params }) => {
  return store.dispatch(resourceDetailReadRequest("wallets", params.id));
};

export default WalletsUpdatePage;
