import { useState } from "react";
import moment from "moment";
import { pending } from "redux-saga-thunk";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconAdd from "@mui/icons-material/Add";
import IconEdit from "@mui/icons-material/Edit";
import IconFilter from "@mui/icons-material/FilterList";
import { resourceListReadRequest } from "store/actions";
import { hasPermission } from "services/permission";
import { fromEntities, fromResource } from "store/selectors";
import { tips } from "services/helpers";
import {
  BlockchainLink,
  Label,
  Filter,
  Table,
  TableActions,
  TableColumn,
  TablePagination,
} from "components";

const filters = {
  archived: {
    label: "Show archived",
    type: "boolean",
  },
};

const AutoPaymentsRecipientsListPage = () => {
  const [filterOpen, setFilterOpen] = useState(false);

  const { count, items, loading } = useSelector(
    (state = {}) => ({
      count: fromResource.getCount(state, "autopaymentsrecipients"),
      items: fromEntities.getList(
        state,
        "autopaymentsrecipients",
        fromResource.getList(state, "autopaymentsrecipients")
      ),
      loading: pending(state, "autopaymentsrecipientsListRead"),
    }),
    shallowEqual
  );

  const handleFilterOpen = () => {
    setFilterOpen(true);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  return (
    <Paper elevation={0}>
      <Table
        TableRowProps={{ hover: true }}
        loading={loading}
        totalRows={count}
        rows={items}
        title="Recipients"
        toolbar={
          <>
            {hasPermission("autopaymentsrecipients", "POST") && (
              <Button
                startIcon={<IconAdd />}
                component={Link}
                to="/autopayments/recipients/create"
              >
                Add Payee
              </Button>
            )}
            <Button startIcon={<IconFilter />} onClick={handleFilterOpen}>
              Filter
            </Button>
          </>
        }
        tableBefore={
          <Filter
            filters={filters}
            open={filterOpen}
            onClose={handleFilterClose}
          />
        }
      >
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "id"}
                component={Link}
                direction={order}
                to={getSortLink("id")}
              >
                Id
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].id}</TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsReceipents.name} arrow>
                <TableSortLabel
                  active={sort === "name"}
                  component={Link}
                  direction={order}
                  to={getSortLink("name")}
                >
                  Name
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              {items[rowIndex].name || (
                <Typography variant="caption" color="textSecondary">
                  Anonymous
                </Typography>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "98%" }}>
              <Tooltip title={tips.autopaymentsReceipents.wallet} arrow>
                <TableSortLabel
                  active={sort === "wallet"}
                  component={Link}
                  direction={order}
                  to={getSortLink("wallet")}
                >
                  Wallet
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell>
              <BlockchainLink
                wallet={items[rowIndex].wallet}
                coinType={items[rowIndex].currency}
              />
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ width: "1%" }}>
              <TableSortLabel
                active={sort === "active"}
                component={Link}
                direction={order}
                to={getSortLink("active")}
              >
                Active
              </TableSortLabel>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].active ? (
                <Label color="success" fullWidth>
                  Yes
                </Label>
              ) : (
                <Label color="error" fullWidth>
                  No
                </Label>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsReceipents.period} arrow>
                <span>Period</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">{items[rowIndex].period}</TableCell>
          )}
        />
        <TableColumn
          header={() => (
            <TableCell align="right" sx={{ width: "1%" }}>
              <Tooltip title={tips.autopaymentsReceipents.percent} arrow>
                <span>Percent/Amount</span>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell align="right">
              {items[rowIndex].paymentType === "PERCENT"
                ? `${items[rowIndex].percent}%`
                : `${items[rowIndex].flatAmount} ${items[rowIndex].currency}`}
            </TableCell>
          )}
        />
        <TableColumn
          header={({ getSortLink, order, sort }) => (
            <TableCell sx={{ whiteSpace: "nowrap", width: "1%" }}>
              <Tooltip title={tips.autopaymentsReceipents.createdAt} arrow>
                <TableSortLabel
                  active={sort === "createdAt"}
                  component={Link}
                  direction={order}
                  to={getSortLink("createdAt")}
                >
                  Created At
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          )}
          cell={({ rowIndex }) => (
            <TableCell sx={{ py: 0 }}>
              {items[rowIndex].createdAt ? (
                moment(items[rowIndex].createdAt).format("L \nLTS")
              ) : (
                <i>[missing]</i>
              )}
            </TableCell>
          )}
        />
        <TableColumn
          header={() => <TableCell />}
          cell={({ rowIndex }) => (
            <TableCell padding="none">
              <TableActions>
                {hasPermission("autopaymentsrecipients", "PUT") && (
                  <Tooltip enterDelay={500} title="Edit" placement="top">
                    <IconButton
                      to={`/autopayments/recipients/${items[rowIndex].id}/update`}
                      component={Link}
                      size="large"
                    >
                      <IconEdit />
                    </IconButton>
                  </Tooltip>
                )}
              </TableActions>
            </TableCell>
          )}
        />
      </Table>
      <TablePagination count={count} />
    </Paper>
  );
};

AutoPaymentsRecipientsListPage.get = ({ store, query }) => {
  return store.dispatch(
    resourceListReadRequest("autopaymentsrecipients", { _limit: 100, ...query })
  );
};

export default AutoPaymentsRecipientsListPage;
